export const m02Fields = [
    { identifier: 'm02-cta-bg', type: 'input', field: 'm02-cta-bg' },
    { identifier: 'm02-cta-color', type: 'input', field: 'm02-cta-color' },
    {
        identifier: 'm02-cta-font-weight',
        type: 'input',
        field: 'm02-cta-font-weight'
    },
    {
        identifier: 'm02-cta-font-size',
        type: 'input',
        field: 'm02-cta-font-size'
    },
    {
        identifier: 'm02-cta-bg-hover',
        type: 'input',
        field: 'm02-cta-bg-hover'
    },
    {
        identifier: 'm02-cta-color-hover',
        type: 'input',
        field: 'm02-cta-color-hover'
    }
]
