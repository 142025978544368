export const jsonMapping = (value: any) => {

        return {
            username: value.username,
            is_active: value.is_active,
            customer: {
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email,
                phoneNumber: value.phoneNumber
            },
            address: [
                {
                    company: value.company,
                    street: value.street,
                    postcode: value.postcode,
                    city: value.city,
                    country_code: value.country_code
                }
            ]
        }
}


export const jsonMappingEdit = (value: any) => {

        return {
            username: value.username,
            is_active: value.is_active,
            id: value.userId,
            customer: {
                id: value.customer_id,
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email,
                phoneNumber: value.phoneNumber
            },
            address: [
                {
                    id: value.address_id,
                    company: value.company,
                    street: value.street,
                    postcode: value.postcode,
                    city: value.city,
                    country_code: value.country_code
                }
            ]
        }
}
