export interface config {
    titel: string
    datum?: any
    content: string
    mediaId: string
    visible?: any
}

export function output(Data: any) {
    let back = {
        titel: Data.titel,
        datum: Data.datum,
        content: Data.content,
        mediaId: Data.mediaId,
        visible: Data.visible
    }

    return back
}
