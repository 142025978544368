const Products = (form: any) => {
    let FormDatas = []
    let FormControll = []
    let inputs = form.elements
    // Iterate over the form controlsc
    for (let i = 0; i < inputs.length; i++) {
        // Disable all form controls
        if (inputs[i].hasAttribute('name')) {
            if (
                inputs[i].getAttribute('name').includes('elements') &&
                inputs[i].getAttribute('name').includes('Products') &&
                !inputs[i].getAttribute('name').includes('seo')
            ) {
                FormDatas.push(inputs[i].getAttribute('name'))
                FormControll.push(i)
            }
        }
    }

    if (FormDatas.length > 0) {
        let elementsdataTranslation: string[] = []
        let Products = []

        for (let ft = 0; ft < FormDatas.length; ft++) {
            if (
                FormDatas[ft].includes('elements') &&
                FormDatas[ft].includes('Products') &&
                !FormDatas[ft].includes('seo')
            ) {
                let preValue
                let type: any = FormDatas[ft].split('[')
                preValue = (type[0] +
                    '[' +
                    type[1] +
                    '[' +
                    type[2] +
                    '[' +
                    ft +
                    '][' +
                    type[4]) as string
                FormDatas[ft] = preValue

                elementsdataTranslation[ft] += FormDatas[ft] + ','
                inputs[FormControll[ft]].setAttribute('name', preValue)
            }
        }

        for (let t = 0; t < elementsdataTranslation.length; t++) {
            if (typeof elementsdataTranslation[t] !== 'undefined') {
                let filtered = elementsdataTranslation[t]
                    .replace('undefined', '')
                    .split(',')
                    .filter(function (el) {
                        return el !== ''
                    })
                Products.push(filtered)
            }
        }

        return Products
    } else {
        return []
    }
}

export default Products
