import {CSSProperties, JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Form from 'react-bootstrap/Form';
import WK24Label from "./Elements/Label";
import WK24FormGroup from "./Elements/FormGroup";
import WK24Select from "./Elements/Select";
import Wk24FloatingLabel from "./Elements/FloatingLabel";
import WK24InputGroupText from "./Elements/InputGroupText";
import WK24FormText from "./Elements/FormText";
import WK24FormControl from "./Elements/FormControl";
import WK24ControlFeedback from "./Elements/ControlFeedback";
import WK24Checks from "./Elements/Check";
import WK24InputGroup from "./Elements/InputGroup";

export interface UniFormProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    /** Passes a Boolean whether validation should be carried out. */
    validated: boolean,
    /** Passes the function for the submit event. */
    Submit: (event: any) => void
    /** Set custom Classes on Element */
    className?:string
    /** Set id on Element in DOM */
    id?: string
    /** Set inline style for Element */
    style?:CSSProperties | undefined
}

/**
 * Wk24Form <br/>
 * ```tsx
 * <Wk24Form validated={validated} Submit={handleSubmit}>
 *          .....
 * </Wk24Form>
 * ```
 * Initializes the form. <br/>
 * The following modules can be used within the form
 * <table><tr><th>Module</th><th>Namespace</th></tr>
 * <tr><td>WK24Input</td><td>Wk24Form.FormControl</td></tr>
 * <tr><td> WK24Label</td><td>Wk24Form.Label</td></tr>
 * <tr><td> WK24FormGroup</td><td> Wk24Form.FormGroup </td></tr>
 * <tr><td> WK24FormControl</td><td> Wk24Form.InputGroup</td></tr>
 * <tr><td> WK24InputGroupText</td><td> Wk24Form.InputGroupText</td></tr>
 * </table><br/>
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const UniForm = (props: UniFormProps) => {

    return <Form noValidate validated={props.validated} onSubmit={props.Submit} id={props.id} className={props.className} style={props.style}>{props.children}</Form>

}

UniForm.FormControl = WK24FormControl
UniForm.Label = WK24Label
UniForm.FormGroup = WK24FormGroup
UniForm.InputGroup = WK24InputGroup
UniForm.InputGroupText = WK24InputGroupText
UniForm.Select = WK24Select
UniForm.FloatingLabel = Wk24FloatingLabel
UniForm.Text = WK24FormText
UniForm.ControlFeedback = WK24ControlFeedback
UniForm.Checks = WK24Checks

export default UniForm;

