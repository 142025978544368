import Alert from "react-bootstrap/Alert";
import ReactDOM from "react-dom";

export const CreateMessage = (message:any) => {

    setTimeout(function(){
        const elementOld = document.getElementById("NotificationMessage");
        if(elementOld !== null) {
            elementOld.remove();
        }
        const elementOldHolder = document.getElementById("NotificationHolder");
        if(elementOldHolder !== null) {

        }},message.dismiss.duration);

    let Notification = (
        <>
            <Alert variant={message.type} className={'animateTop notificationPosition'+message.insert} id={'NotificationMessage'}  style={{visibility:"visible",transition: 'visibility  1s linear',}} dismissible={true}>
                <Alert.Heading>{message.title}</Alert.Heading>
                <p>
                    {message.message}
                </p>
            </Alert>
        </>
    )

    const elementOld = document.getElementById("NotificationMessage");
    if(elementOld !== null) {
        elementOld.remove();
    }
    const elementOldHolder = document.getElementById("NotificationHolder");
    if(elementOldHolder !== null) {
        elementOldHolder.remove();
    }

    const element = document.getElementById("NotificationMessage");
    if(element !== null) {
        element.remove()
    }
    let newElement = Notification
    let temp = document.createElement('div')
    temp.id = 'NotificationHolder'
    ReactDOM.render(newElement, temp)
    let ele = document.getElementById('notification') as HTMLDivElement
    if(ele !== null) {
        ele.appendChild(temp)
    }


//2024-01-03 PG-5
}
