import { useEffect, useState } from 'react'
import { outboundInterface } from '../request/outboundInterface'
import { outbound } from '../request/outbound'
import { error } from '../../../../../../@WUM/core/component/const/error.services'

/**
 * Sends response to Rest API for Blog Overview
 * @memberOf blog
 * @prop limit
 * @prop searchValue
 * @see {@link blogApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const Response = (search: any) => {
    const [posts, setPosts] = useState<outboundInterface[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await outbound
                .getPosts(search)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [search])

    return isError ? posts : posts
}
