import IntlMessage, {
    IntlMessageValue
} from '../../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    let config = [
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: (
                        <IntlMessage Store={Store} messageId="email.login" />
                    ),
                    type: 'text',
                    required: true,
                    placeholder: IntlMessageValue('de', 'email.provide', Store),
                    name: 'email',
                    value: newElement === false ? response.email : ''
                },
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            Store={Store}
                            messageId="common.name.first"
                        />
                    ),
                    type: 'text',
                    placeholder: IntlMessageValue(
                        'de',
                        'name.first.provide',
                        Store
                    ),
                    name: 'first_name',
                    value: newElement === false ? response.first_name : ''
                },
                {
                    formType: 'input',
                    type: 'text',
                    label: (
                        <IntlMessage
                            Store={Store}
                            messageId="common.name.last"
                        />
                    ),
                    placeholder: IntlMessageValue(
                        'de',
                        'name.last.provide',
                        Store
                    ),
                    name: 'last_name',
                    value: newElement === false ? response.last_name : ''
                },
                {
                    formType: 'input',
                    type: 'text',
                    label: <IntlMessage Store={Store} messageId="name.user" />,
                    placeholder: IntlMessageValue(
                        'de',
                        'name.user.provide',
                        Store
                    ),
                    name: 'username',
                    value: newElement === false ? response.username : ''
                }
            ]
        },
        {
            formType: 'input',
            type: newElement === false ? 'password' : 'hidden',
            label: <IntlMessage Store={Store} messageId="common.password" />,
            placeholder: IntlMessageValue('de', 'password.new.provide', Store),
            name: 'password',
            value: ''
        },
        {
            formType: 'fetchselect',
            local: 'MerchantAll',
            valueState: 'id',
            beginn: 'true',
            start: 0,
            type: 'MerchantAll',
            disabled: newElement === false ? 'true' : 'false',
            onClick_id: 'Roles',
            labelState: 'name',
            name: 'merchant_id',
            selected: newElement === false ? response.merchant_id : '',
            label: <IntlMessage Store={Store} messageId="common.merchants" />
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'fetchselect',
                    local: 'Roles',
                    valueState: 'code',
                    beginn: 'true',
                    start: 0,
                    type: 'Roles',
                    onClick_id: 'Roles',
                    labelState: 'name',
                    name: 'discr',
                    selected: newElement === false ? response.discr : '',
                    label: <IntlMessage Store={Store} messageId="group" />
                },
                {
                    formType: 'select',
                    label: (
                        <IntlMessage Store={Store} messageId="common.status" />
                    ),
                    name: 'is_active',
                    selected: newElement === false ? response.is_active : '',
                    options: [
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.activated',
                                Store
                            ),
                            value: 'true'
                        },
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.deactivated',
                                Store
                            ),
                            value: 'false'
                        }
                    ]
                },
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            Store={Store}
                            messageId="password.confirm.legend"
                        />
                    ),
                    type: 'password',
                    required: true,
                    name: 'controllpw',
                    placeholder: '',
                    value: ''
                }
            ]
        },
        {
            formType: 'input',
            label: 'locale_code',
            type: newElement === false ? 'hidden' : 'hidden',
            placeholder: IntlMessageValue('de', 'localcode.provide', Store),
            name: 'locale_code',
            value: newElement === false ? response.locale_code : ''
        }
    ]

    return config
}

export default configuration
