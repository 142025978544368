export const FooterFields = [
    {
        identifier: 'footer-background',
        type: 'input',
        field: 'footer-background'
    },
    {
        identifier: 'font-size-footer-nav',
        type: 'input',
        field: 'font-size-footer-nav'
    },
    {
        identifier: 'font-weight-footer-nav',
        type: 'input',
        field: 'font-weight-footer-nav'
    },
    {
        identifier: 'font-color-footer-nav',
        type: 'input',
        field: 'font-color-footer-nav'
    },
    { identifier: 'footer-width', type: 'input', field: 'footer-width' }
]
