import { ProductApiType } from './ProductApi.interface'
import { requests } from '../../../../../@WUM/core/RestFullApi/api'
import { ProductDetailApiType } from './ProductApiDetail.interface'
import { ProductVariantsDetailApiType } from './ProductVariantsApiDetail.interface'
import { ShippingApiType } from './ShippingApi.interface'
import { DataSheetApiType } from './DataSheetApi.interface'
import { DimensionApiType } from './DimensionApi.interface'
import { allTaxonApiType } from './allTacxonApi.interface'
import { DatenblattDetailApiType } from './DatenblattApi.interface'
import { TaxApiType } from './TaxApi.interface'
import { TaxApiDetail } from './TaxApiDetail.interface'
import { ImagesType } from './ImagesApi.interface'
//import {allUnitApiType} from '../unit/allUnitApi.interface';
import { allProductApiType } from './allProductApi.interface'

export const Product = {
    getPosts: (): Promise<ProductApiType[]> =>
        requests.get('/Product/AllProducts'),
    getPostsLimit: (page: any, search: any): Promise<ProductApiType[]> =>
        requests.get(`/Product/AllProducts/${page}/${search}`),
    getPostsLimitSearch: (page: any, search: any): Promise<ProductApiType[]> =>
        requests.get(`/Product/AllProducts/${page}/${search}`),
    getPostsLimitSearchMerchant: (
        page: any,
        merchant: any,
        search: any
    ): Promise<ProductApiType[]> =>
        requests.get(`/Product/AllProducts/${merchant}/${page}/${search}`),
    getAPost: (id: string): Promise<ProductDetailApiType[]> =>
        requests.get(`/Product/${id}/`),
    createPost: (post: ProductApiType): Promise<ProductApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<ProductDetailApiType> =>
        requests.put(`/Content/${id}/`, post),
    updatePostTax: (post: any, id: number): Promise<TaxApiDetail> =>
        requests.put(`/Tax/${id}/`, post),
    deletePost: (id: number): Promise<void> => requests.delete(`posts/${id}`),
    deletePostTax: (id: string): Promise<void> =>
        requests.delete(`/Tax/${id}/`),
    deletePostTaxon: (id: string): Promise<void> =>
        requests.delete(`SingleTaxon/${id}/`),
    getVariants: (id: number): Promise<ProductVariantsDetailApiType[]> =>
        requests.get(`/Product/Variant/${id}/`),
    getAllShipingTypes: (): Promise<ShippingApiType[]> =>
        requests.get(`/Product/AllShipingTypes/`),
    getAllTax: (): Promise<TaxApiType[]> => requests.get(`/Tax/All/`),
    //getAllUnit: (): Promise<allUnitApiType[]> => requests.get(`/Unit/All/`),
    getAllProducts: (): Promise<allProductApiType[]> =>
        requests.get(`/Product/ByName/`),
    getAPostTax: (id: string): Promise<TaxApiDetail[]> =>
        requests.get(`/Tax/${id}/`),
    getAllTaxLimit: (page: any, search: string): Promise<TaxApiType[]> =>
        requests.get(`/Tax/All/${page}/${search}`),
    getallTaxon: (): Promise<allTaxonApiType[]> =>
        requests.get(`/Product/allTaxon/Translation/0/`),
    getArcticle: (id: number): Promise<DataSheetApiType[]> =>
        requests.get(`/Product/Hauptattribute/${id}/`),
    getImages: (id: number): Promise<ImagesType[]> =>
        requests.get(`/Product/images/${id}/`),
    getDimension: (id: number): Promise<DimensionApiType[]> =>
        requests.get(`/Product/Abmessungen/${id}/`),
    getNutrient: (id: number): Promise<DimensionApiType[]> =>
        requests.get(`/Product/Nutrient/${id}/`),
    getDatenblatt: (id: number): Promise<DatenblattDetailApiType[]> =>
        requests.get(`/Product/Datenblatt/${id}/`)
}
