import React, { useState } from 'react'
import { SpinnerJSX } from '../../../../@WUM/core/component/const/Spinner'
import { useParams } from 'react-router-dom'
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import { useSelector } from 'react-redux'
import {
    ResponseCustomType,
    ResponseCustomTypes
} from '../API/response/customTypes'
import PielersTable from '../../../../@WUM/core/tablemaker'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'

const CustomTypesOverview = (value: any) => {
    const { page } = useParams<{ page: string; deleteID: string }>()
    let limit: any
    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }

    const Store = useSelector((state: any) => state)

    const debug = (
        page: string | undefined,
        modalShow: boolean,
        modalData: any[],
        setModalData: any,
        setModalShow: (
            value: ((prevState: boolean) => boolean) | boolean
        ) => void,
        setSearch: (value: ((prevState: boolean) => boolean) | boolean) => void,
        search: boolean,
        setSearchValue: any,
        destination: string
    ) => {
        return
    }
    const destination = 'customTypes'
    const namespace_search = 'customTypes_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponseCustomTypes(limit, searchValue)
    //const responseType = ResponseCustomType();
    const Single = ResponseCustomType()
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    debug(
        page,
        modalShow,
        modalData,
        setModalData,
        setModalShow,
        setSearch,
        search,
        setSearchValue,
        destination
    )

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['id', 'blueprint', 'type', 'template', 'slug'],
            type: ['string', 'string', 'string', 'string', 'string']
        },
        tableHeader: ['ID', 'blueprint', 'type', 'template', 'slug'],
        tableHeaderWidth: ['10%', '20%', '20%', '20%', '20%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit" />,
                <IntlMessage Store={Store} messageId="common.delete" />
            ],
            menuroute: ['/customTypes/Detail', '/customTypes/Delete/1'],
            menuParams: ['id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }

    /*if (typeof response !== "boolean" && response?.length > 0) {
    let TypeOption = [];
    if(responseType !== true && responseType?.length > 0) {
      for(let t=0;t<responseType.length;t++){
        TypeOption.push(
            <>
              <option value={responseType[t].id}>{ucFirst(responseType[t].type)}</option>
            </>
        )
      }
    }*/

    if (typeof response !== 'boolean' && response?.length > 0) {
        let Type = []
        //console.log(Single)
        if (typeof Single !== 'boolean' && Single?.length > 0) {
            //console.log(Single)
            for (let x = 0; x < Single.length; x++) {
                Type.push(
                    <>
                        <ListGroup.Item>
                            <p
                                className={'float-start m-0'}
                                onClick={() => console.log('overview: Team')}
                            >
                                {ucFirst(Single[x].type)}{' '}
                            </p>
                            {icons('Team', Single[x].id)}
                        </ListGroup.Item>
                    </>
                )
            }
        }

        return (
            <>
                {/*<SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} destinationField={<>
          <Form.Group as={Col} controlId={'typeid'} title={'Auswählen um neuen CustomType zu erstellen'}
                      name={'typeid'} onChange={(e) => switchURL(e)}>
            <Form.Label>CustomType</Form.Label>
            <Form.Select  aria-label="Filter" defaultValue={''}>
              <option value={'0'} />
              {TypeOption}
            </Form.Select> </Form.Group>
        </>} destinationindivudal={'typeid'} />*/}
                <Row>
                    <Col className={'col-2 '}>
                        <ListGroup
                            className={
                                ' react-contextmenu-wrapper MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded mb-3'
                            }
                        >
                            <ListGroup.Item>
                                <a
                                    role="button"
                                    href="/customTypes/Type/new/0"
                                    className=" btn btn-success"
                                >
                                    Create new Custom Type
                                </a>
                            </ListGroup.Item>
                            {Type}
                        </ListGroup>
                    </Col>
                    <Col className={'col-10'}>
                        <PielersTable Configuration={Configuration} />
                    </Col>
                </Row>
                {/*<PaginationJSX response={response} history={history} to={'overview'} handleSuccess={handleSucces} destination={destination} />*/}
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

/*
{% escapeSerilize("Text",customData.title,input) %}
{% escapeSerilize("Text",customData.title,suneditor) %}
 */
export default CustomTypesOverview

const ucFirst = (string: any) => {
    if (string === undefined || string === null) {
        return string
    }
    return string.substring(0, 1).toUpperCase() + string.substring(1)
}

const icons = (type: any, id: any) => {
    return (
        <>
            <p className={'float-end m-0'}>
                <span
                    className="material-icons border p-1"
                    style={{ cursor: 'cell' }}
                    onClick={() =>
                        (window.location.href = '/customTypes/new/' + id)
                    }
                >
                    add_circle_outline
                </span>{' '}
                <span
                    className="material-icons border p-1 "
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                        (window.location.href = '/customType/Detail/' + id)
                    }
                >
                    edit
                </span>
                <span
                    className="material-icons border p-1 "
                    style={{ cursor: 'not-allowed' }}
                    onClick={() =>
                        (window.location.href = '/customType/Delete/1/' + id)
                    }
                >
                    delete_forever
                </span>
            </p>
        </>
    )
}
