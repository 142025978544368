import React, { useEffect } from 'react'
import FormElements from '../../../../../@WUM/core/form-serilizer/modul'
import configuration from '../configuration/content'
import {
    MediaMap,
    newModul,
    SocialMap,
    TextMap
} from '../../../form-serilizer/modul/newModul'
import { isEmptyArray } from 'formik'
import { Accordion } from 'react-bootstrap'
import ElementsContentInterface from '../../../../../@WUM/core/component/core/interface/ElementsContent.interface'
import ElementsContentAppendInterface from '../../../../../@WUM/core/component/core/interface/ElementsContentAppend.interface'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import {
    EmailWebBuilderNav,
    Layout,
    WebBuilderNav
} from '../../../form-serilizer/modulWebBuilder/newModul'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import HomeIcon from '@mui/icons-material/Home'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import Sortable from 'sortablejs'

import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
    AccordionSummaryProps
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Widgets } from '../../../form-serilizer/modulWebBuilder/newModul'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'

const MUIAccordionElement = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

// wo kommt dieses element her
function append(props: ElementsContentAppendInterface) {
    let NewIndex

    if (isEmptyArray(props.element)) {
        if (props.response.elements !== undefined) {
            NewIndex = props.response.elements.length
        } else {
            NewIndex = 0
        }

        props.setIndex(NewIndex)
    } else {
        if (
            props.discr === 'row_start' ||
            props.discr === 'slider_start' ||
            props.discr === 'carouselItem_start'
        ) {
            NewIndex = props.Index + 2
        } else if (props.discr === 'container') {
            NewIndex = props.Index + 2
        } else {
            NewIndex = props.Index + 1
        }

        props.setIndex(NewIndex)
    }

    let para = newModul(
        props.discr,
        NewIndex,
        props.setdeleteElement,
        props.element,
        props.Store,
        props.galerie,
        props.galerieItems,
        props.col,
        props.row
    )
    let allElements = []
    for (let x = 0; x < props.element.length; x++) {
        allElements.push(props.element[x])
    }

    allElements.push(para)
    props.setElement(allElements)
}

function Content(props: ElementsContentInterface) {
    const [expanded, setExpanded] = React.useState<string | false>('panel1')

    let FooterTemplate =
        props.response.layout === 'dynamikFooter.html' ? true : false
    let HeaderTemplate =
        props.response.layout === 'dynamikHeader.html' ? true : false
    console.log(FooterTemplate)
    console.log(HeaderTemplate)
    const handleAccordianChange =
        (panel: string) =>
        (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false)
        }

    const element = props.elements
    const setElement = props.setElements
    //const respo = props.response
    const Store = useSelector((state: any) => state)

    // This function is triggered when the select changes
    /*    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (value !== 'false') {

            append({
                response: props.response,
                setElement: setElement,
                discr: value,
                element: element,
                Index: props.Index,
                setIndex: props.setIndex,
                setdeleteElement: props.setdeleteElement,
                Store,
                galerie: props.galerie,
                galerieItems: props.galerieItems
            })

            let setselect = document.getElementById('newElement');
            if (setselect != null) {
                setselect.getElementsByTagName('option')[0].selected = Boolean(true)
            }
            let setselectMediaMap = document.getElementById('newElementMediaMap');
            if (setselectMediaMap != null) {
                setselectMediaMap.getElementsByTagName('option')[0].selected = Boolean(true)
            }
            let setselectSocialMap = document.getElementById('newElementSocialMap');
            if (setselectSocialMap != null) {
                setselectSocialMap.getElementsByTagName('option')[0].selected = Boolean(true)
            }
        }
    };*/

    const selectClick = (value: any = 'false') => {
        if (value !== 'false') {
            append({
                response: props.response,
                setElement: setElement,
                discr: value.value,
                element: element,
                Index: props.Index,
                col: value.col,
                row: value.row,
                setIndex: props.setIndex,
                setdeleteElement: props.setdeleteElement,
                Store,
                galerie: props.galerie,
                galerieItems: props.galerieItems
            })

            let setselect = document.getElementById('newElement')
            if (setselect != null) {
                setselect.getElementsByTagName('option')[0].selected =
                    Boolean(true)
            }
            let setselectMediaMap =
                document.getElementById('newElementMediaMap')
            if (setselectMediaMap != null) {
                setselectMediaMap.getElementsByTagName('option')[0].selected =
                    Boolean(true)
            }
            let setselectSocialMap = document.getElementById(
                'newElementSocialMap'
            )
            if (setselectSocialMap != null) {
                setselectSocialMap.getElementsByTagName('option')[0].selected =
                    Boolean(true)
            }
        }
    }

    const selectClickLayout = (value: any = 'false') => {
        let setRow = document.getElementById('layoutRow') as HTMLSelectElement
        let setRow1Col = document.getElementById(
            'layoutRow1Cols'
        ) as HTMLSelectElement
        let setRow2Col = document.getElementById(
            'layoutRow2Cols'
        ) as HTMLSelectElement
        let setRow3Col = document.getElementById(
            'layoutRow3Cols'
        ) as HTMLSelectElement
        let setRow4Col = document.getElementById(
            'layoutRow4Cols'
        ) as HTMLSelectElement
        let setRow5Col = document.getElementById(
            'layoutRow5Cols'
        ) as HTMLSelectElement
        let setRow6Col = document.getElementById(
            'layoutRow6Cols'
        ) as HTMLSelectElement

        let Cols = [
            setRow1Col.value,
            setRow2Col.value,
            setRow3Col.value,
            setRow4Col.value,
            setRow5Col.value,
            setRow6Col.value
        ]
        //console.log(Cols)
        if (value !== 'false') {
            append({
                response: props.response,
                setElement: setElement,
                discr: value.value,
                element: element,
                Index: props.Index,
                col: Cols,
                row: setRow.value,
                setIndex: props.setIndex,
                setdeleteElement: props.setdeleteElement,
                Store,
                galerie: props.galerie,
                galerieItems: props.galerieItems
            })

            let setselect = document.getElementById('newElement')
            if (setselect != null) {
                setselect.getElementsByTagName('option')[0].selected =
                    Boolean(true)
            }
            let setselectMediaMap =
                document.getElementById('newElementMediaMap')
            if (setselectMediaMap != null) {
                setselectMediaMap.getElementsByTagName('option')[0].selected =
                    Boolean(true)
            }
            let setselectSocialMap = document.getElementById(
                'newElementSocialMap'
            )
            if (setselectSocialMap != null) {
                setselectSocialMap.getElementsByTagName('option')[0].selected =
                    Boolean(true)
            }
        }
    }

    function Item(props: any) {
        const { sx, ...other } = props
        return (
            <Box
                sx={{
                    p: 1,
                    m: 1,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#101010' : 'grey.100',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    border: '1px solid',

                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: (theme) =>
                        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                    borderRadius: 2,
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    ...sx
                }}
                {...other}
            />
        )
    }

    let optionTextMap = []
    for (let xs = 0; xs < TextMap(props.Store).length; xs++) {
        let text: string | JSX.Element = ''
        if (TextMap(props.Store)[xs].value !== 'false') {
            text = TextMap(props.Store)[xs].label
        }
        optionTextMap.push(
            <>
                <option value={TextMap(props.Store)[xs].value}>{text}</option>
            </>
        )
    }

    let optionMediaMap = []
    for (let xs = 0; xs < MediaMap(props.Store).length; xs++) {
        let text: string | JSX.Element = ''
        if (MediaMap(props.Store)[xs].value !== 'false') {
            text = MediaMap(props.Store)[xs].label
        }
        optionMediaMap.push(
            <>
                <option value={MediaMap(props.Store)[xs].value}>{text}</option>
            </>
        )
    }

    let optionSocialMap = []
    for (let xs = 0; xs < SocialMap(props.Store).length; xs++) {
        let text: string | JSX.Element = ''
        if (SocialMap(props.Store)[xs].value !== 'false') {
            text = SocialMap(props.Store)[xs].label
        }
        optionSocialMap.push(
            <>
                <option value={SocialMap(props.Store)[xs].value}>{text}</option>
            </>
        )
    }

    let content
    if (element !== undefined) {
        content = element
    }

    let nav = []
    let webbuild = (props.mode === 'email' ? EmailWebBuilderNav(Store) : WebBuilderNav(Store))
    for (let x = 0; x < webbuild.length; x++) {
        nav.push(
            <>
                <Item
                    onClick={() => selectClick(webbuild[x])}
                    sx={{
                        flexWrap: 'wrap',
                        width: 98,
                        height: 98,
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                            opacity: [0.9, 0.8, 0.7]
                        }
                    }}
                >
                    {/* <div className={'pro-menu-item pro-sub-menu text-black'} > */}
                    <div className="text-center" role="button">
                        <p className="pro-icon-wrapper">{webbuild[x].icon}</p>
                        <p
                            className="pro-item-content"
                            title={webbuild[x].label}
                        >
                            {webbuild[x].label.substr(0, 16)} ...
                        </p>
                    </div>
                    {/* </div> */}
                </Item>
            </>
        )
    }

    let navWidgets = []
    let webbuildWidgets = Widgets(Store)
    for (let x = 0; x < webbuildWidgets.length; x++) {
        navWidgets.push(
            <>
                <Item
                    onClick={() => selectClick(webbuildWidgets[x])}
                    sx={{
                        flexWrap: 'wrap',
                        width: 98,
                        height: 98,
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                            opacity: [0.9, 0.8, 0.7]
                        }
                    }}
                >
                    {/* <div className={'pro-menu-item pro-sub-menu text-black'} > */}
                    <div className="text-center" role="button">
                        <p className="pro-icon-wrapper">
                            {webbuildWidgets[x].icon}
                        </p>
                        <p
                            className="pro-item-content"
                            title={webbuildWidgets[x].label}
                        >
                            {webbuildWidgets[x].label.substr(0, 16)} ...
                        </p>
                    </div>
                    {/* </div> */}
                </Item>
            </>
        )
    }

    let navLayout = []
    let webbuildLayout = Layout(Store)
    for (let x = 0; x < webbuildLayout.length; x++) {
        navLayout.push(
            <>
                <Item
                    onClick={() => selectClickLayout(webbuildLayout[x])}
                    sx={{
                        flexWrap: 'wrap',
                        width: 98,
                        height: 98,
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                            opacity: [0.9, 0.8, 0.7]
                        }
                    }}
                >
                    {/* <div className={'pro-menu-item pro-sub-menu text-black'} > */}
                    <div className="text-center" role="button">
                        <p className="pro-icon-wrapper">
                            {webbuildLayout[x].icon}
                        </p>
                        <p
                            className="pro-item-content"
                            title={webbuildLayout[x].label}
                        >
                            {webbuildLayout[x].label.substr(0, 16)} ...
                        </p>
                    </div>
                    {/* </div> */}
                </Item>
            </>
        )
    }

    const ElementMenu = (
        <>
            <div
                id="ElementMenu"
                className={'moduleNav '}
                style={{ display: 'none' }}
            >
                <div className="pro-sidebar md nav-pro">
                    <div className="pro-sidebar-inner">
                        <div className="pro-sidebar-layout nav-element-layout">
                            {/* <div className="pro-sidebar-header">
                                <div className="sidebarHeader-elements">



                                    <div className='edit-section'>

                                        <Box sx={{ display: 'inline-flex', width: "100%", alignItems: "center" }} >
                                            <div>
                                                <Typography variant='h6' sx={{ textTransform: 'capitalize' }}>Edit page</Typography>
                                            </div>
                                            <Box sx={{ display: 'flex', justifyContent: 'end' }} className="full-width" >
                                                <ButtonGroup variant="contained" aria-label="outlined primary button group" >
                                                    <IconButton color="primary" aria-label="upload picture" component="label" className='mui-Icon-button'>
                                                        <DesktopMacIcon />
                                                    </IconButton>
                                                    <Divider orientation="vertical" flexItem />

                                                    <IconButton color="primary" aria-label="upload picture" component="label" className='mui-Icon-button'>
                                                        <StayCurrentPortraitIcon />
                                                    </IconButton>
                                                </ButtonGroup>
                                            
                                                &nbsp;
                                                <ButtonGroup variant="contained" aria-label="outlined primary button group" >
                                                    <IconButton color="primary" aria-label="upload picture" component="label" className='mui-Icon-button'>
                                                        <UndoIcon aria-label="Undo" />
                                                    </IconButton>
                                                    <Divider orientation="vertical" flexItem />

                                                    <IconButton color="primary" aria-label="upload picture" component="label" className='mui-Icon-button'>
                                                        <RedoIcon aria-label="Redo" />
                                                    </IconButton>
                                                </ButtonGroup>
                                            </Box>
                                        </Box>
                                    </div>*/}

                            {/* <a href="/">
                                    <img src="/uni_logo.png" height="60"
                                         alt="Logo" className="SidebarLogo"/></a>
                                </div>
                            </div>*/}
                            <div className="pro-sidebar-content bgunset bg-white">
                                <div className="pro-menu shaped circle inner-submenu-arrows flex flex-wrap">
                                    {/* <ul> */}
                                    <br />
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            width: '100%',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <HomeIcon
                                                sx={{ mr: 0.5 }}
                                                fontSize="small"
                                            />
                                            {'Home '}
                                        </Breadcrumbs>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                                color: '#3770F8'
                                            }}
                                            className=" "
                                        >
                                            <KeyboardDoubleArrowLeftIcon
                                                onClick={() => showElementNav()}
                                                className="pointer"
                                            />
                                        </Box>
                                    </Box>
                                    <MUIAccordionElement
                                        expanded={expanded === 'panel1'}
                                        onChange={handleAccordianChange(
                                            'panel1'
                                        )}
                                        sx={{ width: '100%', minWidth: '100%' }}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                        >
                                            Common Modules
                                        </AccordionSummary>

                                        <AccordionDetails
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            {nav}
                                        </AccordionDetails>
                                    </MUIAccordionElement>
                                    {(props.mode === 'email' ? <></> : <MUIAccordionElement
                                        expanded={expanded === 'panel2'}
                                        onChange={handleAccordianChange(
                                            'panel2'
                                        )}
                                        sx={{ width: '100%', minWidth: '100%' }}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel2d-content"
                                            id="panel2d-header"
                                        >
                                            Common Widgets
                                        </AccordionSummary>

                                        <AccordionDetails
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            {navWidgets}
                                        </AccordionDetails>
                                    </MUIAccordionElement> )}
                                    {(props.mode === 'email' ? <></> :<MUIAccordionElement
                                        expanded={expanded === 'panel3'}
                                        onChange={handleAccordianChange(
                                            'panel3'
                                        )}
                                        sx={{ width: '100%', minWidth: '100%' }}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel3d-content"
                                            id="panel3d-header"
                                        >
                                            Common Layouts
                                        </AccordionSummary>

                                        <AccordionDetails
                                            sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap'
                                            }}
                                        >
                                            <Row>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text id="basic-addon1">
                                                        Rows
                                                    </InputGroup.Text>
                                                    <Form.Select
                                                        id={'layoutRow'}
                                                        aria-label="Default select example"
                                                    >
                                                        <option>0</option>
                                                        <option value="1">
                                                            1
                                                        </option>
                                                        <option value="2">
                                                            2
                                                        </option>
                                                        <option value="3">
                                                            3
                                                        </option>
                                                        <option value="4">
                                                            4
                                                        </option>
                                                        <option value="5">
                                                            5
                                                        </option>
                                                        <option value="6">
                                                            6
                                                        </option>
                                                    </Form.Select>
                                                </InputGroup>
                                                <Row>
                                                    <Col className={'col-3'}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">
                                                                R1 <br />
                                                                Cols
                                                            </InputGroup.Text>
                                                            <Form.Select
                                                                id={
                                                                    'layoutRow1Cols'
                                                                }
                                                            >
                                                                <option>
                                                                    0
                                                                </option>
                                                                <option value="1">
                                                                    1
                                                                </option>
                                                                <option value="2">
                                                                    2
                                                                </option>
                                                                <option value="3">
                                                                    3
                                                                </option>
                                                                <option value="4">
                                                                    4
                                                                </option>
                                                                <option value="5">
                                                                    5
                                                                </option>
                                                                <option value="6">
                                                                    6
                                                                </option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col className={'col-3'}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">
                                                                R2 <br />
                                                                Cols
                                                            </InputGroup.Text>
                                                            <Form.Select
                                                                id={
                                                                    'layoutRow2Cols'
                                                                }
                                                            >
                                                                <option>
                                                                    0
                                                                </option>
                                                                <option value="1">
                                                                    1
                                                                </option>
                                                                <option value="2">
                                                                    2
                                                                </option>
                                                                <option value="3">
                                                                    3
                                                                </option>
                                                                <option value="4">
                                                                    4
                                                                </option>
                                                                <option value="5">
                                                                    5
                                                                </option>
                                                                <option value="6">
                                                                    6
                                                                </option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col className={'col-3'}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">
                                                                R3 <br />
                                                                Cols
                                                            </InputGroup.Text>
                                                            <Form.Select
                                                                id={
                                                                    'layoutRow3Cols'
                                                                }
                                                            >
                                                                <option>
                                                                    0
                                                                </option>
                                                                <option value="1">
                                                                    1
                                                                </option>
                                                                <option value="2">
                                                                    2
                                                                </option>
                                                                <option value="3">
                                                                    3
                                                                </option>
                                                                <option value="4">
                                                                    4
                                                                </option>
                                                                <option value="5">
                                                                    5
                                                                </option>
                                                                <option value="6">
                                                                    6
                                                                </option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col className={'col-3'}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">
                                                                R4 <br />
                                                                Cols
                                                            </InputGroup.Text>
                                                            <Form.Select
                                                                id={
                                                                    'layoutRow4Cols'
                                                                }
                                                            >
                                                                <option>
                                                                    0
                                                                </option>
                                                                <option value="1">
                                                                    1
                                                                </option>
                                                                <option value="2">
                                                                    2
                                                                </option>
                                                                <option value="3">
                                                                    3
                                                                </option>
                                                                <option value="4">
                                                                    4
                                                                </option>
                                                                <option value="5">
                                                                    5
                                                                </option>
                                                                <option value="6">
                                                                    6
                                                                </option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col className={'col-3'}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">
                                                                R5 <br />
                                                                Cols
                                                            </InputGroup.Text>
                                                            <Form.Select
                                                                id={
                                                                    'layoutRow5Cols'
                                                                }
                                                            >
                                                                <option>
                                                                    0
                                                                </option>
                                                                <option value="1">
                                                                    1
                                                                </option>
                                                                <option value="2">
                                                                    2
                                                                </option>
                                                                <option value="3">
                                                                    3
                                                                </option>
                                                                <option value="4">
                                                                    4
                                                                </option>
                                                                <option value="5">
                                                                    5
                                                                </option>
                                                                <option value="6">
                                                                    6
                                                                </option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col className={'col-3'}>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text id="basic-addon1">
                                                                R6 <br />
                                                                Cols
                                                            </InputGroup.Text>
                                                            <Form.Select
                                                                id={
                                                                    'layoutRow6Cols'
                                                                }
                                                            >
                                                                <option>
                                                                    0
                                                                </option>
                                                                <option value="1">
                                                                    1
                                                                </option>
                                                                <option value="2">
                                                                    2
                                                                </option>
                                                                <option value="3">
                                                                    3
                                                                </option>
                                                                <option value="4">
                                                                    4
                                                                </option>
                                                                <option value="5">
                                                                    5
                                                                </option>
                                                                <option value="6">
                                                                    6
                                                                </option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Row>
                                            {navLayout}
                                        </AccordionDetails>
                                    </MUIAccordionElement>)}

                                    {/* </ul> */}
                                </div>
                            </div>
                            <div className="pro-sidebar-footer ta-center">
                                <div className="sidebar-btn-wrapper">
                                    <a
                                        href="https://wum-solution.de"
                                        target="_blank"
                                        className="sidebar-btn"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src="/WUM Logo.png"
                                            alt="Logo"
                                            style={{ maxHeight: '60px' }}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    let ElementAdded = (
        <>
            <div
                id={'elementadd'}
                className="react-page-editable react-page-editable-mode-edit widget-selection-box"
                onClick={() => showElementNav()}
            >
                <div
                    className="react-page-cell-insert-new"
                    style={{
                        pointerEvents: 'all',
                        zIndex: 1,
                        overflow: 'hidden',
                        //width: '50%',
                        minWidth: '120px',
                        margin: 'auto',
                        cursor: 'pointer'
                    }}
                >
                    <div className="react-page-cell-insert-new-icon">
                        <svg
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            data-testid="AddIcon"
                        >
                            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                        </svg>
                    </div>
                </div>
                <p>Add Modules or Widgets Here</p>
            </div>
        </>
    )

    useEffect(() => {
        position()
        const displayTabs = (id: any, from: any, li: any) => {
            let Holder = (document.getElementById(from) as HTMLDivElement)
                .childNodes[1]
            let Childs = (Holder as HTMLDivElement).childNodes
            for (let x = 0; x < Childs.length; x++) {
                ;(Childs[x] as HTMLDivElement).style.display = 'none'
            }
            ;(document.getElementById(id) as HTMLDivElement).style.display =
                'block'

            let ul = (
                (document.getElementById(from) as HTMLDivElement)
                    .childNodes[0] as HTMLUListElement
            ).childNodes

            for (let l = 0; l < ul.length; l++) {
                console.log(ul[l])
                ;(
                    (ul[l] as HTMLLIElement).childNodes[0] as HTMLButtonElement
                ).classList.remove('active')
            }
            ;(document.getElementById(li) as HTMLButtonElement).classList.add(
                'active'
            )
        }

        var Header;
        var Footer;

        Header = document.getElementById('headerPreview')
        var Linkh = $(Header as HTMLElement).find('a')
        for (let ha = 0; ha < Linkh.length; ha++) {
            ;(Linkh[ha] as HTMLAnchorElement).addEventListener(
                'click',
                function (e) {
                    e.preventDefault()
                }
            )
        }

        Footer = document.getElementById('footerPreview')
        var Linkf = $(Footer as HTMLElement).find('a')
        for (let fa = 0; fa < Linkf.length; fa++) {
            ;(Linkf[fa] as HTMLAnchorElement).addEventListener(
                'click',
                function (e) {
                    e.preventDefault()
                }
            )
        }

        if(props.mode !== 'email') {
            loadjscssfile(
                process.env.REACT_APP_FRONTEND_URL +
                '/templates/' +
                process.env.REACT_APP_TPL +
                '/css/customize.css',
                'css'
            )
            loadjscssfile(
                process.env.REACT_APP_FRONTEND_URL +
                '/templates/' +
                process.env.REACT_APP_TPL +
                '/css/bootstrap-icons.css',
                'css'
            )
            loadjscssfile(
                'https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js',
                'js'
            )
        } else {

        }
        var append = document.querySelectorAll('[data-appendcontainer]')
        let z = 0
        for (let a = 0; a < append.length; a++) {
            let appendField = append[a] as HTMLDivElement
            if (
                appendField !== undefined &&
                appendField !== null &&
                appendField.dataset.appendcontainer !== 'false' &&
                appendField.dataset.appendcontainer !== undefined
            ) {
                if (
                    (document.getElementById(
                        appendField.dataset.appendcontainer
                    ) as HTMLDivElement) !== undefined &&
                    (document.getElementById(
                        appendField.dataset.appendcontainer
                    ) as HTMLDivElement) !== null &&
                    (
                        document.getElementById(
                            appendField.dataset.appendcontainer
                        ) as HTMLDivElement
                    ).dataset.appenddiv !== undefined &&
                    (
                        document.getElementById(
                            appendField.dataset.appendcontainer
                        ) as HTMLDivElement
                    ).dataset.appenddiv === 'true'
                ) {
                    let temp = document.createElement('div')
                    temp.className =
                        ((
                            document.getElementById(
                                appendField.dataset.appendcontainer
                            ) as HTMLDivElement
                        ).dataset.appentclass as string) +
                        '' +
                        ((document.getElementById(
                            appendField.id
                        ) as HTMLDivElement) !== null &&
                        (document.getElementById(
                            appendField.id
                        ) as HTMLDivElement) !== undefined
                            ? (
                                  document.getElementById(
                                      appendField.id
                                  ) as HTMLDivElement
                              ).dataset.appendclasses
                            : '')
                    if (
                        (document.getElementById(
                            appendField.id
                        ) as HTMLDivElement) !== null &&
                        (document.getElementById(
                            appendField.id
                        ) as HTMLDivElement) !== undefined
                    ) {
                        let ClassReplace = $(
                            document.getElementById(
                                appendField.id
                            ) as HTMLDivElement
                        ).find(
                            '[class*= "' +
                                (
                                    document.getElementById(
                                        appendField.id
                                    ) as HTMLDivElement
                                ).dataset.appendclasses +
                                '"]'
                        )
                        for (let cr = 0; cr < ClassReplace.length; cr++) {
                            let tempClassHolder = ClassReplace[
                                cr
                            ] as HTMLDivElement
                            tempClassHolder.className =
                                tempClassHolder.className.replace(
                                    (
                                        document.getElementById(
                                            appendField.id
                                        ) as HTMLDivElement
                                    ).dataset.appendclasses as string,
                                    ''
                                )
                        }
                    }

                    temp.id = appendField.id + a
                    if (document.getElementById(temp.id) === null) {
                        ;(
                            document.getElementById(
                                appendField.dataset.appendcontainer
                            ) as HTMLDivElement
                        ).appendChild(temp)
                    }

                    $('#' + appendField.id).appendTo('#' + appendField.id + a)
                } else if (
                    (document.getElementById(
                        appendField.dataset.appendcontainer
                    ) as HTMLDivElement) !== undefined &&
                    (document.getElementById(
                        appendField.dataset.appendcontainer
                    ) as HTMLDivElement) !== null &&
                    (
                        document.getElementById(
                            appendField.dataset.appendcontainer
                        ) as HTMLDivElement
                    ).dataset.appendtab !== undefined &&
                    (
                        document.getElementById(
                            appendField.dataset.appendcontainer
                        ) as HTMLDivElement
                    ).dataset.appendtab === 'true'
                ) {
                    let tempLi = document.createElement('li')
                    tempLi.classList.add('nav-item')
                    tempLi.role = 'presentation'
                    tempLi.onclick = function () {
                        displayTabs(
                            'uncontrolled-tab-example-tabpane-' +
                                appendField?.id,
                            appendField.dataset.appendcontainer,
                            'uncontrolled-tab-example-tab-' + appendField.id
                        )
                    }
                    tempLi.id = z + 'li'
                    tempLi.innerHTML =
                        '<button type="button" id="uncontrolled-tab-example-tab-' +
                        appendField.id +
                        '" ' +
                        'role="tab" data-rr-ui-event-key="' +
                        appendField.id +
                        '" class="nav-link ' +
                        (z === 0 ? 'active' : '') +
                        '">Item-' +
                        z +
                        '</button>'
                    if (document.getElementById(tempLi.id) === null) {
                        ;(
                            (
                                document.getElementById(
                                    appendField.dataset.appendcontainer
                                ) as HTMLDivElement
                            ).childNodes[0] as HTMLUListElement
                        ).appendChild(tempLi)
                    }

                    let temptabs = document.createElement('div')
                    temptabs.role = 'tabpanel'
                    temptabs.className = ('tab-pane ' +
                        (z === 0 ? 'active' : '') +
                        ' ' +
                        (
                            document.getElementById(
                                appendField.dataset.appendcontainer
                            ) as HTMLDivElement
                        ).dataset.appentclass) as string
                    temptabs.style.marginTop = '50px'
                    temptabs.id =
                        'uncontrolled-tab-example-tabpane-' + appendField.id
                    temptabs.setAttribute(
                        'aria-labelledby',
                        'uncontrolled-tab-example-tab-' + appendField.id
                    )
                    if (document.getElementById(temptabs.id) === null) {
                        ;(
                            (
                                document.getElementById(
                                    appendField.dataset.appendcontainer
                                ) as HTMLDivElement
                            ).childNodes[1] as HTMLDivElement
                        ).appendChild(temptabs)
                    }

                    $('#' + appendField.id).appendTo(
                        '#uncontrolled-tab-example-tabpane-' + appendField.id
                    )
                    z++
                } else {
                    $('#' + appendField.id).appendTo(
                        '#' + appendField.dataset.appendcontainer
                    )
                }
            }
        }

        $('#backContent').appendTo('#injectback')
        $('#btn_save').appendTo('#injectsave')
        $('#previewButton').appendTo('#injectpreview')

        let Form = document.getElementById('form001') as HTMLFormElement
        Form.style.position = 'absolute'
        Form.style.top = '0px'
        Form.style.right = '10px'
        Form.style.zIndex = '1015'
        Form.style.overflowY = 'scroll'
        Form.style.overflowX = 'hidden'
        Form.style.width = '100%'
        Form.style.height = '100%'
        Form.style.paddingLeft = '15px'
        Form.style.paddingBottom = '0px'

        let footer = document.getElementById(
            'headerMCfooter'
        ) as HTMLFormElement
        if (footer !== null) {
            footer.style.display = 'none'
        }

        let nestedSortables = document.getElementsByClassName('nested-sortable')
        for (var i = 0; i < nestedSortables.length; i++) {
            new Sortable(nestedSortables[i] as HTMLElement, {
                group: 'nested',
                animation: 150,
                fallbackOnBody: true,
                swapThreshold: 0.65,
                onEnd: (evt) => {
                    position()
                    var elAppentTo = $(
                        document.getElementById('elements') as HTMLElement
                    ).find("[data-appendto*='order']")
                    //console.log(elAppentTo)
                    for (let np = 0; np < elAppentTo.length; np++) {
                        let appendTos = (elAppentTo[np] as HTMLDivElement)
                            .dataset.appendto
                        let appentParent = $(
                            (elAppentTo[np] as HTMLDivElement).parentNode
                                ?.parentNode?.parentNode as HTMLDivElement
                        ).find('.element-body')

                        let appentParentValue = (
                            $(appentParent[0] as HTMLDivElement).find(
                                "input[name*='position']"
                            )[0] as HTMLInputElement
                        ).value
                        console.log(appentParentValue)
                        console.log(appendTos)
                        var elAappendcontainer = $(
                            document.getElementById('elements') as HTMLElement
                        ).find("[data-appendcontainer*='" + appendTos + "']")
                        //console.log(elAappendcontainer)
                        let targetappento = $(
                            elAappendcontainer[0] as HTMLDivElement
                        ).find("input[name*='appendto']")[0] as HTMLInputElement
                        //console.log($(elAappendcontainer[0] as HTMLDivElement).find("input[name*='appendto']"))
                        if (
                            targetappento !== undefined &&
                            targetappento !== null
                        ) {
                            let switchAppent = targetappento.value.replace(
                                targetappento.value.split('-')[0],
                                'order' + appentParentValue
                            )
                            if (switchAppent !== 'order' + appentParentValue) {
                                targetappento.value = switchAppent
                                ;(
                                    elAppentTo[np] as HTMLDivElement
                                ).dataset.appendto = switchAppent
                                ;(elAppentTo[np] as HTMLDivElement).dataset.id =
                                    appentParentValue
                                ;(elAppentTo[np] as HTMLDivElement).id =
                                    switchAppent
                                ;(
                                    elAappendcontainer[0] as HTMLDivElement
                                ).dataset.appendcontainer = switchAppent
                                //console.log('NeuAppend: '+switchAppent)
                            }
                        }
                    }

                    let element = $(evt.to).find('.element-body')
                    let parent = evt.item.parentElement as HTMLDivElement
                    if (parent !== undefined && parent !== null) {
                        let mainChilds = $(evt.item).find(
                            "textarea[name='elements'],select[name='elements'] ,input[name*='elements']"
                        )
                        for (var mc = 0; mc < mainChilds.length; mc++) {
                            var names = $(mainChilds[mc]).attr('name')
                            if (
                                names !== undefined &&
                                names.includes('appendto')
                            ) {
                                let appendTo = mainChilds[
                                    mc
                                ] as HTMLInputElement
                                if (
                                    !appendTo.value.includes(
                                        'Injectcontainerend'
                                    )
                                ) {
                                    appendTo.value =
                                        parent.dataset.appendto === undefined
                                            ? ''
                                            : parent.dataset.appendto
                                }
                            }
                            if (
                                names !== undefined &&
                                names.includes('class')
                            ) {
                                let appendClass = mainChilds[
                                    mc
                                ] as HTMLInputElement
                                //let TmpClass = (parent.dataset.appendclass === undefined ?  clearCol(appendClass.value) :  (appendClass.value.includes(' col ' + parent.dataset.appendclass) ? appendClass.value  :appendClass.value+' col '+parent.dataset.appendclass));
                                let TmpClass =
                                    parent.dataset.appendclass === undefined
                                        ? appendClass.value
                                        : appendClass.value.includes(
                                              ' col ' +
                                                  parent.dataset.appendclass
                                          )
                                        ? appendClass.value
                                        : appendClass.value +
                                          ' col ' +
                                          parent.dataset.appendclass
                                appendClass.value = TmpClass
                            }
                        }
                    }

                    for (var x = 0; x < element.length; x++) {
                        let childss = element[x].parentNode?.parentNode
                            ?.parentNode?.parentNode
                            ?.parentNode as HTMLDivElement
                        let parentsss = element[x].parentNode as HTMLDivElement
                        let parentss = parentsss.parentNode as HTMLDivElement
                        let parents = parentss.parentNode as HTMLDivElement
                        var childs = $(element[x]).find(
                            "textarea[name='elements'],select[name='elements'] ,input[name*='elements']"
                        )

                        for (var c = 0; c < childs.length; c++) {
                            //let conter = 0;
                            var name = $(childs[c]).attr('name')
                            if (
                                name !== undefined &&
                                name.includes('position')
                            ) {
                                let position = childs[c] as HTMLInputElement

                                let EndChild = position.parentNode?.parentNode
                                    ?.parentNode?.parentNode as HTMLElement
                                if (EndChild.childNodes.length >= 3)
                                    if (
                                        (
                                            EndChild.lastChild as HTMLDivElement
                                        ).hasAttribute('data-injectEnd')
                                    ) {
                                        let Con = $(
                                            (
                                                EndChild.lastChild as HTMLDivElement
                                            ).lastChild as HTMLElement
                                        ).find('.element-body')
                                        let InputFild = $(Con[0]).find(
                                            "input[name*='appendto']"
                                        )[0] as HTMLInputElement
                                        if (InputFild !== undefined) {
                                            InputFild.value =
                                                'Injectcontainerend' + x
                                        }
                                    }

                                if (parentss.dataset.appendid !== undefined) {
                                    parentss.dataset.appendid = '' + x + ''
                                }
                                if (parents !== undefined && parents !== null) {
                                    if (
                                        parents.dataset.appendto !== undefined
                                    ) {
                                        if (
                                            childss !== undefined &&
                                            childss !== null &&
                                            childss.dataset !== undefined &&
                                            childss.dataset !== null
                                        ) {
                                            //parents.dataset.appendto = parents.dataset.appendto.replace(parents.dataset.appendto.split('-')[0], 'order' + childss.dataset.injectid )
                                        }
                                    }
                                }
                            }
                            if (parents !== undefined && parents !== null) {
                                let mainChilds = $(parents).find(
                                    "textarea[name='elements'],select[name='elements'] ,input[name*='elements']"
                                )
                                for (
                                    var mcs = 0;
                                    mcs < mainChilds.length;
                                    mcs++
                                ) {
                                    if (
                                        parents.dataset.appendto !== undefined
                                    ) {
                                        var namess = $(mainChilds[mcs]).attr(
                                            'name'
                                        )
                                        if (
                                            namess !== undefined &&
                                            namess.includes('appendto')
                                        ) {
                                            let appendTo = mainChilds[
                                                mcs
                                            ] as HTMLInputElement
                                            if (
                                                !appendTo.value.includes(
                                                    'Injectcontainerend'
                                                )
                                            ) {
                                                appendTo.value =
                                                    parents.dataset.appendto ===
                                                    undefined
                                                        ? ''
                                                        : parents.dataset
                                                              .appendto
                                            }
                                        }
                                        if (
                                            namess !== undefined &&
                                            namess.includes('class')
                                        ) {
                                            let appendClass = mainChilds[
                                                mcs
                                            ] as HTMLInputElement
                                            let TmpClasss =
                                                parents.dataset.appendclass ===
                                                undefined
                                                    ? clearCol(
                                                          appendClass.value
                                                      )
                                                    : appendClass.value.includes(
                                                          ' col ' +
                                                              parents.dataset
                                                                  .appendclass
                                                      )
                                                    ? appendClass.value
                                                    : appendClass.value +
                                                      ' col ' +
                                                      parents.dataset
                                                          .appendclass
                                            appendClass.value = TmpClasss
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })
        }
    })
    if (
        props.response !== undefined &&
        props.response.hasOwnProperty('elements') === false
    ) {
        return (
            <>
                <>
                    {/*{configuration('titel', props.response, true, Store)}
                {configuration('description', props.response, true, Store)}*/}
                    {/* <hr />*/}
                    {ElementMenu}

                    {/*  <hr />*/}
                    {Header(props.response,props.mode)}
                    <main>
                        <Accordion id={'elements'}>
                            {HeaderTemplate === true ? (
                                <>
                                    {' '}
                                    <header id="site-header">
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                true,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store,
                                            props.galerie,
                                            props.galerieItems
                                        )}
                                        <div
                                            style={{ marginTop: '120px' }}
                                            className={
                                                'list-group col nested-sortable'
                                            }
                                        >
                                            {content}
                                        </div>
                                    </header>
                                </>
                            ) : FooterTemplate === true ? (
                                <>
                                    <footer>
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                true,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store,
                                            props.galerie,
                                            props.galerieItems
                                        )}
                                        <div
                                            style={{ marginTop: '120px' }}
                                            className={
                                                'list-group col nested-sortable'
                                            }
                                        >
                                            {content}
                                        </div>
                                    </footer>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{ marginTop: '120px' }}
                                        className={
                                            'list-group col nested-sortable'
                                        }
                                    >
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                true,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store,
                                            props.galerie,
                                            props.galerieItems
                                        )}
                                        {content}
                                    </div>
                                </>
                            )}
                        </Accordion>
                    </main>
                    {Footer(props.response,props.mode)}

                    {ElementAdded}
                </>
                {/*<Row>
                    <Col md>
                        <FloatingLabel controlId="floatingSelectGrid"
                                       label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                            <Form.Select id={'newElement'} aria-label="Floating label select example"
                                         onChange={selectChange}>
                                {optionTextMap}
                            </Form.Select>
                        </FloatingLabel>
                    </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionMediaMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionSocialMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                </Row>*/}
            </>
        )
    } else if (
        props.response !== undefined &&
        props.response.hasOwnProperty('elements')
    ) {
        return (
            <>
                <>
                    {ElementMenu}
                    {/* {configuration('titel', props.response, false, Store)}
                {configuration('description', props.response, false, Store)}*/}
                    {/*<hr />*/}
                    {Header(props.response,props.mode)}
                    <main>
                        <Accordion id={'elements'}>
                            {HeaderTemplate === true ? (
                                <>
                                    {' '}
                                    <header id="site-header">
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                false,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store,
                                            props.galerie,
                                            props.galerieItems
                                        )}
                                        {content}
                                    </header>
                                </>
                            ) : FooterTemplate === true ? (
                                <>
                                    <footer>
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                false,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store,
                                            props.galerie,
                                            props.galerieItems
                                        )}
                                        {content}
                                    </footer>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{ marginTop: '30px' }}
                                        className={
                                            'list-group col nested-sortable'
                                        }
                                    >
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                false,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store,
                                            props.galerie,
                                            props.galerieItems
                                        )}
                                        {content}
                                    </div>
                                </>
                            )}
                        </Accordion>
                    </main>
                    {Footer(props.response,props.mode)}
                    {ElementAdded}
                </>

                {/*<Row>
                    <Col md>
                        <FloatingLabel controlId="floatingSelectGrid"
                                       label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                            <Form.Select id={'newElement'} aria-label="Floating label select example"
                                         onChange={selectChange}>
                                {optionTextMap}
                            </Form.Select>
                        </FloatingLabel>
                    </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionMediaMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionSocialMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                </Row>*/}
            </>
        )
    } else {
        return (
            <>
                <>
                    {/* {configuration('titel', props.response, true, Store)}
                {configuration('description', props.response, true, Store)}*/}
                    {/* <hr />*/}
                    {ElementMenu}
                    {Header(props.response,props.mode)}
                    <main>
                        <Accordion id={'elements'}>
                            {HeaderTemplate === true ? (
                                <>
                                    {' '}
                                    <header id="site-header">
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                true,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store
                                        )}
                                        {content}
                                    </header>
                                </>
                            ) : FooterTemplate === true ? (
                                <>
                                    <footer>
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                true,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store
                                        )}
                                        {content}
                                    </footer>
                                </>
                            ) : (
                                <>
                                    <div
                                        style={{ marginTop: '30px' }}
                                        className="drag-box list-group col nested-sortable"
                                    >
                                        {FormElements(
                                            configuration(
                                                'default',
                                                props.response,
                                                true,
                                                Store
                                            ),
                                            props.modalShow,
                                            props.setModalShow,
                                            props.modalData,
                                            props.setModalData,
                                            false,
                                            false,
                                            false,
                                            false,
                                            Store
                                        )}
                                        {content}
                                    </div>
                                </>
                            )}
                        </Accordion>
                    </main>
                    {Footer(props.response,props.mode)}
                    {ElementAdded}
                </>

                {/*   <Row>
                    <Col md>
                        <FloatingLabel controlId="floatingSelectGrid"
                                       label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                            <Form.Select id={'newElement'} aria-label="Floating label select example"
                                         onChange={selectChange}>
                                {optionTextMap}
                            </Form.Select>
                        </FloatingLabel>
                    </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionMediaMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionSocialMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                </Row>*/}
            </>
        )
    }
}

export default Content

export const showElementNav = () => {
    let nav = document.getElementById('ElementMenu') as HTMLDivElement
    let wrapperContent = document.getElementById('form001') as HTMLDivElement

    if (nav.style.display === 'none') {
        nav.style.display = 'block'
        wrapperContent.style.marginLeft = '125px'
    } else {
        nav.style.display = 'none'
        wrapperContent.style.marginLeft = 'auto'
    }
}

export const clearCol = (input: string) => {
    let tmp = ''

    if (input === undefined || input === null) {
        return input
    }
    tmp = input
    if (tmp.includes(' col col-1')) {
        tmp = tmp.replace(' col col-1', '')
    } else if (tmp.includes(' col col-2')) {
        tmp = tmp.replace(' col col-2', '')
    } else if (tmp.includes(' col col-3')) {
        tmp = tmp.replace(' col col-3', '')
    } else if (tmp.includes(' col col-4')) {
        tmp = tmp.replace(' col col-4', '')
    } else if (tmp.includes(' col col-5')) {
        tmp = tmp.replace(' col col-5', '')
    } else if (tmp.includes(' col col-6')) {
        tmp = tmp.replace(' col col-6', '')
    } else if (tmp.includes(' col col-7')) {
        tmp = tmp.replace(' col col-7', '')
    } else if (tmp.includes(' col col-8')) {
        tmp = tmp.replace(' col col-8', '')
    } else if (tmp.includes(' col col-9')) {
        tmp = tmp.replace(' col col-9', '')
    } else if (tmp.includes(' col col-10')) {
        tmp = tmp.replace(' col col-10', '')
    } else if (tmp.includes(' col col-11')) {
        tmp = tmp.replace(' col col-11', '')
    } else if (tmp.includes(' col col-12')) {
        tmp = tmp.replace(' col col-12', '')
    }

    return tmp
}

const Header = (response: any,mode:any) => {


    if(mode !== 'email') {
        return (
            <>
                <div
                    style={{ marginRight: '-25px' }}
                    id={'headerPreview'}
                    className={'preview-container'}
                    dangerouslySetInnerHTML={{ __html: response.previewwHeader }}
                />
            </>
        )
    } else {
        return ( <>
            <div
                style={{ marginRight: '-25px', marginTop: '15px' }}
                id={'headerPreview'}
                className={'preview-container'}
            />
            <br />
        </> )
    }

}

const Footer = (response: any,mode:any) => {

    if(mode !== 'email') {
        return (
            <>
                <div
                    style={{ marginRight: '-25px', marginTop: '-14px' }}
                    id={'footerPreview'}
                    className={'preview-container'}
                    dangerouslySetInnerHTML={{ __html: response.previewFooter }}
                />
            </>
        )
    } else {
        return ( <>

        </>  )
    }
}

const loadjscssfile = (filename: any, filetype: any) => {
    let fileref
    if (filetype === 'js') {
        //if filename is a external JavaScript file
        fileref = document.createElement('script')
        let ref = fileref as HTMLScriptElement
        ref.setAttribute('type', 'text/javascript')
        ref.setAttribute('src', filename)
    } else if (filetype === 'css') {
        //if filename is an external CSS file
        fileref = document.createElement('link')
        let ref = fileref as HTMLLinkElement
        ref.setAttribute('rel', 'stylesheet')
        ref.setAttribute('type', 'text/css')
        ref.setAttribute('href', filename)
    }
    if (typeof fileref != 'undefined')
        document.getElementsByTagName('head')[0].appendChild(fileref)
}

export const position = () => {
    var elPosition = $(document.getElementById('elements') as HTMLElement).find(
        "input[name*='position']"
    )

    for (let np = 0; np < elPosition.length; np++) {
        let position = elPosition[np] as HTMLInputElement
        position.value = '' + np + ''
    }
    var elElementSwitch = (document.getElementById('elements') as HTMLElement)
        .childNodes

    for (let np = 0; np < elElementSwitch.length; np++) {
        let SettingNode = $(elElementSwitch[np] as HTMLElement).find(
            '.settingsWebBuilderModul'
        )
        let EditNode = $(elElementSwitch[np] as HTMLElement).find(
            '.element-body'
        )

        for (let iterator = 0; iterator < EditNode.length; iterator++) {
            // Debug Element
            //console.log(EditNode[iterator])
            if (
                ($(EditNode[iterator]).find(
                    "input[name*='position']"
                )[0] as HTMLInputElement) !== undefined
            ) {
                let SwitchElementNode = (
                    $(EditNode[iterator]).find(
                        "input[name*='position']"
                    )[0] as HTMLInputElement
                ).value
                let EditNodes = $(EditNode[iterator]).find(
                    "textarea[name*='elements'],select[name*='elements'] ,input[name*='elements']"
                )
                let SettingsNode = $(SettingNode[iterator]).find(
                    "textarea[name*='elements'],select[name*='elements'] ,input[name*='elements']"
                )

                for (let node = 0; node < EditNodes.length; node++) {
                    if (EditNodes[node].tagName === 'INPUT') {
                        let tmpEditField = EditNodes[node] as HTMLInputElement
                        if (tmpEditField !== undefined) {
                            let search =
                                '[' +
                                tmpEditField
                                    .getAttribute('name')
                                    ?.split(']')[0]
                                    .split('[')[1] +
                                ']'
                            tmpEditField.setAttribute(
                                'name',
                                (
                                    tmpEditField.getAttribute('name') as string
                                ).replace(search, '[' + SwitchElementNode + ']')
                            )
                        }
                    } else if (EditNodes[node].tagName === 'SELECT') {
                        let tmpEditField = EditNodes[node] as HTMLSelectElement
                        if (tmpEditField !== undefined) {
                            let search =
                                '[' +
                                tmpEditField
                                    .getAttribute('name')
                                    ?.split(']')[0]
                                    .split('[')[1] +
                                ']'
                            tmpEditField.setAttribute(
                                'name',
                                (
                                    tmpEditField.getAttribute('name') as string
                                ).replace(search, '[' + SwitchElementNode + ']')
                            )
                        }
                    } else if (EditNodes[node].tagName === 'TEXTAREA') {
                        let tmpEditField = EditNodes[
                            node
                        ] as HTMLTextAreaElement
                        if (tmpEditField !== undefined) {
                            let search =
                                '[' +
                                tmpEditField
                                    .getAttribute('name')
                                    ?.split(']')[0]
                                    .split('[')[1] +
                                ']'
                            tmpEditField.setAttribute(
                                'name',
                                (
                                    tmpEditField.getAttribute('name') as string
                                ).replace(search, '[' + SwitchElementNode + ']')
                            )
                        }
                    }
                }
                for (let node = 0; node < SettingsNode.length; node++) {
                    if (SettingsNode[node].tagName === 'INPUT') {
                        let tmpSettingField = SettingsNode[
                            node
                        ] as HTMLInputElement
                        if (tmpSettingField !== undefined) {
                            let search =
                                '[' +
                                tmpSettingField
                                    .getAttribute('name')
                                    ?.split(']')[0]
                                    .split('[')[1] +
                                ']'
                            tmpSettingField.setAttribute(
                                'name',
                                (
                                    tmpSettingField.getAttribute(
                                        'name'
                                    ) as string
                                ).replace(search, '[' + SwitchElementNode + ']')
                            )
                        }
                    } else if (SettingsNode[node].tagName === 'SELECT') {
                        let tmpSettingField = SettingsNode[
                            node
                        ] as HTMLSelectElement
                        if (tmpSettingField !== undefined) {
                            let search =
                                '[' +
                                tmpSettingField
                                    .getAttribute('name')
                                    ?.split(']')[0]
                                    .split('[')[1] +
                                ']'
                            tmpSettingField.setAttribute(
                                'name',
                                (
                                    tmpSettingField.getAttribute(
                                        'name'
                                    ) as string
                                ).replace(search, '[' + SwitchElementNode + ']')
                            )
                        }
                    } else if (SettingsNode[node].tagName === 'TEXTAREA') {
                        let tmpSettingField = SettingsNode[
                            node
                        ] as HTMLTextAreaElement
                        if (tmpSettingField !== undefined) {
                            let search =
                                '[' +
                                tmpSettingField
                                    .getAttribute('name')
                                    ?.split(']')[0]
                                    .split('[')[1] +
                                ']'
                            tmpSettingField.setAttribute(
                                'name',
                                (
                                    tmpSettingField.getAttribute(
                                        'name'
                                    ) as string
                                ).replace(search, '[' + SwitchElementNode + ']')
                            )
                        }
                    }
                }
            }
        }
    }
}
