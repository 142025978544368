import React, { useState } from 'react'
import IntlMessage, {
    IntlMessageValue
} from '../../../../../@WUM/core/component/const/IntlMessage'
import {
    ResponsetemplateDelete,
    ResponsetemplateSite
} from '../../API/response/site/site'
import { SpinnerJSX } from '../../../../../@WUM/core/component/const/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import ContentSwitch from '../../../../../@WUM/core/component/const/ContentSwitch'
import TemplateOverviewInterface from '../../interface/TemplateOverview.interface'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import SearchJSX from '../../../../../@WUM/core/component/const/SearchJSX'
import { ResponseallProducts } from '../../../../shop/products/API/response/product/Product'
import FetchselectAPI from '../../../../../@WUM/core/component/const/fetchselectAPI'
// import { connect } from 'react-redux'
//import * as actions from '../../../../../store/Actions/'

const Template = (props: TemplateOverviewInterface) => {
    //const dispatch = useDispatch()
    localStorage.setItem('TemplateLayout', '')
    const history = useNavigate()
    const Store = useSelector((state: any) => state)
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    const namespace_search = 'Template_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    let limit: any
    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    } else {
        limit = 0
    }
    let response = ResponsetemplateSite(limit, searchValue) // old data get
    const debug = (deleteID: string | undefined, search: boolean) => {
        return
    }
    /*
     * Neuladen der Seite
     */
    const routeChange = () => {
        history(-1)
    }

    if (deleteID) {
        ResponsetemplateDelete(deleteID)
        routeChange()
    }
    //let response = ResponseSite(limit,searchValue);

    const [search, setSearch] = useState<boolean>(false)
    debug(deleteID, search)
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    const [mode, setMode] = useState('cart')
    const destination = 'Template'
    FetchselectAPI()
    ResponseallProducts()
    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'common.search_for_title',
                    param: 'translation.title',
                    method: 'like'
                },
                {
                    id: 'search_title',
                    translation: 'common.date',
                    param: 'filter',
                    method: '=',
                    individual: (
                        <Form.Group
                            as={Col}
                            controlId={'search_title'}
                            name={'search_title'}
                        >
                            <Form.Label>Nach Titel Sortieren</Form.Label>
                            <Form.Select
                                aria-label="Filter"
                                defaultValue={
                                    localStorage.getItem(
                                        namespace_search + '_search_title'
                                    ) as string
                                }
                            >
                                <option />
                                <option value={'title'}>
                                    {IntlMessageValue(
                                        'de',
                                        'searchadded.title.up',
                                        Store
                                    )}
                                </option>
                                <option value={'-title'}>
                                    {IntlMessageValue(
                                        'de',
                                        'searchadded.title.down',
                                        Store
                                    )}
                                </option>
                            </Form.Select>{' '}
                        </Form.Group>
                    )
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'common.create_new_page'
            ]
        }
    ]

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: [
                'id',
                'translation.de.slug',
                'translation.de.description',
                'translation.de.title'
            ],
            type: ['string', 'string', 'string', 'string']
        },
        tableHeader: [
            <IntlMessage
                Store={Store}
                messageId="common.id"
                locale={props.value}
            />,
            <IntlMessage
                Store={Store}
                messageId="common.slug"
                locale={props.value}
            />,
            <IntlMessage
                Store={Store}
                messageId="common.description"
                locale={props.value}
            />,
            <IntlMessage
                Store={Store}
                messageId="common.title"
                locale={props.value}
            />
        ],
        tableHeaderWidth: ['auto', '30%', '30%', '30%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage
                    Store={Store}
                    messageId="common.edit"
                    locale={props.value}
                />,
                <IntlMessage
                    Store={Store}
                    messageId="common.delete"
                    locale={props.value}
                />
            ],
            menuroute: ['/Template/Detail', '/Template/Delete/[ID]'],
            menuParams: ['', 'id', 'id'],
            menuIcons: ['pencil', 'Delete']
        }
    }
    const CardsConfiguration = {
        data: {
            ressource: response,
            img: '',
            status: 'visible',
            statusActive: (
                <IntlMessage Store={Store} messageId="common.activated" />
            ),
            statusDeactive: (
                <IntlMessage Store={Store} messageId="common.deactivated" />
            ),
            link: '',
            prelink: 'http://myhomice-f2.pielers.de/produkte/',
            title: 'translation.de.title',
            descriptionShow: true,
            description: 'translation.de.description',
            parentShow: false,
            parent: 'parent.translation.title',
            router: 'id',
            preroute: '/Template/Detail/',
            edit: <IntlMessage Store={Store} messageId="common.edit_page" />,
            delete: true,
            Dontvisited: true,
            deleteLink: '/Template/Delete/' + page + '/[ID]'
        }
    }

    const switchURL = (e: any) => {
        let type = document.getElementById('indisubmit') as HTMLLinkElement
        if (e.target.value !== '0') {
            type.classList.remove('disabled')
            localStorage.setItem('TemplateLayout', e.target.value)
        } else {
            type.classList.add('disabled')
        }
        return
    }

    let NewPage = page === undefined ? 1 : page
    if (response?.length > 0) {
        return (
            <>
                <SearchJSX
                    setSearchValue={setSearchValue}
                    Store={Store}
                    SearchConfig={SearchConfig}
                    destinationField={
                        <>
                            <Form.Group
                                as={Col}
                                controlId={'typeid'}
                                title={
                                    'Auswählen um neues Template zu erstellen'
                                }
                                name={'typeid'}
                                onChange={(e) => switchURL(e)}
                            >
                                <Form.Label>Template Type</Form.Label>
                                <Form.Select
                                    aria-label="Filter"
                                    defaultValue={''}
                                >
                                    <option value={'0'} />
                                    <option value={'custom'}>CustomType</option>
                                    <option value={'dynamikHeader.html'}>
                                        Header
                                    </option>
                                    <option value={'dynamikFooter.html'}>
                                        Footer
                                    </option>
                                </Form.Select>{' '}
                            </Form.Group>
                        </>
                    }
                    destinationindivudal={'typeid'}
                />
                {
                    <ContentSwitch
                        pagenew={'new'}
                        destinationnew={'Template'}
                        tonew={'new'}
                        text={
                            <IntlMessage
                                Store={Store}
                                locale={'de'}
                                messageId={'common.create_new_page'}
                            />
                        }
                        response={response}
                        CardsConfiguration={CardsConfiguration}
                        mode={mode}
                        Configuration={Configuration}
                        setMode={setMode}
                        destination={destination}
                        page={NewPage}
                        to={'overview'}
                        setSearchValues={setSearchValue}
                        setSearchs={setSearch}
                        search={false}
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        modalData={modalData}
                        setModalData={setModalData}
                        searchfield={false}
                        allTaxon={[]}
                        _handleKeyFilters={[]}
                        _handleEnter={[]}
                        filter={true}
                        stop={false}
                        SearchParam={'?translation.title'}
                        Store={Store}
                    />
                }
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default Template
