import { IntlMessageValue } from '../../component/const/IntlMessage'
import Formular from './index'

export const newForms = (
    type: any,
    index: any,
    setdeleteElement: any,
    setElements: any,
    Store: any,
    galerie?: any,
    galerieItems?: any
) => {
    const tmp = []

    const elements: any = {
        type: type
    }
    tmp.push(elements)

    const elementsContent = [{ type: type }]

    return Formular(
        elementsContent,
        false,
        false,
        false,
        false,
        true,
        index,
        setdeleteElement,
        setElements,
        Store,
        galerie,
        galerieItems
    )
}

export const Form = (Store: any) => {
    return [
        { value: 'false', label: '' },
        {
            value: 'headline',
            label: IntlMessageValue('de', 'form.elements.headline', Store)
        },
        {
            value: 'text',
            label: IntlMessageValue('de', 'form.elements.text', Store)
        }, //x
        {
            value: 'checkbox',
            label: IntlMessageValue('de', 'form.elements.checkbox', Store)
        }, //x
        {
            value: 'div_start',
            label: IntlMessageValue('de', 'form.elements.div_start', Store)
        },
        {
            value: 'div_end',
            label: IntlMessageValue('de', 'form.elements.div_end', Store)
        }, //x
        {
            value: 'fieldset_start',
            label: IntlMessageValue('de', 'form.elements.fieldset_start', Store)
        }, //x
        {
            value: 'fieldset_end',
            label: IntlMessageValue('de', 'form.elements.fieldset_end', Store)
        }, //x
        {
            value: 'select_start',
            label: IntlMessageValue('de', 'form.elements.select_start', Store)
        }, //x
        {
            value: 'select_end',
            label: IntlMessageValue('de', 'form.elements.select_end', Store)
        }, //x
        {
            value: 'textarea',
            label: IntlMessageValue('de', 'form.elements.textarea', Store)
        }, //x
        {
            value: 'label',
            label: IntlMessageValue('de', 'form.elements.label', Store)
        }, //x
        {
            value: 'option',
            label: IntlMessageValue('de', 'form.elements.option', Store)
        }, //x
        {
            value: 'input',
            label: IntlMessageValue('de', 'form.elements.input', Store)
        }, //x
        {
            value: 'fileupload',
            label: IntlMessageValue('de', 'form.elements.fileupload', Store)
        }, //x
        {
            value: 'radio',
            label: IntlMessageValue('de', 'form.elements.radio', Store)
        }, //x
        {
            value: 'button',
            label: IntlMessageValue('de', 'form.elements.button', Store)
        }
    ]
}
