import WidgetURLMapping from '../../core/WidgetURLMapping'
import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import React from 'react'
import IntlMessage from '../../../../../@WUM/core/component/const/IntlMessage'

const VisitsInRealTime = (props: { Store: any }) => {
    return (
        <>
            <CreateMatomoIframe
                headline={
                    <IntlMessage
                        Store={props.Store}
                        messageId="Visitor.user.on.realtime"
                    />
                }
                width={'100%'}
                height={'650'}
                url={WidgetURLMapping({ name: 'Visits in real time' })}
                scrolling={'yes'}
                id={'vrt1'}
                Store={props.Store}
            />
        </>
    )
}
export default VisitsInRealTime
