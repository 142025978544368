import React from 'react'
import NotificationOverview from "./Pages/NotificationOverview";

export const NotificationPagesConfig = (value: any) => {
    return [
        {
            path: '/notification/:page',
            elements: <NotificationOverview value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        }
    ]
}

