import React from 'react'
import TemplateNew from './Pages/new'
import Template from './Pages/Template'
import TemplateDetail from './Pages/TemplateDetail'

export const TemplateConfig = (value: any) => {
    return [
        {
            path: '/Template/overview/:page',
            elements: <Template value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Template/new/new',
            elements: <TemplateNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },

        {
            path: '/Template/new/:page',
            elements: <TemplateNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Template/Detail/:id',
            elements: <TemplateDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Template/Delete/:page/:deleteID',
            elements: <Template value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}

export const TemplateMenuConfig = (value: any) => {
    return [
        {
            name: 'Template',
            route: '/Template/overview/1',
            icon: 'build_circle',
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}
