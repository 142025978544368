import setElements from '../setter/setElements'

export const FormSerializeElementsTranslations = (
    map: any,
    form: any,
    z: any
) => {
    let FormDatas = []
    let stringJSON: string = ''
    var inputs = form.elements

    // Iterate over the form controlsc
    for (let i = 0; i < inputs.length; i++) {
        // Disable all form controls
        if (inputs[i].hasAttribute('name')) {
            if (inputs[i].getAttribute('name') === map) {
                stringJSON += setElements(map, z, inputs, i)
            }
        }
    }

    FormDatas.push(stringJSON)

    return FormDatas[0]
}

export default FormSerializeElementsTranslations
