import TableCell from '@material-ui/core/TableCell'
import React from 'react'

export const addedString = (
    text: string,
    id: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any,
    Rows: any,
) => {
    console.log(Rows)

    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
                id={'td' + id + '_' + index}
            >
                {Rows.split('|')[1]}{text}
            </TableCell>
        </>
    )
}

