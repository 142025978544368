import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'

function deleteIcon() {
    return (
        <>
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
        </>
    )
}

export default deleteIcon
