import React from 'react'
import PaymentHistorytOverview from "./overview"
export const Payment_historyPagesConfig = (value: any) => {
    return [
        {
            path: '/payment_history/overview/:page',
            elements: (
                <PaymentHistorytOverview
                    value={value}
                />
            ),
            roles: ['superadmin', 'admin']
        }
    ]
}

export const Payment_historyMenuConfig = (value: any) => {
    return [
        {
            name: 'Zahlungsverlauf',
            route: '/payment_history/overview/1',
            icon: 'manage_history',
            roles: ['superadmin', 'admin']
        }
    ]
}

export const Payment_historyDasboard = () => [
    {
        title: 'Zahlungsverlauf',
        href: '/payment_history/overview/1',
        undertitle: 'Backoffice',
        icon: 'manage_history',
        text: 'EWIV',
        roles: ['superadmin', 'admin']
    }
]

