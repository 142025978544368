import React from 'react'
import PageOverview from './Pages/Pageoverview'
import PageDetail from './Pages/PageDetail'
import PageNew from './Pages/new'
import CalenderDeleted from './Pages/deleted'

export const CalenderPagesConfig = (value: any) => {
    return [
        {
            path: '/Calender/overview/:page',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            elements: <PageOverview value={value} />
        },
        {
            path: '/Calender/Detail/:id',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            elements: <PageDetail value={value} />
        },
        {
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            path: '/Calender/Delete/:page/:deleteID',
            elements: <CalenderDeleted value={value} />
        },
        {
            path: '/Calender/new/:page',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            elements: <PageNew value={value} />
        }
    ]
}
export const CalenderMenuConfig = (value: any) => {
    return [
        {
            header: 'Veranstaltungskalender',
            icon: 'event',
            roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            menuitem: [
                {
                    name: 'Kalender',
                    route: '/Calender/overview/1',
                    icon: 'calendar_today',
                    roles: (process.env.REACT_APP_CALENDER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator'])
                }
            ]
        }
    ]
}
export const CalenderSidebar: string = '/Calender/overview/1'
