import IntlMessage from '../../component/const/IntlMessage'
import React from 'react'
import Badge from 'react-bootstrap/Badge'
import TableCell from '@material-ui/core/TableCell'
function status(
    data: any,
    id: number,
    index: number,
    param: any,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any
) {
    if (param.length === 1) {
        if (data === 1 || data === true) {
            return (
                <>
                    <TableCell
                        style={{ textAlign: tableBodyPositionElement }}
                        key={'td' + id + '_' + index}
                        data-param={VisitData}
                        data-param-del={VisitDataDel}
                        data-id={id}
                        id={'td' + id + '_' + index}
                    >
                        <Badge bg="success" id={'badge' + id + '_' + index}>
                            <IntlMessage
                                Store={Store}
                                messageId="common.activated"
                            />
                        </Badge>
                    </TableCell>
                </>
            )
        } else if (data === 0) {
            return (
                <>
                    <TableCell
                        style={{ textAlign: tableBodyPositionElement }}
                        key={'td' + id + '_' + index}
                        data-param={VisitData}
                        data-param-del={VisitDataDel}
                        data-id={id}
                        id={'td' + id + '_' + index}
                    >
                        <Badge bg="danger" id={'badge' + id + '_' + index}>
                            <IntlMessage
                                Store={Store}
                                messageId="common.deactivated"
                            />
                        </Badge>
                    </TableCell>
                </>
            )
        }
    } else {
        if (data === 1 || data === true) {
            return (
                <>
                    <TableCell
                        style={{ textAlign: tableBodyPositionElement }}
                        key={'td' + id + '_' + index}
                        data-param={VisitData}
                        data-param-del={VisitDataDel}
                        data-id={id}
                        id={'td' + id + '_' + index}
                    >
                        <Badge bg="success" id={'badge' + id + '_' + index}>
                            <IntlMessage Store={Store} messageId={param[1]} />
                        </Badge>
                    </TableCell>
                </>
            )
        } else if (data === 0) {
            return (
                <>
                    <TableCell
                        style={{ textAlign: tableBodyPositionElement }}
                        key={'td' + id + '_' + index}
                        data-param={VisitData}
                        data-param-del={VisitDataDel}
                        data-id={id}
                        id={'td' + id + '_' + index}
                    >
                        <Badge bg="danger" id={'badge' + id + '_' + index}>
                            <IntlMessage Store={Store} messageId={param[2]} />
                        </Badge>
                    </TableCell>
                </>
            )
        }
    }
}

export default status
