import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { ResponseDetail } from '../../../API/response/merchant/merchant'
import profil from './elements/profil'
import billadress from './elements/billadress'
import { merchantApiDetail } from '../../../API/request/merchant/merchantApiDetail.interface'
import init from '../../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from './configuration/mapping'
import { merchant } from '../../../API/request/merchant/merchant'
import image from './elements/image'
import provision from './elements/provision'
import Header from './elements/header'
import { saveDataIndividualPut } from '../../../../../../@WUM/core/RestFullApi/ApiHook'
import { isEmptyArray } from 'formik'
import {
    ResponseAllShipingTypes,
    ResponseallTaxon
} from '../../../../products/API/response/product/Product'
import { ShippingApiType } from '../../../../products/API/request/Product/ShippingApi.interface'
import {
    notify_reload_forbidden,
    notify_save
} from '../../../../../../@WUM/core/component/const/notification.service'
import { error } from '../../../../../../@WUM/core/component/const/error.services'
import taxon from './elements/taxon'
import { SpinnerJSX } from '../../../../../../@WUM/core/component/const/Spinner'
import { btn_back } from '../../../../../../@WUM/core/component/const/btn_back'
import IntlMessage from '../../../../../../@WUM/core/component/const/IntlMessage'
import {
    modalDataSheetMerchant,
    setDataSheetMerchant
} from './elements/datasheetMerchant'
import { DummyUser } from '../../../../../../@WUM/core/dummy/user'
import '../../../css/merchant.css'
import { useSelector } from 'react-redux'

const MerchantDetail = (value: any) => {
    const user = DummyUser()

    const Store = useSelector((state: any) => state)
    const forbidden = (search: any) => {
        if (user !== null) {
            for (let u = 0; u < user.role.length; u++) {
                if (user.role[u] === search) {
                    return true
                } else {
                }
            }
        }
    }
    const { id } = useParams<{ id: string }>()
    let confimID: string | undefined = ''
    if (
        user !== null &&
        user !== undefined &&
        (forbidden('merchant') === true || forbidden('merchant_ma') === true)
    ) {
        if (user.merchant_id !== null) {
            confimID = user.merchant_id
        } else {
            confimID = id
        }
    } else {
        confimID = id
    }

    const debug = (Shipping: ShippingApiType[], allTaxon: any) => {
        return
    }
    const [loading, setloading] = useState<boolean>(false)

    const Shipping = ResponseAllShipingTypes()
    const allTaxon = ResponseallTaxon()
    debug(Shipping, allTaxon)
    const history = useNavigate()

    const [savevacation, setSavevacation] = React.useState(false)
    const [savevisible, setSavevisible] = React.useState(false)
    const [stateValue, setstateValue] = React.useState('vacation')
    const [savestate, setSavestate] = React.useState(false)
    const [enabledState, setenabledState] = React.useState(0)
    const [approveState, setapproveState] = React.useState('')
    const [modalDataSheetShow, setModalDataSheet] = React.useState(false)
    const [getDataSheetValue, saveGetDataSheetValue] = useState('')

    const [DimensionsValue, setDimensionsValue] = useState(null)

    const [modalDimensionsShow, setModalDimensionsShow] = React.useState(false)
    const debugdeleteElement = (
        DimensionsValue: unknown,
        modalDimensionsShow: boolean
    ) => {
        if (modalDimensionsShow === true) {
            saveGetDataSheetValue('')
        }
    }
    debugdeleteElement(DimensionsValue, modalDimensionsShow)
    const response2 = ResponseDetail(
        confimID,
        setloading,
        setstateValue,
        setenabledState,
        setapproveState
    )

    //ResponseDataSheetsMerchants(confimID)
    const [currentTutorial, setCurrentTutorial] =
        useState<merchantApiDetail[]>()
    if (isEmptyArray(response2) === true && loading !== false) {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    } else {
        /**
         * Neuladen der Seite
         */
        const routeChange = (path: string) => {
            let paths = path
            history(paths)
        }

        if (
            response2 !== true &&
            loading !== false &&
            approveState === 'disabled'
        ) {
            notify_reload_forbidden(Store)
            setTimeout(() => routeChange(`/Merchant/overview/1`), 3000)
            return (
                <>
                    <SpinnerJSX messageId={'common.loading'} Store={Store} />
                </>
            )
        }

        /**
         * Sendet Formular Daten an die API
         * @param status
         * @param id
         */
        const updateData = (status: boolean, id: any) => {
            let confimID
            if (
                user !== null &&
                user !== undefined &&
                (forbidden('merchant') === true ||
                    forbidden('merchant_ma') === true)
            ) {
                if (user.merchant_id !== null) {
                    confimID = user.merchant_id
                } else {
                    confimID = id
                }
            } else {
                confimID = id
            }
            /*
             *  Wichtig zum Rausholen der Datan aus Formular und senden
             *  Das Interface muss immer angepasst werden
             */
            let Data = init()

            //console.log(Data)

            let reauestData: merchantApiDetail[] & config = Object.assign(
                {},
                currentTutorial,
                output(Data)
            )

            //console.log(reauestData)
            /*
             *  Senden der Erhaltenen Daten an das Frontend
             */
            merchant
                .updatePost(reauestData, confimID)
                .then((response) => {
                    // @ts-ignore
                    setCurrentTutorial({ ...currentTutorial })
                    notify_save(Store)
                    localStorage?.removeItem('merchant')
                    //setTimeout(() => window.location.reload(), 3000);
                })
                .catch((e) => {
                    error(e)
                })
        }

        const StateSave = (
            id: any,
            mode: any,
            value: any,
            notification: any
        ) => {
            if (mode === 1) {
                if (value === 1) {
                    setstateValue('vacation')
                } else {
                    setstateValue('visible')
                }
                saveDataIndividualPut(
                    '/channel/merchant/vacation/' + id + '/',
                    { state: value }
                )
                    .then((data: any) => {
                        setSavevacation(true)
                        notification()
                    })
                    .catch((e) => {
                        error(e)
                    })
                setSavevacation(true)
            } else {
                if (value === 1) {
                    setstateValue('hidden')
                } else {
                    setstateValue('visible')
                }
                saveDataIndividualPut('/channel/merchant/visible/' + id + '/', {
                    state: value
                })
                    .then((data: any) => {
                        setSavevisible(true)
                        notification()
                    })
                    .catch((e) => {
                        error(e)
                    })
                setSavevisible(true)
            }
        }

        const enabled = (id: any, enabled: any, notification: any) => {
            setenabledState(enabled)
            saveDataIndividualPut('channel/merchant/state/' + id + '/', {
                enabled: enabled
            })
                .then((data: any) => {
                    setSavestate(true)
                    notification()
                })
                .catch((e) => {
                    error(e)
                })
            setSavestate(true)
        }
        const saveProvision = (id: any, provision: any, notification: any) => {
            let sumProvision = provision * 100

            let body = { provision: sumProvision }
            saveDataIndividualPut(
                'channel/merchant/provision/' + id + '/',
                body
            )
                .then((data: any) => {
                    setSavestate(true)
                    notification()
                })
                .catch((e) => {
                    error(e)
                })
            setSavestate(true)
        }

        const saveApproved = (id: any, approved: any, notification: any) => {
            let body = { token: 'token', approved: approved }
            saveDataIndividualPut(
                'channel/merchant/onBoarding/' + id + '/',
                body
            )
                .then((data: any) => {
                    setapproveState('')
                    notification()
                })
                .catch((e) => {
                    error(e)
                })
            setapproveState('')
        }

        const saveDimensions = () => {
            let bodyData = { nutrient: localStorage.getItem('Dimensions') }
            let setter
            if (localStorage.getItem('Dimensions') !== null) {
                setter = localStorage.getItem('Dimensions') as string
            } else {
                setter = ''
            }
            let link = 9999
            saveDataIndividualPut(
                '/Product/Abmessungen/' + link + '/',
                bodyData
            )
                .then((data: any) => {
                    notify_save(Store)
                })
                .catch((e) => {
                    error(e)
                })
            // @ts-ignore
            setDimensionsValue(setter)
            setModalDimensionsShow(false)
        }

        let Headers

        let className = ''
        if (
            savevacation === false &&
            savevisible === false &&
            savestate === false &&
            forbidden('merchant') !== true &&
            forbidden('merchant_ma') !== true
        ) {
            Headers = Header(
                response2,
                savevacation,
                setSavevacation,
                savevisible,
                setSavevisible,
                savestate,
                setSavestate,
                StateSave,
                enabled,
                stateValue,
                enabledState,
                saveProvision,
                approveState,
                saveApproved,
                Store
            )
            //className = 'formMC';
        } else {
            if (savevacation === true) {
                setSavevacation(false)
            }

            if (savevisible === true) {
                setSavevisible(false)
            }

            if (savestate === true) {
                setSavestate(false)
            }
            className = ''
        }

        let back
        let category = false
        if (forbidden('merchant') !== true) {
            back = btn_back(history, 'common.abort', '', Store)
            category = true
        }

        let categroyJSX = <></>
        if (category === true) {
            categroyJSX = (
                <Tab
                    eventKey="anbieterkategorien"
                    title={
                        <IntlMessage
                            Store={Store}
                            messageId="common.provider_category"
                        />
                    }
                    key={'anbieterkategorien'}
                    id={'anbieterkategorien'}
                    style={{
                        backgroundColor: 'White',
                        padding: '15px',
                        marginTop: '-15px'
                    }}
                >
                    {taxon(response2, Store)}
                </Tab>
            )
        }

        let datasheetComponent = <></>
        if (process.env.REACT_APP_AGRICA === 'True') {
            datasheetComponent = (
                <>
                    <Button
                        variant="light"
                        style={{
                            marginTop: '5px',
                            float: 'right',
                            background: 'white'
                        }}
                        onClick={() => setDataSheetMerchant(setModalDataSheet)}
                    >
                        <span
                            className="material-icons"
                            style={{ verticalAlign: '-20%' }}
                        >
                            content_paste
                        </span>
                        <IntlMessage Store={Store} messageId="datasheet.edit" />
                    </Button>
                </>
            )
        }
        /** todo sobald Marketplace möglich if erstellen und oberen Headers wieder zulassen */
        Headers = <></>
        return (
            <div>
                {Headers}
                <form id={'form001'} className={className}>
                    {datasheetComponent}
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        key={'tabindex'}
                    >
                        <Tab
                            eventKey="home"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.public_profile"
                                />
                            }
                            key={'allgemein'}
                            id={'allgemein'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {profil(response2, Store)}
                        </Tab>
                        {categroyJSX}
                        <Tab
                            eventKey="billadress"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.billing_information"
                                />
                            }
                            key={'billadress'}
                            id={'billadress'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {billadress(response2, Store)}
                        </Tab>
                        <Tab
                            eventKey="bilder"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.images"
                                />
                            }
                            key={'bilder'}
                            id={'bilder'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {image(response2, Store)}
                        </Tab>
                        <Tab
                            eventKey="freigaben"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.shipping"
                                />
                            }
                            key={'freigaben'}
                            id={'freigaben'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {provision(response2, Store)}
                        </Tab>
                    </Tabs>
                    {modalDataSheetMerchant(
                        modalDataSheetShow,
                        setModalDataSheet,
                        saveDimensions,
                        getDataSheetValue,
                        response2,
                        Store
                    )}
                    <div id={'headerMCfooter'}>
                        {back}
                        <Button
                            variant="success"
                            onClick={() => updateData(false, id)}
                            style={{ float: 'right', marginRight: '15px' }}
                            type={'button'}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.save"
                            />
                        </Button>
                        <br style={{ clear: 'both' }} />
                    </div>
                </form>
            </div>
        )
    }
}
export default MerchantDetail
