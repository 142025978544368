import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import {ResponseAllTimesbyUser} from "../API/response/worktimetracking";
import setNavi from "../../../@WUM/core/component/const/setNavi";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import intlMessage, {IntlMessageValue} from "../../../@WUM/core/component/const/IntlMessage";
import '../css/worktimetracking.css'
import {init2} from "../../../@WUM/core/form-serilizer/seriliaze/Formserialize";
import {getToken} from "../../../@WUM/core/component/const/getToken";
import {ApiURL} from "../../../@WUM/core/component/const/ApiURL";
import WUMCalender from "../../../@WUM/core/component/const/WUMCalender";
import UniModal from "../../../@WUM/core/component/const/UniModal";
const WorkTimeTrackingCalender = (props:{value:any}) =>{
    setNavi({
        route:'/calender/worktime/tracking/software/'
    })

    const SunEditorConfig = () => {
        return [
            // Default
            ['italic','bold','outdent', 'indent','align', 'list','link']
        ]
    }
    const { id } = useParams<{ id: string }>()
    const Store = useSelector((state: any) => state)
    const [fetchData, setFetchData] = useState(0);
    const [load,setLoad] = useState(false);
    const [value, setValue] = useState('');
    const response = ResponseAllTimesbyUser(localStorage.getItem('userid'),fetchData);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const successForm = (result:any) => {
        setFetchData(Date.now())
        setValue('')
        handleClose()
    }

    const Reloadsuccess = () => {
        setFetchData(Date.now())
        setLoad(false)
    }

    const errorForm = (error:any)=> {
        handleClose()
    }


    const setEditorContent = (value:any,textarea?:any) =>{
        setValue(value)
        if(textarea !== undefined) {
            (document.getElementById('descriptionTextarea') as HTMLTextAreaElement).value = value
        }
    }

    const CloseDynmaikModal = () => {
        handleClose();
    }

    const calculateTimeDifferenceinMili = (d1:any, d2:any) => {
        const startTime = new Date('2012/10/09 '+d1).getTime();
        const endTime = new Date('2012/10/09 '+d2).getTime();
        // Differenz berechnen
        var diff = endTime - startTime;

        return diff;

    };

    const handlesubmit = (e:any) => {
        const form = e.currentTarget
        e.preventDefault()
        let Data = init2(form)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ getToken());

        var raw = JSON.stringify(
            {
                "grund": Data.grund,
                "time_start": Data.time_start+':00',
                "time_end": Data.time_end+':00',
                "dauer": calculateTimeDifferenceinMili(Data.time_start,Data.time_end)/1000,
                "date": Data.date,
                "user": localStorage.getItem('userid')
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };

       fetch(ApiURL() +"work/save/timetracking/", requestOptions)
            .then(response => response.text())
            .then(result => {successForm(result)})
            .catch(error => errorForm(error));
    };

    let DataHolder = [];

    const test = (content:any) => {
        console.log(content)
    }

    if (typeof response !== "boolean" && response?.length > 0) {

        for (let x = 0; x < response.length; x++) {

            let dataSet = response[x]
            DataHolder.push(
                {
                    date: dataSet.date + ' ' + dataSet.time_start,
                    enddate: dataSet.date + ' ' + dataSet.time_end,
                    title: IntlMessageValue('de','wtt.'+dataSet.grund,Store),
                    time: dataSet.dauer * 1000,
                    on_Click: test,
                    content: 'test3466',
                    color: (dataSet.grund === 'Pause' ? "#FBB17C" : (dataSet.grund === 'Urlaub' ? "#000"  : "#B3FFBF") ),
                    breack: (dataSet.grund === 'Pause' ? true : false),
                    tcolor:(dataSet.grund === 'Urlaub' ? "#fff"  : "#000")
                }
            )
        }
    }
    console.log(DataHolder)
    return(
        <>
            <div className={'ticketsystem'} style={{paddingLeft: '25px',paddingRight: '25px'}}>
                <Card className={'CardTargetWorkTracking'}>
                    <Card.Body className={'pb-0'}>
                <h3 >
                    <Link className={'btn btn-info float-start mr-2'}
                          to={'/support/software/overview/1'}
                          style={{background: 'white', borderColor: '#23AAE1', color: '#23AAE1'}}>
                        {IntlMessageValue('de', 'common.back', Store)}
                    </Link> {IntlMessageValue('de', 'wtt.headline', Store)}
                    <Button variant="secondary" className={'float-end ml-3'} style={{position:'relative',right:'5px',zIndex:'96'}}
                            onClick={(e) => Reloadsuccess()}>
                                        <span className="material-icons" style={{verticalAlign:'bottom'}}>
                                            autorenew
                                        </span>
                        Reload Page
                    </Button>

                    <UniModal
                        headline={'Add Time Entry' } ClassOpenButton={'float-end'}
                        ClassSaveButton={'float-end'}
                        field={
                        <>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{IntlMessageValue('de', 'wtt.activity', Store)}</Form.Label>
                                        <Form.Select aria-label="Default select example" name={'grund'}>
                                            <option label={IntlMessageValue('de', 'wtt.select.activity', Store)} />
                                            <option value="Arbeit">{IntlMessageValue('de', 'wtt.Arbeit', Store)}</option>
                                            <option value="Pause">{IntlMessageValue('de', 'wtt.Pause', Store)}</option>
                                            <option value="Urlaub">{IntlMessageValue('de', 'wtt.Urlaub', Store)}</option>
                                            <option value="Unterbrechung">{IntlMessageValue('de', 'wtt.Unterbrechung', Store)}</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{IntlMessageValue('de', 'common.date', Store)}</Form.Label>
                                        <Form.Control type="date" name={'date'} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{IntlMessageValue('de', 'wtt.time_start', Store)}</Form.Label>
                                        <Form.Control type="time" name={'time_start'} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>{IntlMessageValue('de', 'wtt.time_end', Store)}</Form.Label>
                                        <Form.Control type="time" name={'time_end'} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    }
                  saveButtonLabel={IntlMessageValue('de', 'common.save', Store)} handlesubmit={handlesubmit}
                  CloseButtonLabel={IntlMessageValue('de', 'common.close', Store)}
                  ButtonLabel={<>
                      <span className="material-icons pr-2" style={{verticalAlign:'bottom'}}>
                            add
                     </span>
                      {IntlMessageValue('de', 'wtt.button.timeEntry', Store)}</>
                    }
                                />


                </h3>
                    </Card.Body>
                </Card>

                <WUMCalender data={DataHolder} All={40} label={IntlMessageValue('de', 'wtt.week.work.time', Store)}
                             breackAll={5} breacklabel={IntlMessageValue('de', 'wtt.week.break.time', Store)} dontShow={false} />



            </div>
        </>
    )
}

export default WorkTimeTrackingCalender;


