const setValue = (map: any, x: number, inputs: any, i: number) => {
    let stringJSONBack: string = ''
    if (inputs[i].nodeName === 'TEXTAREA' || inputs[i].nodeName === 'SELECT') {
        if (x === 0) {
            stringJSONBack =
                '"' +
                map[x] +
                '":"' +
                inputs[i].value
                    ?.replaceAll('"', '\\"')
                    .replaceAll('\n', '\\n') +
                '"'
        } else {
            stringJSONBack =
                ',"' +
                map[x] +
                '":"' +
                inputs[i].value
                    ?.replaceAll('"', '\\"')
                    .replaceAll('\n', '\\n') +
                '"'
        }
    } else if (inputs[i].nodeName === 'INPUT') {
        let Value: any
        if (inputs[i].attributes.type.nodeValue === 'checkbox') {
            let check = inputs[i].checked
            if (check === false) {
                Value = 0
            } else {
                Value = 1
            }
        } else {
            Value = inputs[i].value
        }
        if (x === 0) {
            stringJSONBack = '"' + map[x] + '":"' + Value + '"'
        } else {
            stringJSONBack = ',"' + map[x] + '":"' + Value + '"'
        }
    }

    return stringJSONBack
}

export default setValue
