export const LinksFields = [
    { identifier: 'link-color', type: 'input', field: 'link-color' },
    {
        identifier: 'link-color-hover',
        type: 'input',
        field: 'link-color-hover'
    },
    {
        identifier: 'link-font-weight',
        type: 'input',
        field: 'link-font-weight'
    }
]
