import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import Form from '../../../../../@WUM/core/form-serilizer'
import configuration from '../configuration/home'
import IntlMessage from '../../../../../@WUM/core/component/const/IntlMessage'
import ElementsHomeInterface from '../../../../../@WUM/core/component/core/interface/ElementsHome.interface'

function home(props: ElementsHomeInterface) {
    let Store = props.Store

    if (props.response === 'new') {
        return (
            <>
                {/*    {configuration('titel',[],true,Store)}*/}
                {/*{configuration('description',[],true,Store)}*/}
                <hr key={'hrone'} />
                {/* <Row className="g-2" key={'spalte'}>
          <Col md key={'colone'}>*/}
                {Form(configuration('dataColOne', [], true, Store))}
                {/*  </Col>
          <Col md key={'coltwo'}>*/}
                {Form(configuration('dataColtwo', [], true, Store))}
                {/* </Col>
        </Row>*/}
                <hr key={'hrtwo'} />
            </>
        )
    } else if (props.response.hasOwnProperty('translation')) {
        return (
            <>
                {/* {configuration('titel',props.response,false,Store)}*/}
                {/*{configuration('description',props.response,false,Store)}*/}
                {/*<hr key={'hrone'}/>*/}
                {/* <Row className="g-2" key={'spalte'}>
          <Col md key={'colone'}>*/}
                {Form(
                    configuration('dataColOne', props.response, false, Store)
                )}
                {/*</Col>
          <Col md key={'coltwo'}>*/}
                {Form(
                    configuration('dataColtwo', props.response, false, Store)
                )}
                {/* </Col>
        </Row>*/}
                <hr key={'hrtwo'} />
            </>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                        <IntlMessage Store={Store} messageId="common.loading" />
                    </span>
                </Spinner>
            </>
        )
    }
}

export default home
