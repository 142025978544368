import React from 'react'
import NewsletterDeleted from './Pages/deleted'
import NewsletterOverview from './Pages/Pageoverview'
import NewsletterDetail from './Pages/PageDetail'
import NewsletterNew from './Pages/new'

export const NewsletterPagesConfig = (value: any) => {
    return [
        {
            path: '/Newsletter/overview/:page',
            roles: (process.env.REACT_APP_NEWSLETTER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            elements: <NewsletterOverview value={value} />
        },
        {
            path: '/Newsletter/Detail/:id',
            roles: (process.env.REACT_APP_NEWSLETTER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            elements: <NewsletterDetail value={value} />
        },
        {
            roles: (process.env.REACT_APP_NEWSLETTER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            path: '/Newsletter/Delete/:page/:deleteID',
            elements: <NewsletterDeleted value={value} />
        },
        {
            path: '/Newsletter/new/:page',
            roles: (process.env.REACT_APP_NEWSLETTER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            elements: <NewsletterNew value={value} />
        }
    ]
}
export const NewsletterMenuConfig = (value: any) => {
    return [
        {
            header: 'Newsletter System',
            icon: 'event',
            roles: (process.env.REACT_APP_NEWSLETTER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
            menuitem: [
                {
                    name: 'Newsletter',
                    route: '/Newsletter/overview/1',
                    icon: 'calendar_today',
                    roles: (process.env.REACT_APP_NEWSLETTER_FORBIDDEN === 'true' ? ['Forbidden'] : ['superadmin', 'admin', 'ContentCreator']),
                }
            ]
        }
    ]
}


export const DynamikNewsletterMenuSingleConfig = (value: any) => {
    return [
        {
            name: 'Newsletter',
            route: '/Newsletter/overview/1',
            icon: 'calendar_today',
            roles: ['superadmin', 'admin']
        }
    ]
}


export const DynamikNewsletterDasboard = () => [
    {
        title: 'Newsletter System',
        href: '/Newsletter/overview/1',
        undertitle: 'Newsletter',
        icon: 'calendar_today',
        text: 'Newsletter System',
        roles: ['superadmin', 'admin']
    }
]

export const NewsletterSidebar: string = '/Newsletter/overview/1'
