import IntlMessage, {
    IntlMessageValue
} from '../../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    let config = [
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.name" />,
            type: 'text',
            required: true,
            placeholder: IntlMessageValue('de', 'common.insert_name', Store),
            name: 'name',
            value: newElement === false ? response.name : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.code" />,
            required: true,
            type: 'text',
            name: 'code',
            value: newElement === false ? response.code : ''
        },
        {
            formType: 'textarea',
            label: <IntlMessage Store={Store} messageId="common.description" />,
            type: 'textarea',
            row: 3,
            name: 'description',
            value: newElement === false ? response.description : ''
        }
    ]

    return config
}

export default configuration
