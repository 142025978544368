import { isEmptyArray } from 'formik'
import HelperTechTesterProps from '../../Interface/HelperTechTester.interface'

export const HelperTechTester = (props: HelperTechTesterProps) => {
    let testerHolder = []
    if (props.issue.tester !== null && props.issue.tester !== undefined) {
        for (let x = 0; x < props.issue.tester.length; x++) {
            testerHolder.push(props.issue.tester[x].tester)
        }
    }
    if (props.tech !== undefined && !isEmptyArray(props.tech)) {
        let OptionSwimlane = []
        for (let x = 0; x < props.tech.length; x++) {
            let dataAttribute
            let type = ''
            if (testerHolder.includes(props.tech[x].username)) {
                type = '&#9745;'
                dataAttribute = 'Delete'
            } else {
                dataAttribute = 'Post'
            }

            let options = type + ' ' + props.tech[x].username

            OptionSwimlane.push(
                <>
                    <option
                        id={
                            'optionTester' +
                            props.tech[x].username.replaceAll(' ', '_')
                        }
                        value={props.tech[x].username}
                        data-param={dataAttribute}
                        dangerouslySetInnerHTML={{ __html: options }}
                    />
                </>
            )
        }

        return <>{OptionSwimlane}</>
    } else {
        return <></>
    }
}

export default HelperTechTester
