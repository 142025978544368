export interface config {
    id: number
    url: string
    target: string
    method: string
    active: string
    isDeleted: string
}

export function output(Data: any) {
    let back = {
        id: Data.id,
        url: Data.url,
        target: Data.target,
        method: Data.method,
        active: Data.active,
        isDeleted: Data.isDeleted
    }

    return back
}
