import Avatar from "@material-ui/core/Avatar";
import ReactDOM from "react-dom";

const appendIcon = (teamMember:any,id:any,func:any,x:any,p:any) => {

    if(teamMember !== undefined && teamMember !== '') {

        let newElement = <Avatar className={'float-end teamMember '} id={id+'added'} style={{border: '1px solid #bdbdbd',background: 'white',color: 'blue'}}>
                                    <span className="material-icons" onClick={()=>func(x,p)}>
                                        add
                                        </span>
        </Avatar>
        let temp = document.createElement('div')
        temp.id = id+'_new'
        ReactDOM.render(newElement, temp)
        let ele = document.getElementById(id) as HTMLDivElement
        if(ele !== null) {
            ele.prepend(temp)
        }



    } else {
        return;
    }

}

export default appendIcon;


