

const Setter = (part:any,Fetch:any) => {

    if(Fetch !== undefined && Fetch !== null) {
        for(let x=0;x<Fetch.length;x++) {
            if(Fetch[x].userid !== undefined ){
                if(Fetch[x].userid == localStorage.getItem('userid')) {
                    if(part !== undefined && part !== null) {
                        return Fetch[x][part]
                    } else {
                        return Fetch[x]
                    }

                }
            }

        }
    }
}

export default Setter;
