import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import PielersForm from '../../form-serilizer'
import React from 'react'
import IntlMessage, { IntlMessageValue } from './IntlMessage'

const MediaGalerie = (store: any) => {
    const closeModal = (id: any, idsecond: any) => {
        let eles = document.getElementById(id) as HTMLDivElement
        eles.style.display = 'none'
        let ele = document.getElementById(idsecond) as HTMLDivElement
        ele.innerHTML = ''
        let eletags = document.getElementById('tags') as HTMLDivElement
        eletags.innerHTML = ''
    }

    const closeModalIMG = (id: any, idsecond: any) => {
        let eles = document.getElementById(id) as HTMLDivElement
        eles.style.display = 'none'
        let eless = document.getElementById(idsecond) as HTMLDivElement
        eless.innerHTML = ''
        let date = document.getElementById('ga-img-date') as HTMLSpanElement
        date.innerHTML = 'Loading....'

        let name = document.getElementById('ga-img-name') as HTMLSpanElement
        name.innerHTML = 'Loading....'

        let content_type = document.getElementById(
            'ga-img-type'
        ) as HTMLSpanElement
        content_type.innerHTML = 'Loading....'

        let content_size = document.getElementById(
            'ga-img-content_size'
        ) as HTMLSpanElement
        content_size.innerHTML = 'Loading....'

        let width = document.getElementById('ga-img-width') as HTMLSpanElement
        width.innerHTML = 'Loading....'

        let height = document.getElementById('ga-img-height') as HTMLSpanElement
        height.innerHTML = 'Loading....'

        let eleIMG = document.getElementById('placeIMG') as HTMLImageElement
        eleIMG.src = ''
    }
    const config = [
        {
            formType: 'formFileBucket',
            onclick: true,
            onclick_context: 'pielers_element_text_image',
            append: 'preview_media',
            experiment: true,
            src: 'image_title_image_',
            type: 'file',
            size: {
                xl: 10,
                md: 10,
                sm: 10
            }
        }
    ]
    return (
        <>
            <div id={'modal'}>
                <div
                    id={'dialog'}
                    role="dialog"
                    aria-modal="true"
                    className="fade modal show"
                >
                    <div className="modal-dialog modal-fullscreen">
                        <div
                            className="modal-content"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                            <div className="modal-header">
                                <img
                                    src={process.env.REACT_APP_LOGO_IMG}
                                    height="60"
                                    alt="Logo"
                                    className="SidebarLogo"
                                />
                                <div className="modal-title h4 ml-4">
                                    <IntlMessage
                                        messageId={'galerie.header'}
                                        Store={store}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() =>
                                        closeModal('dialog', 'imgHolder')
                                    }
                                />
                            </div>
                            <div className="modal-body">
                                <Tabs
                                    key={'home'}
                                    defaultActiveKey="content"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab
                                        eventKey="home"
                                        title={IntlMessageValue(
                                            'de',
                                            'galerie.tab.header.upload',
                                            store
                                        )}
                                        key={'allgemein'}
                                        id={'allgemein'}
                                        style={{
                                            backgroundColor: 'White',
                                            padding: '15px',
                                            marginTop: '-15px'
                                        }}
                                    >
                                        <input
                                            id={'namespace'}
                                            type={'hidden'}
                                            defaultValue={''}
                                        />
                                        <input
                                            id={'Imgsrc'}
                                            type={'hidden'}
                                            defaultValue={''}
                                        />
                                        <input
                                            id={'append'}
                                            type={'hidden'}
                                            defaultValue={''}
                                        />
                                        <input
                                            id={'Galeriemode'}
                                            type={'hidden'}
                                            defaultValue={''}
                                        />
                                        {PielersForm(config)}
                                    </Tab>
                                    <Tab
                                        key={'content'}
                                        eventKey="content"
                                        title={IntlMessageValue(
                                            'de',
                                            'galerie.tab.header.medien',
                                            store
                                        )}
                                        id={'Inhalt'}
                                        style={{
                                            backgroundColor: 'White',
                                            padding: '15px',
                                            marginTop: '-15px'
                                        }}
                                    >
                                        <div id={'tags'} />
                                        <div className="row ga-h-100">
                                            <div className="col ga-h-100">
                                                <div
                                                    id="imgHolder"
                                                    className="row ga-img-holder"
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="float-end btn btn-primary"
                                    onClick={() =>
                                        closeModal('dialog', 'imgHolder')
                                    }
                                >
                                    <IntlMessage
                                        messageId={'galerie.close'}
                                        Store={store}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id={'modal'}>
                <div
                    id={'ga-options'}
                    role="dialog"
                    aria-modal="true"
                    className="fade modal show"
                >
                    <div className="modal-dialog modal-lg">
                        <div
                            className="modal-content"
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                            <div className="modal-header">
                                <img
                                    src={process.env.REACT_APP_LOGO_IMG}
                                    height="60"
                                    alt="Logo"
                                    className="SidebarLogo"
                                />
                                <div className="modal-title h4 ml-4">
                                    <IntlMessage
                                        messageId={'galerie.attachment'}
                                        Store={store}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() =>
                                        closeModalIMG(
                                            'ga-options',
                                            'optionHolder'
                                        )
                                    }
                                />
                            </div>
                            <div
                                className="modal-body"
                                style={{ padding: '0px 12px 12px  12px' }}
                            >
                                <div className="row">
                                    <div
                                        className="details"
                                        style={{ background: '#f6f7f7' }}
                                    >
                                        <br />
                                        <div className="uploaded">
                                            <strong>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.upload.at'
                                                    }
                                                    Store={store}
                                                />{' '}
                                            </strong>{' '}
                                            <span id={'ga-img-date'}>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.loading'
                                                    }
                                                    Store={store}
                                                />
                                            </span>{' '}
                                            |
                                            <strong>
                                                {' '}
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.file.name'
                                                    }
                                                    Store={store}
                                                />{' '}
                                            </strong>{' '}
                                            <span id={'ga-img-name'}>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.loading'
                                                    }
                                                    Store={store}
                                                />
                                            </span>
                                        </div>
                                        <div className="file-type">
                                            <strong>
                                                {' '}
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.file.type'
                                                    }
                                                    Store={store}
                                                />{' '}
                                            </strong>
                                            <span id={'ga-img-type'}>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.loading'
                                                    }
                                                    Store={store}
                                                />
                                            </span>{' '}
                                            |
                                            <strong>
                                                {' '}
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.file.size'
                                                    }
                                                    Store={store}
                                                />{' '}
                                            </strong>
                                            <span id={'ga-img-content_size'}>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.loading'
                                                    }
                                                    Store={store}
                                                />
                                            </span>{' '}
                                            <IntlMessage
                                                messageId={
                                                    'galerie.attachment.file.size.unit'
                                                }
                                                Store={store}
                                            />{' '}
                                            <strong>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.file.scale'
                                                    }
                                                    Store={store}
                                                />{' '}
                                            </strong>
                                            <span id={'ga-img-width'}>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.loading'
                                                    }
                                                    Store={store}
                                                />
                                            </span>
                                            <IntlMessage
                                                messageId={
                                                    'galerie.attachment.file.scale.seperator'
                                                }
                                                Store={store}
                                            />
                                            <span id={'ga-img-height'}>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.loading'
                                                    }
                                                    Store={store}
                                                />
                                            </span>{' '}
                                            <IntlMessage
                                                messageId={
                                                    'galerie.attachment.file.pixel'
                                                }
                                                Store={store}
                                            />
                                        </div>
                                        <div>
                                            <strong>
                                                {' '}
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.file.url'
                                                    }
                                                    Store={store}
                                                />{' '}
                                            </strong>{' '}
                                            <span id={'ga-img-url'}>
                                                <IntlMessage
                                                    messageId={
                                                        'galerie.attachment.loading'
                                                    }
                                                    Store={store}
                                                />
                                            </span>
                                        </div>

                                        <br />
                                    </div>
                                </div>
                                <div className="row ga-h-100">
                                    <div id="imgPlace" className="col">
                                        <br />
                                        <img id={'placeIMG'} alt={''} />
                                        <video
                                            id="placeVideo"
                                            width={500}
                                            controls
                                        >
                                            <source type="video/mp4" />
                                            Your browser does not support the
                                            video tag.
                                        </video>
                                    </div>
                                    <div
                                        id="optionHolder"
                                        className="col ga-options"
                                    />
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="float-end btn btn-danger"
                                    onClick={() =>
                                        closeModalIMG(
                                            'ga-options',
                                            'optionHolder'
                                        )
                                    }
                                >
                                    <IntlMessage
                                        messageId={'galerie.close'}
                                        Store={store}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MediaGalerie
