export const H1 = {
    eventkey: 'H1',
    key: 'H1',
    title: 'H1',
    content: [
        {
            label: 'headline.h1.font-size-h1',
            discr: 'font-size-h1',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h1.font-weight-h1',
            discr: 'font-weight-h1',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h1.font-color-h1',
            discr: 'font-color-h1',
            size: [3, 3, 3],
            type: 'text'
        }
    ]
}
