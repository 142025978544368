import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";

const SendMessage = (text:any,preText:any) => {

    var raw = JSON.stringify(
    {
        "attachments": [
        {
            "pretext": preText,
            "text": text.replace(/<[^>]*>?/gm, ''),
        }
    ]
    }
    );


    let Token = (process.env.REACT_APP_MM_TOKEN !== undefined && process.env.REACT_APP_MM_TOKEN !== null && process.env.REACT_APP_MM_TOKEN !== '' ?
        process.env.REACT_APP_MM_TOKEN :
        'ffdk18sicin6fb4t19wuybc4pw')

    let URL = (process.env.REACT_APP_MM_URL !== undefined && process.env.REACT_APP_MM_URL !== null && process.env.REACT_APP_MM_URL !== '' ?
        process.env.REACT_APP_MM_TOREACT_APP_MM_URLKEN :
        'https://mm.wum-solution.de/hooks/')

    var requestOptions = {
        method: 'POST',
        body: raw
    };

    fetch(URL+Token, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}

export default SendMessage;




export const FetchwithMattermostANDsuccessFormWithClosedivIdandTrue = (props:{endpoint:string,requestOptions:any,successForm:any,errorForm:any,close:any,divid:any,text:string,preText:any}) => {
    fetch(ApiRoot()+props.endpoint, props.requestOptions)
        .then((response) => response.text())
        .then((result) => props.successForm(result,props.close,true,props.divid))
        .catch((error) => props.errorForm(error,props.close,props.divid))
    SendMessage(props.text,props.preText)

}

export const FetchwithMattermostANDsuccessFormWithClose = (props:{endpoint:string,requestOptions:any,successForm:any,errorForm:any,close:any,text:string,preText:any}) => {
    fetch(ApiRoot()+props.endpoint, props.requestOptions)
        .then((response) => response.text())
        .then((result) => props.successForm(result,props.close))
        .catch((error) => props.errorForm(error,props.close))
    SendMessage(props.text,props.preText)

}

export const FetchwithMattermostANDsuccessForm = (props:{endpoint:string,requestOptions:any,successForm:any,errorForm:any,text:string,preText:any}) => {
    fetch(ApiRoot()+props.endpoint, props.requestOptions)
        .then((response) => response.text())
        .then((result) => props.successForm(result))
        .catch((error) => props.errorForm(error))
    SendMessage(props.text,props.preText)

}

export const FetchwithMattermostOnlysetFetchData = (props:{endpoint:string,requestOptions:any,setFetchData:any,text:string,preText:any}) => {
    fetch(ApiRoot()+props.endpoint, props.requestOptions)
        .then(response => response.text())
        .then(result =>  props.setFetchData(Date.now()))
        .catch(error => console.log('error', error));
    SendMessage(props.text,props.preText)

}
