import React from 'react'
import Menu from './modules'

export const MenuConfig = (value: any) => {
    return [
        {
            path: '/Menu/:page',
            elements: <Menu value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        }
    ]
}

export const MenuMenuConfig = (value: any) => {
    return [
        {
            name: 'Menü',
            route: '/Menu/1',
            icon: 'menu',
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        }
    ]
}
