import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'
import { Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'

const Engagements = () => {
    return (
        <>
            <CreateMatomoIframe
                headline={'Graph der wiederkehrenden Besuche'}
                width={'100%'}
                height={'300'}
                url={WidgetURLMapping({
                    name: 'Graph of recent visits engagements'
                })}
                scrolling={'yes'}
                id={'engagement1'}
            />
            <CreateMatomoIframe
                headline={'Häufigkeitsübersicht'}
                width={'100%'}
                height={'600'}
                url={WidgetURLMapping({ name: 'Frequency overview' })}
                scrolling={'yes'}
                id={'engagement2'}
            />
            <Row>
                <Col>
                    <CreateMatomoIframe
                        headline={'Besuche nach Besuchsdauer'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({
                            name: 'Visits by visit duration'
                        })}
                        scrolling={'yes'}
                        id={'engagement3'}
                    />
                    <CreateMatomoIframe
                        headline={'Besuche nach Besuchsanzahl'}
                        width={'100%'}
                        height={'600'}
                        url={WidgetURLMapping({
                            name: 'Visits by number of visits'
                        })}
                        scrolling={'yes'}
                        id={'engagement4'}
                    />
                </Col>
                <Col>
                    <CreateMatomoIframe
                        headline={'Besuche nach Seitenanzahl'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({ name: 'Visits by page count' })}
                        scrolling={'yes'}
                        id={'engagement5'}
                    />
                    <CreateMatomoIframe
                        headline={'Besuche nach Tage seit letztem Besuch'}
                        width={'100%'}
                        height={'600'}
                        url={WidgetURLMapping({
                            name: 'Visits by days since last visit'
                        })}
                        scrolling={'yes'}
                        id={'engagement5'}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Engagements
