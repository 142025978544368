import { UserActionType } from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const UserRequestInit = () => {
    return {
        type: UserActionType.User_INIT
    }
}

export const UserSuccess = (
    username: string,
    email: string,
    is_superuser: any,
    id: any,
    first_name: any,
    last_name: any,
    roles: any
) => {
    return {
        type: UserActionType.User_INIT,
        username: username,
        email: email,
        first_name: first_name,
        last_name: last_name,
        is_superuser: is_superuser,
        roles: roles,
        id: id
    }
}

export const getUser = (
    username: string,
    email: any,
    is_superuser: any,
    id: any,
    first_name: any,
    last_name: any,
    roles: any
) => {
    return (dispatch: Dispatch) => {
        dispatch(
            UserSuccess(
                username,
                email,
                first_name,
                last_name,
                is_superuser,
                roles,
                id
            )
        )
    }
}
