export const footer = {
    eventkey: 'footer',
    key: 'Footers',
    title: 'Footer',
    content: [
        {
            label: 'footer.footer-background',
            discr: 'footer-background',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'footer.font-size-footer-nav',
            discr: 'font-size-footer-nav',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'footer.font-weight-footer-nav',
            discr: 'font-weight-footer-nav',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'footer.font-color-footer-nav',
            discr: 'font-color-footer-nav',
            size: [3, 3, 3],
            type: 'color'
        },
        {
            label: 'footer.footer-width',
            discr: 'footer-width',
            size: [3, 3, 3],
            type: 'color'
        }
    ]
}
