import { useEffect, useState } from 'react'
import { customizer } from '../../request/customizer/customizer'
import { customizerApiType } from '../../request/customizer/customizerApi.interface'
import { customizerApiDetailType } from '../../request/customizer/customizerApiDetail.interface'
import { checkLogin } from '../../../../../../@WUM/templates/default/Auth/service/AuthService'

/**
 * Sends response to Rest API for example
 * @memberOf customizer
 * @prop limit
 * @prop searchValue
 * @see {@link exampleApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const ResponseCustomizer = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<customizerApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await customizer
                .getPosts(limit, searchValue)
                .then((data: customizerApiType[]) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err: any) => {
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

/**
 * Sends response to Rest API for delete a example Post
 * @memberOf customizer
 * @prop id
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseCustomizerDelete = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await customizer
                .deletePost(id)
                .then((data: void) => {
                    return data
                })
                .catch((err: any) => {
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}

/**
 * Sends response to Rest API for example Detail PAge
 * @memberOf customizer
 * @prop id
 * @see {@link exampleApiType}
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponseDetailCustomizer = (id: any) => {
    const [posts, setPosts] = useState<customizerApiDetailType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await customizer
                .getAPost(id)
                .then((data: customizerApiDetailType[]) => {
                    localStorage.setItem('js', data[0].js)
                    return setPosts(data)
                })
                .catch((err: any) => {
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}
