import React from 'react'
import Form from 'react-bootstrap/Form'
import legende from './helper/legende'
import Col from 'react-bootstrap/Col'
import IntlMessage from '../../component/const/IntlMessage'
import Gallery from '../../component/const/galery'

function formFile(data: any, Store: any) {
    const namespace = 'galerieFile' + data.expandfile

    let label =
        data.label !== undefined ? (
            <>
                {' '}
                <Form.Label>{data.label}</Form.Label>
            </>
        ) : (
            <></>
        )
    let br =
        data.Nonebr === 'true' ? (
            <></>
        ) : (
            <>
                <br />
            </>
        )
    if (data.experiment !== undefined) {
        const senden = () => {
            let eles = document.getElementById('dialog') as HTMLDivElement
            eles.style.display = 'block'
            if (data.jsonID !== null && data.jsonID !== undefined) {
                Gallery(
                    data.append,
                    data.src,
                    namespace,
                    Store,
                    data.mode,
                    data.jsonID,
                    data.appendJson,
                    data.appendJsonField
                )
            } else {
                Gallery(data.append, data.src, namespace, Store, data.mode)
            }
        }

        if (data.imgupload !== undefined) {
            return (
                <>
                    {br}
                    {label}
                    <div
                        className="mb-3"
                        style={{
                            border: ' 1px solid #ced4da',
                            cursor: 'pointer',
                            borderRadius: '0.25rem',
                            transition:
                                'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                            width:
                                data.width !== undefined
                                    ? data.width + 20 + 'px'
                                    : 'auto',
                            height:
                                data.height !== undefined
                                    ? data.width + 35 + 'px'
                                    : 'auto',
                            textAlign: 'center'
                        }}
                        onClick={() => senden()}
                    >
                        <img
                            id={data.src}
                            src={data.imgsrc}
                            alt={'Bild'}
                            style={{
                                width:
                                    data.width !== undefined
                                        ? data.width + 'px'
                                        : 'auto',
                                height:
                                    data.height !== undefined
                                        ? data.height + 'px'
                                        : 'auto'
                            }}
                        />
                        <label
                            className="form-label"
                            style={{ paddingTop: '8px', cursor: 'pointer' }}
                            id={namespace}
                        >
                            <IntlMessage
                                Store={data.Store}
                                messageId="common.formfile.none_selected"
                            />
                        </label>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    {label}
                    {br}
                    <div
                        className="mb-3"
                        style={{
                            border: ' 1px solid #ced4da',
                            cursor: 'pointer',
                            borderRadius: '0.25rem',
                            transition:
                                'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                        }}
                        onClick={() => senden()}
                    >
                        <Col
                            style={{
                                minWidth: '140px',
                                maxWidth: 'fit-content',
                                padding: 0,
                                float: 'left',
                                marginRight: '10px'
                            }}
                        >
                            <span
                                className="input-group-text"
                                style={{ cursor: 'pointer' }}
                            >
                                <IntlMessage
                                    Store={data.Store}
                                    messageId="common.formfile.file"
                                />
                            </span>
                        </Col>
                        <Col style={{ minWidth: '125px' }}>
                            <label
                                className="form-label"
                                style={{ paddingTop: '8px', cursor: 'pointer' }}
                                id={namespace}
                            >
                                <IntlMessage
                                    Store={data.Store}
                                    messageId="common.formfile.none_selected"
                                />
                            </label>
                        </Col>
                    </div>
                    <p
                        style={{
                            color: '#b0b0b0',
                            fontSize: '14px',
                            lineHeight: '1.3em',
                            marginTop: '5px'
                        }}
                        key={'legende'}
                    >
                        <IntlMessage
                            Store={data.Store}
                            messageId="bucket.file.placeholder"
                        />
                    </p>
                </>
            )
        }
    } else {
        return (
            <>
                {br}
                <Form.Group
                    controlId={'formFile' + data.label}
                    key={'formFile' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control type={data.type} />
                </Form.Group>
                {legende(data)}
            </>
        )
    }
}

export default formFile

export const defaultIMG = () => {
    return '/assets/images/noimage.jpg'
}
