import React from 'react'
import PielersForm from '../../index'
import { Button } from 'react-bootstrap'
import { base, basesettings } from './base'
import IntlMessage, {
    IntlMessageValue
} from '../../../component/const/IntlMessage'
import MergeTwoObjecttoArray from '../../../component/const/MergeTwoObjecttoArray'
import CopyElements from '../../../component/const/CopyElements'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import WebBuilderElementsHead from '../../../component/const/WebBuilderElementsHead'
import SunEditorBig from '../../../component/const/SunEditorBig'
import ReactDOM from 'react-dom'
import { defaultIMG } from '../../elements/formFile'
import Box from '@mui/material/Box'

function ung_masonryWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    galerie?: any,
    galerieItems?: any
) {
    localStorage.removeItem('Iterator')

    const index = newElement === false ? i : newelementIndex
    const fake_headlines = [1, 2, 3, 4, 5, 6].map((n) => ({
        label: IntlMessageValue(
            'de',
            'module.fake_headline_n',
            Store,
            'replace',
            '[n]',
            n
        ),
        value: n
    }))

    const headlines = [1, 2, 3, 4, 5, 6].map((n) => ({
        label: IntlMessageValue(
            'de',
            'module.headline_n',
            Store,
            'replace',
            '[n]',
            n
        ),
        value: n + 6
    }))

    let options = MergeTwoObjecttoArray(fake_headlines, headlines)

    const configuration = [
        {
            formType: 'select',
            label: (
                <IntlMessage Store={Store} messageId="module.headline_type" />
            ),
            name: 'elements[' + index + '][headline_type]',
            legende: <IntlMessage Store={Store} messageId="common.status" />,
            selected: newElement === false ? element.headline_type : '',
            options: options
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.headline" />,
            name: 'elements[' + index + '][translation][headline]',
            type: 'text',
            placeholder: 'lang[common.insert_headline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.headline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/ung_masonry.html'
        }
    ]

    localStorage.setItem(
        'Iterator_ung_masonry' + index,
        newElement === false
            ? element.hasOwnProperty('items')
                ? element.items.length
                : 0
            : 0
    )

    const newItemGenerate = (
        element: any,
        index: any,
        data: any,
        Store: any,
        galerie: any,
        galerieItems: any
    ) => {
        let Items = []
        let x
        if (
            localStorage.getItem('Iterator_ung_masonry' + index) !==
                undefined &&
            localStorage.getItem('Iterator_ung_masonry' + index) !== null
        ) {
            x = localStorage.getItem('Iterator_ung_masonry' + index)
        } else {
            x = element.items.length
        }

        Items.push({
            formType: 'multiplyinRow',
            RowId: 'ung_masonrynRow_' + x + '_' + index,
            singelCol: true,
            class: 'mb-3 boxShadowCard card hexacard',
            colsize: 2,
            cols: [
                {
                    formType: 'input',
                    name: 'elements[' + index + '][items][' + x + '][id]',
                    type: 'hidden',
                    value: 0
                },
                {
                    formType: 'formFile',
                    onclick: true,
                    id: 'media_titleFormField' + index + x,
                    onclick_context: 'pielers_element_title_image',
                    Gallery: galerie,
                    galerieItems: galerieItems,
                    expandfile: index + 'galerie' + x,
                    imgupload: true,
                    experiment: true,
                    imgsrc: defaultIMG(),
                    label: 'Bild',
                    height: 170,
                    append:
                        'elements[' + index + '][items][' + x + '][media_id]',
                    src: 'media_title_image_' + index + x,
                    size: {
                        xl: 6,
                        md: 6,
                        sm: 6
                    }
                },
                {
                    formType: 'input',
                    name: 'elements[' + index + '][items][' + x + '][media_id]',
                    id: 'elements[' + index + '][items][' + x + '][media_id]',
                    visible: true,
                    label: 'mediaID',
                    type: 'hidden',
                    placeholder: 'mediaID',
                    value: ''
                },
                SunEditorBig(
                    '',
                    'text',
                    true,
                    data,
                    'elements[' + index + '][items][' + x + '][text]',
                    IntlMessageValue('de', 'common.edit_text', Store),
                    '',
                    '',
                    '',
                    '',
                    'inline',
                    150
                ),
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            Store={Store}
                            messageId="common.css_class"
                        />
                    ),
                    type: 'text',
                    placeholder: IntlMessageValue(
                        'de',
                        'common.css_class',
                        Store
                    ),
                    name: 'elements[' + index + '][items][' + x + '][class]',
                    value: ''
                }
            ]
        })

        let newConfiguration = Items
        let newElement = <>{PielersForm(newConfiguration)}</>
        let temp = document.createElement('div')
        temp.classList.add('col-md-4')
        ReactDOM.render(newElement, temp)
        let ele = document.getElementById(
            'ung_masonrynRow' + index
        ) as HTMLDivElement
        if (ele !== null) {
            ele.appendChild(temp)
            // @ts-ignore

            if (
                localStorage.getItem('Iterator_ung_masonry' + index) !==
                    undefined &&
                localStorage.getItem('Iterator_ung_masonry' + index) !== null
            ) {
                let iterator =
                    parseInt(
                        String(
                            localStorage.getItem(
                                'Iterator_ung_masonry' + index
                            ) as unknown as number
                        )
                    ) + 1
                localStorage.setItem(
                    'Iterator_ung_masonry' + index,
                    String(iterator)
                )
            } else {
                localStorage.setItem('Iterator_ung_masonry' + index, x + 1)
            }
        }
    }

    let newConfiguration = configuration

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }

    return (
        <>
            <Box
                id={'ung_masonry' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    'ung_masonry',
                    'ung_masonry',
                    '',
                    Status,
                    index,
                    'elementButton',
                    'ung_masonry',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_ung_masonry' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_ung_masonry' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_ung_masonry' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <div id={'Teaser'}>text</div>
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                ung_masonry
                            </Col>
                            <Button
                                variant={'success'}
                                onClick={() =>
                                    newItemGenerate(
                                        element,
                                        index,
                                        data,
                                        Store,
                                        galerie,
                                        galerieItems
                                    )
                                }
                                className={'mb-3 '}
                            >
                                Neues Galerie Bild hinzufügen
                            </Button>
                            <br />
                            <Row id={'ung_masonrynRow' + index}>
                                {buildItems(
                                    element,
                                    index,
                                    newElement,
                                    data,
                                    Store,
                                    galerie,
                                    galerieItems
                                )}
                            </Row>
                            {PielersForm(
                                base(element, i, newElement, newelementIndex)
                            )}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'ung_masonry'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(newConfiguration)}
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(element.id, 'Text Icon', Store)
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default ung_masonryWebBuilder

const buildItems = (
    element: any,
    index: any,
    newElement: any,
    data: any,
    Store: any,
    galerie: any,
    galerieItems: any
) => {
    let Items = []
    let elementItem = element.items

    if (elementItem !== undefined) {
        for (let x = 0; x < elementItem.length; x++) {
            Items.push({
                formType: 'multiplyinRow',
                RowId: 'ung_masonrynRow_' + x + '_' + index,
                singelCol: true,
                class: 'mb-3 boxShadowCard card hexacard',
                colsize: 2,
                cols: [
                    {
                        formType: 'input',
                        name: 'elements[' + index + '][items][' + x + '][id]',
                        type: 'hidden',
                        value: elementItem[x].id
                    },
                    {
                        formType: 'formFile',
                        onclick: true,
                        id: 'media_titleFormField' + index + x,
                        onclick_context:
                            newElement === false
                                ? element.context
                                : 'pielers_element_title_image',
                        Gallery: galerie,
                        galerieItems: galerieItems,
                        expandfile: index + 'galerie' + x,
                        imgupload: true,
                        experiment: true,
                        imgsrc:
                            newElement === false
                                ? elementItem[x].media !== null
                                    ? elementItem[x].media.cdn_url
                                    : defaultIMG()
                                : defaultIMG(),
                        label: 'Bild',
                        height: 170,
                        append:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][media_id]',
                        src: 'media_title_image_' + index + x,
                        size: {
                            xl: 6,
                            md: 6,
                            sm: 6
                        }
                    },
                    {
                        formType: 'input',
                        name:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][media_id]',
                        id:
                            'elements[' +
                            index +
                            '][items][' +
                            x +
                            '][media_id]',
                        visible: true,
                        label: 'mediaID',
                        type: 'hidden',
                        placeholder: 'mediaID',
                        value:
                            newElement === false ? elementItem[x].media_id : ''
                    },
                    SunEditorBig(
                        newElement === false ? elementItem[x] : '',
                        'text',
                        newElement,
                        data,
                        'elements[' + index + '][items][' + x + '][text]',
                        IntlMessageValue('de', 'common.edit_text', Store),
                        '',
                        '',
                        '',
                        '',
                        'inline',
                        150
                    ),
                    {
                        formType: 'input',
                        label: (
                            <IntlMessage
                                Store={Store}
                                messageId="common.css_class"
                            />
                        ),
                        type: 'text',
                        placeholder: IntlMessageValue(
                            'de',
                            'common.css_class',
                            Store
                        ),
                        name:
                            'elements[' + index + '][items][' + x + '][class]',
                        value: newElement === false ? elementItem[x].class : ''
                    }
                ]
            })
        }
        let newConfiguration = Items
        return PielersForm(newConfiguration)
    } else {
        return
    }
}
