import { IntlMessageValue } from '../component/const/IntlMessage'
import React from 'react'

interface ChangeHeaderProps {
    value: any
    header: string
    Store: any
}

const ChangeHeader = (props: ChangeHeaderProps) => {
    if (props.header !== undefined && props.header.includes('[br]')) {
        return (
            <>
                {props.header.split('[br]')[0]}
                <br />
                {props.header.split('[br]')[1]}
            </>
        )
    } else {
        return IntlMessageValue(props.value, props.header, props.Store)
    }
}

export default ChangeHeader
