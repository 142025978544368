import IntlMessage, {
    IntlMessageValue
} from '../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    //const titel = (<><h2><IntlMessage Store={Store} messageId="seo.title" /></h2></>);
    const titel = (
        <>
            <h2>SEO</h2>
        </>
    )
    //const description = (<><p><IntlMessage Store={Store} messageId="seo.description" /></p></>);
    const description = <></>

    const configuration = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'translation[seo][id]',
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.id
                        : 0
                    : 0
        },
        {
            formType: 'textarea',
            label: <IntlMessage Store={Store} messageId="common.title" />,
            type: 'textarea',
            max: 70,
            seo: true,
            name: 'translation[seo][seo_title]',
            id: 'seo_title',
            placeholder: IntlMessageValue(
                'de',
                'common.provide_pagetitle',
                Store
            ),
            //'legende': <IntlMessage Store={Store} messageId="seo.title.legend" />,
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.seo_title
                        : ''
                    : ''
        },
        {
            formType: 'textarea',
            label: <IntlMessage Store={Store} messageId="common.description" />,
            max: 160,
            seo: true,
            type: 'textarea',
            id: 'seo_description',
            //'legende': <IntlMessage Store={Store} messageId="seo.description.legend" />,
            row: 3,
            name: 'translation[seo][seo_description]',
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.seo_description
                        : ''
                    : ''
        }
    ]

    const configurationCol2 = [
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="seo.keywords" />,
            type: 'text',
            name: 'translation[seo][seo_keywords]',
            placeholder: 'keyword, keyword 2, ...',
            //'legende': <IntlMessage Store={Store} messageId="seo.keywords.legend" />,
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.seo_keywords
                        : ''
                    : ''
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="seo.index" />,
            //'legende': <IntlMessage Store={Store} messageId="seo.index.legend" />,
            name: 'translation[seo][seo_index]',
            selected:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.seo_index
                        : 0
                    : 0,
            options: [
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: '1'
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: '0'
                }
            ]
        }
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'colOne') {
        return configuration
    } else if (conf === 'colTwo') {
        return configurationCol2
    }
}

export default configuration
