import React from 'react'
import CustomizerOverview from './Pages/overview'
import CustomizerDetail from './Pages/Detail'

export const CustomizerJSPagesConfig = (value: any) => {
    return [
        {
            path: '/customizerJS/:page',
            elements: <CustomizerOverview value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        },
        {
            path: '/customizerJS/Detail/:id',
            elements: <CustomizerDetail value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        }
    ]
}

export const CustomizerJSMenuConfig = (value: any) => {
    return [
        {
            name: 'customizerJS',
            route: '/customizerJS/1',
            icon: 'javascript',
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        }
    ]
}
