import CreateIcon from '@material-ui/icons/Create'
import React from 'react'
import ListItemIcon from '@material-ui/core/ListItemIcon'

function pencil() {
    return (
        <>
            <ListItemIcon>
                <CreateIcon fontSize="small" />
            </ListItemIcon>
        </>
    )
}

export default pencil
