export const FooterNaviFields = [
    {
        identifier: 'font-color-footer-hover',
        type: 'input',
        field: 'font-color-footer-hover'
    },
    {
        identifier: 'font-color-footer-active',
        type: 'input',
        field: 'font-color-footer-active'
    },
    {
        identifier: 'font-size-footer',
        type: 'input',
        field: 'font-size-footer'
    },
    {
        identifier: 'font-weight-footer',
        type: 'input',
        field: 'font-weight-footer'
    },
    {
        identifier: 'font-color-footer',
        type: 'input',
        field: 'font-color-footer'
    }
]
