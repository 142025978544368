import { User } from '../../referal/configuration/SearchConfig'

const SearchConfig = (destination: any) => {
    return [
        {
            namespace: destination + '_searchValue',
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'Nach Unternehmensnamen suchen',
                    param: 'company',
                    method: 'like'
                },
                {
                    id: 'search_name_customer_id',
                    translation: 'common.date',
                    param: 'gf_first_name',
                    method: 'like',
                    individual: User(
                        destination,
                        'search_name_customer_id',
                        'Nach Geschäftsführer Suchen',
                        'name'
                    )
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'Neues Dt. Unternehmen anlegen'
            ]
        }
    ]
}

export default SearchConfig
