export const H3 = {
    eventkey: 'H3',
    key: 'H3',
    title: 'H3',
    content: [
        {
            label: 'headline.h3.font-size-h3',
            discr: 'font-size-h3',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h3.font-weight-h3',
            discr: 'font-weight-h3',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h3.font-color-h3',
            discr: 'font-color-h3',
            size: [3, 3, 3],
            type: 'text'
        }
    ]
}
