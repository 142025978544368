import Modal from 'react-bootstrap/Modal'
import WYSIWYGindi from '../../../../../../../@WUM/core/form-serilizer/elements/WYSIWYGindi'
import Button from 'react-bootstrap/Button'
import React from 'react'
import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage'

const _handleKeyDown = (values: any) => {
    const value = values
    localStorage.setItem('materials', value)
}

export const setMaterials = (
    setModalMaterialsShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void
) => {
    setModalMaterialsShow(true)
}

export const setterMaterials = (
    materialsValue: string | undefined,
    setMaterialsValue: (arg0: any) => void,
    response: { translation: { allergy: any } }[]
) => {
    if (materialsValue === '' || materialsValue === undefined) {
        setMaterialsValue(response[0].translation.allergy)
    }
}

export const modalMaterials = (
    modalMaterialsShow: boolean,
    setModalMaterialsShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    saveMaterials: any,
    materialsValue: any,
    Store: any
) => {
    let DataMaterial: any = materialsValue

    return (
        <>
            <MaterialsModal
                show={modalMaterialsShow}
                data={DataMaterial}
                onHide={() => setModalMaterialsShow(false)}
                saveMaterials={saveMaterials}
                _handleKeyDown={_handleKeyDown}
                Store={Store}
            />
        </>
    )
}

const MaterialsModal = (props: any) => {
    let label
    if (process.env.REACT_APP_AGRICA === 'True') {
        label = 'Zutaten bearbeiten'
    } else {
        label = <IntlMessage Store={props.Store} messageId="materials.edit" />
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {label}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {WYSIWYGindi(
                    undefined,
                    0,
                    {
                        name: 'hallo',
                        lang: 'de',
                        value: props.data,
                        height: '400px'
                    },
                    props._handleKeyDown
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>
                    <IntlMessage Store={props.Store} messageId="common.close" />
                </Button>
                <Button
                    type={'button'}
                    className={'float-end'}
                    variant="success"
                    onClick={() => props.saveMaterials()}
                >
                    <IntlMessage Store={props.Store} messageId="common.save" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
