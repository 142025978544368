import {
    ProductMenuConfig,
    ProductPagesConfig
} from './products/modules/Product'
import {
    PromotionMenuConfig,
    PromotionPagesConfig
} from './order/modules/Promotion'
import {
    InvoiceMenuConfig,
    InvoicePagesConfig
} from './invoice/modules/invoice'
import {
    merchantMenuConfig,
    merchantPagesConfig
} from './merchant/modules/merchant'
import { OrdersMenuConfig, OrdersPagesConfig } from './order/modules/Orders'
import {CustomerMenuConfig, CustomerPagesConfig} from "./customer";

export const ShopRouteConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'SHOPwithMatomo':
            return [
                ...ProductPagesConfig(value),
                ...PromotionPagesConfig(value),
                ...InvoicePagesConfig(value),
                ...merchantPagesConfig(value),
                ...OrdersPagesConfig(value),
                ...CustomerPagesConfig(value)
            ]
        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [...ProductPagesConfig(value), ...merchantPagesConfig(value)]
        default:
            return [
                ...ProductPagesConfig(value),
                ...PromotionPagesConfig(value),
                ...InvoicePagesConfig(value),
                ...merchantPagesConfig(value),
                ...OrdersPagesConfig(value),
                ...CustomerPagesConfig(value)
            ]
    }
}

export const ShopMenuConfig = (value: any) => {
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'SHOPwithMatomo':
            return [
                {
                    header: 'Shop',
                    icon: 'shopping_cart',
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ],
                    menuitem: [
                        ...ProductMenuConfig(value),
                        ...OrdersMenuConfig(value),
                        ...PromotionMenuConfig(value),
                        ...InvoiceMenuConfig(value),
                        ...merchantMenuConfig(value),
                        ...CustomerMenuConfig(value)
                    ]
                }
            ]
        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                {
                    header: 'Shop',
                    icon: 'shopping_cart',
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ],
                    menuitem: [
                        ...ProductMenuConfig(value),
                        ...merchantMenuConfig(value),
                        ...CustomerMenuConfig(value)
                    ]
                }
            ]
        default:
            return [
                {
                    header: 'Shop',
                    icon: 'shopping_cart',
                    roles: [
                        'superadmin',
                        'admin',
                        'operator',
                        'merchant',
                        'merchant_ma'
                    ],
                    menuitem: [
                        ...ProductMenuConfig(value),
                        ...OrdersMenuConfig(value),
                        ...PromotionMenuConfig(value),
                        ...InvoiceMenuConfig(value),
                        ...merchantMenuConfig(value),
                        ...CustomerMenuConfig(value)
                    ]
                }
            ]
    }
}

export const ShopSidebar: string =
    '/Product/overview/1,/Orders/overview/1,/Promotion/overview/1,/Invoice/overview/1,/merchant/overview/1'
