/**
 * Erstellt den Header
 * @param name
 * @param nameID
 * @param Status
 * @param index
 * @param type
 * @param typeName
 * @param modalShow
 * @param setModalShow
 * @param modalData
 * @param setModalData
 * @param newElement
 * @param element
 * @param content
 * @param setElements
 * @constructor
 */
import { Col, Row } from 'react-bootstrap'
import React from 'react'

import deleteElementsWebBuilder from '../../form-serilizer/elements/helper/deleteElementsWebBuilder'

export const WebBuilderElementsHead = (
    name: any,
    nameID: any,
    preview: any,
    Status: any,
    index: any,
    type: any,
    typeName: any,
    modalShow: any,
    setModalShow: any,
    modalData: any,
    setModalData: any,
    newElement: any,
    element: any,
    content: any = false,
    setElements: any = [],
    Store: any,
    isimgPreview: any = false,
    isVideoPreview: any = false,
    elementId: any = 0,
    styleSet?: any,
    parentID?: any
) => {
    if (newElement === false) {
    } else {
    }

    const openMoadl = () => {
        let modal = document.getElementById(
            'ModalCopyElements' + elementId
        ) as HTMLDivElement
        modal.style.display = 'block'
    }

    const showElement = () => {
        let nav = document.getElementById(
            'settingstext' + index + nameID
        ) as HTMLDivElement
        let Elemente = document.getElementsByClassName(
            'settingsWebBuilderModul'
        )

        if (nav.style.display === 'none') {
            nav.style.display = 'block'
            if (
                nav.parentElement?.parentElement !== undefined &&
                nav.parentElement?.parentElement !== null
            ) {
                ;(
                    nav.parentElement?.parentElement as HTMLDivElement
                ).style.border = '4px solid rgb(56, 112, 247)'
                ;(
                    nav.parentElement?.parentElement as HTMLDivElement
                ).style.boxShadow =
                    '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
            }
        } else {
            nav.style.display = 'none'
            if (nav.parentElement !== undefined && nav.parentElement !== null) {
                ;(
                    nav.parentElement?.parentElement as HTMLDivElement
                ).style.border = '4px solid transparent'
                ;(
                    nav.parentElement?.parentElement as HTMLDivElement
                ).style.boxShadow = 'unset'
            }
        }
        for (var i = 0; i < Elemente.length; i++) {
            if (
                (Elemente[i] as HTMLDivElement).id ===
                'settingstext' + index + nameID
            ) {
            } else {
                let search = Elemente[i] as HTMLDivElement

                search.style.display = 'none'
                if (
                    search !== null &&
                    search !== undefined &&
                    search.parentElement?.parentElement !== undefined &&
                    search.parentElement?.parentElement !== null
                ) {
                    ;(
                        search.parentElement?.parentElement as HTMLDivElement
                    ).style.border = '4px solid transparent'
                    ;(
                        search.parentElement?.parentElement as HTMLDivElement
                    ).style.boxShadow = 'unset'
                }
            }
        }
    }

    return (
        <>
            <Col
                id={'setting' + index + nameID}
                className={'toolbox ' + styleSet}
            >
                <Row
                    style={{
                        position: 'relative',
                        zIndex: '9999999',
                        top: '0',
                        height: '40px'
                    }}
                >
                    <Col
                        className="flex d-flex justify-content-center"
                        style={{ height: '40px' }}
                    >
                        <p
                            className="grabbable  drag-icon"
                            style={{
                                height: '100%',
                                padding: '0.08em 0em',
                                width: '30px',
                                textAlign: 'left',
                                margin: '0px',
                                background: '#3870F7',
                                color: 'white',
                                top: 0,
                                left: 0
                            }}
                        >
                            <span
                                className="material-icons"
                                style={{
                                    marginTop: '-0px',
                                    color: 'white',
                                    padding: '5px'
                                }}
                            >
                                open_with
                            </span>
                        </p>
                        <div
                            className="settings-tab"
                            style={{ height: '40px' }}
                        >
                            {deleteElementsWebBuilder(
                                nameID + '' + index,
                                '',
                                nameID,
                                modalShow,
                                setModalShow,
                                modalData,
                                setModalData,
                                newElement === false ? element.id : 0,
                                content,
                                setElements,
                                Store,
                                parentID
                            )}

                            <p
                                style={{
                                    height: '100%',
                                    width: '30px',
                                    textAlign: 'left',
                                    float: 'right',
                                    padding: '5px',
                                    margin: '0px',
                                    background: '#3870F7',
                                    color: 'white',
                                    cursor: 'pointer'
                                }}
                                onClick={() => showElement()}
                            >
                                <span className="material-icons">settings</span>
                            </p>
                            {elementId !== 0 ? (
                                <p
                                    style={{
                                        height: '100%',
                                        width: '30px',
                                        textAlign: 'left',
                                        float: 'right',
                                        margin: '0px',
                                        padding: '5px',
                                        background: '#3870F7',
                                        color: 'white',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => openMoadl()}
                                >
                                    <span className="material-icons">
                                        file_copy
                                    </span>
                                </p>
                            ) : (
                                <></>
                            )}
                            {noneEditMap(nameID) && newElement === false ? (
                                <p
                                    style={{
                                        height: '100%',
                                        width: '30px',
                                        textAlign: 'left',
                                        float: 'right',
                                        padding: '5px',
                                        margin: '0px',
                                        background: '#3870F7',
                                        color: 'white',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() =>
                                        showEditPreview(nameID, index)
                                    }
                                >
                                    <span className="material-icons">edit</span>
                                </p>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Col>
                </Row>

                {/* <Col>
        {name}
        </Col> */}
                {/*
        <Col>

            {//preview
                 }

            {//deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements,Store)
                }
    </Col> */}

                {/* <Col style={{width: '50px', maxWidth: '50px',marginTop: '10px'}}>
      {deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements)}
    </Col>*/}
            </Col>
        </>
    )
}

export default WebBuilderElementsHead

export const showEditPreview = (nameID: any, index: any) => {
    let edit = document.getElementById(
        'edit_' + nameID + index
    ) as HTMLDivElement
    let preview = document.getElementById(
        'preview-container_' + nameID + index
    ) as HTMLDivElement
    let Elemente = document.getElementsByClassName('settingsWebBuilderModul')

    if (edit.style.display === 'none') {
        edit.style.display = 'block'
        preview.style.display = 'none'
    } else {
        edit.style.display = 'none'
        preview.style.display = 'block'
    }
    for (var i = 0; i < Elemente.length; i++) {
        if (
            (Elemente[i] as HTMLDivElement).id ===
            'settingstext' + index + nameID
        ) {
        } else {
            ;(Elemente[i] as HTMLDivElement).style.display = 'none'
        }
    }
}

const noneEditMap = (nameID: any) => {
    let map = [
        'rs',
        're',
        'sitemap',
        'slider_start',
        'slider_end',
        'container',
        'menu',
        'carouselItem_start',
        'carouselItem_end',
        'magazin',
        'blog',
        'search_result',
        'module_social_media',
        'map'
    ]
    if (map.includes(nameID)) {
        return false
    } else {
        return true
    }
}

export const Settingsprepare = (
    main: any,
    index: any,
    nameId: any,
    mode: any
) => {
    if (mode === 'in') {
        ;(
            document.getElementById(
                'setting' + index + nameId
            ) as HTMLDivElement
        ).style.display = 'block'
        ;(document.getElementById(main) as HTMLDivElement).style.marginTop =
            '0px'
    } else if (mode === 'out') {
        ;(
            document.getElementById(
                'setting' + index + nameId
            ) as HTMLDivElement
        ).style.display = 'none'
        ;(document.getElementById(main) as HTMLDivElement).style.marginTop =
            '50px'
    }
}
