import { IntlMessageValue } from '../../component/const/IntlMessage'
import FormElements from './index'

export const newModul = (
    type: any,
    index: any,
    setdeleteElement: any,
    setElements: any,
    Store: any,
    galerie?: any,
    galerieItems?: any,
    col?: any,
    row?: any
) => {
    const tmp = []

    const elements: any = {
        type: type
    }
    tmp.push(elements)

    const elementsContent = {
        conf: [
            {
                lang: 'de',
                height: '400px'
            },
            {
                lang: 'de',
                height: '200px'
            },
            {
                lang: 'de',
                height: '400px'
            }
        ],
        elements: tmp
    }

    return FormElements(
        elementsContent,
        false,
        false,
        false,
        false,
        true,
        index,
        setdeleteElement,
        setElements,
        Store,
        galerie,
        galerieItems,
        col,
        row
    )
}

export const TextMap = (Store: any) => {
    return [
        { value: 'false', label: '' },
        { value: 'text', label: IntlMessageValue('de', 'module.text', Store) }, //x
        {
            value: 'accordion',
            label: IntlMessageValue('de', 'module.accordion', Store)
        }, //x
        {
            value: 'text_blocker',
            label: IntlMessageValue('de', 'module.text_with_background', Store)
        },
        {
            value: 'headline',
            label: IntlMessageValue('de', 'module.headline', Store)
        }, //x
        {
            value: 'text_icon',
            label: IntlMessageValue('de', 'module.text_with_icon', Store)
        }, //x
        {
            value: 'divider',
            label: IntlMessageValue('de', 'module.divider', Store)
        }, //x
        {
            value: 'anchor',
            label: IntlMessageValue('de', 'module.anchor', Store)
        }, //x
        {
            value: 'row_start',
            label: IntlMessageValue('de', 'module.row_start', Store)
        }, //x
        {
            value: 'row_end',
            label: IntlMessageValue('de', 'module.row_end', Store)
        }, //x
        {
            value: 'sitemap',
            label: IntlMessageValue('de', 'module.sitemap', Store)
        }, //x
        {
            value: 'html',
            label: IntlMessageValue('de', 'module.html_modul', Store)
        }, //x
        { value: 'slider_start', label: 'slider_start' }, //x
        { value: 'slider_end', label: 'slider_end' }, //x
        { value: 'carouselItem_start', label: 'carouselItem_Start' }, //x
        { value: 'carouselItem_end', label: 'carouselItem_end' }, //x
        { value: 'formular', label: 'Form' } //x
    ]
}

export const MediaMap = (Store: any) => {
    return [
        { value: 'false', label: '' },
        {
            value: 'image_title',
            label: IntlMessageValue('de', 'module.hero_image', Store)
        }, // x
        {
            value: 'module_customer_steps',
            label: IntlMessageValue('de', 'module.three_blocks', Store)
        }, //x
        {
            value: 'media_text',
            label: IntlMessageValue('de', 'module.text_with_image', Store)
        }, //x
        {
            value: 'slider',
            label: IntlMessageValue('de', 'module.slider', Store)
        }, //x
        //{'value': 'module_content', 'label': IntlMessageValue('de',"module.editorial",Store)},
        {
            value: 'image',
            label: IntlMessageValue('de', 'module.image', Store)
        }, //x
        { value: 'video', label: 'UM02 - Video' }, //x
        { value: 'ung_masonry', label: 'UMG01 - Masonry' }, //x
        {
            value: 'video_duo',
            label: IntlMessageValue(
                'de',
                'content.element.module_video_duo.type',
                Store
            )
        },
        {
            value: 'divider',
            label: IntlMessageValue('de', 'module.divider', Store)
        }, //x
        {
            value: 'anchor',
            label: IntlMessageValue('de', 'module.anchor', Store)
        }, //x
        {
            value: 'row_start',
            label: IntlMessageValue('de', 'module.row_start', Store)
        }, //x
        {
            value: 'row_end',
            label: IntlMessageValue('de', 'module.row_end', Store)
        }, //x
        {
            value: 'sitemap',
            label: IntlMessageValue('de', 'module.sitemap', Store)
        }, //x
        {
            value: 'html',
            label: IntlMessageValue('de', 'module.html_modul', Store)
        }, //x
        { value: 'formular', label: 'Form' } //x
    ]
}

export const SocialMap = (Store: any) => {
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'ProductCatalog':
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            return [
                { value: 'false', label: '' },
                {
                    value: 'cta',
                    label: IntlMessageValue('de', 'module.cta', Store)
                }, //x
                {
                    value: 'module_customer_steps',
                    label: IntlMessageValue('de', 'module.three_blocks', Store)
                }, //x
                {
                    value: 'search_result',
                    label: IntlMessageValue('de', 'module.search', Store)
                },
                {
                    value: 'm_duo',
                    label: IntlMessageValue('de', 'module.duo', Store)
                },
                {
                    value: 'blog_teaser',
                    label: IntlMessageValue('de', 'module.blog', Store)
                }, //x
                { value: 'magazin', label: 'Magazin' }, //x
                //{'value': 'gallery', 'label': IntlMessageValue('de','content.element.gallery.type',Store)},
                {
                    value: 'social_media',
                    label: IntlMessageValue(
                        'de',
                        'content.element.module_social_media.type',
                        Store
                    )
                }, //x
                {
                    value: 'divider',
                    label: IntlMessageValue('de', 'module.divider', Store)
                }, //x
                {
                    value: 'anchor',
                    label: IntlMessageValue('de', 'module.anchor', Store)
                }, //x
                {
                    value: 'row_start',
                    label: IntlMessageValue('de', 'module.row_start', Store)
                }, //x
                {
                    value: 'row_end',
                    label: IntlMessageValue('de', 'module.row_end', Store)
                }, //x
                {
                    value: 'sitemap',
                    label: IntlMessageValue('de', 'module.sitemap', Store)
                }, //x
                {
                    value: 'html',
                    label: IntlMessageValue('de', 'module.html_modul', Store)
                }, //x
                { value: 'formular', label: 'Form' } //x
                //{'value': 'calender', 'label': IntlMessageValue('de','content.element.module_calender.type',Store)},
            ]
        default:
            return [
                { value: 'false', label: '' },
                {
                    value: 'cta',
                    label: IntlMessageValue('de', 'module.cta', Store)
                }, //x
                {
                    value: 'module_customer_steps',
                    label: IntlMessageValue('de', 'module.three_blocks', Store)
                }, //x
                {
                    value: 'search_result',
                    label: IntlMessageValue('de', 'module.search', Store)
                },
                {
                    value: 'blog_teaser',
                    label: IntlMessageValue('de', 'module.blog', Store)
                }, //x
                { value: 'magazin', label: 'Magazin' }, //x
                //{'value': 'gallery', 'label': IntlMessageValue('de','content.element.gallery.type',Store)},
                {
                    value: 'social_media',
                    label: IntlMessageValue(
                        'de',
                        'content.element.module_social_media.type',
                        Store
                    )
                }, //x
                {
                    value: 'divider',
                    label: IntlMessageValue('de', 'module.divider', Store)
                }, //x
                {
                    value: 'anchor',
                    label: IntlMessageValue('de', 'module.anchor', Store)
                }, //x
                {
                    value: 'row_start',
                    label: IntlMessageValue('de', 'module.row_start', Store)
                }, //x
                {
                    value: 'row_end',
                    label: IntlMessageValue('de', 'module.row_end', Store)
                }, //x
                {
                    value: 'sitemap',
                    label: IntlMessageValue('de', 'module.sitemap', Store)
                }, //x
                {
                    value: 'html',
                    label: IntlMessageValue('de', 'module.html_modul', Store)
                }, //x
                { value: 'formular', label: 'Form' } //x
                //{'value': 'calender', 'label': IntlMessageValue('de','content.element.module_calender.type',Store)},
            ]
    }
}
