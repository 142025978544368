import React from 'react'
import Form from 'react-bootstrap/Form'
//import legende from './helper/legende';
import IntlMessage from '../../component/const/IntlMessage'
import MediaImageUpload from '../../component/const/MediaImageUpload'
import SendImage, { SendMultiMedia } from '../../component/const/SaveImage'

export const FormUploadFile = (props: { data: any; Store: any }) => {
    let type: boolean
    if (props.data.Buckettype !== undefined && props.data.Buckettype) {
        type = true
    } else {
        type = false
    }
    let id: any
    let url = ''
    if (props.data.merchant_id !== undefined && props.data.merchant_id) {
        id = props.data.merchant_id
        url = 'merchant/'
    } else if (props.data.product_id !== undefined && props.data.product_id) {
        id = props.data.product_id
        url = 'Product'
    } else if (props.data.url !== undefined) {
        id = props.data.send_id
        url = props.data.url
    } else {
        id = false
    }

    let multi = false
    if (props.data.multiple !== undefined) {
        multi = props.data.multiple
    }
    let srcType = ''
    if (props.data.srcType !== undefined) {
        srcType = props.data.srcType
    }

    if (props.data.experiment !== undefined) {
        return (
            <>
                <Form.Group
                    controlId={'formFile' + props.data.label}
                    key={'formFile' + props.data.label}
                    className="mb-3"
                >
                    <Form.Label>{props.data.label}</Form.Label>
                    <Form.Control
                        type={props.data.type}
                        name={props.data.name}
                        accept="video/*,image/*,application/pdf"
                        id={props.data.id}
                        multiple={
                            props.data.multi !== undefined
                                ? props.data.multi
                                : true
                        }
                        disabled={true}
                        data-context={props.data.onclick_context}
                        data-append={props.data.append}
                        onChange={(e) => {
                            MediaImageUpload(
                                e,
                                props.data.append,
                                props.data.fieldcontext !== undefined
                                    ? (
                                          document.getElementById(
                                              props.data.fieldcontext
                                          ) as HTMLSelectElement
                                      ).value
                                    : props.data.onclick_context,
                                props.data.src,
                                type,
                                id,
                                url,
                                srcType,
                                props.Store,
                                props.data.namespace,
                                props.data.addedRoute,
                                props.data.reload
                            )
                        }}
                    />
                </Form.Group>
                <p
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em',
                        marginTop: '5px'
                    }}
                    key={'legende'}
                >
                    <IntlMessage
                        Store={props.Store}
                        messageId="bucket.file.produkt.placeholder"
                    />
                </p>
            </>
        )
    } else if (multi === true) {
        return (
            <>
                <Form.Group
                    controlId={'formFile' + props.data.label}
                    key={'formFile' + props.data.label}
                    className="mb-3"
                >
                    <Form.Label>{props.data.label}</Form.Label>
                    <Form.Control
                        type={props.data.type}
                        multiple={multi}
                        name={props.data.name}
                        id={props.data.id}
                        data-context={props.data.onclick_context}
                        data-append={props.data.append}
                        onChange={(e) => {
                            SendMultiMedia(
                                e,
                                props.data.append,
                                props.data.onclick_context,
                                props.data.src,
                                type,
                                id,
                                url,
                                props.Store
                            )
                        }}
                    />
                </Form.Group>
                <p
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em',
                        marginTop: '5px'
                    }}
                    key={'legende'}
                >
                    <IntlMessage
                        Store={props.Store}
                        messageId="bucket.file.produkt.placeholder"
                    />
                </p>
            </>
        )
    } else if (srcType === 'video') {
        return (
            <>
                <Form.Group
                    controlId={'formFile' + props.data.label}
                    key={'formFile' + props.data.label}
                    className="mb-3"
                >
                    <Form.Label>{props.data.label}</Form.Label>
                    <Form.Control
                        type={props.data.type}
                        name={props.data.name}
                        id={props.data.id}
                        data-context={props.data.onclick_context}
                        data-append={props.data.append}
                        onChange={(e) => {
                            SendImage(
                                e,
                                props.data.append,
                                props.data.onclick_context,
                                props.data.src,
                                type,
                                id,
                                url,
                                srcType,
                                props.Store
                            )
                        }}
                    />
                </Form.Group>
                <p
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em',
                        marginTop: '5px'
                    }}
                    key={'legende'}
                >
                    <IntlMessage
                        Store={props.Store}
                        messageId="bucket.file.video.placeholder"
                    />
                </p>
            </>
        )
    } else {
        return (
            <>
                <Form.Group
                    controlId={'formFile' + props.data.label}
                    key={'formFile' + props.data.label}
                    className="mb-3"
                >
                    <Form.Label>{props.data.label}</Form.Label>
                    <Form.Control
                        type={props.data.type}
                        name={props.data.name}
                        id={props.data.id}
                        data-context={props.data.onclick_context}
                        data-append={props.data.append}
                        onChange={(e) => {
                            SendImage(
                                e,
                                props.data.append,
                                props.data.onclick_context,
                                props.data.src,
                                type,
                                id,
                                url,
                                '',
                                props.Store
                            )
                        }}
                    />
                </Form.Group>
                <p
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em',
                        marginTop: '5px'
                    }}
                    key={'legende'}
                >
                    <IntlMessage
                        Store={props.Store}
                        messageId="bucket.file.placeholder"
                    />
                </p>
            </>
        )
    }
}

export default FormUploadFile
