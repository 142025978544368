import { IntlMessageValue } from '../../../../../../@WUM/core/component/const/IntlMessage'

const configuration = (
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) => {
    let config = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'id',
            value: newElement === false ? response.id : 0
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: 'Ausgangs-URL',
                    type: 'text',
                    required: true,
                    placeholder: 'Bitte Ausgangs-URL eingeben',
                    legende:
                        'URL, von der weitergeleitet wird. Nur relative URL eingeben (das, was hinter der Domain steht).',
                    name: 'url',
                    value: newElement === false ? response.url : ''
                },
                {
                    formType: 'input',
                    label: 'Ziel-URL',
                    type: 'text',
                    required: true,
                    placeholder: 'Bitte Ziel-URL der Umleitung angeben',
                    legende:
                        'Relative URL von der Seite, auf die weitergeleitet werden soll, ebenfalls als relative URL.',
                    name: 'target',
                    value: newElement === false ? response.target : ''
                },
                {
                    formType: 'select',
                    label: 'Umleitungsmethode',
                    name: 'method',
                    legende:
                        'Sie haben die Wahl zwischen einer permanenter Umleitung (wenn die Seite dauerhaft entfernt wurde) oder einer temporären Umleitung (wenn die Seite zeitweise nicht mehr erreichbar sein soll). Dazu werden entweder die Codes 301 oder 302 geschickt.',
                    selected: newElement === false ? response.method : 0,
                    options: [
                        {
                            label: '301 permanent umgeleitet',
                            value: '301'
                        },
                        {
                            label: '302 temporär umgeleitet',
                            value: '302'
                        }
                    ]
                },
                {
                    formType: 'select',
                    label: 'Aktivierungszustand',
                    name: 'active',
                    legende:
                        'Auswählen, ob die Regel angewendet werden soll oder nicht.',
                    selected: newElement === false ? response.active : 0,
                    options: [
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.activated',
                                Store
                            ),
                            value: '1'
                        },
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.deactivated',
                                Store
                            ),
                            value: '0'
                        }
                    ]
                }
            ]
        }
    ]

    return config
}

export default configuration
