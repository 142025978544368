import React from 'react'
import { LanguageSwitcherValue } from '../../../core/components/LanguageSwitcher'
import Promotionoverview from './Pages/Promotionoverview'
import PromotionDetail from './Pages/PromotionDetail'

export const PromotionPagesConfig = (value: any) => {
    return [
        {
            path: '/Promotion/overview/:page',
            elements: <Promotionoverview value={value} />,
            roles: (process.env.REACT_APP_PROMOTION_FORBIDDEN === 'true' ? ['Forbidden'] : [
                'superadmin',
                'admin',
                'operator',
                'merchant',
                'merchant_ma'
            ]),
        },
        {
            path: '/Promotion/Detail/:id',
            elements: <PromotionDetail value={value} />,
            roles: (process.env.REACT_APP_PROMOTION_FORBIDDEN === 'true' ? ['Forbidden'] : [
                'superadmin',
                'admin',
                'operator',
                'merchant',
                'merchant_ma'
            ]),
        }
    ]
}
export const PromotionMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('shop.promotion.header'),
            route: '/Promotion/overview/1',
            icon: 'redeem',
            roles: (process.env.REACT_APP_PROMOTION_FORBIDDEN === 'true' ? ['Forbidden'] : [
                'superadmin',
                'admin',
                'operator',
                'merchant',
                'merchant_ma'
            ]),
        }
    ]
}
