import IntlMessage, {
    IntlMessageValue
} from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'
import SunEditorBig from '../../../../@WUM/core/component/const/SunEditorBig'
import { SunEditorConfig } from '../../../../@WUM/core/component/const/SunEditorConfig'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    let cutDate =
        newElement === false
            ? response.eventdate !== undefined && response.eventdate !== null
                ? response.eventdate.split(' ')[0]
                : ' '
            : ' '

    let cutDateend =
        newElement === false
            ? response.eventEnd !== undefined && response.eventEnd !== null
                ? response.eventEnd.split(' ')[0]
                : ' '
            : ' '

    const titel = (
        <>
            <h2>
                <IntlMessage
                    messageId="configuration.home.title"
                    Store={Store}
                />
            </h2>
        </>
    )

    const description = (
        <>
            <p>
                <IntlMessage
                    messageId="configuration.home.description"
                    Store={Store}
                />
            </p>
        </>
    )

    const dataColOne = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'discr',
            value: newElement === false ? response.discr : ''
        },
        {
            formType: 'input',
            type: 'hidden',
            name: 'layout',
            value: newElement === false ? response.layout : ''
        },
        {
            formType: 'input',
            label: <IntlMessage messageId="comment.event.name" Store={Store} />,
            required: true,
            type: 'text',
            placeholder: 'Bitte Veranstaltungsname eingeben',
            name: 'title',
            value: newElement === false ? response.title : ''
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            messageId="comment.event.begin"
                            Store={Store}
                        />
                    ),
                    type: 'datetime-local',
                    name: 'eventdate',
                    format: 'en',
                    nowFormat: 'en',
                    placeholder: 'xx.xx.xxxx',
                    value: cutDate
                },
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            messageId="comment.event.end"
                            Store={Store}
                        />
                    ),
                    type: 'datetime-local',
                    name: 'eventEnd',
                    format: 'en',
                    nowFormat: 'en',
                    placeholder: 'xx.xx.xxxx',
                    value: cutDateend
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            messageId="comment.event.link.title"
                            Store={Store}
                        />
                    ),
                    type: 'text',
                    placeholder: 'Bitte Seitentitel eingeben',
                    name: 'linkTitle',
                    value: newElement === false ? response.linkTitle : ''
                },
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            messageId="comment.event.link.url"
                            Store={Store}
                        />
                    ),
                    type: 'text',
                    placeholder: 'Bitte Link URL eingeben',
                    name: 'linkUrl',
                    value: newElement === false ? response.linkUrl : ''
                }
            ]
        },
        SunEditorBig(
            response,
            'shortDescription',
            newElement,
            { lang: 'de', height: '250px;', config: SunEditorConfig() },
            'shortDescription',
            <IntlMessage messageId="common.short_description" Store={Store} />,
            '',
            '',
            '',
            '',
            'classic',
            '120'
        )
    ]

    const dataColtwo = [
        {
            formType: 'fetchselect',
            local: 'allTaxon',
            valueState: 'id',
            labelState: 'name',
            beginn: 'true',
            start: 0,
            name: 'taxon_id',
            selected: newElement === false ? response.taxon_id : '',
            label: IntlMessageValue('de', 'category.main', Store)
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_title_image_',
                    path:
                        newElement === false
                            ? response.image !== null
                                ? response.image !== undefined
                                    ? response.image.cdn_url
                                    : ''
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 12,
                        md: 12,
                        sm: 12
                    }
                },
                {
                    formType: 'formFile',
                    onclick: true,
                    onclick_context: 'pielers_element_text_image',
                    append: 'image_id',
                    src: 'image_title_image_',
                    experiment: true,
                    size: {
                        xl: 12,
                        md: 12,
                        sm: 12
                    }
                }
            ]
        },
        {
            formType: 'input',
            name: 'mediaId',
            id: 'image_id',
            visible: true,
            type: 'hidden',
            placeholder: 'imageID',
            value:
                newElement === false
                    ? response.mediaId !== null
                        ? response.mediaId !== undefined
                            ? response.mediaId
                            : null
                        : null
                    : null
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.visible" />,
            name: 'visible',
            selected: newElement === false ? response.visible : 0,
            options: [
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: '1'
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: '0'
                }
            ]
        }
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'dataColOne') {
        return dataColOne
    } else if (conf === 'dataColtwo') {
        return dataColtwo
    }
}

export default configuration
