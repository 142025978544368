import TableCell from '@material-ui/core/TableCell'
import React from 'react'
import dateCut from './dateCut'

export const dateCutfromdate = (
    text: string,
    id: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any
) => {
    let newText = text.split(' ')[0]

    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
                id={'td' + id + '_' + index}
            >
                {formatDate(
                    newText,
                    id,
                    index,
                    VisitData,
                    VisitDataDel,
                    Store,
                    tableBodyPositionElement
                )}
            </TableCell>
        </>
    )
}

function formatDate(
    dateString: any,
    id: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any
) {
    if (dateString.includes('T')) {
        dateString = dateString.split('T')
        dateString = dateString[0] + ' ' + dateString[1].replace('Z', '')
    }
    if (dateString !== '' && dateString !== null && dateString !== undefined) {
        let allDate = dateString.split(' ')
        let thisDate = allDate[0]?.split('-')
        if (thisDate.length === 1) {
            return dateCut(
                dateString,
                id,
                index,
                VisitData,
                VisitDataDel,
                Store,
                tableBodyPositionElement
            )
        }
        let thisTime = allDate[1]?.split(':')
        let newDate = [thisDate[2], thisDate[1], thisDate[0]].join('.')
        let newTime
        if (thisTime !== undefined) {
            let hour = thisTime[0]
            hour = hour < 10 ? '0' + hour : hour
            let min = thisTime[1]
            let sec = thisTime[2]
            newTime = hour + ':' + min + ':' + sec
        } else {
            newTime = ''
        }
        return newDate + ' ' + newTime

        //return newDate;
    } else {
        return ''
    }
}
