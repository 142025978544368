import React, {useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ResponseDetail } from '../../../API/response/invoice/invoice'
import { SpinnerJSX } from '../../../../../../@WUM/core/component/const/Spinner'
import HomeInvoice from './elements/home'
import { useSelector } from 'react-redux'

const InvoiceDetail = (value: any) => {
    const { id } = useParams<{ id: string }>()
    const response = [ResponseDetail(id)]
    const history = useNavigate()
    const [load, setLoad] = useState<boolean>(false)
    const [loadItems, setLoadItems] = useState<boolean>(false)
    const Store = useSelector((state: any) => state)
    if (response.length > 0 && load === true) {
        return (
            <HomeInvoice
                history={history}
                response2={response[0]}
                loadItems={loadItems}
                setLoadItems={setLoadItems}
                Store={Store}
            />
        )
    } else {
        if (response.length > 0) {
            setLoad(true)
        }
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default InvoiceDetail
