import React, { useState } from 'react'
import ApiURL from '../modules/components/ApiURL'
import getToken from '../modules/components/Token'
import { checkLogin } from '../../../@WUM/templates/default/Auth/service/AuthService'

const AllTech = () => {
    const [tech, setTech] = useState([]) // Array instead of object
    React.useEffect(() => {
        let root = ApiURL()
        const fetchData = async () => {
            const result = await fetch(root + `User/isSuperuser/`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + getToken()
                }
            })
                .then((response) => response.json())
                .then((response) => {
                    checkLogin(response)
                    return response
                })
                .catch((err) => console.error(err))

            setTech(result)
        }

        fetchData()
    }, [])

    return tech
}

export default AllTech
