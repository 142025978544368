import React from 'react'
import { useParams } from 'react-router-dom'

const DynamikDeleted = (props: {
    value: any
    reponse: any
    namespace: any
}) => {
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    let landingpages = '/' + props.namespace + '/overview/' + page
    let deleted = props.reponse(deleteID)
    if (deleted !== null && deleted !== undefined && deleted !== false) {
        setTimeout(() => (window.location.href = landingpages), 3000)
    }
    return <></>
}
export default DynamikDeleted
