export const H4 = {
    eventkey: 'H4',
    key: 'H4',
    title: 'H4',
    content: [
        {
            label: 'headline.h4.font-size-h4',
            discr: 'font-size-h4',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h4.font-weight-h4',
            discr: 'font-weight-h4',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h4.font-color-h4',
            discr: 'font-color-h4',
            size: [3, 3, 3],
            type: 'text'
        }
    ]
}
