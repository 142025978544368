import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Form} from "react-bootstrap";

const UniModal = (props:{headline:any,field:any,saveButtonLabel:any,CloseButtonLabel:any,ButtonLabel:any,ClassOpenButton:any,ClassSaveButton:any,handlesubmit:any}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const Formsend = (e:any) => {
        props.handlesubmit(e)
        handleClose()
    }


    return (
        <>
            <Button variant="primary" className={props.ClassOpenButton} onClick={handleShow}>
                {props.ButtonLabel}
            </Button>

            <Modal show={show} onHide={handleClose} backdrop="static" centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{props.headline}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id={'form001'}
                        className={'formControl'}
                        onSubmit={(e) =>Formsend(e)} >
                        {props.field}
                <Modal.Footer>
                    <Button  variant="primary" type={'submit'} className={props.ClassSaveButton}>
                        {props.saveButtonLabel}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {props.CloseButtonLabel}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal.Body>
            </Modal>

        </>
    );
}

export default UniModal;
