import {authApiDetailType} from "../../../../templates/default/Auth/API/request/authApiDetailType";
import {requestJson, requests} from "../../../RestFullApi/api";


export const ribbon  = {
    getRibbons: (endoint:any): Promise<authApiDetailType[]> =>
        requestJson.get(`/${endoint}`),
    putRibbons: (post: any, id:any,endoint:any): Promise<any> =>
        requests.put(`/${endoint}/${id}/`, post),
}


