import React from 'react'
import ShippingOverview from './Pages/overview'
import ShippingDetail from './Pages/Detail'
import ShippingNew from './Pages/new'
import { LanguageSwitcherValue } from '../../shop/core/components/LanguageSwitcher'

export const ShippingPagesConfig = (value: any) => {
    return [
        {
            path: '/Shipping/overview/:page',
            elements: <ShippingOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Shipping/Detail/:id',
            elements: <ShippingDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Shipping/Delete/:page/:deleteID',
            elements: <ShippingOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Shipping/new/new',
            elements: <ShippingNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}

export const ShippingMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('settings.shipping.header'),
            route: '/Shipping/overview/1',
            icon: 'local_shipping',
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}
