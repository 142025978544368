const elements = (form: any) => {
    let FormDatas = []
    let inputs = form.elements
    // Iterate over the form controlsc
    for (let i = 0; i < inputs.length; i++) {
        // Disable all form controls
        if (inputs[i].hasAttribute('name')) {
            if (
                inputs[i].getAttribute('name').includes('elements') &&
                !inputs[i].getAttribute('name').includes('translation') &&
                !inputs[i].getAttribute('name').includes('Products')
            ) {
                FormDatas.push(inputs[i].getAttribute('name'))
            }
        }
    }
    if (FormDatas.length > 0) {
        let elementsdata = []
        let elementtmp: string[] = []
        if (FormDatas[FormDatas.length - 1].includes('[')) {
            let types = FormDatas[FormDatas.length - 1].split('[')
            let typeidents = types[1].split(']')
            let heads: number = parseFloat(typeidents[0])
            for (let h = 0; h <= heads; h++) {
                for (let fe = 0; fe < FormDatas.length; fe++) {
                    if (
                        FormDatas[fe].includes('elements') &&
                        !FormDatas[fe].includes('translation') &&
                        !FormDatas[fe].includes('Produtcs')
                    ) {
                        let type = FormDatas[fe].split('[')
                        let typeident = type[1].split(']')
                        let head: number = parseFloat(typeident[0])
                        if (head === h) {
                            elementtmp[h] += FormDatas[fe] + ','
                        }
                    }
                }
            }

            for (let t = 0; t < elementtmp.length; t++) {
                if (typeof elementtmp[t] !== 'undefined') {
                    let filtered = elementtmp[t]
                        .replace('undefined', '')
                        .split(',')
                        .filter(function (el) {
                            return el !== ''
                        })
                    elementsdata.push(filtered)
                }
            }
        }
        return elementsdata
    } else {
        return []
    }
}

export default elements
