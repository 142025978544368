export interface config {
    newsletter: any
    event: any
}

export function output(Data: any) {
    let back = {
        newsletter: Data.newsletter,
        event:Data.event
    }

    return back
}
