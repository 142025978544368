const setElements = (map: any, x: number, inputs: any, i: number) => {
    let type = inputs[i].getAttribute('name').split('[')
    let ident = type.length - 1
    let typeident = type[ident].split(']')
    let head = typeident[0]

    let stringJSONBack: string = ''
    if (inputs[i].nodeName === 'TEXTAREA' || inputs[i].nodeName === 'SELECT') {
        if (x === 0) {
            stringJSONBack =
                '"' +
                head +
                '":"' +
                inputs[i].value
                    ?.replaceAll('"', '\\"')
                    .replaceAll('\n', '\\n') +
                '"'
        } else {
            stringJSONBack =
                ',"' +
                head +
                '":"' +
                inputs[i].value
                    ?.replaceAll('"', '\\"')
                    .replaceAll('\n', '\\n') +
                '"'
        }
    } else if (inputs[i].nodeName === 'INPUT') {
        let Value: any

        if (inputs[i].attributes.type?.nodeValue === 'checkbox') {
            let check = inputs[i].checked
            if (check !== undefined && check === false) {
                Value = 0
            } else {
                Value = 1
            }
        } else {
            Value = inputs[i].value
        }

        if (x === 0) {
            stringJSONBack = '"' + head + '":"' + Value + '"'
        } else {
            stringJSONBack = ',"' + head + '":"' + Value + '"'
        }
    }

    return stringJSONBack
}

export default setElements
