import { requests } from '../../../../../@WUM/core/RestFullApi/api'
import {
    customTypeApiType,
    customTypesApiType
} from './customTypesAPI.interface.'

export const customTypes = {
    getPost: (): Promise<customTypesApiType[]> =>
        requests.get(`custom/type/parent/fetch/`),
    getPostBlueprint: (): Promise<customTypesApiType[]> =>
        requests.get(`custom/type/blueprint/fetch/`),
    getPosts: (limit: string, search: any): Promise<customTypesApiType[]> =>
        requests.get(`custom/type/${limit}/${search}`),
    getAFormPosts: (
        id: string | undefined,
        add?: any
    ): Promise<customTypesApiType[]> =>
        requests.get(`custom/type/form/${id}/${add}`),
    getAPost: (id: string | undefined): Promise<customTypesApiType[]> =>
        requests.get(`custom/type/single/${id}/`),
    getAPostType: (id: string | undefined): Promise<customTypeApiType[]> =>
        requests.get(`custom/type/parent/single/${id}/`),
    createPost: (post: customTypesApiType): Promise<customTypesApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<customTypesApiType> =>
        requests.post(`custom/type/single/${id}/`, post),
    updateTypePost: (post: any, id: number): Promise<customTypeApiType> =>
        requests.post(`customTypeParent/Type/single/${id}/`, post),
    deletePost: (id: any): Promise<void> =>
        requests.delete(`rewrite/Single/${id}/`),
    deleteTypePost: (id: any): Promise<void> =>
        requests.delete(`customTypeParent/Type/single/${id}/`),
    getPostsSingle: (): Promise<customTypesApiType[]> =>
        requests.get(`custom/type/fetch/`)
}
