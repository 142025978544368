import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import IntlMessage, {
    IntlMessageValue
} from '../../../component/const/IntlMessage'
import CopyElements from '../../../component/const/CopyElements'
import WebBuilderElementsHead from '../../../component/const/WebBuilderElementsHead'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function module_contentWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    galerie?: any,
    galerieItems?: any
) {
    const index = newElement === false ? i : newelementIndex

    const configuration = [
        {
            formType: 'input',
            label: (
                <IntlMessage
                    Store={Store}
                    messageId="module.editorial.headline"
                />
            ),
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'module.editorial.headline',
                Store
            ),
            name: 'elements[' + index + '][translation][headline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.headline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.topline" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.topline', Store),
            name: 'elements[' + index + '][translation][topline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.topline
                        : ''
                    : ''
        },

        {
            formType: 'divider'
        },
        {
            formType: 'input',
            label: (
                <IntlMessage
                    Store={Store}
                    messageId="module.editorial.editorial_title.headline"
                />
            ),
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'module.editorial.editorial_title.headline',
                Store
            ),
            name: 'elements[' + index + '][translation][editorial_title]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_title
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.subline" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.subline', Store),
            name:
                'elements[' + index + '][translation][editorial_title_subline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_title_subline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.url" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.url', Store),
            name: 'elements[' + index + '][translation][editorial_title_url]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_title_url
                        : ''
                    : ''
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'editorial_title_image_id' + index,
                    path:
                        newElement === false
                            ? element.media3 !== undefined
                                ? element.media.cdn_url
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFile',
                    onclick: true,
                    id: 'editorial_title_image_idFormField' + index,
                    onclick_context:
                        newElement === false
                            ? element.context
                            : 'marketplace_api',
                    Gallery: galerie,
                    galerieItems: galerieItems,
                    expandfile: index + 'editorial_title_image_id',
                    experiment: true,
                    append:
                        'elements[' +
                        index +
                        '][translation][editorial_title_image_id]',
                    src: 'editorial_title_image_id' + index,
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },
        {
            formType: 'input',
            name:
                'elements[' +
                index +
                '][translation][editorial_title_image_id]',
            id:
                'elements[' +
                index +
                '][translation][editorial_title_image_id]',
            visible: true,
            type: 'text',
            label: 'imageID',
            placeholder: 'imageID',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_title_image_id
                        : ''
                    : ''
        },
        {
            formType: 'divider'
        },
        {
            formType: 'input',
            label: (
                <IntlMessage
                    Store={Store}
                    messageId="module.editorial.editorial_first.headline"
                />
            ),
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'module.editorial.editorial_first.headline',
                Store
            ),
            name: 'elements[' + index + '][translation][editorial_first]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_first
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.subline" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.subline', Store),
            name:
                'elements[' + index + '][translation][editorial_first_subline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_first_subline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.url" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.url', Store),
            name: 'elements[' + index + '][translation][editorial_first_url]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_first_url
                        : ''
                    : ''
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'editorial_first_image_id' + index,
                    path:
                        newElement === false
                            ? element.media !== undefined
                                ? element.media2.cdn_url
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFile',
                    onclick: true,
                    id: 'editorial_first_image_idFormField' + index,
                    onclick_context:
                        newElement === false
                            ? element.context
                            : 'marketplace_api',
                    Gallery: galerie,
                    galerieItems: galerieItems,
                    expandfile: index + 'editorial_first_image_id',
                    experiment: true,
                    append:
                        'elements[' +
                        index +
                        '][translation][editorial_first_image_id]',
                    src: 'editorial_first_image_id' + index,
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },
        {
            formType: 'input',
            name:
                'elements[' +
                index +
                '][translation][editorial_first_image_id]',
            id:
                'elements[' +
                index +
                '][translation][editorial_first_image_id]',
            visible: true,
            label: 'imageID',
            type: 'text',
            placeholder: 'imageID',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_first_image_id
                        : ''
                    : ''
        },
        {
            formType: 'divider'
        },
        {
            formType: 'input',
            label: (
                <IntlMessage
                    Store={Store}
                    messageId="module.editorial.editorial_second.headline"
                />
            ),
            type: 'text',
            placeholder: IntlMessageValue(
                'de',
                'module.editorial.editorial_second.headline',
                Store
            ),
            name: 'elements[' + index + '][translation][editorial_second]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_second
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.subline" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.subline', Store),
            name:
                'elements[' +
                index +
                '][translation][editorial_second_subline]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_second_subline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.url" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.url', Store),
            name: 'elements[' + index + '][translation][editorial_second_url]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_second_url
                        : ''
                    : ''
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'editorial_second_image_id' + index,
                    path:
                        newElement === false
                            ? element.media2 !== undefined
                                ? element.media3.cdn_url
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 2,
                        md: 2,
                        sm: 2
                    }
                },
                {
                    formType: 'formFile',
                    onclick: true,
                    id: 'editorial_second_image_idFormField' + index,
                    onclick_context:
                        newElement === false
                            ? element.context
                            : 'marketplace_api',
                    Gallery: galerie,
                    galerieItems: galerieItems,
                    expandfile: index + 'editorial_first_image_id',
                    experiment: true,
                    append:
                        'elements[' +
                        index +
                        '][translation][editorial_second_image_id]',
                    src: 'editorial_second_image_id' + index,
                    size: {
                        xl: 10,
                        md: 10,
                        sm: 10
                    }
                }
            ]
        },
        {
            formType: 'input',
            name:
                'elements[' +
                index +
                '][translation][editorial_second_image_id]',
            id:
                'elements[' +
                index +
                '][translation][editorial_second_image_id]',
            visible: true,
            label: 'imageID',
            type: 'text',
            placeholder: 'imageID',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.editorial_second_image_id
                        : ''
                    : ''
        }
    ]

    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex)
    )
    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Row
                id={'module_content' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                className={'boxShadowCard card ElementBorder'}
                style={{
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    width: '100%',
                    margin: '0px 0px 10px 0px'
                }}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                <div id={'Teaser'}>module_content</div>
                {WebBuilderElementsHead(
                    <IntlMessage Store={Store} messageId="module.editorial" />,
                    'module_content',
                    newElement === false
                        ? element.translation !== undefined
                            ? element.translation.headline
                            : ''
                        : '',
                    Status,
                    index,
                    'elementButton',
                    <IntlMessage Store={Store} messageId="module.editorial" />,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_module_blog' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_module_content' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_module_content' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                Module Content
                            </Col>
                            <div id={'Teaser'}>image_title</div>
                            {PielersForm(newConfiguration)}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'module_content'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.editorial"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Row>
        </>
    )
}

export default module_contentWebBuilder
