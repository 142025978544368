import IntlMessage from '../../const/IntlMessage'
import React from 'react'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    //const titel = (<><h2><IntlMessage Store={Store} messageId="share.title" /></h2></>);
    const titel = (
        <>
            <h2>Social Media</h2>
        </>
    )
    //const description = (<><p><IntlMessage Store={Store} messageId="share.description" /></p></>);
    const description = (
        <>
            <p></p>
        </>
    )

    const configuration = [
        {
            formType: 'textarea',
            label: <IntlMessage Store={Store} messageId="common.title" />,
            type: 'textarea',
            placeholder: '',
            id: 'share_title',
            max: 70,
            //'legende': <IntlMessage Store={Store} messageId="share.title.legend" />,
            name: 'translation[seo][share_title]',
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.share_title
                        : ''
                    : ''
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'image',
                    altLink: true,
                    id: 'image_title_image_seo_',
                    path:
                        newElement === false
                            ? response !== undefined &&
                              response !== null &&
                              response.media !== null
                                ? response.media !== undefined
                                    ? response.media.cdn_url
                                    : ''
                                : ''
                            : '',
                    width: '44',
                    height: '44',
                    class: 'elementsIMg',
                    size: {
                        xl: 12,
                        md: 12,
                        sm: 12
                    }
                },
                {
                    formType: 'formFile',
                    onclick: true,
                    onclick_context: 'pielers_element_text_image',
                    append: 'preview_media_seo',
                    src: 'image_title_image_seo_',
                    experiment: true,
                    size: {
                        xl: 12,
                        md: 12,
                        sm: 12
                    }
                }
            ]
        },
        {
            formType: 'input',
            name: 'translation[seo][share_media]',
            id: 'preview_media_seo',
            visible: true,
            type: 'hidden',
            placeholder: 'imageID',
            value:
                newElement === false
                    ? response !== undefined &&
                      response !== null &&
                      response.media !== null
                        ? response.media !== undefined
                            ? response.media.id
                            : null
                        : null
                    : null
        }
    ]

    const configurationCol2 = [
        {
            formType: 'textarea',
            label: <IntlMessage Store={Store} messageId="common.description" />,
            type: 'textarea',
            id: 'share_description',
            max: 250,
            //'legende': <IntlMessage Store={Store} messageId="share.description.legend" />,
            row: 6,
            name: 'translation[seo][share_description]',
            value:
                newElement === false
                    ? response !== undefined && response !== null
                        ? response.share_description
                        : ''
                    : ''
        }
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'colOne') {
        return configuration
    } else if (conf === 'colTwo') {
        return configurationCol2
    }
}

export default configuration
