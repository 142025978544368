import { useEffect, useState } from 'react'
import { OrdersApiType } from '../../request/Orders/OrdersAPI.interface'
import { orders } from '../../request/Orders/Orders'
import { OrdersDetailApiType } from '../../request/Orders/OrdersDetailAPI.interface'
import { OrdersMerchantDetailApiType } from '../../request/Orders/OrdersDetailMerchantAPI.interface'
import { error } from '../../../../../../@WUM/core/component/const/error.services'
import { checkLogin } from '../../../../../../@WUM/templates/default/Auth/service/AuthService'

export const Response = (limit: string, searchValue: any) => {
    const [posts, setPosts] = useState<OrdersApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await orders
                .getPosts(limit, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

export const ResponseMerchant = (id: string, limit: string, searchValue: any) => {
    const [posts, setPosts] = useState<OrdersApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await orders
                .getPostsMerchant(id, limit,searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id, limit,searchValue])

    return isError ? posts : posts
}

export const ResponseMerchantDetail = (id: string | undefined) => {
    const [posts, setPosts] = useState<OrdersMerchantDetailApiType>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await orders
                .getAPostMerchant(id)
                .then((data) => {
                    checkLogin(data)
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const ResponseDetail = (id: string | undefined) => {
    const [posts, setPosts] = useState<OrdersDetailApiType>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await orders
                .getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}

export const Responsestatus = (id: any) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await orders
                .getPostsStatust('', id)
                .then((data) => {
                    checkLogin(data)
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])
    return isError ? isError : isError
}
