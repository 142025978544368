import Items from './item'
import React from 'react'

/**
 *
 * @param heads
 * @param route
 * @param icons
 * @constructor
 */
function MenuItem(
    heads: string | any[],
    route: string[],
    icons: string[],
    display: string[]
) {
    const row = []

    if (display !== undefined && display[1] === 'true') {
        let icon = parseInt(display[0])

        row.push(Items(heads[icon], route[icon], icons[icon], 0))

        return <>{row}</>
    } else {
        for (let i = 0; i < heads.length; i++) {
            row.push(Items(heads[i], route[i], icons[i], i))
        }
        return <>{row}</>
    }
}
export default MenuItem
