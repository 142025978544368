import React from 'react'
import CustomizerOverview from './Pages/overview'
import CustomizerDetail from './Pages/Detail'
import { LanguageSwitcherValue } from '../../../../@WUM/core/component/core/components/LanguageSwitcher'

export const CustomizerPagesConfig = (value: any) => {
    return [
        {
            path: '/customizer/:page',
            elements: <CustomizerOverview value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        },
        {
            path: '/customizer/Detail/:id',
            elements: <CustomizerDetail value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        }
    ]
}

export const CustomizerMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('cms.customize.header'),
            route: '/customizer/1',
            icon: 'css',
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        }
    ]
}

export const CustomizeURL = (value: any) => {
    return ['/customizer/Detail/']
}
