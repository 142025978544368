import React, { useRef, useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import PielersForm from '../../../../@WUM/core/form-serilizer'
import configuration from './configuration/Page/configuration'
import { saveDataIndividualPut } from '../../../../@WUM/core/RestFullApi/ApiHook'
import { notify_save } from '../../../../@WUM/core/component/const/notification.service'
import { error } from '../../../../@WUM/core/component/const/error.services'
import { handleSubmitForm } from '../../../../@WUM/core/component/const/handleSubmitForm'
import { btn_back } from '../../../../@WUM/core/component/const/btn_back'
import { Form } from 'react-bootstrap'
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import { useSelector } from 'react-redux'
import {
    ResponseCustomTypeDetail,
    ResponseCustomTypesFormDetail
} from '../API/response/customTypes'
import Spinner from 'react-bootstrap/Spinner'

const CustomTypesNew = (value: any) => {
    const { id } = useParams<{ id: string }>()
    const Store = useSelector((state: any) => state)
    const history = useNavigate()
    const [validated, setValidated] = useState(false)
    const FormField = ResponseCustomTypesFormDetail(id, '?mode=1')
    const CustomType = ResponseCustomTypeDetail(id)

    const forms = useRef(null)
    const submit = () => {
        let Dataform = forms.current
        if (Dataform !== undefined && Dataform !== null) {
            const data = new FormData(Dataform)
            saveDataIndividualPut('/custom/type/single/0/', data)
                .then((data: any) => {
                    notify_save(Store)
                    history(-1)
                })
                .catch((e) => {
                    error(e)
                })
        }
    }
    if (
        typeof FormField !== 'boolean' &&
        FormField.length > 0 &&
        typeof CustomType !== 'boolean' &&
        CustomType.length > 0
    ) {
        return (
            <div>
                <Form
                    ref={forms}
                    id={'form001'}
                    noValidate
                    validated={validated}
                    onSubmit={(e) =>
                        handleSubmitForm(e, submit, setValidated, 0)
                    }
                >
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        key={'tabindex'}
                    >
                        <Tab
                            eventKey="home"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.general"
                                />
                            }
                            key={'Allgemein'}
                            id={'Allgemein'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {PielersForm(
                                configuration(
                                    'dataColOne',
                                    [],
                                    true,
                                    Store,
                                    FormField,
                                    CustomType[0]
                                )
                            )}
                        </Tab>
                    </Tabs>
                    <div style={{ backgroundColor: 'white', padding: '15px' }}>
                        {btn_back(history, 'common.abort', '', Store)}
                        <Button
                            type={'submit'}
                            variant="success"
                            style={{ float: 'right', marginRight: '15px' }}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.save"
                            />
                        </Button>
                        <br style={{ clear: 'both' }} />
                    </div>
                </Form>
            </div>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </>
        )
    }
}

export default CustomTypesNew
