import { logout } from '../service/AuthService'
import {useLocation, useNavigate} from "react-router-dom";
import {notify_logout, notify_token_exp} from "../../../../core/component/const/notification.service";
import Alert from 'react-bootstrap/Alert';
import {IntlMessageValue} from "../../../../core/component/const/IntlMessage";
import {useSelector} from "react-redux";

const Logout = (value: any) => {
    let { state } = useLocation();
    const navigate = useNavigate();
    const Store = useSelector((state: any) => state)
    let alert = <></>;

    if(state !== undefined && state !== null && state.reload === true) {

        alert = <>
            <Alert variant="warning" className={'animateTop notificationPositiontop'}  dismissible>
                <Alert.Heading>{IntlMessageValue(
                    'de',
                    'notify.jwtTokenexpire.headline',
                    Store
                )}</Alert.Heading>
                <p>
                    {IntlMessageValue(
                        'de',
                        'notify.jwtTokenexpire.text',
                        Store
                    )}
                </p>
            </Alert>
        </>
        state.reload = false
        setTimeout(
            () => window.location.reload(),
            3000
        )

    } else {
        alert = <>
            <Alert variant="success" className={'animateTop notifiLogout'}  dismissible>
                <Alert.Heading>{IntlMessageValue(
                    'de',
                    'notify.logout.headline',
                    Store
                )}</Alert.Heading>
                <p>
                    {IntlMessageValue(
                        'de',
                        'notify.logout.text',
                        Store
                    )}
                </p>
            </Alert>
        </>
        logout(navigate)
        setTimeout(
            () => window.location.href = '/Login',
            3000
        )

    }



    return <>
        {alert}
    </>
}

export default Logout
