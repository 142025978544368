import Col from 'react-bootstrap/Col'
import { Row } from 'react-bootstrap'
import React from 'react'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import { LanguageSwitcherValue } from '../../components/LanguageSwitcher'

const AnalyticsConfig = () => {
    return (
        <>
            <Row>
                <Col>
                    <CreateMatomoIframe
                        headline={LanguageSwitcherValue(
                            'AnalyticsConfig.realtime.headline'
                        )}
                        width={'100%'}
                        height={'600'}
                        url={WidgetURLMapping({ name: 'Visits in real time' })}
                        scrolling={'yes'}
                        id={'a1'}
                    />
                </Col>
                <Col>
                    <CreateMatomoIframe
                        headline={LanguageSwitcherValue(
                            'AnalyticsConfig.visitor.headline'
                        )}
                        width={'100%'}
                        height={'600'}
                        url={WidgetURLMapping({
                            name: 'Visitor map in real time'
                        })}
                        scrolling={'yes'}
                        id={'a2'}
                    />
                </Col>
            </Row>
            <CreateMatomoIframe
                headline={LanguageSwitcherValue(
                    'AnalyticsConfig.site.headline'
                )}
                width={'100%'}
                height={'300'}
                url={WidgetURLMapping({ name: 'page title' })}
                scrolling={'yes'}
                id={'a3'}
            />
            <CreateMatomoIframe
                headline={LanguageSwitcherValue(
                    'AnalyticsConfig.chanel.headline'
                )}
                width={'100%'}
                height={'300'}
                url={WidgetURLMapping({ name: 'channel type' })}
                scrolling={'yes'}
                id={'a4'}
            />
            <CreateMatomoIframe
                headline={LanguageSwitcherValue(
                    'AnalyticsConfig.graph.headline'
                )}
                width={'100%'}
                height={'300'}
                url={WidgetURLMapping({ name: 'Graph of recent visits' })}
                scrolling={'yes'}
                id={'a5'}
            />
            <CreateMatomoIframe
                headline={LanguageSwitcherValue(
                    'AnalyticsConfig.visitor.list.headline'
                )}
                width={'100%'}
                height={'500'}
                url={WidgetURLMapping({ name: 'Visitor overview' })}
                scrolling={'yes'}
                id={'a6'}
            />
        </>
    )
}

export default AnalyticsConfig
