import { templateApiType } from './templateApi.interface'
import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { templateApiDetailType } from './templateApiDetail.interface'

/**
 * Sends the call to the FULL REST API with the methods GET, POST, PUT
 * @namespace example
 * @see {@link siteApiType}
 * @prop {any} limit Indicates the current page
 * @prop {any} searchValue Submits search filters
 * @prop {any} post Indicates POST
 * @prop {any} id Transmits the ID of the target element
 * @example
 * getPosts: (limit: any, searchValue: any): Promise<blogApiType[]> => requests.get(`Content/Blog/${limit}/${searchValue}`),
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const template = {
    getPosts: (limit: any, searchValue: any): Promise<templateApiType[]> =>
        requests.get(`template/all/${limit}/${searchValue}`),
    //getPosts: (): Promise<siteApiType[]> => requests.get(`page/`),
    getAPost: (id: string): Promise<templateApiDetailType[]> =>
        requests.get(`template/${id}/`),
    createPost: (post: any, id: number): Promise<templateApiDetailType> =>
        requests.put(`/template/insert/${id}/`, post),
    updatePost: (post: any, id: number): Promise<templateApiDetailType> =>
        requests.post(`template/update/${id}/`, JSON.stringify(post)),
    deletePost: (id: string): Promise<void> =>
        requests.delete(`SingleTemplate/on/delete/${id}/`)
}
