import { useEffect, useState } from 'react'
import { error } from '../../../../../@WUM/core/component/const/error.services'
import { promo } from '../request/promo'
import { promoApiType } from '../request/promoData.interface'
import { checkLogin } from '../../../../../@WUM/templates/default/Auth/service/AuthService'

/**
 * Sends response to Rest API for Blog Overview
 * @memberOf promo
 * @prop limit
 * @prop searchValue
 * @see {@link promoApiType}
 * @author Marcel
 * Ugurcu
 * @version 0.0.1
 */
export const ResponsePromo = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<promoApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await promo
                .getPosts(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

/**
 * Sends response to Rest API for Blog Detail PAge
 * @memberOf blog
 * @prop id
 * @see {@link blogApiDetail}
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ResponsePromoDetail = (id: string | undefined) => {
    const [posts, setPosts] = useState<promoApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await promo
                .getPostsData(id)
                .then((data) => {
                    checkLogin(data)
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}
