export interface config {
    id: number
    data: any
    slug: any
}

export function output(Data: any) {
    return {
        id: Data.id,
        data: Data.data,
        slug: Data.slug
    }
}

export interface configType {
    companyType: any
    form: any
}

export function outputType(Data: any) {
    return {
        companyType: Data.companyType,
        form: Data.form
    }
}
