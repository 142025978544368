import IntlMessage from '../../const/IntlMessage'
import React from 'react'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    const titel = (
        <>
            <h2>
                <IntlMessage
                    Store={Store}
                    messageId="configuration.content.title"
                />
            </h2>
        </>
    )

    const description = (
        <>
            <p>
                <IntlMessage
                    Store={Store}
                    messageId="configuration.content.description"
                />
            </p>
        </>
    )

    const configuration = [
        {
            conf: [
                {
                    lang: 'de',
                    height: '400px'
                },
                {
                    lang: 'de',
                    height: '200px'
                },
                {
                    lang: 'de',
                    height: '400px'
                }
            ],
            elements:
                newElement === false
                    ? response !== undefined && response.elements !== undefined
                        ? response.elements
                        : []
                    : []
        }
    ]
    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'default') {
        return configuration[0]
    }
}

export default configuration
