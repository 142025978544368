import React from 'react'
import Col from 'react-bootstrap/Col'
import renderFormSwitch from '../../registerForm'

function col_helper(data: any, Store: any) {
    const row = []
    const single = []
    for (let c = 0; c < data.cols.length; c++) {
        if (data.singelCol !== undefined && data.singelCol === true) {
            single.push(
                <>{renderFormSwitch(data.cols, c, null, null, Store)}</>
            )
        } else if (data.cols[c].size !== undefined) {
            row.push(
                <>
                    <Col
                        xl={data.cols[c].size.xl}
                        md={data.cols[c].size.xl}
                        sm={data.cols[c].size.xl}
                    >
                        {renderFormSwitch(data.cols, c, null, null, Store)}
                    </Col>
                </>
            )
        } else {
            row.push(
                <>
                    <Col md className={data.class}>
                        {renderFormSwitch(data.cols, c, null, null, Store)}
                    </Col>
                </>
            )
        }
    }
    if (data.singelCol !== undefined && data.singelCol === true) {
        if (
            data.singleNew !== undefined &&
            data.singleNew &&
            single.length > 0
        ) {
            row.push(
                <>
                    <div className={'outerDiv'}>{single}</div>
                </>
            )
        } else {
            if (data.nonCol !== undefined) {
                row.push(
                    <>
                        <div className={data.class + ' innerDiv'}>{single}</div>
                    </>
                )
            } else {
                row.push(
                    <>
                        <Col
                            md={data.colsize !== undefined ? data.colsize : 4}
                            id={data.RowId}
                        >
                            <div className={data.class + ' innerDiv'}>
                                {single}
                            </div>
                        </Col>
                    </>
                )
            }
        }
    }
    if (data.hasOwnProperty('cols')) {
        return (
            <>
                <>{row}</>
            </>
        )
    } else {
        return
    }
}

export default col_helper
