import { useEffect, useState } from 'react'
import { ProductApiType } from '../../request/Product/ProductApi.interface'
import { Product } from '../../request/Product/Product'
import { ProductDetailApiType } from '../../request/Product/ProductApiDetail.interface'
import { ProductVariantsDetailApiType } from '../../request/Product/ProductVariantsApiDetail.interface'
import { ShippingApiType } from '../../request/Product/ShippingApi.interface'
import { DataSheetApiType } from '../../request/Product/DataSheetApi.interface'
import { DimensionApiType } from '../../request/Product/DimensionApi.interface'
import { allTaxonApiType } from '../../request/Product/allTacxonApi.interface'
import { DatenblattDetailApiType } from '../../request/Product/DatenblattApi.interface'
import { TaxApiType } from '../../request/Product/TaxApi.interface'
import { TaxApiDetail } from '../../request/Product/TaxApiDetail.interface'
import { error } from '../../../../../@WUM/core/component/const/error.services'
import { ImagesType } from '../../request/Product/ImagesApi.interface'
import { allProductApiType } from '../../request/Product/allProductApi.interface'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../../../../store'
import { checkLogin } from '../../../../../@WUM/templates/default/Auth/service/AuthService'
export const Response = (): any => {
    const [posts, setPosts] = useState<ProductApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.getPosts()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [])

    return isError ? posts : posts
}

export const ResponseLimit = (page: any, search: any) => {
    const [posts, setPosts] = useState<ProductApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.getPostsLimit(page, search)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [page, search])

    return isError ? posts : posts
}

export const ResponseLimitSearch = (page: any, search: any) => {
    const [posts, setPosts] = useState<ProductApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.getPostsLimitSearch(page, search)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [page, search])

    return isError ? posts : posts
}
export const ResponseLimitSearchMerchant = (
    page: any,
    merchant: any,
    search: any
) => {
    const [posts, setPosts] = useState<ProductApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.getPostsLimitSearchMerchant(page, merchant, search)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [page, merchant, search])

    return isError ? posts : posts
}

export const ResponseDetail = (id: any) => {
    const [posts, setPosts] = useState<ProductDetailApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseVariants = (
    id: any,
    saveLoadVariants: any,
    saveLoadVariantsValue: any
) => {
    const [posts, setPosts] = useState<ProductVariantsDetailApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await Product.getVariants(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    saveLoadVariants(true)
                    saveLoadVariantsValue(data)
                    let form = data
                    // localStorage.setItem('variant', JSON.stringify(form))
                    dispatch({ type: 'PRODUCT_VARIANT', form })
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch, id, saveLoadVariants, saveLoadVariantsValue])

    return isError ? isError : posts
}

export const ResponseAllShipingTypes = () => {
    const [posts, setPosts] = useState<ShippingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await Product.getAllShipingTypes()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    let form = data
                    dispatch({ type: 'PRODUCT_SHIPPING', form })
                    // localStorage.setItem('Shipping', JSON.stringify(form))
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch])

    return isError ? posts : posts
}

export const ResponseAllTax = () => {
    const [posts, setPosts] = useState<TaxApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await Product.getAllTax()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    let form = data
                    // localStorage.setItem('Tax', JSON.stringify(form))
                    dispatch({ type: 'PRODUCT_PRODUCT_TAX', form })
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch])

    return isError ? posts : posts
}

export const ResponseDetailTax = (id: any) => {
    const [posts, setPosts] = useState<TaxApiDetail[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.getAPostTax(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}

export const ResponseallTaxLimit = (page: any, searchValue: any) => {
    const [posts, setPosts] = useState<TaxApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.getAllTaxLimit(page, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [page, searchValue])

    return isError ? isError : posts
}

export const ResponseTaxDelete = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.deletePostTax(id)
                .then((data) => {
                    checkLogin(data)
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}

export const ResponseTaxonDelete = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await Product.deletePostTaxon(id)
                .then((data) => {
                    checkLogin(data)
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}

export const ResponseallTaxon = () => {
    const [posts, setPosts] = useState<allTaxonApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await Product.getallTaxon()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    // localStorage.setItem('allTaxon', JSON.stringify(data))
                    dispatch({ type: 'PRODUCT_ALL_TAXON', data })
                })
                .catch((err) => {
                    //error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch])

    return isError ? isError : posts
}

export const ResponseallProducts = () => {
    const [posts, setPosts] = useState<allProductApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await Product.getAllProducts()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    // localStorage.setItem('allProducts', JSON.stringify(data))
                    dispatch({ type: 'PRODUCT_ALL_PRODUCT', data })
                })
                .catch((err) => {
                    //error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch])

    return isError ? isError : posts
}

export const ResponseImages = (id: any) => {
    const [posts, setPosts] = useState<ImagesType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await Product.getImages(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    let form = data
                    dispatch({ type: 'PRODUCT_IMAGES', form })
                    // localStorage.setItem('images', JSON.stringify(form))
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch, id])

    return isError ? isError : posts
}

export const ResponseArcticle = (id: any) => {
    const [posts, setPosts] = useState<DataSheetApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await Product.getArcticle(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //let form = data
                    // localStorage.setItem('PRODUCT_ARTICLE', JSON.stringify(form))
                    dispatch({ type: 'PRODUCT_ARTICLE', data })
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch, id])

    return isError ? isError : posts
}

export const ResponseDimension = (id: any, props: any): any => {
    const [posts, setPosts] = useState<DimensionApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            if (process.env.REACT_APP_AGRICA !== 'True') {
                await Product.getDimension(id)
                    .then((data) => {
                        checkLogin(data)
                        setPosts(data)
                        let form = data
                        // localStorage.setItem('Dimensions', JSON.stringify(form))
                        dispatch({ type: 'PRODUCT_DIMENSIONS', form })
                    })
                    .catch((err) => {
                        error(err)
                        setIsError(true)
                    })
            } else {
                await Product.getNutrient(id)
                    .then((data) => {
                        setPosts(data)
                        let form = data
                        // localStorage.setItem('Dimensions', JSON.stringify(form))
                        // props.getProductDimensions(form)
                        dispatch({ type: 'PRODUCT_DIMENSIONS', form })
                    })
                    .catch((err) => {
                        error(err)
                        setIsError(true)
                    })
            }
        })()
    }, [dispatch, id])

    return isError ? isError : posts
}

export const ResponseDataSheets = (id: any) => {
    const [posts, setPosts] = useState<DatenblattDetailApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    const dispatch: AppDispatch = useDispatch()
    useEffect(() => {
        ;(async () => {
            await Product.getDatenblatt(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    let seals: any = []
                    let ergonomie: any = []
                    let shippingType: any = []
                    let shippingTime: any = []
                    let montage: any = []
                    let origins: any = []
                    let criteria: any = []
                    let allergens: any = []
                    let sealsPielers: any = []
                    let shippingtypePielers: any = []
                    let packaging: any = []
                    let storagePielers: any = []
                    let portion: any = []
                    let formsOfNutrition: any = []
                    let rigonalBrand: any = []
                    for (let e = 0; e < data[0].children.length; e++) {
                        seals.push({
                            formType: 'checkbox',
                            label: data[0].children[e].title,
                            name:
                                'seals[' + data[0].children[e].id + '][title]',
                            id: 'seals' + data[0].children[e].id + '][title]',
                            visible: data[0].children[e].check
                        })
                        seals.push({
                            formType: 'input',
                            type: 'text',
                            value: data[0].children[e].description,
                            name:
                                'seals[' +
                                data[0].children[e].id +
                                '][description]',
                            id:
                                'seals[' +
                                data[0].children[e].id +
                                '][description]'
                        })
                        seals.push({
                            formType: 'divider'
                        })
                    }

                    for (let e = 0; e < data[1].children.length; e++) {
                        if (data[1].children[e].id !== 121) {
                            ergonomie.push({
                                formType: 'radio',
                                label: data[1].children[e].title,
                                name: 'ergonomie[id]',
                                value: data[1].children[e].id,
                                id:
                                    'ergonomie[' +
                                    data[1].children[e].id +
                                    '][title]',
                                visible: data[1].children[e].check
                            })
                            ergonomie.push({
                                formType: 'input',
                                type: 'text',
                                value: data[1].children[e].description,
                                name: 'ergonomie[description]',
                                id:
                                    'ergonomie' +
                                    data[1].children[e].id +
                                    '[description]'
                            })
                        } else {
                            ergonomie.push({
                                formType: 'checkbox',
                                label: data[1].children[e].title,
                                name:
                                    'ergonomie[' +
                                    data[1].children[e].id +
                                    '][title]',
                                id:
                                    'ergonomie[' +
                                    data[1].children[e].id +
                                    '][title]',
                                visible: data[1].children[e].check
                            })
                            ergonomie.push({
                                formType: 'input',
                                type: 'text',
                                value: data[1].children[e].description,
                                name:
                                    'ergonomie[' +
                                    data[1].children[e].id +
                                    '][description]',
                                id:
                                    'ergonomie' +
                                    data[1].children[e].id +
                                    '[description]'
                            })
                        }

                        ergonomie.push({
                            formType: 'divider'
                        })
                    }
                    for (let e = 0; e < data[2].children.length; e++) {
                        shippingType.push({
                            formType: 'checkbox',
                            label: data[2].children[e].title,
                            name:
                                'shippingType[' +
                                data[2].children[e].id +
                                '][title]',
                            id:
                                'shippingType[' +
                                data[2].children[e].id +
                                '][title]',
                            visible: data[2].children[e].check
                        })
                    }
                    for (let e = 0; e < data[3].children.length; e++) {
                        if (data[3].children[e].id !== 131) {
                            shippingTime.push({
                                formType: 'checkbox',
                                label: data[3].children[e].title,
                                name:
                                    'shippingTime[' +
                                    data[3].children[e].id +
                                    '][title]',
                                id:
                                    'shippingTime[' +
                                    data[3].children[e].id +
                                    '][title]',
                                visible: data[3].children[e].check
                            })
                        }
                    }
                    for (let e = 0; e < data[3].children.length; e++) {
                        if (data[3].children[e].id === 131) {
                            shippingTime.push({
                                formType: 'checkbox',
                                label: data[3].children[e].title,
                                name:
                                    'shippingTime[' +
                                    data[3].children[e].id +
                                    '][title]',
                                id:
                                    'shippingTime[' +
                                    data[3].children[e].id +
                                    '][title]',
                                visible: data[3].children[e].check
                            })
                        }
                    }
                    for (let e = 0; e < data[4].children.length; e++) {
                        montage.push({
                            formType: 'checkbox',
                            label: data[4].children[e].title,
                            name:
                                'montage[' +
                                data[4].children[e].id +
                                '][title]',
                            id:
                                'montage[' +
                                data[4].children[e].id +
                                '][title]',
                            visible: data[4].children[e].check
                        })
                    }
                    for (let e = 0; e < data[5].children.length; e++) {
                        origins.push({
                            formType: 'checkbox',
                            label: data[5].children[e].title,
                            class: 'origins',
                            identifierClass: 'origins',
                            excluded:
                                'origins[' +
                                data[5].children[e].id +
                                '][title]',
                            onclick: true,
                            name:
                                'origins[' +
                                data[5].children[e].id +
                                '][title]',
                            id:
                                'origins[' +
                                data[5].children[e].id +
                                '][title]',
                            visible: data[5].children[e].check
                        })
                        if (
                            data[5].children[e].id === 81 ||
                            data[5].children[e].id === 82
                        ) {
                            origins.push({
                                formType: 'input',
                                type: 'text',
                                value: data[5].children[e].description,
                                name:
                                    'origins[' +
                                    data[5].children[e].id +
                                    '][description]',
                                id:
                                    'origins[' +
                                    data[5].children[e].id +
                                    '][description]'
                            })
                            origins.push({
                                formType: 'divider'
                            })
                        }
                    }
                    for (let e = 0; e < data[7].children.length; e++) {
                        criteria.push({
                            formType: 'checkbox',
                            label: data[7].children[e].title,
                            name:
                                'criteria[' +
                                data[7].children[e].id +
                                '][title]',
                            id:
                                'criteria[' +
                                data[7].children[e].id +
                                '][title]',
                            visible: data[7].children[e].check
                        })
                        criteria.push({
                            formType: 'input',
                            type: 'text',
                            value: data[7].children[e].description,
                            name:
                                'seals[' +
                                data[7].children[e].id +
                                '][description]',
                            id:
                                'seals[' +
                                data[7].children[e].id +
                                '][description]'
                        })
                        criteria.push({
                            formType: 'divider'
                        })
                    }
                    for (let e = 0; e < data[10].children.length; e++) {
                        allergens.push({
                            formType: 'checkbox',
                            label: data[10].children[e].title,
                            name:
                                'allergens[' +
                                data[10].children[e].id +
                                '][title]',
                            id:
                                'allergens[' +
                                data[10].children[e].id +
                                '][title]',
                            visible: data[10].children[e].check
                        })
                    }
                    for (let e = 0; e < data[6].children.length; e++) {
                        sealsPielers.push({
                            formType: 'checkbox',
                            label: data[6].children[e].title,
                            name:
                                'seals[' + data[6].children[e].id + '][title]',
                            id: 'seals' + data[6].children[e].id + '][title]',
                            visible: data[6].children[e].check
                        })
                        sealsPielers.push({
                            formType: 'input',
                            type: 'text',
                            value: data[6].children[e].description,
                            name:
                                'seals[' +
                                data[6].children[e].id +
                                '][description]',
                            id:
                                'seals[' +
                                data[6].children[e].id +
                                '][description]'
                        })
                        sealsPielers.push({
                            formType: 'divider'
                        })
                    }
                    for (let e = 0; e < data[8].children.length; e++) {
                        shippingtypePielers.push({
                            formType: 'checkbox',
                            label: data[8].children[e].title,
                            name:
                                'shippingtypePielers[' +
                                data[8].children[e].id +
                                '][title]',
                            id:
                                'shippingtypePielers[' +
                                data[8].children[e].id +
                                '][title]',
                            visible: data[8].children[e].check
                        })
                    }
                    for (let e = 0; e < data[14].children.length; e++) {
                        packaging.push({
                            formType: 'checkbox',
                            label: data[14].children[e].title,
                            name:
                                'packaging[' +
                                data[14].children[e].id +
                                '][title]',
                            id:
                                'packaging[' +
                                data[14].children[e].id +
                                '][title]',
                            visible: data[14].children[e].check
                        })
                    }
                    for (let e = 0; e < data[15].children.length; e++) {
                        storagePielers.push({
                            formType: 'checkbox',
                            label: data[15].children[e].title,
                            name:
                                'storagePielers[' +
                                data[15].children[e].id +
                                '][title]',
                            id:
                                'storagePielers[' +
                                data[15].children[e].id +
                                '][title]',
                            visible: data[15].children[e].check
                        })
                    }
                    for (let e = 0; e < data[12].children.length; e++) {
                        portion.push({
                            formType: 'checkbox',
                            label: data[12].children[e].title,
                            name:
                                'portion[' +
                                data[12].children[e].id +
                                '][title]',
                            id:
                                'portion[' +
                                data[12].children[e].id +
                                '][title]',
                            visible: data[12].children[e].check
                        })
                    }
                    for (let e = 0; e < data[13].children.length; e++) {
                        formsOfNutrition.push({
                            formType: 'checkbox',
                            label: data[13].children[e].title,
                            name:
                                'formsOfNutrition[' +
                                data[13].children[e].id +
                                '][title]',
                            id:
                                'formsOfNutrition[' +
                                data[13].children[e].id +
                                '][title]',
                            visible: data[13].children[e].check
                        })
                    }

                    for (let e = 0; e < data[16].children.length; e++) {
                        rigonalBrand.push({
                            formType: 'checkbox',
                            label: data[16].children[e].title,
                            name:
                                'rigonalBrand[' +
                                data[16].children[e].id +
                                '][title]',
                            id:
                                'rigonalBrand[' +
                                data[16].children[e].id +
                                '][title]',
                            visible: data[16].children[e].check
                        })
                    }

                    let form = {
                        seals: seals,
                        ergonomie: ergonomie,
                        shippingType: shippingType,
                        shippingTime: shippingTime,
                        montage: montage,
                        origins: origins,
                        criteria: criteria,
                        allergens: allergens,
                        sealsPielers: sealsPielers,
                        shippingtypePielers: shippingtypePielers,
                        packaging: packaging,
                        storagePielers: storagePielers,
                        portion: portion,
                        formsOfNutrition: formsOfNutrition,
                        rigonalBrand: rigonalBrand
                    }

                    // localStorage.setItem('datasheet', JSON.stringify(form))
                    dispatch({ type: 'PRODUCT_DATASHEET', form })
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [dispatch, id])

    return isError ? isError : posts
}
