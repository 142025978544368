import { requests } from "../../../RestFullApi/api"
import { notificationApiType } from "./notificationApi.interface.ts"

export const notification = {
    getPostsALL: (limit: any, searchValue: any): Promise<notificationApiType[]> =>
        requests.get(`notifications/all/${limit}/${searchValue}`),
    getPostsALLUnread: (limit: any): Promise<notificationApiType[]> =>
        requests.get(`notifications/unread/all/`),
    getAPost: (id: string | undefined): Promise<notificationApiType[]> =>
        requests.get(`notifications/${id}/`),
    createPostFAQ: (put: notificationApiType, id: number): Promise<notificationApiType> =>
        requests.post(`notifications/${id}/`, put),
}
