import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { allUnitApiType } from './allUnitApi.interface'

export const Unit = {
    getallUnitLimit: (page: any, search: any): Promise<allUnitApiType[]> =>
        requests.get(`/Unit/${page}/${search}`),
    getAPost: (id: string): Promise<allUnitApiType[]> =>
        requests.get(`/Unit/Single/${id}/`),
    deletePostUnit: (id: string): Promise<void> =>
        requests.delete(`/Unit/delete/${id}/`),
    updatePost: (post: any, id: number): Promise<allUnitApiType> =>
        requests.put(`Unit/update/${id}/`, post),
    updatePostNew: (post: any, id: number): Promise<allUnitApiType> =>
        requests.put(`Unit/do/insert/${id}/`, post)
}
