import TableBodyRow from './bodycontent'
import React from 'react'
import TableBody from '@material-ui/core/TableBody'
/**
 * Create Body of Table
 * @prop {object} response Response of API Call
 * @prop {object} tableConfiguration Configuration for Body
 * @prop {object} Menu Configuration for MenuElement
 * @prop {object} tableBodyType Configuration for BodyType
 * @prop {any} indexID ID of Table Row
 * @memberOf PielersTable
 * @author Marcel Ugurcu
 * @version 0.0.1
 */

function TableBodyarea(
    response: any,
    tableConfiguration: any,
    Menu: any,
    tableBodyType: any,
    indexID: any,
    Store: any,
    tableBodyPosition: any
) {
    return (
        <>
            <TableBody>
                {response.map(
                    (
                        i: { id: string | number | null | undefined },
                        index: number
                    ) => (
                        <TableBodyRow
                            key={i.id}
                            data={i}
                            tableConfiguration={tableConfiguration}
                            Menu={Menu}
                            index={index}
                            tableBodyType={tableBodyType}
                            indexID={indexID}
                            Store={Store}
                            tableBodyPosition={tableBodyPosition}
                        />
                    )
                )}
            </TableBody>
        </>
    )
}

export default TableBodyarea
