import {timetrackingApiType} from "./TimetrackingApi.interface";
import {requests} from "../../../../@WUM/core/RestFullApi/api";

export const activity_recording = {
    getPosts: (date:any,user:any): Promise<timetrackingApiType[]> =>
        requests.get(`activity_recording/${date}/yoptimize/${user}/track/`),
    createPost: (post: timetrackingApiType): Promise<timetrackingApiType> =>
        requests.post('save/activity_recording/yoptimize/', post),
    updatePost: (post: any, id: number): Promise<timetrackingApiType> =>
        requests.put(`User/update/${id}/`, post),
    deletePost: (id: any): Promise<void> =>
        requests.delete(`User/delete/${id}/`)
}
