import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import ReactDOM from 'react-dom'
import Form from 'react-bootstrap/Form'
import { ValidateMessage } from '../../component/const/ValidateMessage'
import legende from './helper/legende'
import React from 'react'

const SyntaxHighlight = (data: any, Store: any) => {
    const simultanScroll = () => {
        let t1 = null
        let t2 = null

        if (document.getElementById('t1' + data.index)) {
            t1 = document.getElementById('t1' + data.index)
        }
        if (document.getElementById('t2' + data.index)) {
            t2 = document.getElementById('t2' + data.index)
        }

        if (t1 !== null && t2 !== null) {
            t2.scrollTop = t1.scrollTop
            t1.scrollTop = t2.scrollTop
            t2.scrollLeft = t1.scrollLeft
            t1.scrollLeft = t2.scrollLeft
        } else {
            console.log('t1=' + t1 + ' t2=' + t2)
        }
    }

    const setHighlighter = (e: any, id: any) => {
        let clear = document.getElementById(id) as HTMLDivElement
        clear.innerHTML = ''

        let newElement = (
            <>
                {/* @ts-ignore */}
                <SyntaxHighlighter
                    id={'t2' + data.index}
                    showLineNumbers={false}
                    language="html"
                    style={dark}
                    className={'vh80 '}
                    onScroll={() => simultanScroll()}
                    wrapLines={true}
                >
                    {e.target.value}
                </SyntaxHighlighter>
            </>
        )

        let temp = document.createElement('div')
        ReactDOM.render(newElement, temp)
        let ele = document.getElementById(id)
        if (ele !== null) {
            ele.appendChild(temp)
            let t1 = document.getElementById(
                't1' + data.index
            ) as HTMLTextAreaElement
            let t2 = document.getElementById(
                't2' + data.index
            ) as HTMLDivElement
            t2.scrollTop = t1.scrollTop
            t2.scrollLeft = t1.scrollLeft
            let target = document.getElementById(
                data.targetid
            ) as HTMLDivElement
            target.innerHTML = e.target.value
        }
    }

    data.value = localStorage.getItem('HTMLModul' + data.index)

    return (
        <>
            <Form.Group
                className="mb-3"
                controlId={'Form.ControlTextarea' + data.index}
                key={'Form.ControlTextarea' + data.index}
            >
                <Form.Control
                    id={'t1' + data.index}
                    className={'editorTextareaModul hideScrollbar htmlFace'}
                    name={data.name}
                    as={data.type}
                    defaultValue={data.value}
                    onScroll={() => simultanScroll()}
                    onChange={(e) =>
                        setHighlighter(e, 'SyntaxHighlighter' + data.index)
                    }
                />
                <ValidateMessage
                    valid={data.valid !== undefined ? data.valid : undefined}
                    Store={Store}
                />
            </Form.Group>
            {legende(data)}
            <div
                id={'SyntaxHighlighter' + data.index}
                style={{ marginTop: '-81.3vh' }}
            >
                {/* @ts-ignore */}
                <SyntaxHighlighter
                    id={'t2' + data.index}
                    showLineNumbers={false}
                    language="html"
                    style={dark}
                    onScroll={() => simultanScroll()}
                    className={'vh80'}
                    wrapLines={true}
                >
                    {data.value}
                </SyntaxHighlighter>
            </div>
        </>
    )
}

export default SyntaxHighlight
