import excape from '../helper/excape'
import PielersserializeProductsType from '../helper/elementProductsTypes'

const serializProducts = (elementsdata: any, Data: any, form: any) => {
    let elementstmp: string = ''
    let zahl: number = 0
    let tmpZahl: number = 0
    let tmpZahlBeginn: number = 0
    let DataHolder = []
    let newElement = []
    let setElement = []
    let ControllElement = []

    if (elementsdata.length > 0) {
        let types = elementsdata[elementsdata.length - 1][0].split('[')
        let typeidents = types[1].split(']')
        let heads: number = parseFloat(typeidents[0])
        tmpZahl = heads + 1

        let typest = elementsdata[0][0].split('[')
        let typeidentst = typest[1].split(']')
        tmpZahlBeginn = parseFloat(typeidentst[0])

        for (let xs = tmpZahlBeginn; xs < tmpZahl; xs++) {
            for (let i = 0; i < elementsdata.length; i++) {
                let typeControll = elementsdata[i][0].split('[')
                let typeidentControll = typeControll[1].split(']')
                let headControll: number = parseFloat(typeidentControll[0])
                if (headControll === xs) {
                    newElement.push({
                        id: xs,
                        element: elementsdata[i][0],
                        int: 0,
                        multiplikator: 0
                    })
                    ControllElement.push(xs)
                }
            }
        }
        let int: number = 0

        for (let xs = tmpZahlBeginn; xs < tmpZahl; xs++) {
            for (let e = 0; e < newElement.length; e++) {
                if (newElement[e].id === xs) {
                    int = int + 1
                }
            }
            setElement.push(int)
            int = 0
        }

        let start = 0
        let intervall = 0
        let end = 0
        for (let xs = tmpZahlBeginn; xs < tmpZahl; xs++) {
            if (setElement[start] !== 0) {
                if (ControllElement.includes(xs) === true) {
                    elementstmp += '['
                    end =
                        end + (setElement[start] !== 0 ? setElement[start] : 0)
                    let Iterator = setElement[start] / 3 + (intervall + 1)
                    elementstmp += '{'
                    for (let x = 0; x <= Iterator; x++) {
                        let setter = (x + 1) * 3 - 1

                        for (
                            let intvall = setter - 2;
                            intvall <= setter;
                            intvall++
                        ) {
                            for (let i = 0; i < elementsdata.length; i++) {
                                let type = elementsdata[i][0].split('[')
                                let typeident = type[3].split(']')
                                zahl = parseFloat(typeident[0])
                                let typeControll = elementsdata[i][0].split('[')
                                let typeidentControll =
                                    typeControll[1].split(']')
                                let headControll: number = parseFloat(
                                    typeidentControll[0]
                                )

                                if (headControll === xs) {
                                    if (zahl === intvall) {
                                        if (i === end - 1) {
                                            elementstmp +=
                                                excape(
                                                    PielersserializeProductsType(
                                                        elementsdata[i],
                                                        form
                                                    )
                                                ) + '}'
                                        } else if (i === setter) {
                                            elementstmp +=
                                                excape(
                                                    PielersserializeProductsType(
                                                        elementsdata[i],
                                                        form
                                                    )
                                                ) + '},{'
                                        } else {
                                            elementstmp +=
                                                excape(
                                                    PielersserializeProductsType(
                                                        elementsdata[i],
                                                        form
                                                    )
                                                ) + ','
                                        }
                                    }
                                }
                            }
                        }
                    }
                    intervall = intervall + 1
                }
            }
            if (ControllElement.includes(xs) === true) {
                elementstmp += ']'

                DataHolder.push({ id: xs, json: elementstmp })
                elementstmp = ''
            }
            start = start + 1
        }
    }
    for (let holder = 0; holder < DataHolder.length; holder++) {
        if (
            DataHolder[holder].json !== '[]' &&
            DataHolder[holder].json !== ''
        ) {
            Data['elements'][DataHolder[holder].id].Products = JSON.parse(
                DataHolder[holder].json
            )
        }
    }
    //
    return Data
}

export default serializProducts
