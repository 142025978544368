import { LocaleActionType } from '../ActionType'
import { Dispatch } from 'redux'

/**
 * @typedef Locale Init
 * @returns noting
 * @author IOTA IT
 * @version 0.0.1
 */
export const LocaleRequestInit = () => {
    return {
        type: LocaleActionType.LOCALE_INIT
    }
}

export const LocaleSwitchSuccess = (locale: string) => {
    return {
        type: LocaleActionType.LOCALE_INIT,
        locale: locale
    }
}

export const getLocale = (locale: string) => {
    // console.log(locale);

    return (dispatch: Dispatch) => {
        dispatch(LocaleSwitchSuccess(locale))
    }
}
