import React from 'react'
import configuration from '../configuration/share'
import PielersForm from '../../../form-serilizer'
import Spinner from 'react-bootstrap/Spinner'

function share(props: { response: any; Store: any; disabled?: any }) {
    if (props.response === 'new') {
        return (
            <>
                {configuration('titel', [], true, props.Store)}
                {configuration('description', [], true, props.Store)}
                {/*  <hr />*/}
                {/*  <Row className="g-2">
          <Col md>*/}
                {PielersForm(configuration('colOne', [], true, props.Store))}
                {/* </Col>
          <Col md>
            <div style={{paddingLeft: '10px'}}>*/}
                {PielersForm(configuration('colTwo', [], true, props.Store))}
                {/* </div>
        </Col>
        </Row>*/}
                <hr />
            </>
        )
    } else if (
        props.response !== undefined &&
        props.response.hasOwnProperty('translation') === true
    ) {
        const data = props.response.translation.de.seo
        return (
            <>
                {configuration('titel', data, false, props.Store)}
                {configuration('description', data, false, props.Store)}
                {/*  <hr />*/}
                {/* <Row className="g-2">
          <Col md>*/}
                {PielersForm(configuration('colOne', data, false, props.Store))}
                {/*</Col>
          <Col md>
            <div style={{paddingLeft: '10px'}}>*/}
                {PielersForm(configuration('colTwo', data, false, props.Store))}
                {/*  </div>
          </Col>
        </Row>*/}
                {/*  <hr />*/}
            </>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </>
        )
    }
}

export default share
