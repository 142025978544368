import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'

function header(data: any) {
    let highlighter = <></>

    if (data.SyntaxHighlighter !== undefined) {
        highlighter = (
            <>
                <br />
                {/* @ts-ignore */}
                <SyntaxHighlighter
                    showLineNumbers={true}
                    language={data.SyntaxHighlighterLanguage}
                    style={dark}
                    wrapLines={true}
                >
                    {data.SyntaxHighlighterValue}
                </SyntaxHighlighter>
            </>
        )
    }

    if (data.type === 'h1') {
        return (
            <>
                <>
                    <br />
                    <h1>{data.value}</h1>
                    {highlighter}
                </>
            </>
        )
    } else if (data.type === 'h3') {
        return (
            <>
                <>
                    <br />
                    <h3>{data.value}</h3>
                    {highlighter}
                </>
            </>
        )
    } else if (data.type === 'h4') {
        return (
            <>
                <>
                    <br />
                    <h4>{data.value}</h4>
                    {highlighter}
                </>
            </>
        )
    } else if (data.type === 'h5') {
        return (
            <>
                <>
                    <br />
                    <h5>{data.value}</h5>
                    {highlighter}
                </>
            </>
        )
    } else if (data.type === 'h6') {
        return (
            <>
                <>
                    <br />
                    <h6>{data.value}</h6>
                    {highlighter}
                </>
            </>
        )
    } else {
        return (
            <>
                <>
                    <br />
                    <h2>{data.value}</h2>
                    {highlighter}
                </>
            </>
        )
    }
}

export default header
