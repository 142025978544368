import { UserActionType } from '../../actions/ActionType'

const initialState = {
    loading: false,
    username: localStorage.getItem('username'),
    email: localStorage.getItem('email'),
    first_name: localStorage.getItem('first_name'),
    last_name: localStorage.getItem('last_name'),
    id: localStorage.getItem('userid'),
    is_superuser: false,
    roles: localStorage.getItem('userRole')
}

type action = {
    type: string
    username?: any
    email?: any
    first_name?: any
    last_name?: any
    id?: any
    is_superuser?: any
    error?: any
    roles?: any
}

const UserReducer = (state = initialState, action: action) => {
    switch (action.type) {
        case UserActionType.User_INIT:
            return {
                ...state,
                username: action.username,
                email: action.email,
                first_name: action.first_name,
                last_name: action.last_name,
                id: action.id,
                role: action.roles,
                is_superuser: action.is_superuser,
                loading: true
            }
        default:
            return state
    }
}

export default UserReducer
