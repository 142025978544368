import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'
import { LanguageSwitcherValue } from '../../components/LanguageSwitcher'

const AllChannels = () => {
    return (
        <>
            <CreateMatomoIframe
                headline={LanguageSwitcherValue('CreateMatomoIframe.headline')}
                width={'100%'}
                height={'400'}
                url={WidgetURLMapping({ name: 'channel type' })}
                scrolling={'yes'}
                id={'ac1'}
            />
        </>
    )
}

export default AllChannels
