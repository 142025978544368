import React, { useState } from 'react'
import { Response } from '../../../API/response/invoice/invoice'
import { SpinnerJSX } from '../../../../../../@WUM/core/component/const/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { removeUseLocalStorage } from '../../../../../../@WUM/core/component/const/useLocalStoreage'
import PielersTable from '../../../../../../@WUM/core/tablemaker'
import { handleSucces } from '../../../../../../@WUM/core/component/const/HandleSucces'
import PaginationJSX from '../../../../../../@WUM/core/form-serilizer/elements/pagination'
import { IntlMessageValue } from '../../../../../../@WUM/core/component/const/IntlMessage'
import { useSelector } from 'react-redux'
import SearchJSX from '../../../../../../@WUM/core/component/const/SearchJSX'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'

interface NotificationsProps {
    value: any
}

const InvoiceOverview: React.FC<NotificationsProps> = (value: any) => {
    const Store = useSelector((state: any) => state)
    const { page } = useParams<{ page: string }>()
    removeUseLocalStorage('seo_title')
    removeUseLocalStorage('seo_description')

    const history = useNavigate()

    let limit: any

    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }

    const destination = 'Invoice'
    const namespace_search = 'Invoice_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const response = Response(limit, searchValue)
    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['rechnunsgnummer', 'Rechnungsdatum', 'Typ', 'From', 'To'],
            type: ['string', 'dateInvoice', 'invoice_type', 'string', 'string']
        },
        tableHeader: [
            IntlMessageValue('de', 'invoice.no', Store),
            IntlMessageValue('de', 'invoice.date', Store),
            IntlMessageValue('de', 'common.type', Store),
            IntlMessageValue('de', 'invoice.issued_from', Store),
            IntlMessageValue('de', 'recipient', Store)
        ],
        tableHeaderWidth: ['auto', '25%', '20%', '20%', '20%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                IntlMessageValue('de', 'common.displays', Store),
                IntlMessageValue('de', 'common.delete', Store)
            ],
            menuroute: ['/Invoice/Detail', '/Invoice/Delete/' + page + '/[ID]'],
            menuParams: ['', 'id', 'id'],
            menuIcons: ['eye', 'Delete']
        }
    }
    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_number',
                    translation: 'Rechnungsnummer',
                    param: 'number',
                    method: 'like'
                },
                {
                    id: 'search_discr',
                    translation: 'Typ',
                    param: 'discr',
                    method: 'like',
                    individual: (
                        <Form.Group
                            as={Col}
                            controlId={'search_discr'}
                            name={'search_discr'}
                        >
                            <Form.Label>Typ Filter</Form.Label>
                            <Form.Select
                                aria-label="Filter"
                                defaultValue={
                                    localStorage.getItem(
                                        namespace_search + '_search_discr'
                                    ) as string
                                }
                            >
                                <option />
                                <option value={'merchant_invoice'}>
                                    Bestellung
                                </option>
                                <option value={'reversal_order_invoice'}>
                                    Stornorechnungen
                                </option>
                                <option value={'discount_order_invoice'}>
                                    Gutscheinrechnungen
                                </option>
                            </Form.Select>{' '}
                        </Form.Group>
                    )
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'blog.new.post',
                'common.create_new_taxon'
            ]
        }
    ]
    if (response.length > 0) {
        return (
            <>
                <div className={'site'}>
                    <SearchJSX
                        noNewButton={true}
                        setSearchValue={setSearchValue}
                        Store={Store}
                        SearchConfig={SearchConfig}
                    />
                    <PielersTable Configuration={Configuration} />
                    <PaginationJSX
                        response={response}
                        history={history}
                        to={'overview'}
                        handleSuccess={handleSucces}
                        destination={destination}
                    />
                </div>
            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default InvoiceOverview
