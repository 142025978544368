import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import PielersForm from '../../../../@WUM/core/form-serilizer/index'
import configuration from '../configuration/home'
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import { isEmptyArray } from 'formik'

function home(response: any, Store: any) {
    console.log(response)

    if (response === 'new') {
        return (
            <>
                {configuration('titel', [], true, Store)}
                {configuration('description', [], true, Store)}
                <hr key={'hrone'} />
                <Row className="g-2" key={'spalte'}>
                    <Col md key={'colone'}>
                        {PielersForm(
                            configuration('dataColOne', [], true, Store)
                        )}
                    </Col>
                    <Col md key={'coltwo'}>
                        {PielersForm(
                            configuration('dataColtwo', [], true, Store)
                        )}
                    </Col>
                </Row>
                <hr key={'hrtwo'} />
            </>
        )
    } else if (isEmptyArray(response) === false) {
        return (
            <>
                {configuration('titel', response, false, Store)}
                {configuration('description', response, false, Store)}
                <hr key={'hrone'} />
                <Row className="g-2" key={'spalte'}>
                    <Col md key={'colone'}>
                        {PielersForm(
                            configuration('dataColOne', response, false, Store)
                        )}
                    </Col>
                    <Col md key={'coltwo'}>
                        {PielersForm(
                            configuration('dataColtwo', response, false, Store)
                        )}
                    </Col>
                </Row>
                <hr key={'hrtwo'} />
            </>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                        <IntlMessage messageId="common.loading" Store={Store} />
                    </span>
                </Spinner>
            </>
        )
    }
}

export default home
