import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function table(conf: string, response: any, page: any, Store: any) {
    return {
        tableBody: {
            data: response,
            dataID: 'id',
            visitMenu: 'translation.slug',
            underdata: true,
            keys: [
                'id',
                'company',
                'gffirstname',
                'gflastname',
                'street',
                'postcode',
                'city',
                'hrb'
            ],
            type: [
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
                'string',
                'string'
            ]
        },
        tableHeader: [
            'ID',
            'Company',
            'Vorname',
            'Nachname',
            'Straße',
            'PLZ',
            'Stadt',
            'HRB'
        ],
        tableHeaderWidth: [
            '10%',
            '20%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%',
            '10%'
        ],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit" />
            ],
            menuroute: [
                '/businessgerman/Detail'
            ],
            menuParams: ['id'],
            menuIcons: ['pencil']
        }
    }
}

export default table
