export const BlogFields = [
    {
        identifier: 'font-size-blog-headline',
        type: 'input',
        field: 'font-size-blog-headline'
    },
    {
        identifier: 'font-weight-blog-headline',
        type: 'input',
        field: 'font-weight-blog-headline'
    },
    { identifier: 'font-color-blog', type: 'input', field: 'font-color-blog' },
    {
        identifier: 'font-size-m21-posts-title',
        type: 'input',
        field: 'font-size-m21-posts-title'
    },
    {
        identifier: 'blog-action-link-color',
        type: 'input',
        field: 'blog-action-link-color'
    }
]
