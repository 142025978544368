import React from 'react'
import TaxOverview from './Pages/overview'
import TaxDetail from './Pages/Detail'
import TaxNew from './Pages/new'
import { LanguageSwitcherValue } from '../../shop/core/components/LanguageSwitcher'

export const TaxPagesConfig = (value: any) => {
    return [
        {
            path: '/Tax/overview/:page',
            elements: <TaxOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Tax/Detail/:id',
            elements: <TaxDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Tax/Delete/:page/:deleteID',
            elements: <TaxOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Tax/new/',
            elements: <TaxNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Tax/new/new',
            elements: <TaxNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}

export const TaxMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('settings.tax.header'),
            route: '/Tax/overview/1',
            icon: 'account_balance',
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}
