import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(conf: string, response: any, Store: any) {
    const information = [
        {
            formType: 'header',
            value: <IntlMessage Store={Store} messageId="common.delivery" />
        },
        {
            formType: 'holder',
            ressource: JSON.parse(localStorage.getItem('Shipping') as string),
            value: response.MerchantShippingType,
            type: 'Shippingmerchant'
        }
    ]
    //approval_state

    if (conf === 'information') {
        return information
    }
}

export default configuration
