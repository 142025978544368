import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { useRef } from 'react'
import { saveDataIndividualPut } from '../../../../../../../@WUM/core/RestFullApi/ApiHook'
import PielersForm from '../../../../../../../@WUM/core/form-serilizer'
import { _handleValidateShipping } from '../../../../../../../@WUM/core/component/const/_handleValidateShipping'
import { number } from '../../../../../../../@WUM/core/component/const/number'
import { notify_saveProduct } from '../../../../../../../@WUM/core/component/const/notification.service'
import { error } from '../../../../../../../@WUM/core/component/const/error.services'
import IntlMessage, {
    IntlMessageValue
} from '../../../../../../../@WUM/core/component/const/IntlMessage'

export const setvariants = (
    setModalvariantsShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    savevariantsValue: any,
    id: any,
    Store: any
) => {
    if (id !== 'new') {
        localStorage.removeItem('copyVariants')
    }
    setModalvariantsShow(true)
    savevariantsValue(id)
}

const buildForm = (
    loadVariantsValue: any,
    loadVariants: any,
    checktype: any,
    Store: any
) => {
    let Variants = []
    if (loadVariantsValue !== 'new') {
        for (let v = 0; v < checktype.length; v++) {
            if (checktype[v].code === loadVariantsValue) {
                Variants.push(checktype[v])
            }
        }
    }

    return [
        {
            formType: 'header',
            type: 'h5',
            value:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? '' +
                      IntlMessageValue('de', 'variants.edit', Store) +
                      '' +
                      Variants[0].translations[0].name
                    : '' + IntlMessageValue('de', 'variants.new', Store) + ''
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.status" />,
            name: 'status',
            required: true,
            selected:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? Variants[0].enabled
                    : localStorage.getItem('copyVariants') !== null
                    ? JSON.parse(localStorage.getItem('copyVariants') as string)
                          .enabled
                    : '',
            options: [
                {
                    label: '',
                    value: ''
                },
                {
                    label: IntlMessageValue('de', 'sell_on.plattform', Store),
                    value: '1'
                },
                {
                    label: IntlMessageValue('de', 'take_off.plattform', Store),
                    value: '0'
                }
            ]
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.name" />,
            type: 'text',
            required: true,
            placeholder: IntlMessageValue('de', 'common.insert_name', Store),
            name: 'name',
            id: 'name',
            value:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? Variants[0].translations[0].name
                    : localStorage.getItem('copyVariants') !== null
                    ? JSON.parse(localStorage.getItem('copyVariants') as string)
                          .translations[0].name
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="article.no" />,
            required: true,
            type:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? 'text'
                    : 'text',
            placeholder: IntlMessageValue('de', 'article.no.provide', Store),
            name: 'artikelnummer',
            id: 'artikelnummer',
            value:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? Variants[0].code
                    : ''
        },
        {
            formType: 'header',
            value: <IntlMessage Store={Store} messageId="stock.management" />,
            type: 'h5'
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="stock.keeping" />,
            name: 'lagerhaltung',
            required: true,
            onclick: 'stock',
            selected:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? Variants[0].tracked
                    : localStorage.getItem('copyVariants') !== null
                    ? JSON.parse(localStorage.getItem('copyVariants') as string)
                          .tracked
                    : '',
            options: [
                {
                    label: '',
                    value: ''
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: '0'
                },
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: '1'
                }
            ]
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="stock.on_hand" />,
            type:
                Variants.length > 0 && Variants[0].lagerhaltung === 1
                    ? 'onclickText'
                    : 'onclickHidden',
            containerID: 'stockContainer',
            placeholder: IntlMessageValue(
                'de',
                'stock.on_hand.placeholder',
                Store
            ),
            name: 'on_hand',
            id: 'on_hand',
            value:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? Variants[0].on_hand
                    : localStorage.getItem('copyVariants') !== null
                    ? JSON.parse(localStorage.getItem('copyVariants') as string)
                          .on_hand
                    : 0
        },
        {
            formType: 'header',
            value: <IntlMessage Store={Store} messageId="price.info" />,
            type: 'h5'
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: (
                        <IntlMessage
                            Store={Store}
                            messageId="article.price.euro"
                        />
                    ),
                    type: 'text',
                    required: true,
                    placeholder: IntlMessageValue('de', 'article.price', Store),
                    name: 'price',
                    id: 'price',
                    value:
                        loadVariantsValue !== 'new' && Variants.length > 0
                            ? number('de', Variants[0].pricing.price / 100)
                            : localStorage.getItem('copyVariants') !== null
                            ? number(
                                  'de',
                                  JSON.parse(
                                      localStorage.getItem(
                                          'copyVariants'
                                      ) as string
                                  ).pricing.price / 100
                              )
                            : ''
                },
                {
                    formType: 'fetchselect',
                    local: 'Tax',
                    valueState: 'id',
                    beginn: 'true',
                    start: 0,
                    name: 'taxId',
                    nonetype: true,
                    required: true,
                    type: 'Tax',
                    onClick_id: 'Tax',
                    labelState: 'name',
                    selected:
                        loadVariantsValue !== 'new' && Variants.length > 0
                            ? Variants[0].taxCategoryId.id
                            : localStorage.getItem('copyVariants') !== null
                            ? JSON.parse(
                                  localStorage.getItem('copyVariants') as string
                              ).taxCategoryId.id
                            : '',
                    label: <IntlMessage Store={Store} messageId="tax.vat" />
                }
            ]
        },

        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: (
                        <IntlMessage Store={Store} messageId="sale.volume" />
                    ),
                    type: 'text',
                    required: true,
                    placeholder: IntlMessageValue('de', 'sale.volume', Store),
                    name: 'menge',
                    id: 'menge',
                    value:
                        loadVariantsValue !== 'new' && Variants.length > 0
                            ? Variants[0].pricing.price_base_factor
                            : localStorage.getItem('copyVariants') !== null
                            ? JSON.parse(
                                  localStorage.getItem('copyVariants') as string
                              ).pricing.price_base_factor
                            : ''
                },
                {
                    formType: 'fetchselect',
                    local: 'Unit',
                    valueState: 'code',
                    beginn: 'true',
                    start: 0,
                    nonetype: true,
                    required: true,
                    name: 'type',
                    type: 'type',
                    onClick_id: 'Unit',
                    labelState: 'name',
                    selected:
                        loadVariantsValue !== 'new' && Variants.length > 0
                            ? Variants[0].pricing.price_base_unit
                            : localStorage.getItem('copyVariants') !== null
                            ? JSON.parse(
                                  localStorage.getItem('copyVariants') as string
                              ).pricing.price_base_unit
                            : '',
                    label: <IntlMessage Store={Store} messageId="unit" />
                }
            ]
        },
        {
            formType: 'header',
            value: <IntlMessage Store={Store} messageId="shipping.info" />,
            type: 'h5'
        },
        {
            formType: 'fetchselect',
            local: 'Shipping',
            valueState: 'id',
            beginn: 'true',
            start: 0,
            type: 'Shipping',
            onClick_id: 'Shipping',
            labelState: 'name',
            selected: '',
            label: <IntlMessage Store={Store} messageId="shipping.add" />
        },
        {
            formType: 'holder',
            ressource:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? Variants[0].shippingCategory
                    : localStorage.getItem('copyVariants') !== null
                    ? JSON.parse(localStorage.getItem('copyVariants') as string)
                          .shippingCategory
                    : '',
            type: 'Shipping'
        },
        {
            formType: 'input',
            type: 'hidden',
            name: 'variantID',
            id: 'variantID',
            value:
                loadVariantsValue !== 'new' && Variants.length > 0
                    ? Variants[0].id
                    : ''
        }
    ]
}

export const modalvariants = (
    loadVariants: any,
    loadVariantsValue: any,
    modalvariantsShow: boolean,
    setModalvariantsShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    variantsValue: any,
    id: any,
    Store: any
) => {
    let variants: any = loadVariantsValue

    return (
        <>
            <DataSheetModal
                show={modalvariantsShow}
                data={variantsValue}
                controllData={{ loadVariants }}
                checkType={variants}
                fetchId={id}
                onHide={() => setModalvariantsShow(false)}
                Store={Store}
            />
        </>
    )
}

const DataSheetModal = (props: any) => {
    //let variants:any = JSON.parse(localStorage.getItem('datasheet') as string);

    const form = useRef(null)
    let link
    const submited = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        let Dataform = form.current
        let check = _handleValidateShipping()

        if (check === true) {
            if (Dataform !== undefined && Dataform !== null) {
                const data = new FormData(Dataform)
                if (props.data !== 'new') {
                    link = '/Product/Variant/' + props.fetchId + '/'
                } else {
                    link = '/Product/Variant/new/' + props.fetchId + '/'
                }
                saveDataIndividualPut(link, data)
                    .then((data: any) => {
                        notify_saveProduct(props.Store)
                        localStorage.removeItem('copyVariants')
                        setTimeout(() => window.location.reload(), 3000)
                    })
                    .catch((e) => {
                        error(e)
                    })
            }
            props.onHide()
        } else {
            alert(
                <IntlMessage
                    Store={props.Store}
                    messageId="shipping.price.invalid"
                />
            )
        }
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <form ref={form} onSubmit={submited}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <IntlMessage Store={props.Store} messageId="variants" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'modalBody'}>
                        {PielersForm(
                            buildForm(
                                props.data,
                                props.controllData,
                                props.checkType,
                                props.Store
                            ),
                            null,
                            null,
                            props.Store
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>
                        <IntlMessage
                            Store={props.Store}
                            messageId="common.close"
                        />
                    </Button>
                    <Button
                        type={'submit'}
                        className={'float-end'}
                        variant="success"
                    >
                        <IntlMessage
                            Store={props.Store}
                            messageId="common.save"
                        />
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
