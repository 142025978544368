import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import CopyElements from '../../../component/const/CopyElements'
import WebBuilderElementsHead from '../../../component/const/WebBuilderElementsHead'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Box from '@mui/material/Box'

function carouselItem_endWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    overStart?: any
) {
    const index = newElement === false ? i : newelementIndex
    const configuration = [
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/carouselItem_end.html'
        }
    ]

    let newConfiguration = configuration
    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Box
                id={'carouselItem_end' + index}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    'carouselItem_end',
                    'carouselItem_end',
                    '',
                    Status,
                    index,
                    'elementButton',
                    'Text',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <Row
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        paddingBottom: '15px',
                        paddingTop: '15px',
                        width: '100%',
                        margin: '0px 0px 10px 0px'
                    }}
                >
                    <div id={'Teaser'}>carouselItem_end</div>
                    <Col>carouselItem_end</Col>
                    <Col className={'accordion-body element-body'}>
                        {PielersForm(
                            base(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                overStart
                            )
                        )}
                        {/*<Tabs defaultActiveKey="Einstellungen" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
                {PielersForm(newConfiguration)}
              </Tab>
            </Tabs>*/}
                    </Col>
                    <div
                        id={'settingstext' + index + 'carouselItem_end'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                        <h3>Modul</h3>
                        {PielersForm(newConfiguration)}
                    </div>
                    {newElement === false ? (
                        CopyElements(element.id, 'carouselItem_end', Store)
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default carouselItem_endWebBuilder
