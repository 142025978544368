export interface config {
    id: number
    name: string
    locale: string
    value: string
}

export function output(Data: any) {
    let back = {
        id: Data.id,
        name: Data.name,
        locale: Data.locale,
        value: Data.value
    }

    return back
}
