export const H2 = {
    eventkey: 'H2',
    key: 'H2',
    title: 'H2',
    content: [
        {
            label: 'headline.h2.font-size-h2',
            discr: 'font-size-h2',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h2.font-weight-h2',
            discr: 'font-weight-h2',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h2.font-color-h2',
            discr: 'font-color-h2',
            size: [3, 3, 3],
            type: 'text'
        }
    ]
}
