import WidgetURLMapping from '../../core/WidgetURLMapping'
import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import React from 'react'
import IntlMessage from '../../../../../@WUM/core/component/const/IntlMessage'

const VisitorMapInRealTime = (props: { Store: any }) => {
    return (
        <>
            <CreateMatomoIframe
                headline={
                    <IntlMessage
                        Store={props.Store}
                        messageId="Visitor.user.map.on.realtime"
                    />
                }
                width={'100%'}
                height={'600'}
                url={WidgetURLMapping({ name: 'Visitor map in real time' })}
                scrolling={'no'}
                id={'vmrt1'}
                Store={props.Store}
            />
        </>
    )
}

export default VisitorMapInRealTime
