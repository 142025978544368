const TranslationsSeo = (form: any) => {
    let FormDatas = []
    let inputs = form.elements
    // Iterate over the form controlsc
    for (let i = 0; i < inputs.length; i++) {
        // Disable all form controls
        if (inputs[i].hasAttribute('name')) {
            if (
                !inputs[i].getAttribute('name').includes('elements') &&
                inputs[i].getAttribute('name').includes('translation') &&
                inputs[i].getAttribute('name').includes('seo')
            ) {
                FormDatas.push(inputs[i].getAttribute('name'))
            }
        }
    }

    if (FormDatas.length > 0) {
        let elementsdataTranslation: string[] = []
        let TranslationSeo = []

        for (let ft = 0; ft < FormDatas.length; ft++) {
            if (
                !FormDatas[ft].includes('elements') &&
                FormDatas[ft].includes('translation') &&
                FormDatas[ft].includes('seo')
            ) {
                elementsdataTranslation[ft] += FormDatas[ft] + ','
            }
        }

        for (let t = 0; t < elementsdataTranslation.length; t++) {
            if (typeof elementsdataTranslation[t] !== 'undefined') {
                let filtered = elementsdataTranslation[t]
                    .replace('undefined', '')
                    .split(',')
                    .filter(function (el) {
                        return el !== ''
                    })
                TranslationSeo.push(filtered)
            }
        }

        return TranslationSeo
    } else {
        return []
    }
}

export default TranslationsSeo
