import React from 'react'
import TableCell from '@material-ui/core/TableCell'
function Twofields(
    text: string,
    data: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    Rows: any,
    i: any,
    tableBodyPositionElement: any
) {

    let var1 = data[Rows.split('|')[1]]
    let var2 = data[Rows.split('|')[2]]
    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                key={'td' + i + '_' + index}
            >
                {var1}{var2}
            </TableCell>
        </>
    )
}

export default Twofields
