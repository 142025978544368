import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React, { useRef } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import PielersForm from '../../../../../../../@WUM/core/form-serilizer'
import { saveDataIndividualPut } from '../../../../../../../@WUM/core/RestFullApi/ApiHook'
import { notify_saveProduct } from '../../../../../../../@WUM/core/component/const/notification.service'
import { error } from '../../../../../../../@WUM/core/component/const/error.services'
import Col from 'react-bootstrap/Col'
import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage'

export const setDataSheetMerchant = (
    setModalDataSheetShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void
) => {
    setModalDataSheetShow(true)
}

export const modalDataSheetMerchant = (
    modalDataSheetShow: boolean,
    setModalDataSheetShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    saveDataSheet: any,
    DataSheetValue: any,
    id: any,
    Store: any
) => {
    let DataDataSheet: any = DataSheetValue

    //console.log(id)
    return (
        <>
            <DataSheetModalMerchant
                show={modalDataSheetShow}
                data={DataDataSheet}
                fetchId={id.merchant_id}
                onHide={() => setModalDataSheetShow(false)}
                saveDataSheet={saveDataSheet}
                Store={Store}
            />
        </>
    )
}

const DataSheetModalMerchant = (props: any) => {
    let datasheet: any = JSON.parse(
        localStorage.getItem('datasheetMerchant') as string
    )

    let component = []

    if (process.env.REACT_APP_AGRICA === 'True') {
        component.push(
            <>
                <Tabs
                    defaultActiveKey="rigonalBrand"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    key={'tabindex'}
                >
                    <Tab
                        eventKey="rigonalBrand"
                        title={
                            <IntlMessage
                                Store={props.Store}
                                messageId="rigonalBrand.origin"
                            />
                        }
                        key={'rigonalBrand'}
                        id={'rigonalBrand'}
                        style={{
                            backgroundColor: 'White',
                            padding: '15px',
                            marginTop: '-15px'
                        }}
                    >
                        <h3>
                            <IntlMessage
                                Store={props.Store}
                                messageId="rigonalBrand.origin"
                            />
                        </h3>
                        <Col>{PielersForm(datasheet?.rigonalBrand)}</Col>
                    </Tab>
                </Tabs>
            </>
        )
    }

    const form = useRef(null)
    const submited = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        let Dataform = form.current
        if (Dataform !== undefined && Dataform !== null) {
            const data = new FormData(Dataform)
            saveDataIndividualPut(
                'channel/merchant/Datenblatt/' + props.fetchId + '/',
                data
            )
                .then((data: any) => {
                    notify_saveProduct(props.Store)
                    setTimeout(() => window.location.reload(), 3000)
                })
                .catch((e) => {
                    error(e)
                    setTimeout(() => window.location.reload(), 3000)
                })
        }
        props.onHide()
        /* window.location.reload()*/
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <form ref={form} onSubmit={submited}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <IntlMessage
                            Store={props.Store}
                            messageId="datasheet.edit"
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'modalBody'}>{component}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>
                        <IntlMessage
                            Store={props.Store}
                            messageId="common.close"
                        />
                    </Button>
                    <Button
                        type={'submit'}
                        className={'float-end'}
                        variant="success"
                    >
                        <IntlMessage
                            Store={props.Store}
                            messageId="common.save"
                        />
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
