export const HeaderFields = [
    {
        identifier: 'header-hero-font-weight',
        type: 'input',
        field: 'header-hero-font-weight'
    },
    { identifier: 'hero-font-size', type: 'input', field: 'hero-font-size' },
    {
        identifier: 'hero-font-size-h1',
        type: 'input',
        field: 'hero-font-size-h1'
    },
    {
        identifier: 'hero-font-weight-h1',
        type: 'input',
        field: 'hero-font-weight-h1'
    },
    {
        identifier: 'hero-font-family',
        type: 'input',
        field: 'hero-font-family'
    }
]
