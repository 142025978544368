import { useEffect, useState } from 'react'
import { error } from '../../../../../../@WUM/core/component/const/error.services'
import { allShippingApiType } from '../../request/shipping/allShippingApi.interface'
import { shipping } from '../../request/shipping/Shipping'
import { checkLogin } from '../../../../../../@WUM/templates/default/Auth/service/AuthService'

export const ResponseallShippingDetail = (id: any) => {
    const [posts, setPosts] = useState<allShippingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await shipping
                .getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : posts
}
export const ResponseShippingDelete = (id: string) => {
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await shipping
                .deletePostShipping(id)
                .then((data) => {
                    checkLogin(data)
                    return data
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? isError : isError
}

export const ResponseallShippingLimit = (page: any, searchValue: any) => {
    const [posts, setPosts] = useState<allShippingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await shipping
                .getallShippingLimit(page, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [page, searchValue])

    return isError ? isError : posts
}
