const SearchConfig = (destination: any) => {
    return [
        {
            namespace: destination + '_searchValue',
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'Nach Kunden suchen',
                    param: 'username',
                    method: 'like'
                }
                /*  {id:'search_name',translation: 'Nach Namen suchen',param: 'customer.name',method: 'like'}*/
            ],
            button: ['common.search', 'common.del.search']
        }
    ]
}

export default SearchConfig
