export const TabsNavbar = {
    eventkey: 'Navbar',
    key: 'Navbar',
    title: 'Navbar',
    content: [
        {
            label: 'navbar.font-color-navbar',
            discr: 'font-color-navbar',
            size: [3, 3, 3],
            type: 'color'
        },
        {
            label: 'navbar.font-size-navbar',
            discr: 'font-size-navbar',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'navbar.font-weight-navbar',
            discr: 'font-weight-navbar',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'navbar.text-transform-navbar',
            discr: 'text-transform-navbar',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'navbar.font-color-navbar-active',
            discr: 'font-color-navbar-active',
            size: [3, 3, 3],
            type: 'color'
        },
        {
            label: 'navbar.font-color-navbar-hover',
            discr: 'font-color-navbar-hover',
            size: [3, 3, 3],
            type: 'color'
        }
    ]
}
