import { siteApiDetailType } from '../modules/CMS/Pages/API/request/site/siteApiDetail.interface'
import {
    config,
    output
} from '../modules/CMS/Pages/modules/configuration/mapping'
import {
    configBlog,
    outputBlog
} from '../modules/CMS/Blog/modules/configuration/mapping'
import { blogApiDetail } from '../modules/CMS/Blog/API/request/blog/blogApiDetail.interface'
import { customizerApiDetailType } from '../modules/CMS/customize/API/request/customizer/customizerApiDetail.interface'
import updateDataMappingProps from '../@WUM/core/component/core/interface/updateDataMappingProps.interface'
import { faqApiType } from '../@WUM/helpdesk/api/request/faqApi.interface'
import {
    configFAQ,
    outputFAQ
} from '../@WUM/helpdesk/modules/Pages/configuration/mapping'

const updateDataMapping = (props: updateDataMappingProps) => {
    switch (props.part) {
        case 'site':
            let requestDataSite: siteApiDetailType[] & config = Object.assign(
                {},
                props.currentTutorial,
                output(props.Data)
            )
            return requestDataSite
        case 'blog':
            let requestDataBlog: blogApiDetail[] & configBlog = Object.assign(
                {},
                props.currentTutorial,
                outputBlog(props.Data)
            )
            return requestDataBlog
        case 'customizer':
            let requestDataCustomizer: customizerApiDetailType[] & config =
                Object.assign({}, props.currentTutorial, output(props.Data))
            return requestDataCustomizer
        case 'faq':
            let requestFAQ: faqApiType[] & configFAQ = Object.assign(
                {},
                props.currentTutorial,
                outputFAQ(props.Data)
            )
            return requestFAQ
    }
}

export default updateDataMapping
