import React from 'react'
function divider() {
    return (
        <>
            <hr />
        </>
    )
}

export default divider
