import {
    Element,
    Translation
} from '../../API/request/blog/blogApiDetail.interface'
/**
 *
 * @memberOf blog
 * @typedef config
 * @prop {number} visible ID des Language Key
 * @prop {string} identifier Preperator Function name
 * @prop {number} discr Param for Preperator Function
 * @prop {string} layout Replace value for Preperator
 * @prop {string} position Replace value for Preperator
 * @prop {number} image_id Replace value for Preperator
 * @prop {number} author_id Param for Preperator Function
 * @prop {string} share_title Replace value for Preperator
 * @prop {string} share_description Replace value for Preperator
 * @prop {Element} elements Replace value for Preperator
 * @prop {Translations} translations Replace value for Preperator
 */
export interface configBlog {
    id: number
    header: any
    footer: any
    visible: number
    identifier: string
    type: string
    isCategory: string
    layout: string
    position: any
    parent: any
    preview_media: number
    author: any
    share_title: any
    share_description: any
    published_at: any
    elements: Element[]
    translation: Translation
}

/**
 *
 * @memberOf blog
 * @prop Data
 */
export function outputBlog(Data: any) {
    let back = {
        id: Data.id,
        header: Data.header,
        footer: Data.footer,
        visible: Data.visible,
        identifier: Data.identifier,
        type: Data.type,
        layout: Data.layout,
        isCategory: Data.isCategory,
        parent: Data.parent,
        position: Data.position,
        preview_media: Data.preview_media === '' ? null : Data.preview_media,
        author: Data.author,
        share_title: Data.share_title,
        share_description: Data.share_description,
        published_at: Data.published_at,
        elements: Data.elements,
        translation: Data.translation
    }

    return back
}
