export const TabsBlog = {
    eventkey: 'Blog',
    key: 'Blog',
    title: 'Blog',
    content: [
        {
            label: 'blog.font-size-blog-headline',
            discr: 'font-size-blog-headline',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'blog.font-weight-blog-headline',
            discr: 'font-weight-blog-headline',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'blog.font-color-blog',
            discr: 'font-color-blog',
            size: [3, 3, 3],
            type: 'color'
        },
        {
            label: 'blog.font-size-m21-posts-title',
            discr: 'font-size-m21-posts-title',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'blog.blog-action-link-color',
            discr: 'blog-action-link-color',
            size: [3, 3, 3],
            type: 'color'
        }
    ]
}
