import React from "react";
import ActivityRecordingOverview from './Pages/overview'
import Reports from "./Pages/reports";

export const ActivityRecordingConfig = (value:any) => {

    return [
        {
            path: '/activityrecording/overview/:page',
            elements: <ActivityRecordingOverview value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/report/activityrecording/:page',
            elements: <Reports value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        }
    ]
}

export const ActivityRecordingMenuConfig = (value:any) => {
    return [
        {
        header: 'Leistungserfassung',
        icon: 'schedule',
        roles: ['superadmin', 'admin'],
        menuitem: [
            {
                name: 'Erfassen',
                route: '/activityrecording/overview/1',
                icon: 'more_time',
                roles: ['superadmin', 'admin', 'merchant']
            },
            {
                name: 'Berichte',
                route: '/report/activityrecording/1',
                icon: 'query_stats',
                roles: ['superadmin', 'admin', 'merchant']
            }
            ]
        }
    ]
}

export const ActivityRecording = () => [
    {
        title: 'Leistungserfassung',
        href: '/activityrecording/overview/1',
        undertitle: 'Leistungserfassung',
        icon: 'manage_accounts',
        text: 'Zeiterfassung',
        roles: ['superadmin', 'admin', 'merchant']
    }
]
