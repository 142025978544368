import GetAvatarView from './getAvatarView'
import React from 'react'
import AllTesterProps from '../../Interface/AllTester.interface'

const AllTester = (props: AllTesterProps) => {
    let row = []
    let tester = props.tester
    if (props.tester !== undefined && props.tester !== null) {
        for (let x = 0; x < tester.length; x++) {
            row.push(
                <>
                    <div id={'Tester' + tester[x].tester.replaceAll(' ', '_')}>
                        <GetAvatarView
                            name={tester[x].tester}
                            color={'blue'}
                            class={'ticketAvatarFloatLeft'}
                        />
                        <p className={'pAvatar'}>{tester[x].tester}</p>
                    </div>
                </>
            )
        }
    }
    return <>{row}</>
}

export default AllTester
