export const NavbarFields = [
    {
        identifier: 'font-color-navbar',
        type: 'input',
        field: 'font-color-navbar'
    },
    {
        identifier: 'font-size-navbar',
        type: 'input',
        field: 'font-size-navbar'
    },
    {
        identifier: 'font-weight-navbar',
        type: 'input',
        field: 'font-weight-navbar'
    },
    {
        identifier: 'text-transform-navbar',
        type: 'input',
        field: 'text-transform-navbar'
    },
    {
        identifier: 'font-color-navbar-active',
        type: 'input',
        field: 'font-color-navbar-active'
    },
    {
        identifier: 'font-color-navbar-hover',
        type: 'input',
        field: 'font-color-navbar-hover'
    }
]
