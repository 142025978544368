import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import IntlMessage from '../../../component/const/IntlMessage'
import CopyElements from '../../../component/const/CopyElements'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import WebBuilderElementsHead from '../../../component/const/WebBuilderElementsHead'

function module_searchformWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any
) {
    const index = newElement === false ? i : newelementIndex

    let newConfiguration = base(element, i, newElement, newelementIndex)

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Row
                id={'module_searchform' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                className={'boxShadowCard card ElementBorder'}
                style={{
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    width: '100%',
                    margin: '0px 0px 10px 0px'
                }}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                <div id={'Teaser'}>module_searchform</div>
                {WebBuilderElementsHead(
                    <IntlMessage
                        Store={Store}
                        messageId="content.element.module_searchform.type"
                    />,
                    'module_searchform',
                    '',
                    Status,
                    index,
                    'elementButton',
                    <IntlMessage
                        Store={Store}
                        messageId="content.element.module_searchform.type"
                    />,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <Col className={'accordion-body element-body'}>
                    {PielersForm(newConfiguration)}
                    {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}
                </Col>
                <div
                    id={'settingstext' + index + 'module_searchform'}
                    className={'settingsWebBuilderModul '}
                    style={{ display: 'none' }}
                >
                    {PielersForm(
                        basesettings(
                            element,
                            i,
                            newElement,
                            newelementIndex,
                            Store
                        )
                    )}
                </div>
                {newElement === false ? (
                    CopyElements(
                        element.id,
                        <IntlMessage
                            messageId="content.element.module_searchform.type"
                            Store={Store}
                        />,
                        Store
                    )
                ) : (
                    <></>
                )}
            </Row>
        </>
    )
}

export default module_searchformWebBuilder
