import React, { useState } from 'react'
import { SpinnerJSX } from '../../../../../@WUM/core/component/const/Spinner'
import configuration from './configuration/Page/overview'
import { useNavigate, useParams } from 'react-router-dom'
import { removeUseLocalStorage } from '../../../../../@WUM/core/component/const/useLocalStoreage'
import { IntlMessageValue } from '../../../../../@WUM/core/component/const/IntlMessage'
import TableMaker from '../../../../../@WUM/core/tablemaker'
import {
    ResponseCustomizer,
    ResponseCustomizerDelete
} from '../../API/response/customizer/customizer'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import SearchJSX from '../../../../../@WUM/core/component/const/SearchJSX'

/**
 * @typedef CustomizerOverviewProps
 * @prop {any} value
 */

interface CustomizerOverviewProps {
    value: any
}

/**
 * Give View Overview for Content Blog
 * @memberOf blog
 * @prop page
 * @prop deleteID
 * @author Marcel Ugurcu
 * @version 0.0.1
 */

const CustomizerOverview = (props: CustomizerOverviewProps) => {
    const Store = useSelector((state: any) => state)
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    removeUseLocalStorage('seo_title')
    removeUseLocalStorage('seo_description')

    const history = useNavigate()
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths: string) => {
        let path = paths
        history(path)
    }
    if (deleteID) {
        ResponseCustomizerDelete(deleteID)
        routeChange('/customizerJS/overview/1')
    }

    let limit: any
    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    } else {
        limit = 0
    }
    const namespace_search = 'customizerJS_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const destination = 'customizerJS'
    const response = ResponseCustomizer(limit, searchValue)
    const Configuration = configuration('', response, page, Store)

    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'common.search_for_title',
                    param: 'templateName',
                    method: 'like'
                },
                {
                    id: 'search_title',
                    translation: 'common.date',
                    param: 'filter',
                    method: '=',
                    individual: (
                        <Form.Group
                            as={Col}
                            controlId={'search_title'}
                            name={'search_title'}
                        >
                            <Form.Label>Nach Titel Sortieren</Form.Label>
                            <Form.Select
                                aria-label="Filter"
                                defaultValue={
                                    localStorage.getItem(
                                        namespace_search + '_search_title'
                                    ) as string
                                }
                            >
                                <option />
                                <option value={'title'}>
                                    {IntlMessageValue(
                                        'de',
                                        'searchadded.title.up',
                                        Store
                                    )}
                                </option>
                                <option value={'-title'}>
                                    {IntlMessageValue(
                                        'de',
                                        'searchadded.title.down',
                                        Store
                                    )}
                                </option>
                            </Form.Select>{' '}
                        </Form.Group>
                    )
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'common.create_new_Customize'
            ]
        }
    ]

    if (response.length > 0) {
        return (
            <>
                <div className="white-pad-15">
                    <SearchJSX
                        noNewButton={true}
                        setSearchValue={setSearchValue}
                        Store={Store}
                        SearchConfig={SearchConfig}
                    />

                    <TableMaker Configuration={Configuration} />
                </div>
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default CustomizerOverview
