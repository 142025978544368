import React from 'react'
import './@WUM/templates/default/assets/css/App.css'
import TemplateSwitcher from './@WUM/templates'
import { useSelector } from 'react-redux'
import MediaGalerie from './@WUM/core/component/const/mediaGalerie'
import TooltipModal from './@WUM/core/component/const/Tooltip'
import { Notifications } from 'react-push-notification';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    ResponseAllNotification,
    ResponseAllNotificationUnread
} from "./@WUM/core/notification/api/response/notification";
import {NotificationService} from "./@WUM/core/notification/notification.service";


const App = () => {
    const Store = useSelector((state: any) => state)

    if (process.env.REACT_APP_WITH_NOTIFICATION !== undefined) {
        const notification = ResponseAllNotificationUnread('0');
        NotificationService(notification);
    }

    return (
        <div className="App">
                <TemplateSwitcher layout={'default'} Store={Store} />
                <MediaGalerie Store={Store} />
                <TooltipModal />
                <Notifications />
        </div>
    )
}

export default App

/* Hinzufügen einer Notification innerhalb des System. Diese ist auf Browser Message eingestellt.
    addNotification({
    title: 'Anmeldung',
    subtitle: 'Anmeldung',
    message: 'Ihre Anmeldung war erfolgreich!',
    theme: 'darkblue',
    native: true,
    silent:true
    });
*/
