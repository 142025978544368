const ControlChecked = (identifierClass: string, excluded: string) => {
    if (identifierClass !== null && excluded !== null) {
        var ele = document.getElementsByClassName(identifierClass)

        for (var i = 0; i < ele.length; i++) {
            var element = ele[i].children[0] as HTMLInputElement
            if (element.id !== excluded) {
                if (element.type === 'checkbox') element.checked = false
            }
        }
    }
}

export default ControlChecked

export const ControlCheckedVariantPic = (
    identifierClass: string,
    excluded: string
) => {
    if (identifierClass !== null && excluded !== null) {
        var ele = document.getElementsByClassName(identifierClass)

        for (var i = 0; i < ele.length; i++) {
            var element = ele[i].children[0] as HTMLInputElement
            if (element.id !== excluded) {
                if (element.type === 'checkbox') element.checked = false
            }
        }

        if (excluded !== 'checkbox0variantID') {
            let check = document.getElementById(excluded) as HTMLInputElement
            check.checked = true
        }
    }
}
