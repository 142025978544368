export const HeadlineFields = [
    {
        identifier: 'font-weight_module_content_headline',
        type: 'input',
        field: 'font-weight_module_content_headline'
    },
    {
        identifier: 'font-color_module_content_headline',
        type: 'input',
        field: 'font-color_module_content_headline'
    }
]
