import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function configuration(conf: string, response: any, page: any, Store: any) {
    return {
        tableBody: {
            data: response,
            dataID: 'id',
            visitMenu: 'translation.slug',
            underdata: true,
            keys: ['id', 'templateName', '/customizer/Detail'],
            type: ['string', 'string', 'buttonTable']
        },
        tableHeader: [
            'ID',
            'Template',
            <IntlMessage Store={Store} messageId="common.edit" />
        ],
        tableHeaderWidth: ['20%', '70%', '10%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.display" />,
                <IntlMessage Store={Store} messageId="common.edit" />,
                <IntlMessage Store={Store} messageId="common.delete" />
            ],
            menuroute: [
                'http://myhomice-f2.pielers.de/magazin/[param]',
                '/customizer/Detail',
                '/customizer/Delete/' + page + '/[ID]'
            ],
            menuParams: ['translation.slug', 'id', 'id'],
            menuIcons: ['eye', 'pencil', 'Delete'],
            menuIconsDetail: ['1', 'true'],
            deactive: true
        }
    }
}

export default configuration
