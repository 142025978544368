import React from 'react'

function buildOption(data: any, selected: any, i: number) {
    if (data.value === selected) {
        return (
            <>
                <option value={data.value} key={i + '_' + data.value}>
                    {data.label}
                </option>
            </>
        )
    } else {
        return (
            <>
                <option value={data.value} key={i + '_' + data.value}>
                    {data.label}
                </option>
            </>
        )
    }
}

export default buildOption
