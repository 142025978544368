import React from 'react'
import IntlMessage, {
    IntlMessageValue
} from '../../../../../../@WUM/core/component/const/IntlMessage'

const configurationSingle = (
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) => {
    let config = [
        {
            formType: 'input',
            label: 'Name',
            name: 'companyType[type]',
            type: 'text',
            placeholder: '',
            value: newElement !== true ? response.type : ''
        },
        {
            formType: 'fetchselect',
            local: 'blueprint',
            valueState: 'id',
            beginn: 'true',
            start: 0,
            name: 'companyType[blueprint]',
            labelState: 'name',
            selected: newElement === false ? response.blueprint : 0,
            label: 'Blueprint'
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.visible" />,
            name: 'companyType[status]',
            selected: newElement === false ? response.status : 0,
            options: [
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: '1'
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: '0'
                }
            ]
        },
        {
            formType: 'input',
            label: 'template',
            name: 'companyType[template]',
            type: 'hidden',
            value: 'Contents/Types/page.html'
        },
        {
            formType: 'input',
            label: 'locale',
            name: 'companyType[locale]',
            type: 'hidden',
            value: 'de'
        }
    ]

    //console.log(config)
    return config
}

export default configurationSingle
