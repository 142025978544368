import PielersserializeElementsTranslations from '../helper/translations'
import excape from '../helper/excape'

const serializeAddress = (
    elementsdataTranslation: any,
    Data: any,
    form: any
) => {
    if (elementsdataTranslation.length > 0) {
        let elementstmps: string = '{'
        elementstmps += ''
        for (let i = 0; i < elementsdataTranslation.length; i++) {
            let end = elementsdataTranslation.length - 1

            for (let z = 0; z < elementsdataTranslation[i].length; z++) {
                if (i === end) {
                    elementstmps += excape(
                        PielersserializeElementsTranslations(
                            elementsdataTranslation[i][z],
                            form,
                            z
                        )
                    )
                } else {
                    elementstmps += excape(
                        PielersserializeElementsTranslations(
                            elementsdataTranslation[i][z],
                            form,
                            z
                        )
                    )
                }
            }
            if (elementsdataTranslation.length - 1 === i) {
            } else {
                elementstmps += ','
            }
        }
        elementstmps += '}'
        Data.address = JSON.parse(JSON.stringify(JSON.parse(elementstmps)))
    }
    return Data
}

export default serializeAddress
