

const index = (e:any,matches:any,msg:any) => {
    var txt:string = String.fromCharCode(e.which);
    if (!txt.match(matches)) {
        alert(txt +' '+msg)
        e.preventDefault();
        return false;
    }

}

export default index;
