import React from 'react'
import IssueBadge from '../components/badge'
import Badge from 'react-bootstrap/Badge'
import GetAvatarView from './getAvatarView'
import Button from 'react-bootstrap/Button'
import Topic from './topic'
import { isEmptyArray } from 'formik'
import { HelperLabelonIssueOption } from '../helper/HelperLabelonIssueOption'
import { HelperTechTech } from '../helper/HelperTechTech'
import { HelperTechTester } from '../helper/HelperTechTester'
import { Form } from 'react-bootstrap'
import saveLabels from '../../API/saveLabel'
import AllDeveloper from './AllDeveloper'
import AllTester from './AllTester'
import { saveTech } from '../../API/saveTech'
import { saveTester } from '../../API/saveTester'
import { dateFormat } from '../components/dateFormat'
import IssueMenuViewProps from '../../Interface/IssueMenuView.interface'
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import { saveClose } from '../../API/saveclose'

const IssueMenuView = (props: IssueMenuViewProps) => {
    const user = {
        displayName: props.Store.user.username,
        id: props.Store.user.id,
        is_superuser: props.Store.user.is_superuser
    }

    let issue: any[] = []
    let dummy = props.dummy
    for (let x = 0; x < dummy.length; x++) {
        if (dummy[x].id === parseInt(props.id)) {
            issue.push(dummy[x])
        }
    }

    let labelHolder = []

    if (
        issue !== undefined &&
        !isEmptyArray(issue) &&
        issue[0].label !== undefined &&
        issue[0].label !== null
    ) {
        for (let x = 0; x < issue[0].label.length; x++) {
            labelHolder.push(
                <IssueBadge
                    bg={issue[0].label[x].bg}
                    tg={issue[0].label[x].tg}
                    text={issue[0].label[x].text}
                    id={issue[0].label[x].id}
                />
            )
        }
    } else {
        labelHolder.push(<></>)
    }
    let it_ma
    let tester
    let closeandstate
    if (user.is_superuser === 1 || user.is_superuser === '1') {
        it_ma = (
            <>
                <Form.Group
                    controlId={'selectLabel'}
                    key={'select'}
                    className="mb-3"
                >
                    <Form.Select
                        name={'tech'}
                        aria-label="Floating label select example"
                        onChange={(e) => saveTech(e, issue[0].id, props.Store)}
                    >
                        <option></option>
                        <HelperTechTech tech={props.tech} issue={issue[0]} />
                    </Form.Select>
                </Form.Group>
            </>
        )
        tester = (
            <>
                <Form.Group
                    controlId={'selectLabel'}
                    key={'select'}
                    className="mb-3"
                >
                    <Form.Select
                        name={'tester'}
                        aria-label="Floating label select example"
                        onChange={(e) =>
                            saveTester(e, issue[0].id, props.Store)
                        }
                    >
                        <option></option>
                        <HelperTechTester tech={props.tech} issue={issue[0]} />
                    </Form.Select>
                </Form.Group>
            </>
        )

        closeandstate = (
            <>
                {' '}
                <br />
                <p className={'text-center'}>
                    {issue[0].close === '0' ? (
                        <>
                            <Button
                                variant={'success'}
                                onClick={(e) =>
                                    saveClose(e, 1, issue[0].id, props.Store, 2)
                                }
                            >
                                Ticket Schließen
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant={'danger'}
                            onClick={(e) =>
                                saveClose(e, 0, issue[0].id, props.Store, 1)
                            }
                        >
                            Ticket wieder Öffnen
                        </Button>
                    )}
                </p>
            </>
        )
    } else {
        it_ma = <></>
        tester = <></>
        closeandstate = <></>
    }

    let IssueEdit = <></>
    let Labelmake = <></>
    if (issue[0].close !== 1) {
        IssueEdit = (
            <>
                <Button
                    variant={'success'}
                    type={'button'}
                    href={'/ticket/edit/Issue/' + issue[0].id}
                >
                    <IntlMessage
                        Store={props.Store}
                        messageId={'common.issue.edit'}
                    />
                </Button>
            </>
        )
        Labelmake = (
            <>
                <div id={'addLabel'}>
                    <Form.Group
                        controlId={'selectLabel'}
                        key={'select'}
                        className="mb-3"
                    >
                        <Form.Select
                            name={'label'}
                            aria-label="Floating label select example"
                            onChange={(e) =>
                                saveLabels(
                                    e,
                                    issue[0],
                                    props.label,
                                    props.Store
                                )
                            }
                        >
                            <option></option>
                            <HelperLabelonIssueOption
                                label={props.label}
                                issue={issue[0]}
                            />
                        </Form.Select>
                    </Form.Group>
                </div>
            </>
        )
    }

    return (
        <>
            <aside style={{ padding: '10px' }}>
                <br />
                <div>
                    <p>
                        <p>
                            <strong>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId={'common.state'}
                                />{' '}
                            </strong>
                        </p>
                        <Badge
                            pill={true}
                            className={'m-1'}
                            bg={issue[0].state.bg}
                        >
                            {issue[0].state.title}
                        </Badge>
                        {closeandstate}
                    </p>
                </div>
                <hr />
                <div>
                    <p>
                        <p>
                            <strong>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId={'section'}
                                />
                                :{' '}
                            </strong>
                        </p>
                        <Topic
                            text={issue[0].topic}
                            bg={'dark'}
                            textColor={false}
                        />
                    </p>
                </div>
                <hr />
                <div>
                    <p>
                        <p>
                            <strong>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId={'common.issue.creator'}
                                />{' '}
                            </strong>
                        </p>
                        <GetAvatarView
                            name={issue[0].user}
                            color={'orange'}
                            class={'ticketAvatarFloatLeft'}
                        />
                        <p className={'pAvatar'}>{issue[0].user}</p>
                    </p>
                </div>
                <hr />
                <div>
                    <p>
                        <p>
                            <strong>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId={'common.issue.created.at'}
                                />{' '}
                            </strong>
                        </p>
                        {dateFormat(
                            issue[0].created_at,
                            'de',
                            'us',
                            true,
                            true
                        )}{' '}
                    </p>
                </div>
                <hr />
                <div>
                    <p>
                        <p>
                            <strong>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId={'common.label'}
                                />
                            </strong>
                        </p>
                        {Labelmake}
                        <div id={'LabelHolder'}>{labelHolder}</div>
                    </p>
                </div>
                <hr />
                <div>
                    <p>
                        <p>
                            <strong>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId={'common.it.ma'}
                                />{' '}
                            </strong>
                        </p>
                        {it_ma}
                        <div id={'TechHolder'}>
                            <AllDeveloper developer={issue[0].itMA} />
                        </div>
                    </p>
                </div>
                <hr />
                <div>
                    <p>
                        <p>
                            <strong>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId={'common.tester.ma'}
                                />{' '}
                            </strong>
                        </p>
                        {tester}
                        <div id={'TesterHolder'}>
                            <AllTester tester={issue[0].tester} />
                        </div>
                    </p>
                </div>
                <hr />
                <div>
                    <Button
                        variant={'primary'}
                        href={'/ticket/overview/1'}
                        className={'mr-10'}
                    >
                        <IntlMessage
                            Store={props.Store}
                            messageId={'common.back'}
                        />
                    </Button>
                    {IssueEdit}
                </div>
            </aside>
        </>
    )
}

export default IssueMenuView
