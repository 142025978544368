import {ApiURL} from "../../../../core/component/const/ApiURL";
// @ts-ignore
import { Notification } from 'create-notifications/notfication'
import Button from "react-bootstrap/Button";
import React from "react";
import Form from 'react-bootstrap/Form'
const ForgottPassword = (props: { value: any }) => {
    const send = (e: any) => {
        e.preventDefault()
        let data = new FormData(e.target)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let value = Object.fromEntries(data.entries())
        let raw = JSON.stringify(value)
        let root = ApiURL()
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        fetch(root + 'user/passwort-reset/', requestOptions)
            .then((response) => response.text())
            .then((result) => afterSend('success'))
            .catch((error) => afterSend('error'))
    }
    const afterSend = (state: any) => {
        if (state === 'success') {
            Notification({
                reload: true,
                type: 'success',
                mode: 'classic',
                title: { text: 'Success' },
                text: { text: 'Saved successfully' },
                insert: 'center right',
                duration: 4
            })
        } else if (state === 'error') {
            Notification({
                reload: true,
                type: 'danger',
                mode: 'classic',
                title: { text: 'Error' },
                text: { text: 'Sorry, an error has occurred' },
                insert: 'center right',
                duration: 4
            })
        }
    }

    return(

        <div className="card text-center " style={{width:'300px',paddingBottom: '2em',margin: '0 auto',marginTop: '10%'}} >
            <Form onSubmit={(e) => send(e)}>
            <div className="card-header h5 text-white " style={{backgroundColor: 'rgb(0, 98, 204)', borderColor: '#005cbf '}}>Password Reset</div>
            <div className="card-body px-5">
                <p className="card-text py-2">
                    Enter your email address and we'll send you an email with instructions to reset your password.
                </p>
                <div className="form-outline">
                    <input type="email" id="typeEmail" name={'email'} className="form-control my-3" placeholder={'Email input'}/>
                </div>
                <Button variant="success" type="submit">
                    Reset password
                </Button>
                <div className="d-flex justify-content-between mt-4">
                    <a className="btn btn-secondary" href="/">Login</a>
                    <a className="btn btn-secondary" href="/Signin">Register</a>
                </div>
            </div>
        </Form>
        </div>

    )
}

export default ForgottPassword;
