import {JSONServerUrl} from "./enviroment";
import React, { useState } from 'react'
import Setter from "./Setter";

const GetRoute = (point:any,id?:any,part?:any,SetLocal?:any) => {

    const [get, setget] = useState([]) // Array instead of object

    React.useEffect(() => {
        let root = JSONServerUrl
        const fetchData = async () => {
            const result = await fetch(root +"/"+ point , {
                method: 'GET',
            })
                .then((response) => response.json())
                .then((response) => {
                    return response
                })
                .catch((err) => console.error(err))

            setget(result)
        }

        fetchData().then(r =>{} )
    }, [])
    if(SetLocal !== undefined && SetLocal !== null) {
        localStorage.setItem('Favo',JSON.stringify(Setter(part,get)))
    }

    return Setter(part,get)

}

export default GetRoute;
