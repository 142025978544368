import {useEffect, useState} from "react";
import {error} from "../../../../@WUM/core/component/const/error.services";
import {worktimetrackingApiType} from "../request/worktimetrackingApi.interface";
import {worktimetracking} from "../request/worktimetracking";
import {checkLogin} from "../../../../@WUM/templates/default/Auth/service/AuthService";

export const ResponseAllTimesbyUser = (id:any,fetchData:any) => {
    const [posts, setPosts] = useState<worktimetrackingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
            ;(async () => {
                    await worktimetracking
                        .getPosts(id)
                        .then((data) => {
                            checkLogin(data)
                            setPosts(data)
                            //console.log(data);
                        })
                        .catch((err) => {
                            error(err)
                            setIsError(true)
                        })
                })()

    }, [id,fetchData])

    return isError ? isError : posts
}

export const ResponseAllTimesbyUserSheets = (id:any,date:any,fetchData:any) => {
    const [posts, setPosts] = useState<worktimetrackingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await worktimetracking
                .getPostsTimeSheet(id,date)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()

    }, [id,fetchData])

    return isError ? isError : posts
}

export const ResponseAllPlaner = (fetchData:any) => {
    const [posts, setPosts] = useState<worktimetrackingApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await worktimetracking
                .getPostsPlaner()
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                    //console.log(data);
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()

    }, [fetchData])

    return isError ? isError : posts
}
