import { isEmptyArray } from 'formik'
import HelperLabelonIssueOptionProps from '../../Interface/HelperLabelonIssueOption.interface'

export const HelperLabelonIssueOption = (
    props: HelperLabelonIssueOptionProps
) => {
    let LabelHolder = []
    if (props.issue.label !== null && props.issue.label !== undefined) {
        for (let x = 0; x < props.issue.label.length; x++) {
            LabelHolder.push(props.issue.label[x].text)
        }
    }

    if (props.label !== undefined && !isEmptyArray(props.label)) {
        let OptionSwimlane = []

        for (let x = 0; x < props.label.length; x++) {
            let dataAttribute
            let type = ''
            if (LabelHolder.includes(props.label[x].text)) {
                type = '&#9745;'
                dataAttribute = 'Delete'
            } else {
                dataAttribute = 'Post'
            }

            let options = type + ' ' + props.label[x].text

            OptionSwimlane.push(
                <>
                    <option
                        id={'option' + props.label[x].id}
                        value={props.label[x].id}
                        data-param={dataAttribute}
                        dangerouslySetInnerHTML={{ __html: options }}
                    />
                </>
            )
        }

        return <>{OptionSwimlane}</>
    } else {
        return <></>
    }
}

export default HelperLabelonIssueOption
