import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { init2 } from '../../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { config, output } from '../configuration/mapping'
import FormMaker from '../../../../../@WUM/core/form-serilizer'
import configuration from '../configuration/Page/configuration'
import Spinner from 'react-bootstrap/Spinner'
import { notify_save } from '../../../../../@WUM/core/component/const/notification.service'
import { error } from '../../../../../@WUM/core/component/const/error.services'
import { handleSubmitForm } from '../../../../../@WUM/core/component/const/handleSubmitForm'
import { Form } from 'react-bootstrap'
import IntlMessage from '../../../../../@WUM/core/component/const/IntlMessage'
import { ResponsetextblocksDetail } from '../../API/response/textblocks/textBlocks'
import { textBlocksApiType } from '../../API/request/textBlocks/textBlocksAPI.interface'
import { textBlocks } from '../../API/request/textBlocks/textBlocks'
import { isEmptyArray } from 'formik'
import { useSelector } from 'react-redux'

const TextBlocksDetail = (props: { value: any }) => {
    const { id } = useParams<{ id: any }>()
    const response2 = ResponsetextblocksDetail(id)
    const history = useNavigate()
    const Store = useSelector((state: any) => state)

    /**
     * Neuladen der Seite
     */
    const routeChange = (path: string) => {
        let paths = path
        history(paths)
    }

    const [currentTutorial, setCurrentTutorial] = useState<textBlocksApiType[]>(
        response2 !== true ? response2 : []
    )
    const [validated, setValidated] = useState(false)
    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     * @param forms
     */
    const updateData = (status: boolean, id: any, forms: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        //let Data = init();
        let Data = init2(forms)

        let reauestData: textBlocksApiType[] & config = Object.assign(
            {},
            currentTutorial,
            output(Data)
        )

        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        textBlocks
            .updatePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save(Store)
                routeChange(`/TextBlocksdynamik/Detail/` + id)
            })
            .catch((e) => {
                error(e)
            })
    }

    if (!isEmptyArray(response2)) {
        return (
            <div>
                <Form
                    id={'form001'}
                    noValidate
                    validated={validated}
                    onSubmit={(e) =>
                        handleSubmitForm(e, updateData, setValidated, id)
                    }
                >
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        key={'tabindex'}
                    >
                        <Tab
                            eventKey="home"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.general"
                                />
                            }
                            key={'Allgemein'}
                            id={'Allgemein'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {FormMaker(
                                configuration('dataColOne', response2, false)
                            )}
                        </Tab>
                    </Tabs>
                    <div style={{ backgroundColor: 'white', padding: '15px' }}>
                        <Button
                            variant="light"
                            style={{ float: 'left', marginLeft: '15px' }}
                            onClick={() => history(-1)}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.back"
                            />
                        </Button>
                        <Button
                            variant="success"
                            style={{ float: 'right', marginRight: '15px' }}
                            type={'submit'}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.save"
                            />
                        </Button>
                        <br style={{ clear: 'both' }} />
                    </div>
                </Form>
            </div>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                        <IntlMessage Store={Store} messageId="common.loading" />
                    </span>
                </Spinner>
            </>
        )
    }
}

export default TextBlocksDetail
