import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'
import IntlMessage from '../../../../../@WUM/core/component/const/IntlMessage'

const CustomerId = (props: { Store: any }) => {
    return (
        <>
            <CreateMatomoIframe
                headline={
                    <IntlMessage
                        Store={props.Store}
                        messageId="Visitor.user.id"
                    />
                }
                width={'100%'}
                height={'350'}
                url={WidgetURLMapping({ name: 'user IDs' })}
                scrolling={'yes'}
                id={'cId1'}
                Store={props.Store}
            />
        </>
    )
}

export default CustomerId
