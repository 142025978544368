import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { allShippingApiType } from './allShippingApi.interface'

export const shipping = {
    getallShippingLimit: (
        page: any,
        search: any
    ): Promise<allShippingApiType[]> =>
        requests.get(`/AllShipingTypes/${page}/${search}`),
    deletePostShipping: (id: string): Promise<void> =>
        requests.delete(`/AllShipingTypes/Single/${id}/`),
    getAPost: (id: string): Promise<allShippingApiType[]> =>
        requests.get(`/AllShipingTypes/Single/${id}/`),
    updatePost: (post: any, id: number): Promise<allShippingApiType> =>
        requests.put(`/AllShipingTypes/Single/update/${id}/`, post),
    updatePostNew: (post: any, id: number): Promise<allShippingApiType> =>
        requests.put(`/AllShipingTypes/Signle/${id}/new`, post)
}
