import React from 'react'
import { LanguageSwitcherValue } from '../../shop/core/components/LanguageSwitcher'
import MemberOverview from './Pages/overview'
import MemberDetail from './Pages/Detail'
import MemberNew from './Pages/new'

export const MemberPagesConfig = (value: any) => {
    return [
        {
            path: '/Member/overview/:page',
            elements: <MemberOverview value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/Member/Detail/:id',
            elements: <MemberDetail value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/Member/Delete/:page/:deleteID',
            elements: <MemberOverview value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/Member/new/new',
            elements: <MemberNew value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        }
    ]
}

export const MemberMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('settings.Member.header'),
            route: '/Member/overview/1',
            icon: 'manage_accounts',
            roles: ['superadmin', 'admin', 'merchant']
        }
    ]
}
