import React from 'react'
import PageOverview from './Pages/Pageoverview'
import PageDetail from './Pages/PageDetail'
import PageNew from './Pages/new'
import CalenderDeleted from './Pages/deleted'

export const DynamikMenberDecissionPagesConfig = (value: any) => {
    return [
        {
            path: '/menberDecission/overview/:page',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            elements: <PageOverview value={value} />
        },
        {
            path: '/menberDecission/Detail/:id',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            elements: <PageDetail value={value} />
        },
        {
            roles: ['superadmin', 'admin', 'ContentCreator'],
            path: '/menberDecission/Delete/:page/:deleteID',
            elements: <CalenderDeleted value={value} />
        },
        {
            path: '/menberDecission/new/:page',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            elements: <PageNew value={value} />
        }
    ]
}
export const DynamikMenberDecissionMenuConfig = (value: any) => {
    return [
        {
            header: 'Mitglieder',
            icon: 'event',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            menuitem: [
                {
                    name: 'Beschlüsse',
                    route: '/menberDecission/overview/1',
                    icon: 'newspaper',
                    roles: ['superadmin', 'admin', 'ContentCreator']
                }
            ]
        }
    ]
}

export const DynamikMenberDecissionMenuSingleConfig = (value: any) => {
    return [
        {
            name: 'Mitgliederbeschlüsse',
            route: '/menberDecission/overview/1',
            icon: 'switch_account',
            roles: ['superadmin', 'admin']
        }
    ]
}

export const DynamikMenberDecissionDasboard = () => [
    {
        title: 'Mitgliederbeschlüsse',
        href: '/menberDecission/overview/1',
        undertitle: 'Interne News',
        icon: 'switch_account',
        text: 'Interne News',
        roles: ['superadmin', 'admin']
    }
]
