import Form from 'react-bootstrap/Form';
import {FormCheckType} from "react-bootstrap/FormCheck";

interface WK24ChecksProps {
    /** Valide Value = checkbox | radio | switch */
    type:FormCheckType | undefined,
    /** Set Label text on Element */
    label:string,
    /** set id on Element in DOM */
    id?:string,
    /** Set custom Classes on Element */
    class?:string,
    /** Set Element Disabled by true and remove Disabled by false */
    disabled?:boolean,
    /** Set DefaultValue on Element */
    defaultValue?:string,
}

/**
 * Build Checkbox or Radio Button or Switcher with <strong><a href="https://react-bootstrap.netlify.app/" target="_blank">React Bootstrap</a></strong> Module <a href="https://react-bootstrap.netlify.app/docs/forms/checks-radios" target="_blank">Form.Check</a><br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 * <Wk24Form validated={validated} Submit={handleSubmit}>
 *  <Wk24Form.Checks type={'checkbox'} label={'checkbox'} />
 * </Wk24Form>
 * ```
 * @param props
 * @version 0.0.1
 * @author [Marcel Ugurcu](https://wum-solution.de)
 * @constructor
 */
const WK24Checks = (props:WK24ChecksProps) => {

    let Class:string = props.class === undefined ? 'mb-3' : props.class

    let ID:string = props.id === undefined ? '' : props.id

    return(
        <>
            <Form.Check
                disabled={props.disabled}
                type={props.type}
                label={props.label}
                id={ID}
                className={Class}
                defaultValue={props.defaultValue}
            />
        </>
    )
}

export default WK24Checks;
