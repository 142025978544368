import Card from 'react-bootstrap/Card';
import {CSSProperties, ElementType, JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import {Wk24CardsBody} from "./Elements/Wk24CardsBody";
import {Wk24CardsFooter} from "./Elements/Wk24CardsFooter";
import {Wk24CardsHeader} from "./Elements/Wk24CardsHeader";
import {Wk24CardsImg} from "./Elements/Wk24CardsImg";
import {Wk24CardsImgOverlay} from "./Elements/Wk24CardsImgOverlay";
import {Wk24CardsLink} from "./Elements/Wk24CardsLink";
import {Wk24CardsSubtitle} from "./Elements/Wk24CardsSubtitle";
import {Wk24CardsText} from "./Elements/Wk24CardsText";
import {Wk24CardsTitle} from "./Elements/Wk24CardsTitle";
import {Wk24CardsGroup} from "./Elements/Wk24CardsGroup";
import {UniForm} from "../index";

export interface UniCardsProps {
    /** Allows it to have children and have them reflected. */
    children: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined,
    bg?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
    text?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'white' | 'muted'
    border?:'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light'
    body?:boolean
    /** You can use a custom element type for this component. */
    as?:ElementType<any> | undefined
    /** Set custom Classes on Element */
    class?:string
    /** Set id on Element in DOM */
    id?: string,
    /** Set inline style for Element */
    style?:CSSProperties | undefined
}

const UniCards = (props:UniCardsProps) => {

    return(
        <>
            <Card id={props.id}  className={props.class} body={props.body} border={props.border} as={props.as} text={props.text} bg={props.bg} style={props.style}>
                {props.children}
            </Card>
        </>
    )
}

UniCards.Body = Wk24CardsBody
UniCards.Footer = Wk24CardsFooter
UniCards.Header = Wk24CardsHeader
UniCards.Img = Wk24CardsImg
UniCards.ImgOverlay = Wk24CardsImgOverlay
UniCards.Link = Wk24CardsLink
UniCards.Subtitle = Wk24CardsSubtitle
UniCards.Text = Wk24CardsText
UniCards.Title = Wk24CardsTitle
UniCards.Group = Wk24CardsGroup

export default UniCards;
