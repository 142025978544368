import setElements from '../setter/setElements'

/**
 *
 * @param map
 * @param form
 * @constructor
 */
export const PielersserializeTaxon = (map: any, form: any) => {
    let FormDatas = []
    let stringJSON: string = ''
    var inputs = form.elements
    // Iterate over the form controlsc

    for (let i = 0; i < inputs.length; i++) {
        // Disable all form controls

        if (inputs[i].hasAttribute('name')) {
            for (let z = 0; z < map.length; z++) {
                if (map[z] !== undefined) {
                    if (inputs[i].getAttribute('name') === map[z]) {
                        stringJSON += setElements(map, z, inputs, i)
                    }
                }
            }
        }
    }

    FormDatas.push(stringJSON)

    return FormDatas[0]
}

export default PielersserializeTaxon
