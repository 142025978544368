import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'

const Home = () => {
    return (
        <>
            <CreateMatomoIframe
                headline={'Entwicklung über den Zeitraum'}
                width={'100%'}
                height={'300'}
                url={WidgetURLMapping({
                    name: 'Graph of recent visits engagements'
                })}
                scrolling={'yes'}
                id={'h1'}
            />
            <CreateMatomoIframe
                headline={'Kanaltyp'}
                width={'100%'}
                height={'400'}
                url={WidgetURLMapping({ name: 'channel type' })}
                scrolling={'yes'}
                id={'h2'}
            />
        </>
    )
}

export default Home
