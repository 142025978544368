import Col from 'react-bootstrap/Col'
import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import { Row } from 'react-bootstrap'
import React from 'react'

const Software = (props: { Store: any }) => {
    return (
        <>
            <Row>
                <Col>
                    <CreateMatomoIframe
                        headline={'Betriebssystem-Versionen'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({
                            name: 'Operating System Versions'
                        })}
                        scrolling={'yes'}
                        id={'software1'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Konfigurationen'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({ name: 'configurations' })}
                        scrolling={'yes'}
                        id={'software2'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Browser-Plugins'}
                        width={'100%'}
                        height={'550'}
                        url={WidgetURLMapping({ name: 'browser plugins' })}
                        scrolling={'yes'}
                        id={'software3'}
                        Store={props.Store}
                    />
                </Col>
                <Col>
                    <CreateMatomoIframe
                        headline={'Browser'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({ name: 'browsers' })}
                        scrolling={'yes'}
                        id={'software4'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Browser-Engines'}
                        width={'100%'}
                        height={'350'}
                        url={WidgetURLMapping({ name: 'browser engines' })}
                        scrolling={'yes'}
                        id={'software5'}
                        Store={props.Store}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Software
