import React from 'react'
import Anayltics from './Pages/anayltics'
import Visitor from './Pages/visitor'
import Behave from './Pages/behave'
import Acquisition from './Pages/acquisition'
import Targets from './Pages/targets'
import { LanguageSwitcherValue } from './components/LanguageSwitcher'

export const MatomoPagesConfig = (value: any) => {
    return [
        {
            path: 'analytics',
            elements: <Anayltics value={value} />,
            roles: ['superadmin', 'admin']
        },
        {
            path: '/visitor/analytics',
            elements: <Visitor value={value} />,
            roles: ['superadmin', 'admin']
        },
        {
            path: '/behave/analytics',
            elements: <Behave value={value} />,
            roles: ['superadmin', 'admin']
        },
        {
            path: '/acquisition/analytics',
            elements: <Acquisition value={value} />,
            roles: ['superadmin', 'admin']
        },
        {
            path: '/targets/analytics',
            elements: <Targets value={value} />,
            roles: ['superadmin', 'admin']
        }
    ]
}

export const MatomoMenuConfig = (value: any) => {
    return [
        {
            header: LanguageSwitcherValue('matomo.menu.header'),
            icon: 'stacked_line_chart',
            roles: ['superadmin', 'admin'],
            menuitem: [
                {
                    name: LanguageSwitcherValue('matomo.menu.item.dashboard'),
                    route: '/analytics',
                    icon: 'equalizer',
                    roles: ['superadmin', 'admin']
                },
                {
                    name: LanguageSwitcherValue('matomo.menu.item.visitor'),
                    route: '/visitor/analytics',
                    icon: 'visibility',
                    roles: ['superadmin', 'admin']
                },
                {
                    name: LanguageSwitcherValue('matomo.menu.item.behave'),
                    route: '/behave/analytics',
                    icon: 'psychology',
                    roles: ['superadmin', 'admin']
                },
                {
                    name: LanguageSwitcherValue('matomo.menu.item.acquisition'),
                    route: '/acquisition/analytics',
                    icon: 'report',
                    roles: ['superadmin', 'admin']
                },
                {
                    name: LanguageSwitcherValue('matomo.menu.item.targets'),
                    route: '/targets/analytics',
                    icon: 'track_changes',
                    roles: ['superadmin', 'admin']
                }
            ]
        }
    ]
}

export const matomo = () => [
    {
        title: 'matomo.menu.item.dashboard',
        href: '/analytics',
        undertitle: 'Analytics',
        icon: 'equalizer',
        text: 'matomo.menu.item.dashboard.description',
        roles: ['superadmin', 'admin']
    },
    {
        title: 'matomo.menu.item.visitor',
        href: '/visitor/analytics',
        undertitle: 'Analytics',
        icon: 'visibility',
        text: 'matomo.menu.item.visitor.description',
        roles: ['superadmin', 'admin']
    },
    {
        title: 'matomo.menu.item.behave',
        href: '/behave/analytics',
        undertitle: 'Analytics',
        icon: 'psychology',
        text: 'matomo.menu.item.behave.description',
        roles: ['superadmin', 'admin']
    },
    {
        title: 'matomo.menu.item.acquisition',
        href: '/acquisition/analytics',
        undertitle: 'Analytics',
        icon: 'report',
        text: 'matomo.menu.item.acquisition.description',
        roles: ['superadmin', 'admin']
    }
]
export const matomoSidebar: string =
    '/analytics,/visitor/analytics,/behave/analytics,/acquisition/analytics,/targets/analytics'
