import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Card from 'react-bootstrap/Card';
const Forms = (response: any = [], mode: any = 'new') => {
    return (
        <>
            <h3>Empfehlung</h3>
            <h4>Partner</h4>
            <hr />
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Partnername:</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            defaultValue={
                                mode === 'new' ? '' : response[0].user.username
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="street">
                        <Form.Label>Straße:</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].referer.address[0].street
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="postcode">
                        <Form.Label>Postleitzahl:</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].referer.address[0].postcode
                            }
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="operationDate">
                        <Form.Label>Stadt:</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].referer.address[0].city
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
            <h4>Mandant</h4>
            <hr />
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Mandantenname:</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            defaultValue={
                                mode === 'new' ? '' : response[0].user.username
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="street">
                        <Form.Label>Straße:</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].user.address[0].street
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="postcode">
                        <Form.Label>Postleitzahl:</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].user.address[0].postcode
                            }
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="operationDate">
                        <Form.Label>Stadt:</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={true}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].user.address[0].city
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
            <h4>Provision</h4>
            <hr />
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="provision">
                        <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">
                            Bitte Art der Provision wählen.
                        </InputGroup.Text>
                        <Form.Select name={'method'} aria-label="Default select example" defaultValue={mode === 'new' ? '' : response[0].method}
                        onChange={() => (document.getElementById('provisionField') as HTMLInputElement).value = ''}
                        >
                            <option></option>
                            <option value="1">% Prozent</option>
                            <option value="2">€ Euro </option>
                        </Form.Select>
                            <InputGroup.Text id="basic-addon1">
                                Tragen Sie die Provision ein.
                            </InputGroup.Text>
                            <Form.Control
                                type="number"
                                name={'provision'}
                                step="any"
                                id={'provisionField'}
                                required={true}
                                defaultValue={
                                    mode === 'new' ? '' : response[0].provision
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                </Col>

            </Row>
            <Row>
                <Col>
                    <Card style={{ width: 'max-content',boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)' }}>
                        <Card.Body>
                            <Card.Title>Werber</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Provision für Werber</Card.Subtitle>
                            <Card.Text>
                                {CreateBubble()}
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </>
    )
}

export default Forms




const CreateBubble = () => {



    const BubbleDummy = [
        {name:'Hans Dieter',change:'40'},
        {name:'Manny Dieter',change:'30'},
        {name:'Merle Dieter',change:'20'},
        {name:'Martina Dieter',change:'10'}
    ]

    let Bubble = [];

    for(let x=0;x<BubbleDummy.length;x++) {

        let last = BubbleDummy.length - 1

        if(BubbleDummy[x] === undefined) {

        } else {
            Bubble.push(
                <>
                    <div className={'float-start border rounded-circle ml-1'} style={{width:'110px',height:'110px',boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'}}>
                        <p style={{padding:'12px',paddingTop:'25px',textAlign:'center'}}>{BubbleDummy[x].name.split(' ')[0]}<br/>{BubbleDummy[x].name.split(' ')[1]}<br/><strong>{BubbleDummy[x].change}%</strong></p>
                    </div>
                    {last !== x ? <><span className="float-start material-icons" style={{fontSize: '50px',marginTop: '30px'}}>
                        arrow_right_alt
                        </span></> :<></>}

                </>
            )
        }

    }

    return(
        <>
            {Bubble}
        </>
    )

}
