import item from './map/item'
import elements from './map/elements'
import elementsTranslation from './map/elementsTranslation'
import serializeItem from './serialize/serializeItems'
import serializeElement from './serialize/serializeElements'
import serializeElementTranslations from './serialize/serializElementTranslations'
import Translations from './map/Translation'
import serializeTranslations from './serialize/serializTranslations'
import TranslationsSeo from './map/TranslationSeo'
import serializeTranslationsSeo from './serialize/serializTranslationsSeo'
import Merchant from './map/Merchant'
import serializeMerchant from './serialize/serializMerchant'
import Address from './map/Address'
import serializeAddress from './serialize/serializAddress'
import users from './map/user'
import serializUsers from './serialize/serializUsers'
import MerchantShippingType from './map/MerchantShippingType'
import serializMerchantShippingType from './serialize/serializMerchantShippingType'
import sortElement from './helper/sortElement'
import Rate from './map/rate'
import serializRate from './serialize/serializRate'
import Taxons from './map/taxons'
import serializTaxons from './serialize/serializTaxon'
import Products from './map/Products'
import serializProducts from './serialize/serializeProducts'
import TranslationNormal from './map/TranslationNormal'
import serializeTranslation from './serialize/serializTranslation'
import ItemElementType from './map/ItemElementType'
import serializItemElementType from './serialize/serializItemElementType'
import { isObject } from 'formik'

const init = () => {
    // Aktuelles Formular
    let form = document.querySelector('form')

    // Mapping der Formularfelder
    let map = item(form)

    let elementsdata = elements(form)

    let elementsdataTranslation = elementsTranslation(form)

    let dataTranslation = Translations(form)

    let dataTranslationNormal = TranslationNormal(form)

    let dataTranslationSeo = TranslationsSeo(form)

    let elementItemsTax = ItemElementType(form)

    let dataUsers = users(form)

    let dataAddress = Address(form)

    let dataMerchant = Merchant(form)

    let dataMerchantShippingType = MerchantShippingType(form)

    let dataRate = Rate(form)

    let dataTaxons = Taxons(form)

    let dataProducts = Products(form)

    // Item aus den Formular serialize
    let Data = serializeItem(map, form)

    // Elemente aus den Formular serialize
    let DataWithElement = serializeElement(elementsdata, form, Data)

    // ElementTranslation aus den Formular serialize
    let DataWithElementANDTranslation = serializeElementTranslations(
        elementsdataTranslation,
        DataWithElement,
        form
    )

    // ElementTranslation aus den Formular serialize
    let DataWithElementANDTranslationANDITEMS = serializItemElementType(
        elementItemsTax,
        DataWithElementANDTranslation,
        form
    )

    // ElementTranslation aus den Formular serialize
    let DataMerchant = serializeMerchant(
        dataMerchant,
        DataWithElementANDTranslationANDITEMS,
        form
    )

    let DataTranslationNormal = serializeTranslation(
        dataTranslationNormal,
        DataMerchant,
        form
    )

    // ElementTranslation aus den Formular serialize
    let DataTranslation = serializeTranslations(
        dataTranslation,
        DataTranslationNormal,
        form
    )

    // ElementTranslation aus den Formular serialize
    let DataTranslationSeo = serializeTranslationsSeo(
        dataTranslationSeo,
        DataTranslation,
        form
    )

    // ElementTranslation aus den Formular serialize
    //let DataTranslationTax = serializeTranslationsTax(dataTranslationTax, DataTranslationSeo, form);

    // ElementTranslation aus den Formular serialize
    let DataAddress = serializeAddress(dataAddress, DataTranslationSeo, form)

    // ElementTranslation aus den Formular serialize
    let DataUsers = serializUsers(dataUsers, DataAddress, form)

    // ElementTranslation aus den Formular serialize
    let MerchantShippingTypes = serializMerchantShippingType(
        dataMerchantShippingType,
        DataUsers,
        form
    )

    // ElementTranslation aus den Formular serialize
    let RateTypes = serializRate(dataRate, MerchantShippingTypes, form)

    // ElementTranslation aus den Formular serialize
    let TaxonsTypes = serializTaxons(dataTaxons, RateTypes, form)

    // ElementTranslation aus den Formular serialize
    let ProductsTypes = serializProducts(dataProducts, TaxonsTypes, form)

    let SortElmements = sortElement(ProductsTypes)

    return SortElmements
}

export default init

export const init2 = (forms: any) => {
    let Dataform = forms

    if (Dataform !== undefined && Dataform !== null) {
        const data = new FormData(Dataform)

        let object: any = {}
        data.forEach((value, key) => {
            const regex = /\[([a-zA-Z0-9_]*)\]/gims
            let m
            let indexes: any = []
            while ((m = regex.exec(key)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++
                }

                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    //console.log(`Found match, group ${groupIndex}: ${match}`);
                    if (groupIndex === 1) {
                        indexes[indexes.length] = match.replace(
                            'translations',
                            'translation'
                        )
                    }
                })
            }

            if (key.includes(']')) {
                let newKey = key
                    .split('[')[0]
                    .replace('translations', 'translation')
                if (indexes != null) {
                    if (indexes.length === 1) {
                        if (!isObject(object[newKey])) {
                            object[newKey] = {}
                        }
                        object[newKey][indexes[0]] = replaceCheckbox(
                            indexes[0],
                            value
                        )
                    } else if (indexes.length === 2) {
                        if (!isObject(object[newKey])) {
                            object[newKey] = {}
                        }
                        if (!isObject(object[newKey][indexes[0]])) {
                            object[newKey][indexes[0]] = {}
                        }
                        object[newKey][indexes[0]][indexes[1]] =
                            replaceCheckbox(indexes[1], value)
                    } else if (indexes.length === 3) {
                        if (!isObject(object[newKey])) {
                            object[newKey] = {}
                        }
                        if (!isObject(object[newKey][indexes[0]])) {
                            object[newKey][indexes[0]] = {}
                        }
                        if (!isObject(object[newKey][indexes[0]][indexes[1]])) {
                            object[newKey][indexes[0]][indexes[1]] = {}
                        }

                        object[newKey][indexes[0]][indexes[1]][indexes[2]] =
                            replaceCheckbox(indexes[2], value)
                    } else if (indexes.length === 4) {
                        if (!isObject(object[newKey])) {
                            object[newKey] = {}
                        }
                        if (!isObject(object[newKey][indexes[0]])) {
                            object[newKey][indexes[0]] = {}
                        }
                        if (!isObject(object[newKey][indexes[0]][indexes[1]])) {
                            if (
                                isNaN(parseInt(indexes[2])) !== true &&
                                indexes[1] === 'items'
                            ) {
                                object[newKey][indexes[0]][indexes[1]] = {}
                            } else {
                                object[newKey][indexes[0]][indexes[1]] = {}
                            }
                        }
                        if (
                            !isObject(
                                object[newKey][indexes[0]][indexes[1]][
                                    indexes[2]
                                ]
                            )
                        ) {
                            object[newKey][indexes[0]][indexes[1]][indexes[2]] =
                                {}
                        }

                        object[newKey][indexes[0]][indexes[1]][indexes[2]][
                            indexes[3]
                        ] = replaceCheckbox(indexes[3], value)
                    } else if (indexes.length === 5) {
                        if (!isObject(object[newKey])) {
                            object[newKey] = {}
                        }
                        if (!isObject(object[newKey][indexes[0]])) {
                            object[newKey][indexes[0]] = {}
                        }
                        if (!isObject(object[newKey][indexes[0]][indexes[1]])) {
                            if (
                                isNaN(parseInt(indexes[2])) !== true &&
                                indexes[1] === 'items'
                            ) {
                                object[newKey][indexes[0]][indexes[1]] = {}
                            } else {
                                object[newKey][indexes[0]][indexes[1]] = {}
                            }
                        }
                        if (
                            !isObject(
                                object[newKey][indexes[0]][indexes[1]][
                                    indexes[2]
                                ]
                            )
                        ) {
                            object[newKey][indexes[0]][indexes[1]][indexes[2]] =
                                {}
                        }

                        if (
                            !isObject(
                                object[newKey][indexes[0]][indexes[1]][
                                    indexes[2]
                                ][indexes[3]]
                            )
                        ) {
                            object[newKey][indexes[0]][indexes[1]][indexes[2]][
                                indexes[3]
                            ] = {}
                        }

                        object[newKey][indexes[0]][indexes[1]][indexes[2]][
                            indexes[3]
                        ][indexes[4]] = replaceCheckbox(indexes[3], value)
                    }
                }
            } else {
                object[key] = replaceCheckbox(key, value)
            }
        })

        let newIndex: any = []

        let i = 0

        const debug = (key: string) => {
            return
        }

        if (object['elements'] !== undefined && object['elements'] !== null) {
            //console.log(object['elements'])
            for (const [key, value] of Object.entries(object['elements'])) {
                debug(key)

                newIndex[i] = value
                /**/

                /** todo prüfen ob es funktioniert wen ja kann es final gelöscht werden **
         /*;*/
                i++
            }

            //console.log(object['elements'])
            let SwappIndex = []
            for (let x = 0; x < newIndex.length; x++) {
                let iterator = newIndex[x].position
                SwappIndex[iterator] = newIndex[x]
            }

            for (let x = 0; x < SwappIndex.length; x++) {
                for (let y = 1; y < x; y++) {
                    if (
                        SwappIndex[x - y] !== undefined &&
                        SwappIndex[x - y]['type'] !== undefined &&
                        SwappIndex[x - y]['type'] === 'container'
                    ) {
                        if (
                            SwappIndex[x - y]['appendto'].includes(
                                'Injectcontainerend'
                            )
                        ) {
                            SwappIndex[x - y]['appendto'] =
                                'Injectcontainerend' +
                                SwappIndex[x - y]['position']
                        }
                    }
                }
            }

            object['elements'] = SwappIndex
        }

        /*let f = 0;
    if(object['form'] !== undefined && object['form'] !== null) {
      for (const [key, value] of Object.entries(object['form'])) {
        debug(key)

        newIndex[f] = value
        f++;
      }
      object['form'] = newIndex;
    }*/

        let jsons = object
        return jsons
    }
}

const replaceCheckbox = (key: any, value: any) => {
    switch (key) {
        case 'seo_index':
            return value === 'on' ? 1 : 0
        case 'visible':
            return value === 'on' ? 1 : value
        case 'position':
            return parseInt(value)

        default:
            return value
    }
}
