import IntlMessage from '../../../../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(conf: string, response: any, Store: any) {
    return {
        tableBody: {
            data: response,
            dataID: 'merchant_id',
            underdata: true,
            keys: [
                'merchant_id',
                'merchant.enabled',
                'merchant.company',
                'merchant.slug',
                'merchant.website'
            ],
            type: ['string', 'status', 'string', 'string', 'string']
        },
        tableHeader: [
            'id',
            <IntlMessage Store={Store} messageId="common.status" />,
            <IntlMessage Store={Store} messageId="common.name" />,
            <IntlMessage Store={Store} messageId="common.slug" />,
            <IntlMessage Store={Store} messageId="common.website" />
        ],
        tableHeaderWidth: ['2%', '8%', '30%', '30%', '30%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.display" />,
                <IntlMessage Store={Store} messageId="common.edit" />,
                <IntlMessage Store={Store} messageId="common.delete" />
            ],
            menuroute: [
                '/merchant/Show',
                '/merchant/Detail',
                '/merchant/Delete'
            ],
            menuParams: [false, 'id', 'id'],
            menuIcons: ['eye', 'pencil', 'Delete']
        }
    }
}

export default configuration
