import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const MonitoringOverview = (value: any) => {
    return (
        <div style={{ backgroundColor: 'white', padding: '15px' }}>
            <Row>
                <Col>Comming Soon</Col>
            </Row>
        </div>
    )
}

export default MonitoringOverview
