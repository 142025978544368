import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import Modal from 'react-bootstrap/Modal';

interface UniModalFooterProps{
    /** Allows it to have children and have them reflected. */
    elements: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined
}
export const UniModalFooter = (props:UniModalFooterProps) => {

    return(
        <>
            <Modal.Footer>
                {props.elements}
            </Modal.Footer>
        </>
    )

}
