import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useNavigate} from "react-router-dom";
import {ResponseAllTimes} from "../API/response/favo";
import {ApiRoot} from "../../RestFullApi/api";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import {IntlMessageValue} from "../../component/const/IntlMessage";
import {useSelector} from "react-redux";
import '../css/favo.css'
const Favourite = (props:{name:any, placement: any; newFavo?:any }) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [fetchData, setFetchData] = useState(0);
    const response = ResponseAllTimes(localStorage.getItem('username')?.replaceAll(' ','_'),fetchData);
    const Store = useSelector((state: any) => state)
    let save = true;

    let FavoHolder = [];
    if (typeof response !== "boolean" && response?.length > 0) {
        for(let r=0;r<response.length;r++) {
            let data = response[r];
            if(data.link === window.location.pathname) {
                save = false;
            }
            FavoHolder.push(
                <>

                        <tr onDoubleClick={()=>navigate(data.link)} style={{cursor:'pointer'}}>
                            <td><p>{data.name}</p></td>
                            <td><Button variant="danger" className={'float-end'} onClick={()=>deleteFavo(data.id)}>
                            <span className="material-icons">
                                delete
                            </span>
                            </Button></td>
                        </tr>
                </>
            )
        }

    }


    const MakeClick= () => {
        let Button = document.getElementById('FavoButton') as HTMLButtonElement;
        if(Button !== null) {
            if(Button.style.left === '0px') {
                Button.style.left = '400px'
                handleShow()
            } else {
                Button.style.left = '0'
                handleClose()
            }

        }

    }

    const CloseClick = () =>{
        let Button = document.getElementById('FavoButton') as HTMLButtonElement;
        if(Button !== null) {
            Button.style.left = '0'
        }
        handleClose()
    }

    const addFavo = (e:any) => {
        e.preventDefault()
        let data = e.currentTarget
        var formdata = new FormData(data)

        var raw = JSON.stringify({
            "name": formdata.get('name'),
            "link":window.location.pathname,
            "icon":'',
            "user":localStorage.getItem('username')
        });

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+"add/favo/", requestOptions)
            .then(response => response.text())
            .then(result => succesForm(result))
            .catch(error => console.log('error', error));
    }

    const deleteFavo = (id:any) => {

        var requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
        }

        fetch(ApiRoot()+"delete/favo/"+id+"/", requestOptions)
            .then(response => response.text())
            .then(result => succesForm(result))
            .catch(error => console.log('error', error));
    }

    const succesForm = (result:any) => {
        setFetchData(Date.now())
    }

    return (
        <>
            <Button variant="primary" id={'FavoButton'} onClick={MakeClick} className="me-2 StarButton" style={{left:'0px'}}>
                <span className="material-icons">
                    star
                </span>
            </Button>
            <Offcanvas show={show} onHide={CloseClick} placement={props.placement}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Favoriten</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ButtonGroup aria-label="Basic example">
                    {props.newFavo !== undefined && save === true
                    ?
                        <>
                        <Form  id={'form001'}
                               className={'formControl'}
                               onSubmit={(e) =>
                                   addFavo(e)
                               }>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">Beschreibung</InputGroup.Text>
                                <Form.Control id={'TitleFavo'} name={'name'} type={'text'} required={true}  />
                            </InputGroup>
                        <Button type={'submit'} variant="success">
                            <span className="material-icons" style={{verticalAlign:'bottom'}}>
                                add
                            </span>
                            Favorit hinzufügen</Button>
                        </Form>
                        </>
                        :
                        <></>
                    }
                    </ButtonGroup>
                    <hr/>
                    <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th>Beschreibung</th>
                            <th>Entfernen</th>
                        </tr>
                        </thead>
                        <tbody>
                    {FavoHolder}
                        </tbody>
                    </Table>
                    <Card className={'helpfavo'}>
                        <Card.Body>
                            <Card.Title>{IntlMessageValue('de', 'favo.headline', Store)}</Card.Title>
                            <Card.Text>
                                <p>
                            {IntlMessageValue('de', 'favo.text', Store)}
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Favourite;
