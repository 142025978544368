import React from 'react'
import { useParams } from 'react-router-dom'
import { ResponseDelete } from '../../API/response/site/site'

const PagesDeleted = (value: any) => {
    const { deleteID } = useParams<{ page: string; deleteID: string }>()
    if (typeof deleteID === 'string') {
        ResponseDelete(deleteID)
    }
    let landingpages = '/Pages/overview/1'
    setTimeout(() => (window.location.href = landingpages), 3000)
    return <></>
}
export default PagesDeleted
