import IntlMessage, {
    IntlMessageValue
} from '../../../component/const/IntlMessage'
import React from 'react'

export function base(
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0
): Array<any> {
    const index = newElement === false ? i : newelementIndex
    return [
        {
            formType: 'input',
            name: 'elements[' + index + '][type]',
            type: 'hidden',
            value: element.type
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][id]',
            type: 'hidden',
            value: element.id
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][new]',
            type: 'hidden',
            value: newElement === false ? false : true
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][translation][parent]',
            type: 'hidden',
            value: newElement === false ? element.id : null
        },

        {
            formType: 'input',
            name: 'elements[' + index + '][translation][id]',
            type: 'hidden',
            value:
                newElement === false
                    ? newElement === false && element.translation !== undefined
                        ? element.translation.de.id
                        : null
                    : null
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][position]',
            type: 'hidden',
            value: newElement === false ? element.position : index
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][updated_at]',
            type: 'hidden',
            value: element.updated_at
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][type_id]',
            type: 'hidden',
            value:
                newElement === false
                    ? element.type_id
                    : window.location.pathname.split('/').pop()
        }
    ]
}

export function basesettings(
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    Store: any
): Array<any> {
    const index = newElement === false ? i : newelementIndex
    return [
        {
            formType: 'header',
            type: 'h5',
            value: IntlMessageValue('de', 'common.general', Store)
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.status" />,
            br: true,
            name: 'elements[' + index + '][visible]',
            selected: newElement === false ? element.visible : 1,
            options: [
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: '1'
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: '0'
                }
            ]
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][translations][locale]',
            type: 'hidden',
            value: 'de'
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.css_class" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.css_class', Store),
            name: 'elements[' + index + '][class]',
            value: newElement === false ? element.class : ''
        },
        {
            formType: 'input',
            label: 'Div ID',
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.div.id', Store),
            name: 'elements[' + index + '][div_id]',
            value: newElement === false ? element.div_id : ''
        }
    ]
}
