import { requests } from '../../../core/RestFullApi/api'
import { faqApiType } from './faqApi.interface'

export const faq = {
    getPostsALL: (limit: any, searchValue: any): Promise<faqApiType[]> =>
        requests.get(`Helpdesk/faq/${limit}/${searchValue}`),
    getPosts: (): Promise<faqApiType[]> =>
        requests.get(`Helpdesk/faq/menu/all/de/`),
    getAPost: (id: string | undefined): Promise<faqApiType[]> =>
        requests.get(`Helpdesk/faq/single/${id}/`),
    getPostsSearch: (searchValue: any): Promise<faqApiType[]> =>
        requests.get(`Helpdesk/faq/like/${searchValue}/`),
    createPostFAQ: (put: faqApiType, id: number): Promise<faqApiType> =>
        requests.put(`Helpdesk/insert/cat/${id}/`, put),
    updatePost: (put: any, id: number): Promise<faqApiType> =>
        requests.put(`Helpdesk/update/cat/${id}/`, put)
}
