
export const colorCheck = (code:any,isrgb:any) => {
    const wc_hex_is_light = (color:string) => {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;

        return brightness > 145;
    }

    const componentToHex = (c:any) => {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    const rgbToHex = (r:any, g:any, b:any) => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    if(isrgb === true) {
        let rgb = code.replace('rgb','').replace('(','').replace(')','')
        return wc_hex_is_light(rgbToHex(parseInt(rgb.split(',')[0]),parseInt(rgb.split(',')[1]),parseInt(rgb.split(',')[2])))
    } else {
        return wc_hex_is_light(code)
    }

}
