import setNavi from "../../../@WUM/core/component/const/setNavi";

const Reports = (value:any) => {

    setNavi({
        route:'/report/activityrecording/'
    })

    return(
        <>

        </>
    )
}
export default Reports;
