import React from 'react'
import PielersForm from '../../index'
import { Tabs } from 'react-bootstrap'
import { base, basesettings } from './base'
import IntlMessage from '../../../component/const/IntlMessage'
import Tab from 'react-bootstrap/Tab'
import CopyElements from '../../../component/const/CopyElements'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import WebBuilderElementsHead from '../../../component/const/WebBuilderElementsHead'
import Box from '@mui/material/Box'

function htmlWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any
) {
    const index = newElement === false ? i : newelementIndex

    localStorage.setItem(
        'HTMLModul' + index,
        newElement === false
            ? element.translation !== undefined
                ? element.translation.de.html
                : ''
            : ''
    )

    const configuration = [
        {
            formType: 'SyntaxHighlight',
            label: (
                <IntlMessage Store={Store} messageId="comment.social.link" />
            ),
            type: 'textarea',
            nochar: true,
            id: 't1' + index,
            index: index,
            targetid: 'previewHolder' + index,
            name: 'elements[' + index + '][translation][html]',
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.html
                        : ''
                    : ''
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/html.html'
        }
    ]

    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex)
    )

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Box
                id={'html_modul' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    <IntlMessage Store={Store} messageId="module.html_modul" />,
                    'html_modul',
                    newElement === false
                        ? element.translation !== undefined
                            ? element.translation.de.html
                            : ''
                        : '',
                    Status,
                    index,
                    'elementButton',
                    <IntlMessage Store={Store} messageId="module.anchor" />,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_html_modul' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_html_modul' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_html_modul' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <div id={'Teaser'}>html_modul</div>
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="module.html_modul"
                                />
                            </Col>
                            <Tabs
                                defaultActiveKey="Preview"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                                key={'tabindex'}
                            >
                                <Tab
                                    eventKey="home"
                                    title={'Code'}
                                    key={'allgemein'}
                                    id={'allgemein'}
                                    style={{
                                        backgroundColor: 'White',
                                        padding: '15px',
                                        marginTop: '-15px'
                                    }}
                                >
                                    {PielersForm(newConfiguration)}
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'html_modul'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.html_modul"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default htmlWebBuilder
