import React from 'react'
import IssueView from '../components/issue'
import IssueMenuView from '../components/IssueMenuView'
import { useParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AllIssue from '../../API/allIssue'
import '../../css/Ticketsystem.css'
import { isEmptyArray } from 'formik'
import { SpinnerJSX } from '../components/Spinner'
import Alllabel from '../../API/alllabel'
import AllTech from '../../API/allTech'
import PageIssueProps from '../../Interface/PageIssue.interface'
import { useSelector } from 'react-redux'

const Issue = (props: PageIssueProps) => {
    const { id } = useParams<{ id: string | undefined }>()
    let Issue = AllIssue()
    let labels = Alllabel()
    let tech = AllTech()
    const Store = useSelector((state: any) => state)

    if (
        Issue !== undefined &&
        !isEmptyArray(Issue) &&
        labels !== undefined &&
        !isEmptyArray(labels) &&
        tech !== undefined &&
        !isEmptyArray(tech)
    ) {
        return (
            <>
                <Row>
                    <Col
                        md={10}
                        sm={10}
                        xl={10}
                        xxl={10}
                        lg={10}
                        className={'ContentWrapper'}
                    >
                        <IssueView id={id} dummy={Issue} Store={Store} />
                    </Col>
                    <Col
                        md={2}
                        sm={2}
                        xl={2}
                        xxl={2}
                        lg={2}
                        className={'IssueMenu'}
                    >
                        <IssueMenuView
                            id={id}
                            dummy={Issue}
                            label={labels}
                            tech={tech}
                            Store={Store}
                        />
                    </Col>
                </Row>
            </>
        )
    } else {
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} />
            </>
        )
    }
}

export default Issue
