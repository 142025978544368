import React from 'react'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

function legende(data: any) {
    if (data.hasOwnProperty('legende')) {
        if (data.SyntaxHighlighter !== undefined) {
            return (
                <>
                    {/* @ts-ignore */}
                    <SyntaxHighlighter
                        showLineNumbers={true}
                        language={data.SyntaxHighlighterLanguage}
                        style={dark}
                        wrapLines={true}
                    >
                        {data.legende}
                    </SyntaxHighlighter>
                </>
            )
        } else {
            return (
                <>
                    <p
                        style={{
                            color: '#b0b0b0',
                            fontSize: '14px',
                            lineHeight: '1.3em',
                            marginTop: '5px'
                        }}
                        key={'legende'}
                    >
                        {data.legende}
                    </p>
                </>
            )
        }
    } else {
        return
    }
}

export default legende
