import SunEditorBig from '../../../../../../../@WUM/core/component/const/SunEditorBig'
import { IntlMessageValue } from '../../../../../../../@WUM/core/component/const/IntlMessage'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    //console.log(response)
    let config

    if (newElement === false) {
        config = [
            {
                formType: 'multiply',
                cols: [
                    {
                        formType: 'input',
                        type: 'text',
                        label: IntlMessageValue(
                            'de',
                            'form.settings.name',
                            Store
                        ),
                        legende: IntlMessageValue(
                            'de',
                            'form.settings.name.legend',
                            Store
                        ),
                        name: 'name',
                        readonly: newElement === false ? true : false,
                        value:
                            newElement === false
                                ? response[0] !== undefined
                                    ? response[0].name
                                    : ''
                                : ''
                    },
                    {
                        formType: 'input',
                        type: 'text',
                        label: IntlMessageValue(
                            'de',
                            'form.settings.from',
                            Store
                        ),
                        name: 'from',
                        legende: IntlMessageValue(
                            'de',
                            'form.settings.from.legend',
                            Store
                        ),
                        value:
                            newElement === false
                                ? response[0] !== undefined
                                    ? response[0].from
                                    : ''
                                : ''
                    },
                    {
                        formType: 'input',
                        type: 'text',
                        label: IntlMessageValue(
                            'de',
                            'form.settings.subject',
                            Store
                        ),
                        legende: IntlMessageValue(
                            'de',
                            'form.settings.subject.legend',
                            Store
                        ),
                        name: 'subject',
                        value:
                            newElement === false
                                ? response[0] !== undefined
                                    ? response[0].subject
                                    : ''
                                : ''
                    },
                    {
                        formType: 'input',
                        label: IntlMessageValue(
                            'de',
                            'form.settings.greeting',
                            Store
                        ),
                        legende: IntlMessageValue(
                            'de',
                            'form.settings.greeting.legend',
                            Store
                        ),
                        type: 'textarea',
                        name: 'greeting',
                        value:
                            newElement === false
                                ? response[0] !== undefined
                                    ? response[0].greeting
                                    : ''
                                : ''
                    }
                ]
            },
            SunEditorBig(
                newElement === false
                    ? response[0] !== undefined
                        ? response[0]
                        : ''
                    : '',
                'message_recipient',
                newElement,
                {
                    lang: 'de',
                    height: '160px'
                },
                'message_recipient',
                IntlMessageValue('de', 'form.settings.message_recipient', Store)
            ),
            SunEditorBig(
                newElement === false
                    ? response[0] !== undefined
                        ? response[0]
                        : ''
                    : '',
                'message_admin',
                newElement,
                {
                    lang: 'de',
                    height: '160px'
                },
                'message_admin',
                IntlMessageValue('de', 'form.settings.message_admin', Store)
            ),
            {
                formType: 'input',
                type: 'hidden',
                name: 'id',
                value:
                    newElement === false
                        ? response[0] !== undefined
                            ? response[0].id
                            : 0
                        : 0
            },
            {
                formType: 'input',
                type: 'hidden',
                label: 'name',
                name: 'name',
                value:
                    newElement === false
                        ? response[0] !== undefined
                            ? response[0].name
                            : ''
                        : ''
            }
        ]
    } else {
        config = [
            {
                formType: 'multiply',
                cols: [
                    {
                        formType: 'input',
                        type: 'text',
                        label: IntlMessageValue(
                            'de',
                            'form.settings.name',
                            Store
                        ),
                        legende: IntlMessageValue(
                            'de',
                            'form.settings.name.legend',
                            Store
                        ),
                        name: 'name',
                        readonly: newElement === false ? true : false,
                        value:
                            newElement === false
                                ? response[0] !== undefined
                                    ? response[0].name
                                    : ''
                                : ''
                    },
                    {
                        formType: 'input',
                        type: 'text',
                        label: IntlMessageValue(
                            'de',
                            'form.settings.from',
                            Store
                        ),
                        name: 'from',
                        legende: IntlMessageValue(
                            'de',
                            'form.settings.from.legend',
                            Store
                        ),
                        value:
                            newElement === false
                                ? response[0] !== undefined
                                    ? response[0].from
                                    : ''
                                : ''
                    },
                    {
                        formType: 'input',
                        type: 'text',
                        label: IntlMessageValue(
                            'de',
                            'form.settings.subject',
                            Store
                        ),
                        legende: IntlMessageValue(
                            'de',
                            'form.settings.subject.legend',
                            Store
                        ),
                        name: 'subject',
                        value:
                            newElement === false
                                ? response[0] !== undefined
                                    ? response[0].subject
                                    : ''
                                : ''
                    },
                    {
                        formType: 'input',
                        label: IntlMessageValue(
                            'de',
                            'form.settings.greeting',
                            Store
                        ),
                        legende: IntlMessageValue(
                            'de',
                            'form.settings.greeting.legend',
                            Store
                        ),
                        type: 'textarea',
                        name: 'greeting',
                        value:
                            newElement === false
                                ? response[0] !== undefined
                                    ? response[0].greeting
                                    : ''
                                : ''
                    }
                ]
            },
            SunEditorBig(
                newElement === false
                    ? response[0] !== undefined
                        ? response[0]
                        : ''
                    : '',
                'message_recipient',
                newElement,
                {
                    lang: 'de',
                    height: '160px'
                },
                'message_recipient',
                IntlMessageValue('de', 'form.settings.message_recipient', Store)
            ),
            SunEditorBig(
                newElement === false
                    ? response[0] !== undefined
                        ? response[0]
                        : ''
                    : '',
                'message_admin',
                newElement,
                {
                    lang: 'de',
                    height: '160px'
                },
                'message_admin',
                IntlMessageValue('de', 'form.settings.message_admin', Store)
            ),
            {
                formType: 'input',
                type: 'hidden',
                name: 'id',
                value:
                    newElement === false
                        ? response[0] !== undefined
                            ? response[0].id
                            : 0
                        : 0
            }
        ]
    }

    return config
}

export default configuration
