import PielersserializeElements from '../helper/elements'
import excape from '../helper/excape'

const serializeElement = (elementsdata: any, form: any, Data: any) => {
    let elementstmp: string = ''

    if (elementsdata.length > 0) {
        elementstmp += '['

        for (let i = 0; i < elementsdata.length; i++) {
            let end = elementsdata.length - 1
            if (i === end) {
                elementstmp += excape(
                    PielersserializeElements(elementsdata[i], form)
                )
            } else {
                elementstmp +=
                    excape(PielersserializeElements(elementsdata[i], form)) +
                    ','
            }
        }
        elementstmp += ']'

        Data['elements'] = JSON.parse(elementstmp)
    }
    return Data
}
export default serializeElement
