import React from 'react'
import TableCell from '@material-ui/core/TableCell'
function dateCut(
    text: string,
    id: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any,
    NoneTd: any = false
) {
    let CompletDate;
    let date;
    let Time;

    if(text !== null && text !== undefined) {
        CompletDate = text.split('|')
        date = CompletDate[0] + ' '
        Time = CompletDate[1] + ' Uhr'
    } else {
        date = '';
        Time = '';
    }

    if (NoneTd === true) {
        return (
            <>
                {date}{' '}
                <span
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em'
                    }}
                >
                    {Time}
                </span>
            </>
        )
    } else {
        return (
            <>
                <TableCell
                    style={{ textAlign: tableBodyPositionElement }}
                    key={'td' + id + '_' + index}
                    data-param={VisitData}
                    data-param-del={VisitDataDel}
                    data-id={id}
                    id={'td' + id + '_' + index}
                >
                    {date}{' '}
                    <span
                        style={{
                            color: '#b0b0b0',
                            fontSize: '14px',
                            lineHeight: '1.3em'
                        }}
                    >
                        {Time}
                    </span>
                </TableCell>
            </>
        )
    }
}

export default dateCut
