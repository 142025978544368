import Row from 'react-bootstrap/Row'
import { CardsJSX } from './CardJSX'
import { handleSucces } from './HandleSucces'
import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import PielersTable from '../../tablemaker'
import React from 'react'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import NewButton from '../const/new'
import PaginationJSX from '../../form-serilizer/elements/pagination'
import IntlMessage, { IntlMessageValue } from './IntlMessage'
import { useNavigate } from 'react-router-dom'

interface ContentSwitchProps {
    Store: any
    pagenew: any
    destinationnew: any
    tonew: any
    text: any
    response: any
    CardsConfiguration: any
    mode: any
    Configuration: any
    setMode: any
    destination: any
    page: any
    to: any
    setSearchValues?: any
    setSearchs?: any
    search?: any
    modalShow: any
    setModalShow?: any
    modalData?: any
    setModalData?: any
    allTaxon?: any
    searchfield?: any
    _handleKeyFilters?: any
    filter?: any
    _handleEnter?: any
    merchantSelect?: any
    stop?: any
    SearchParam?: any
    nowWindow?: any
}

/**
 * Component give table or Cards for Overview
 *
 * @component
 * @example
 * <ContentSwitch pagenew={'new'} destinationnew={'Member/Merchant'} tonew={'new'} text={'Neue User erstellen'}
 * response={response} CardsConfiguration={CardsConfiguration} mode={mode}
 * Configuration={Configuration} setMode={setMode} history={history} destination={destination} page={page}
 * to={'overview'} setSearchValues={setSearchValue} setSearchs={setSearch} search={search}
 * modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData}
 * searchfield={searchfield} allTaxon={''} _handleKeyFilters={_handleKeyFilters} _handleEnter={_handleEnter} filter={false}/>
 *
 * @param {ContentSwitchProps} props Übergabe Werte für die erstellung der Tabelle oder der Cards
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const ContentSwitch = (props: ContentSwitchProps) => {
    const history = useNavigate()
    const setSearchValue = props.setSearchValues
    if (props.stop === true) {
        return <></>
    }
    /**
     * Sendet Title an die API
     * @param {event} e Event des HTML Element
     * @param searchParam
     * @author Marcel Ugurcu
     * @version 0.0.1
     */
    const _handleKeyDown = function (
        e: any,
        searchParam: string = '?translation.title'
    ) {
        if (e.key === 'Enter') {
            const value = e.target.value
            setSearchValue(searchParam + "= like '%" + value + "%'")
        }
    }

    /**
     * Sendet Filter an die API
     * @param {event} e Event des HTML Element
     * @author Marcel Ugurcu
     * @version 0.0.1
     */
    const _handleKeyFilter = function (e: any) {
        if (e.target.value !== '0') {
            const value = e.target.value

            setSearchValue('?filter=' + value)
        }
    }

    /**
     * @typedef SearchformProps
     * @prop {any} _handleKeyDown Function
     * @prop {any} _handleKeyFilters Function for Event
     * @prop {any} history history Element
     * @prop {any} pagenew pagenew string
     * @prop {any} destinationnew destinationnew string
     * @prop {any} tonew tonew string
     * @prop {any} text text string
     * @prop {any} filter filter bool
     * @author Marcel Ugurcu
     * @version 0.0.1
     *
     */
    interface SearchformProps {
        _handleKeyDown: (
            event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
            searchParam: any
        ) => void
        _handleKeyFilter: any
        history: any
        pagenew: any
        destinationnew: any
        tonew: any
        text: any
        filter: any
        Store: any
        SearchParam: any
    }
    /**
     * @example
     * <Searchform _handleKeyDown={_handleKeyDown} _handleKeyFilter={_handleKeyFilter} history={props.history} pagenew={props.pagenew}
     * destinationnew={props.destinationnew} tonew={props.tonew} text={props.text} filter={props.filter} />
     * @param {SearchformProps} props zum Bilden der Suche
     * @author Marcel Ugurcu
     * @version 0.0.1
     */
    const Searchform: React.FC<SearchformProps> = (props) => {
        let searchadded

        if (props.filter === true) {
            searchadded = (
                <>
                    <Col>
                        <Form.Select
                            aria-label="Filter"
                            onChange={_handleKeyFilter}
                        >
                            <option value={'0'}></option>
                            <option value={'title'}>
                                {IntlMessageValue(
                                    'de',
                                    'searchadded.title.up',
                                    props.Store
                                )}
                            </option>
                            <option value={'-title'}>
                                {IntlMessageValue(
                                    'de',
                                    'searchadded.title.down',
                                    props.Store
                                )}
                            </option>
                        </Form.Select>
                    </Col>
                </>
            )
        } else {
            searchadded = <></>
        }
        let Search =
            props.SearchParam !== undefined &&
            props.SearchParam !== null &&
            props.SearchParam !== ''
                ? props.SearchParam
                : '?translation.title'
        return (
            <>
                <div style={{ backgroundColor: 'white', padding: '15px' }}>
                    <Row>
                        <Col xl={4} md={4} sm={4}>
                            <Form.Group
                                controlId={'search'}
                                key={'search'}
                                className="mb-3 prelative"
                            >
                                <Form.Control
                                    placeholder={IntlMessageValue(
                                        'de',
                                        'common.search_for_title',
                                        props.Store
                                    )}
                                    onKeyDown={(e) =>
                                        props._handleKeyDown(e, Search)
                                    }
                                />
                                <Form.Text className="text-muted searchPageInfo">
                                    <IntlMessage
                                        Store={props.Store}
                                        messageId="searchadded.placeholder.input"
                                    />
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        {searchadded}
                        {props.pagenew !== '' ? (
                            <NewButton
                                history={props.history}
                                page={props.pagenew}
                                destination={props.destinationnew}
                                to={props.tonew}
                                text={props.text}
                            />
                        ) : (
                            ''
                        )}
                    </Row>
                </div>
            </>
        )
    }

    /**
     * @typedef Searchform2Props
     * @prop {any} _handleKeyDown Function
     * @prop {any} _handleKeyFilters Function for Event
     * @prop {any} history history Element
     * @prop {any} pagenew pagenew string
     * @prop {any} destinationnew destinationnew string
     * @prop {any} tonew tonew string
     * @prop {any} text text string
     * @prop {any} searchfield searchfield string
     * @prop {any} _handleEnter  Function for Event
     * @author Marcel Ugurcu
     * @version 0.0.1
     *
     */
    interface Searchform2Props {
        _handleKeyDown: (
            event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
            searchParam: any
        ) => void
        _handleKeyFilters: any
        history: any
        pagenew: any
        destinationnew: any
        tonew: any
        text: any
        searchfield: any
        _handleEnter: any
        merchant?: any
        Store: any
    }

    /**
     * @example
     * <Searchform2 _handleKeyDown={_handleKeyDown} _handleKeyFilters={props._handleKeyFilters} history={props.history} pagenew={props.pagenew}
     * destinationnew={props.destinationnew} tonew={props.tonew} text={props.text} searchfield={props.searchfield} _handleEnter={props._handleEnter} />
     * @param {Searchform2Props} props zum Bilden der Suche
     * @author Marcel Ugurcu
     * @version 0.0.1
     */
    const Searchform2: React.FC<Searchform2Props> = (props) => {
        let row = []

        for (let x = 1; x < props.searchfield.length; x++) {
            row.push(
                <option value={props.searchfield[x].option.value}>
                    {IntlMessageValue(
                        'de',
                        props.searchfield[x].option.label,
                        props.Store
                    )}
                </option>
            )
        }

        let rowMerchant = []
        if (props.merchant !== undefined && props.merchant.length > 0) {
            let searchSelectMerchant =
                localStorage.getItem('searchSelect') !== null
                    ? (localStorage.getItem('searchSelectMerchant') as string)
                    : ''
            let indiRow = []

            for (let xs = 0; xs < props.merchant.length; xs++) {
                if (props.merchant[xs] !== undefined) {
                    indiRow.push(
                        <option value={props.merchant[xs].id}>
                            {props.merchant[xs].name}
                        </option>
                    )
                }
            }
            rowMerchant.push(
                <>
                    <Col>
                        <Form.Group
                            controlId={'searchSelectMerchant'}
                            key={'searchSelectMerchant'}
                            className="mb-3 prelative"
                        >
                            <Form.Select
                                id={'searchSelectMerchant'}
                                title={IntlMessageValue(
                                    'de',
                                    'filter.by.merchant',
                                    props.Store
                                )}
                                aria-label="Filter"
                                defaultValue={searchSelectMerchant}
                            >
                                <option value={''}></option>
                                {indiRow}
                            </Form.Select>
                            <Form.Text className="text-muted searchPageInfoLeft">
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="filter.by.merchant"
                                />
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </>
            )
        }

        /**
         * Aufruf der Suche
         * @param {event} e Event des HTML Elements
         * @author Marcel Ugurcu
         * @version 0.0.1
         */
        const submitHandler = function (e: any) {
            e.preventDefault()
            props._handleKeyFilters(e)
        }

        let seclectValue =
            localStorage.getItem('searchSelect') !== null
                ? (localStorage.getItem('searchSelect') as string)
                : ''
        let seclectInput =
            localStorage.getItem('searchInput') !== null
                ? localStorage.getItem('searchInput')
                : ''

        return (
            <>
                <div
                    className={'MainBackground'}
                    style={{ backgroundColor: 'white', padding: '15px' }}
                >
                    <form id={'search2'} onSubmit={submitHandler}>
                        <Row>
                            <Col xl={3} md={3} sm={3}>
                                <Form.Group className="mb-3 prelative">
                                    <Form.Control
                                        id={'search'}
                                        //placeholder={IntlMessageValue(props.searchfield[0].param.placeholder)}
                                        defaultValue={seclectInput as string}
                                    />
                                    <Form.Text className="text-muted searchPageInfoLeft">
                                        {IntlMessageValue(
                                            'de',
                                            props.searchfield[0].param
                                                .placeholder,
                                            props.Store
                                        )}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    controlId={'searchSelect'}
                                    key={'searchSelect'}
                                    className="mb-3 prelative"
                                >
                                    <Form.Select
                                        id={'searchSelect'}
                                        title={IntlMessageValue(
                                            'de',
                                            'filter.by.role',
                                            props.Store
                                        )}
                                        aria-label="Filter"
                                        defaultValue={seclectValue}
                                    >
                                        <option value={''}></option>
                                        {row}
                                    </Form.Select>
                                    <Form.Text className="text-muted searchPageInfoLeft">
                                        <IntlMessage
                                            Store={props.Store}
                                            messageId="filter.by.role"
                                        />
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            {rowMerchant}
                            <Col>
                                <Button
                                    type={'button'}
                                    variant={'success'}
                                    onClick={props._handleKeyFilters}
                                >
                                    {IntlMessageValue(
                                        'de',
                                        'common.start_search',
                                        props.Store
                                    )}
                                </Button>
                            </Col>
                            {props.pagenew !== '' ? (
                                <NewButton
                                    history={props.history}
                                    page={props.pagenew}
                                    destination={props.destinationnew}
                                    to={props.tonew}
                                    text={props.text}
                                />
                            ) : (
                                ''
                            )}
                        </Row>
                    </form>
                </div>
            </>
        )
    }

    /**
     * @typedef GetModalProps
     * @prop {boolean} modalShow True or False Show Modal
     * @prop {any} setModalShow Setter for ModalShow
     * @prop {any} modalData Param for ModalData
     * @author Marcel Ugurcu
     * @version 0.0.1
     *
     */
    interface GetModalProps {
        modalShow: boolean
        setModalShow: (
            value: ((prevState: boolean) => boolean) | boolean
        ) => void
        modalData: any
        Store: any
    }

    /**
     * @example
     * <GetModal modalShow={props.modalShow} setModalShow={props.setModalShow} modalData={props.modalData} />
     * @param {GetModalProps} props Werte zum Aufruf der Modal Box
     * @author Marcel Ugurcu
     * @version 0.0.1
     */
    const GetModal: React.FC<GetModalProps> = (props) => {
        return (
            <>
                <MyVerticallyCenteredModal
                    show={props.modalShow}
                    titel={props.modalData[0]}
                    id={props.modalData[1]}
                    link={props.modalData[2]}
                    onHide={() => props.setModalShow(false)}
                    Store={props.Store}
                />
            </>
        )
    }

    interface MyVerticallyCenteredModalProps {
        show: any
        titel: any
        id: any
        link: any
        onHide: any
        Store: any
    }
    /**
     * @example
     * <MyVerticallyCenteredModal show={modalShow} titel={modalData[0]} id={modalData[1]} link={modalData[2]} onHide={() => setModalShow(false)}  />
     * @param {MyVerticallyCenteredModalProps} props Werte für Modal Box
     */
    const MyVerticallyCenteredModal: React.FC<
        MyVerticallyCenteredModalProps
    > = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.id}-{props.titel}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>
                        <IntlMessage
                            Store={props.Store}
                            messageId="modal.del.headline"
                        />
                    </h4>
                    <div id={'modal-delete-div'}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: IntlMessageValue(
                                    'de',
                                    'modal.del.description',
                                    props.Store,
                                    'replace',
                                    '[titel]',
                                    props.titel,
                                    true
                                )
                            }}
                        ></p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                    <Button type={'button'} variant="danger" href={props.link}>
                        <IntlMessage
                            Store={props.Store}
                            messageId="modal.del.button"
                        />
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    let searchForm

    if (props.searchfield === false) {
        searchForm = <></>
    } else if (props.searchfield.length > 0) {
        searchForm = (
            <Searchform2
                _handleKeyDown={_handleKeyDown}
                _handleKeyFilters={props._handleKeyFilters}
                history={history}
                pagenew={props.pagenew}
                destinationnew={props.destinationnew}
                tonew={props.tonew}
                text={props.text}
                searchfield={props.searchfield}
                _handleEnter={props._handleEnter}
                merchant={props.merchantSelect}
                Store={props.Store}
            />
        )
    } else {
        searchForm = (
            <Searchform
                _handleKeyDown={_handleKeyDown}
                _handleKeyFilter={_handleKeyFilter}
                history={history}
                pagenew={props.pagenew}
                destinationnew={props.destinationnew}
                tonew={props.tonew}
                text={props.text}
                filter={props.filter}
                Store={props.Store}
                SearchParam={props.SearchParam}
            />
        )
    }
    if (props.search === false) {
        if (props.response.length > 0) {
            if (props.mode === 'cart') {
                return (
                    <>
                        {searchForm}
                        <Row>
                            <Col>
                                <div
                                    className="form-check form-switch "
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        paddingLeft: '0px'
                                    }}
                                >
                                    <div className={'float-start'}>
                                        <Row>
                                            <Col style={{ maxWidth: '80px' }}>
                                                <label className="form-check-label">
                                                    <IntlMessage
                                                        Store={props.Store}
                                                        messageId="components.function.contentswitch.table"
                                                    />
                                                </label>
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: '80px',
                                                    padding: '0px',
                                                    marginRight: '2em'
                                                }}
                                            >
                                                <input
                                                    className="form-check-input ml-0"
                                                    type="checkbox"
                                                    id="flexSwitchCheckChecked"
                                                    defaultChecked
                                                    onClick={() =>
                                                        props.setMode('table')
                                                    }
                                                />
                                            </Col>
                                            <Col style={{ maxWidth: '60px' }}>
                                                <label className="form-check-label">
                                                    <IntlMessage
                                                        Store={props.Store}
                                                        messageId="components.function.contentswitch.grid"
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={'site'}>
                            <Row>
                                <CardsJSX
                                    destination={props.destination}
                                    data={props.CardsConfiguration}
                                    setModalShow={props.setModalShow}
                                    setModalData={props.setModalData}
                                    allTaxon={props.allTaxon}
                                />
                            </Row>
                            <PaginationJSX
                                response={props.response}
                                history={history}
                                to={props.to}
                                handleSuccess={handleSucces}
                                destination={props.destination}
                                nowWindow={props.nowWindow}
                            />
                        </div>
                        <Spinner
                            className={'spinner'}
                            animation="border"
                            role="status"
                            style={{ display: 'none' }}
                        >
                            <span className="visually-hidden">
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.loading"
                                />
                            </span>
                        </Spinner>
                        <GetModal
                            modalShow={props.modalShow}
                            setModalShow={props.setModalShow}
                            modalData={props.modalData}
                            Store={props.Store}
                        />
                    </>
                )
            } else if (props.mode === 'table') {
                return (
                    <>
                        {searchForm}
                        <Row>
                            <Col>
                                <div
                                    className="form-check form-switch "
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        paddingLeft: '0px'
                                    }}
                                >
                                    <div className={'float-start'}>
                                        <Row>
                                            <Col style={{ maxWidth: '80px' }}>
                                                <label className="form-check-label">
                                                    <IntlMessage
                                                        Store={props.Store}
                                                        messageId="components.function.contentswitch.table"
                                                    />
                                                </label>
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: '80px',
                                                    padding: '0px',
                                                    marginRight: '2em'
                                                }}
                                            >
                                                <input
                                                    className="form-check-input float-end"
                                                    type="checkbox"
                                                    id="flexSwitchCheckChecked"
                                                    onClick={() =>
                                                        props.setMode('cart')
                                                    }
                                                />
                                            </Col>
                                            <Col style={{ maxWidth: '80px' }}>
                                                <label className="form-check-label">
                                                    <IntlMessage
                                                        Store={props.Store}
                                                        messageId="components.function.contentswitch.grid"
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={'site'}>
                            <PielersTable Configuration={props.Configuration} />
                            <PaginationJSX
                                response={props.response}
                                history={history}
                                to={props.to}
                                handleSuccess={handleSucces}
                                destination={props.destination}
                                nowWindow={props.nowWindow}
                            />
                        </div>
                        <Spinner
                            className={'spinner'}
                            animation="border"
                            role="status"
                            style={{ display: 'none' }}
                        >
                            <span className="visually-hidden">
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.loading"
                                />
                            </span>
                        </Spinner>
                        <GetModal
                            modalShow={props.modalShow}
                            setModalShow={props.setModalShow}
                            modalData={props.modalData}
                            Store={props.Store}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.loading"
                                />
                            </span>
                        </Spinner>
                    </>
                )
            }
        }
    } else {
        console.log(props.response.length)
        if (props.response.length > 0) {
            if (props.mode === 'cart') {
                return (
                    <>
                        <Searchform
                            _handleKeyDown={_handleKeyDown}
                            _handleKeyFilter={_handleKeyFilter}
                            history={history}
                            pagenew={props.pagenew}
                            destinationnew={props.destinationnew}
                            tonew={props.tonew}
                            text={props.text}
                            filter={props.filter}
                            Store={props.Store}
                            SearchParam={props.SearchParam}
                        />
                        <Row>
                            <Col>
                                <div
                                    className="form-check form-switch "
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        paddingLeft: '0px'
                                    }}
                                >
                                    <div className={'float-start'}>
                                        <Row>
                                            <Col style={{ maxWidth: '80px' }}>
                                                <label className="form-check-label">
                                                    <IntlMessage
                                                        Store={props.Store}
                                                        messageId="components.function.contentswitch.table"
                                                    />
                                                </label>
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: '80px',
                                                    padding: '0px',
                                                    marginRight: '2em'
                                                }}
                                            >
                                                <input
                                                    className="form-check-input ml-0"
                                                    type="checkbox"
                                                    id="flexSwitchCheckChecked"
                                                    defaultChecked
                                                    onClick={() =>
                                                        props.setMode('table')
                                                    }
                                                />
                                            </Col>
                                            <Col style={{ maxWidth: '60px' }}>
                                                <label className="form-check-label">
                                                    <IntlMessage
                                                        Store={props.Store}
                                                        messageId="components.function.contentswitch.grid"
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={'site'}>
                            <Row xl={4} md={3} sm={2}>
                                <CardsJSX
                                    destination={props.destination}
                                    data={props.CardsConfiguration}
                                    setModalShow={props.setModalShow}
                                    setModalData={props.setModalData}
                                    allTaxon={props.allTaxon}
                                />
                            </Row>
                            <PaginationJSX
                                response={props.response}
                                history={history}
                                to={props.to}
                                handleSuccess={handleSucces}
                                destination={props.destination}
                                nowWindow={props.nowWindow}
                            />
                        </div>
                        <Spinner
                            className={'spinner'}
                            animation="border"
                            role="status"
                            style={{ display: 'none' }}
                        >
                            <span className="visually-hidden">
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.loading"
                                />
                            </span>
                        </Spinner>
                        <GetModal
                            modalShow={props.modalShow}
                            setModalShow={props.setModalShow}
                            modalData={props.modalData}
                            Store={props.Store}
                        />
                    </>
                )
            } else if (props.mode === 'table') {
                return (
                    <>
                        <Searchform
                            _handleKeyDown={_handleKeyDown}
                            _handleKeyFilter={_handleKeyFilter}
                            history={history}
                            pagenew={props.pagenew}
                            destinationnew={props.destinationnew}
                            tonew={props.tonew}
                            text={props.text}
                            filter={props.filter}
                            Store={props.Store}
                            SearchParam={props.SearchParam}
                        />
                        <Row>
                            <Col>
                                <div
                                    className="form-check form-switch "
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        paddingLeft: '0px'
                                    }}
                                >
                                    <div className={'float-start'}>
                                        <Row>
                                            <Col style={{ maxWidth: '80px' }}>
                                                <label className="form-check-label">
                                                    <IntlMessage
                                                        Store={props.Store}
                                                        messageId="components.function.contentswitch.table"
                                                    />
                                                </label>
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: '80px',
                                                    padding: '0px',
                                                    marginRight: '2em'
                                                }}
                                            >
                                                <input
                                                    className="form-check-input float-end"
                                                    type="checkbox"
                                                    id="flexSwitchCheckChecked"
                                                    onClick={() =>
                                                        props.setMode('cart')
                                                    }
                                                />
                                            </Col>
                                            <Col style={{ maxWidth: '80px' }}>
                                                <label className="form-check-label">
                                                    <IntlMessage
                                                        Store={props.Store}
                                                        messageId="components.function.contentswitch.grid"
                                                    />
                                                </label>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={'site'}>
                            <PielersTable Configuration={props.Configuration} />
                            <PaginationJSX
                                response={props.response}
                                history={history}
                                to={props.to}
                                handleSuccess={handleSucces}
                                destination={props.destination}
                                nowWindow={props.nowWindow}
                            />
                        </div>
                        <Spinner
                            className={'spinner'}
                            animation="border"
                            role="status"
                            style={{ display: 'none' }}
                        >
                            <span className="visually-hidden">
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.loading"
                                />
                            </span>
                        </Spinner>
                        <GetModal
                            modalShow={props.modalShow}
                            setModalShow={props.setModalShow}
                            modalData={props.modalData}
                            Store={props.Store}
                        />
                    </>
                )
            } else {
                return (
                    <>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.loading"
                                />
                            </span>
                        </Spinner>
                    </>
                )
            }
        }
    }
    return <></>
}
export default ContentSwitch
