import { Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'

const Places = (props: { Store: any }) => {
    return (
        <>
            <Row>
                <Col>
                    <CreateMatomoIframe
                        headline={'Eindeutige Besucher'}
                        width={'100%'}
                        height={'550'}
                        url={WidgetURLMapping({ name: 'Unique Visitors' })}
                        scrolling={'yes'}
                        id={'place1'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Kontinent'}
                        width={'100%'}
                        height={'200'}
                        url={WidgetURLMapping({ name: 'continent' })}
                        scrolling={'yes'}
                        id={'place2'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Unterschiedliche Länder'}
                        width={'100%'}
                        height={'200'}
                        url={WidgetURLMapping({ name: 'different countries' })}
                        scrolling={'yes'}
                        id={'place3'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Browsersprache'}
                        width={'100%'}
                        height={'200'}
                        url={WidgetURLMapping({ name: 'browser language' })}
                        scrolling={'yes'}
                        id={'place4'}
                        Store={props.Store}
                    />
                </Col>
                <Col>
                    <CreateMatomoIframe
                        headline={'Land'}
                        width={'100%'}
                        height={'200'}
                        url={WidgetURLMapping({ name: 'country' })}
                        scrolling={'yes'}
                        id={'place5'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Region'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({ name: 'region' })}
                        scrolling={'yes'}
                        id={'place6'}
                        Store={props.Store}
                    />
                    <CreateMatomoIframe
                        headline={'Stadt'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({ name: 'city' })}
                        scrolling={'yes'}
                        id={'place7'}
                        Store={props.Store}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Places
