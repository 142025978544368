/**
 * Config für den Suneditor
 * @constructor
 */
const SunEditorConfig = () => {
    return [
        // Default
        ['undo', 'redo'],
        ['formatBlock'],
        ['bold', 'underline', 'italic'],
        ['blockquote'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['link'],
        ['fullScreen', 'showBlocks', 'codeView']
    ]
}

export default SunEditorConfig
