import {JSXElementConstructor, ReactElement, ReactNode, ReactPortal} from "react";
import InfoButtonWrapper from "./Elements/InfoButtonWraper";
import {OverlayTrigger, Tooltip} from "react-bootstrap";


/**
 * Create an Infobuttom with Tooltyp <br/><br/>
 *  <strong>Code Example:</strong><br/>
 * ```tsx
 
	<InfoButton.Wrapper>
		<Wk24Form.Label 
			text={IntlMessage({locale:'de', messageId:'count_coworker.label', Store:Store})} 
		/>
		<InfoButton
			text={IntlMessage({locale:'de', messageId:'count_coworker.infoLabel', Store:Store})}
			icon={'help'}
		/>
	</InfoButton.Wrapper>
 * ```
 *Props
 *<table>
 * <tr>
 *   <th>Property</th>
 *   <th>Type</th>
 *   <th>Description</th>
 * </tr>
 * <tr>
 *   <td>text</td>
 *   <td>string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortale</td>
 *   <td>Info or Helptext</td>
 * </tr>
 * <tr>
 *   <td>icon</td>
 *   <td>string </td>
 *   <td>Icon name of Material Design Icons </td>
 * </tr>
 *</table>
 *
 *
 * @param props
 * @version 0.0.1
 * @author [Tilo Hübschmann](https://weko24.de)
 * @constructor
 */

	export interface InfoButtonProps {
		text:string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal,
		icon?:string
	}
	
	const InfoButton = (props:InfoButtonProps) => {
		
		return <OverlayTrigger 
		delay={{hide:450, show:200}} 
		overlay={(ttpprops) => ( 
		  <Tooltip {...ttpprops}  id="button-tooltip1"> 
			  {props.text}
		  </Tooltip> 
		)} 
		placement="bottom"
	  >
		<span className="material-icons pl-1" >{props.icon !== undefined ? props.icon :  'info'}</span>
	  </OverlayTrigger>
	}
	InfoButton.Wrapper = InfoButtonWrapper;	



export default InfoButton;
