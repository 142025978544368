import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ContentSwitch from '../../../../@WUM/core/component/const/ContentSwitch'
import { saveDataIndividualGet } from '../../../../@WUM/core/RestFullApi/ApiHook'
import { error } from '../../../../@WUM/core/component/const/error.services'
import { removeUseLocalStorage } from '../../../../@WUM/core/component/const/useLocalStoreage'
import { SpinnerJSX } from '../../../../@WUM/core/component/const/Spinner'
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import {
    ResponseCalender,
    ResponseCalenderDelete
} from '../API/response/calender/calender'
import { useSelector } from 'react-redux'
import SearchJSX from '../../../../@WUM/core/component/const/SearchJSX'

const PageOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const { page, deleteID } = useParams<{ page: string; deleteID: string }>()
    removeUseLocalStorage('seo_title')
    removeUseLocalStorage('seo_description')

    const history = useNavigate()
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths: string) => {
        let path = paths
        history(path)
    }
    let limit: any
    if (deleteID) {
        ResponseCalenderDelete(deleteID)
        routeChange('/menberDecission/overview/' + page)
    }
    if (typeof page === 'string') {
        limit = parseFloat(page) * 10 - 10
    }
    const destination = 'menberDecission'
    const namespace_search = 'events_searchValue'
    const [searchValue, setSearchValue] = useState(
        localStorage.getItem(namespace_search) !== null
            ? localStorage.getItem(namespace_search)
            : ''
    )
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponseCalender(limit, searchValue)
    const [modalShow, setModalShow] = React.useState(false)
    const [modalData, setModalData] = React.useState([])
    const [mode, setMode] = useState('cart')
    const [parent_id, setparent_id] = useState(false)
    const row: any = []
    if (parent_id === false) {
        localStorage.removeItem('fetchSelect')
        const getPagesSelect = () => {
            saveDataIndividualGet('/Content/Pages/Select/0/')
                .then((data: any) => {
                    row.push(data)
                    localStorage.setItem('fetchSelect', JSON.stringify(row))
                })
                .catch((e) => {
                    error(e)
                })
        }
        getPagesSelect()
        setparent_id(true)
    }
    const debug = (searchs: any) => {}

    debug(search)
    const SearchConfig = [
        {
            namespace: namespace_search,
            destination: destination,
            noNewButton: true,
            input: [
                {
                    id: 'search_name',
                    translation: 'common.search_for_title',
                    param: 'name',
                    method: 'like'
                }
            ],
            button: [
                'common.search',
                'common.del.search',
                'Neue News erstellen'
            ]
        }
    ]

    const CardsConfiguration = {
        data: {
            ressource: response,
            status: 'state',
            statusActive: (
                <IntlMessage messageId="common.activated" Store={Store} />
            ),
            statusDeactive: (
                <IntlMessage messageId="common.deactivated" Store={Store} />
            ),
            link: '',
            prelink: 'http://myhomice-f2.pielers.de/produkte/',
            title: 'name',
            descriptionShow: false,
            description: 'shortDescription',
            parentShow: false,
            parent: 'parent.translations.title',
            router: 'id',
            preroute: '/menberDecission/Detail/',
            edit: 'Mitgliederbeschluss ',
            delete: false,
            Dontvisited: true,
            deleteLink: '/menberDecission/Delete/' + page + '/[ID]'
        }
    }

    const Configuration = {
        tableBody: {
            data: response,
            dataID: 'id',
            underdata: true,
            keys: ['state', 'name'],
            type: ['string', 'string']
        },
        tableHeader: ['status', 'Name'],
        tableHeaderWidth: ['20%', '80%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [<IntlMessage messageId="common.edit" Store={Store} />],
            menuroute: ['/menberDecission/Detail'],
            menuParams: ['', 'id', 'id'],
            menuIcons: ['pencil']
        }
    }
    if (response.length > 0) {
        return (
            <>
                <SearchJSX
                    noNewButton={true}
                    setSearchValue={setSearchValue}
                    Store={Store}
                    SearchConfig={SearchConfig}
                />
                {
                    <ContentSwitch
                        pagenew={'new'}
                        destinationnew={'Calender'}
                        tonew={'new'}
                        text={
                            <IntlMessage
                                Store={Store}
                                locale={'de'}
                                messageId={'omment.event.new'}
                            />
                        }
                        response={response}
                        CardsConfiguration={CardsConfiguration}
                        mode={mode}
                        Configuration={Configuration}
                        setMode={setMode}
                        destination={destination}
                        page={page}
                        to={'overview'}
                        setSearchValues={setSearchValue}
                        setSearchs={setSearch}
                        search={false}
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        modalData={modalData}
                        setModalData={setModalData}
                        SearchParam={'?translation.title'}
                        searchfield={false}
                        allTaxon={[]}
                        _handleKeyFilters={[]}
                        _handleEnter={[]}
                        filter={true}
                        stop={false}
                        Store={Store}
                    />
                }
            </>
        )
    } else {
        localStorage.setItem('searchEnterInput', '')
        return (
            <>
                <SpinnerJSX messageId={'common.loading'} Store={Store} />
            </>
        )
    }
}

export default PageOverview
