export interface config {
    titel: string
    datum?: any
    name:any,
    content: string
    mediaId: string
    visible?: any
    topics?:[]

}

export function output(Data: any) {
    let back = {
        titel: Data.titel,
        datum: Data.datum,
        name: Data.name,
        content: Data.content,
        mediaId: Data.mediaId,
        visible: Data.visible,
        topics: Data.topics
    }

    return back
}
