import React from 'react'

const MenuIcon = (props: { icon: any,newIcon?:boolean,variant?: '' | '-outlined' | '-rounded' | '-sharp' | undefined }) => {
    if (props.icon === undefined || props.icon === '') {
        return <></>
    } else {
        if(props.newIcon === true) {
            return (
                <span className={"material-symbols" + (props.variant !== undefined ? props.variant : '-outlined')} aria-hidden="true">
                {props.icon}
            </span>
            )
        } else {
            return (
                <span className={"material-icons" + (props.variant !== undefined ? props.variant : '-outlined')} aria-hidden="true">
                {props.icon}
            </span>
            )
        }

    }
}

export default MenuIcon
