import React from 'react'
import renderSwitch from './register'
function elements(
    data: any,
    modalShow: boolean = false,
    setModalShow: any = false,
    modalData: any = false,
    setModalData: any = false,
    newElement: any = false,
    newelementIndex: any = 0,
    setdeleteElement: any = false,
    setElements: any = [],
    Store: any,
    galerie?: any,
    galerieItems?: any
) {
    if (data !== undefined && data.hasOwnProperty('elements') === true) {
        if (data.elements.length > 0) {
            const row = []
            for (let i = 0; i < data.elements.length; i++) {
                row.push(
                    renderSwitch(
                        data,
                        i,
                        newElement,
                        newelementIndex,
                        modalShow,
                        setModalShow,
                        modalData,
                        setModalData,
                        setdeleteElement,
                        setElements,
                        Store,
                        galerie,
                        galerieItems
                    )
                )
            }
            return <>{row}</>
        } else {
            return
        }
    } else {
        return
    }
}

export default elements
