import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'
import { Row } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'

const InternalSearch = () => {
    return (
        <>
            <Row>
                <Col>
                    <CreateMatomoIframe
                        headline={'Suchbegriffe (interne Suche)'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({ name: 'internal search' })}
                        scrolling={'yes'}
                        id={'is1'}
                    />
                    <CreateMatomoIframe
                        headline={'Suchbegriffe ohne Ergebnisse'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({
                            name: 'Search terms with no results'
                        })}
                        scrolling={'yes'}
                        id={'is2'}
                    />
                </Col>
                <Col>
                    <CreateMatomoIframe
                        headline={'Besuchte Seiten nach einer internen Suche'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({
                            name: 'Pages visited after an internal search'
                        })}
                        scrolling={'yes'}
                        id={'is3'}
                    />
                    <CreateMatomoIframe
                        headline={'Suchkategorien'}
                        width={'100%'}
                        height={'300'}
                        url={WidgetURLMapping({ name: 'search categories' })}
                        scrolling={'yes'}
                        id={'is4'}
                    />
                </Col>
            </Row>
        </>
    )
}

export default InternalSearch
