import React from 'react'
import { LanguageSwitcherValue } from '../../../../@WUM/core/component/core/components/LanguageSwitcher'
import Pages from './Pages/Pages'
import PageNew from './Pages/new'
import PagesDetail from './Pages/PagesDetail'
import PagesDeleted from './Pages/deleted'

export const PagesConfig = (value: any) => {
    return [
        {
            path: '/Pages/overview/:page',
            elements: <Pages value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        },
        {
            path: '/Pages/new/new',
            elements: <PageNew value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        },

        {
            path: '/Pages/new/:page',
            elements: <PageNew value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        },
        {
            path: '/Pages/Detail/:id',
            elements: <PagesDetail value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        },
        {
            path: '/Pages/Delete/:page/:deleteID',
            elements: <PagesDeleted value={value} />,
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        }
    ]
}

export const PagesMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('cms.page.header'),
            route: '/Pages/overview/1',
            icon: 'account_tree',
            roles: ['superadmin', 'admin', 'contentcreator', 'operator']
        }
    ]
}
