import React from 'react'
import PageOverview from './Pages/Pageoverview'
import PageDetail from './Pages/PageDetail'
import PageNew from './Pages/new'
import CalenderDeleted from './Pages/deleted'

export const DynamikInternalNewsPagesConfig = (value: any) => {
    return [
        {
            path: '/InternalNews/overview/:page',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            elements: <PageOverview value={value} />
        },
        {
            path: '/InternalNews/Detail/:id',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            elements: <PageDetail value={value} />
        },
        {
            roles: ['superadmin', 'admin', 'ContentCreator'],
            path: '/InternalNews/Delete/:page/:deleteID',
            elements: <CalenderDeleted value={value} />
        },
        {
            path: '/InternalNews/new/:page',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            elements: <PageNew value={value} />
        }
    ]
}
export const DynamikInternalNewsMenuConfig = (value: any) => {
    return [
        {
            header: 'Interne News',
            icon: 'event',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            menuitem: [
                {
                    name: 'News',
                    route: '/InternalNews/overview/1',
                    icon: 'newspaper',
                    roles: ['superadmin', 'admin', 'ContentCreator']
                }
            ]
        }
    ]
}

export const DynamikInternalNewsMenuSingleConfig = (value: any) => {
    return [
        {
            name: 'Interne News',
            route: '/InternalNews/overview/1',
            icon: 'newspaper',
            roles: ['superadmin', 'admin']
        }
    ]
}

export const DynamikInternalNewsDasboard = () => [
    {
        title: 'Interne News',
        href: '/InternalNews/overview/1',
        undertitle: 'Interne News',
        icon: 'newspaper',
        text: 'Interne News',
        roles: ['superadmin', 'admin']
    }
]
