import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import './../css/timetracking.css'
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
    DataListSingle,
    DataListSingleTime,
    DataListSingleWithOnchange
} from "../../../../../@WUM/core/component/const/DataList";
import {useSelector} from "react-redux";
import {
    ResponseAllProject,
    ResponseAllProjectActivityREcords,
    ResponseAllTask,
    ResponseAllTaskInternall
} from "../../../API/response/ticket";
import {ResponseAllTimes} from "../../../API/response/activity_recording";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import setNavi from "../../../../../@WUM/core/component/const/setNavi";

const ActivityRecordingOverview = (value:any) =>{
    setNavi({
        route:'/activityrecording/overview/'
    })
    const [fetchData, setFetchData] = useState(0);
    const [fetchPid, setFetchPid] = useState('4');
    const Store = useSelector((state: any) => state)
    const  responseAll = ResponseAllProjectActivityREcords(fetchData)
    const heute = new Date();
    let day = heute.getDate().toString().padStart(2, '0');
    let month = (heute.getMonth()+1).toString().padStart(2, '0');
    let year = heute.getFullYear().toString().padStart(2, '0');
    let DBdate = year+'-'+month+'-'+day; // 2022
    const Times = ResponseAllTimes(DBdate,localStorage.getItem('first_name')+'_'+localStorage.getItem('last_name'),fetchData);


    const calculateTime = (d1:any) => {
        const startTime = new Date('2012/10/09 '+d1).getTime();
        // Differenz berechnen
        var diff = d1;
        // Werte berechnen
        var tag = Math.floor(diff / (1000*60*60*24));
        diff = diff % (1000*60*60*24);
        var std = Math.floor(diff / (1000*60*60));
        diff = diff % (1000*60*60);
        var min = Math.floor(diff / (1000*60));
        diff = diff % (1000*60);
        var sec = Math.floor(diff / 1000);

        return std.toString().padStart(2, '0')+":"+min.toString().padStart(2, '0')  + ":" + sec.toString().padStart(2, '0');

    };


    let TimesHold = [];
    let Worktime:number = 0;
    if (typeof Times !== "boolean" && Times?.length > 0) {
        for (let t = 0; t < Times.length; t++) {
            Worktime = Worktime + parseInt(Times[t].dauer)
                TimesHold.push(
                    <>
                        <tr>
                            <td>{Times[t].id}</td>
                            <td>{Times[t].time_start}</td>
                            <td>{Times[t].time_end}</td>
                            <td>{calculateTime(Times[t].dauer)}</td>
                            <td dangerouslySetInnerHTML={{__html: Times[t].grund}} />
                        </tr>
                        </>)

        }
    }

    let response;
    if(fetchPid !== '') {
        response = ResponseAllTaskInternall(fetchPid.split('-')[0].replace(' ',''))
    } else {
        response = ResponseAllTaskInternall('1')
    }


    let ProjektHold = [];
    if (typeof responseAll !== "boolean" && responseAll?.length > 0) {
        for (let r = 0; r < responseAll.length; r++) {
            if (responseAll[r].is_main === "0") {
                ProjektHold.push(responseAll[r])
            }
        }
    }


    const setTask = (id:any) => {
        setFetchPid(id.split('-')[0].replaceAll(' ',''));
        setFetchData(Date.now())
    }

    const saveTime = (time_start:any,time_end:any,dauer:any,date:any,grund:any,task_id:any,project_id:any,id?:any) => {
        var raw;
        if(id !== undefined && id !== null) {
            raw = JSON.stringify({
                id: id,
                time_end: time_end,
                dauer: dauer
            })
        } else {
            raw = JSON.stringify({
                user: localStorage.getItem('first_name')+' '+localStorage.getItem('last_name'),
                time_start: time_start,
                time_end: time_end,
                dauer: dauer,
                date: date,
                grund: grund,
                task_id: task_id,
                project_id: project_id
            })
        }

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer ' +
                    JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw
        }

        fetch(ApiRoot()+'save/activity_recording/yoptimize/', requestOptions)
            .then((response) => response.json())
            .then((result) => successFormswimelane(result))
            .catch((error) => errorFormswimelane(error))
    }

    const successFormswimelane = (result:any) => {
        setFetchData(Date.now())
        let setID = document.getElementById('timeid') as HTMLInputElement
        if(setID !== null) {
            setID.value = result.dateID
        }
    }

    const errorFormswimelane = (error:any)=> {
        console.log(error)
    }

    const tick = () => {
        let nowtime = document.getElementById('nowtime') as HTMLHeadElement;
        if( nowtime !== null && nowtime !== undefined) {
            nowtime.innerHTML = new Date().toLocaleTimeString()
        }


    }

    const calculateTimeDifferenceinMili = (d1:any, d2:any) => {
        const startTime = new Date('2012/10/09 '+d1).getTime();
        const endTime = new Date('2012/10/09 '+d2).getTime();
        // Differenz berechnen
        var diff = endTime - startTime;

        return diff;

    };

    const calculateTimeDifference = (d1:any, d2:any) => {
        const startTime = new Date('2012/10/09 '+d1).getTime();
        const endTime = new Date('2012/10/09 '+d2).getTime();
        // Differenz berechnen
        var diff = endTime - startTime;
        // Werte berechnen
        var tag = Math.floor(diff / (1000*60*60*24));
        diff = diff % (1000*60*60*24);
        var std = Math.floor(diff / (1000*60*60));
        diff = diff % (1000*60*60);
        var min = Math.floor(diff / (1000*60));
        diff = diff % (1000*60);
        var sec = Math.floor(diff / 1000);

        return std.toString().padStart(2, '0')+":"+min.toString().padStart(2, '0')  + ":" + sec.toString().padStart(2, '0');

    };


    const addTime = (id:any) => {
        let timeregister = document.getElementById('timeregister') as HTMLButtonElement
        let registertime = document.getElementById('registertime') as HTMLButtonElement
        /*let breakeregister = document.getElementById('breakeregister') as HTMLButtonElement*/
        if(id === 'timeregister') {
            /*breakeregister.removeAttribute("disabled");*/
            registertime.classList.remove("d-none");
            timeregister.classList.add("d-none");
        } else {
            /*breakeregister.setAttribute("disabled", "")*/
            timeregister.classList.remove("d-none");
            registertime.classList.add("d-none");
        }

        if(id !== 'timeregister') {
            let tr =document.getElementsByClassName('TimeHolder')[0].childNodes[document.getElementsByClassName('TimeHolder')[0].childNodes.length - 1];


            let tdStart = tr.childNodes[1] as HTMLTableDataCellElement

            let tdgrund = tr.childNodes[4] as HTMLTableDataCellElement

            let setID = document.getElementById('timeid') as HTMLInputElement

            saveTime(tdStart.innerHTML,new Date().toLocaleTimeString(),calculateTimeDifferenceinMili(tdStart.innerHTML,new Date().toLocaleTimeString()),DBdate,tdgrund.innerHTML,'','',setID.value)
            setID.value = ''

        } else {

            let project = document.getElementById('project') as HTMLSelectElement
            let task = document.getElementById('task') as HTMLSelectElement
            let projectInject = ''
            let taskInject = ''
            let InjectDB = '';
            if(project.value !== null && project.value !== undefined && project.value !== '') {
                projectInject = project.value.split('-')[0].replace(' ','')
                InjectDB = InjectDB+ ''+project.value.split('-')[1].replace(' ','')
            }
            if(task.value !== null && task.value !== undefined && task.value !== '') {
                taskInject = task.value.split('-')[0].replace(' ','')+'-'+task.value.split('-')[1].replace(' ','')
                InjectDB = InjectDB+ ' '+task.value
            }
            saveTime(new Date().toLocaleTimeString(),'00:00:00','0',DBdate,InjectDB,taskInject,projectInject)
            project.value = ''
            task.value = ''
            /*let TimeHolder = (
                <>


                    <td>0</td>
                    <td>{new Date().toLocaleTimeString()}</td>
                    <td>00:00:00</td>
                    <td>00:00:00</td>
                    <td>{Inject}</td>

                </>
            )
            let temp = document.createElement('tr')
            ReactDOM.render(TimeHolder, temp)
            let ele = document.getElementById('TimeHolder') as HTMLDivElement
            if(ele !== null) {
                ele.append(temp)
                project.value = ''
                task.value = ''
            }
        */
        }



    }

    /*const addbreack = (id:any) => {
        let registertime = document.getElementById('registertime') as HTMLButtonElement
        let breakeregister = document.getElementById('breakeregister') as HTMLButtonElement
        let registerbreake = document.getElementById('registerbreake') as HTMLButtonElement
        if(id === 'breakeregister') {
            registertime.setAttribute("disabled", "")
            breakeregister.classList.add("d-none");
            registerbreake.classList.remove("d-none");
            breakeregister.removeAttribute("disabled");
        } else {
            registerbreake.setAttribute("disabled", "")
            registerbreake.classList.add("d-none");
            breakeregister.classList.remove("d-none");
            registertime.removeAttribute("disabled");
            breakeregister.removeAttribute("disabled");
        }
        let tr =document.getElementsByClassName('TimeHolder')[0].childNodes[document.getElementsByClassName('TimeHolder')[0].childNodes.length - 1];

        let tdData = tr.childNodes[2] as HTMLTableDataCellElement

        let tdStart = tr.childNodes[1] as HTMLTableDataCellElement

        let tdSumm = tr.childNodes[3] as HTMLTableDataCellElement

        tdData.innerHTML = new Date().toLocaleTimeString()

        /!* ts-ignore *!/
        tdSumm.innerHTML = calculateTimeDifference(tdStart.innerHTML,new Date().toLocaleTimeString()) as string
        if(id !== 'registerbreake') {
            let TimeHolder = (
                <>


                    <td>0</td>
                    <td>{new Date().toLocaleTimeString()}</td>
                    <td>00:00:00</td>
                    <td>00:00:00</td>

                    <td>Pause</td>

                </>
            )
            let temp = document.createElement('tr')
            ReactDOM.render(TimeHolder, temp)
            let ele = document.getElementById('TimeHolder') as HTMLDivElement
            if (ele !== null) {
                ele.append(temp)
            }
        } else {
            let TimeHolder = (
                <>


                    <td>0</td>
                    <td>{new Date().toLocaleTimeString()}</td>
                    <td>00:00:00</td>
                    <td>00:00:00</td>
                    <td>Arbeit</td>

                </>
            )
            let temp = document.createElement('tr')
            ReactDOM.render(TimeHolder, temp)
            let ele = document.getElementById('TimeHolder') as HTMLDivElement
            if(ele !== null) {
                ele.append(temp)
            }
        }
    }*/


    const dateNow = heute.toLocaleDateString("de-DE", { // you can use undefined as first argument
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })


    useEffect(() => {
       /* let breakeregister = document.getElementById('breakeregister') as HTMLButtonElement
        if(breakeregister !== null) {
            breakeregister.setAttribute("disabled", "")
        }*/
        if (typeof responseAll !== "boolean" && responseAll?.length > 0) {
            let tr = document.getElementsByClassName('TimeHolder')[0].childNodes[document.getElementsByClassName('TimeHolder')[0].childNodes.length - 1];

            if(tr !== null && tr !== undefined) {
                let tdid = tr.childNodes[0] as HTMLTableDataCellElement

                let tdend = tr.childNodes[2] as HTMLTableDataCellElement

                let tdsum = tr.childNodes[3] as HTMLTableDataCellElement

                if (tdend.innerHTML === '00:00:00' && tdsum.innerHTML === '00:00:00') {
                    let timeregister = document.getElementById('timeregister') as HTMLButtonElement
                    let registertime = document.getElementById('registertime') as HTMLButtonElement

                    /*breakeregister.removeAttribute("disabled");*/
                    registertime.classList.remove("d-none");
                    timeregister.classList.add("d-none");
                    let setID = document.getElementById('timeid') as HTMLInputElement
                    setID.value = tdid.innerHTML

                } else {
                    let timeregister = document.getElementById('timeregister') as HTMLButtonElement
                    let registertime = document.getElementById('registertime') as HTMLButtonElement

                    /*breakeregister.removeAttribute("disabled");*/
                    registertime.classList.add("d-none");
                    timeregister.classList.remove("d-none");
                    let setID = document.getElementById('timeid') as HTMLInputElement
                    setID.value = ''
                }
            }
        }
        setInterval(tick, 1000);
    })

    if (typeof responseAll !== "boolean" && responseAll?.length > 0) {
    return(
        <Row className={'m-3 p-3'}>
            <Col className={'col-md-8 col-xl-10'}>
                <h1 className="mb-5 nonprint">
                    Hallo {localStorage.getItem('first_name')} {localStorage.getItem('last_name')}</h1>
                <Button variant="outline-secondary" id="printButton" className="printbutton nonprint float-end m-3">Drucken</Button>
                <div className="shadow p-3 mb-5 bg-body rounded maincontent print">
                    <h3 className="nonprint">Heutige Arbeitszeit am {dateNow}</h3>
                    <h3 className="printable">Arbeitszeit {dateNow} </h3>
                    <Row className={'w-100'}>
                    <div className="container-fluid">
                        <Table striped borderless hover responsive>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Start</th>
                                <th>Ende</th>
                                <th>Dauer</th>
                                <th>Grund</th>
                            </tr>
                            </thead>
                            <tbody id={'TimeHolder'} className={'TimeHolder'}>
                            {TimesHold}
                            </tbody>
                        </Table>
                    </div>
                    </Row>
                </div>

            </Col>
            <Col className={'col-md-4 col-xl-2 print'}>
                <h3 id="nowtime" className="nonprint">{new Date().toLocaleTimeString()}</h3>
                <img src="/Timedefault.svg" className="profileimg mb-3 nonprint" />
                <div className="time_output">
                    <p><strong className="nonprint">Arbeitszeit:</strong>
                        <strong className="printable">Arbeitszeit gesamt: </strong>
                        {calculateTime(Worktime)} </p>
                    {/*<p><strong className="nonprint">Pausenzeit:</strong>
                        <strong className="printable">Pausenzeit gesamt: </strong>
                        00:00:00 </p>*/}
                </div>

                <div className="d-grid gap-2 my-3" >
                    <DataListSingleWithOnchange fetch={ProjektHold} value1={'id'} value2={'title'} response={''}
                                                idInput={'project'} name={'test'} id={'Project'} Store={Store} label={'Projekt'}
                                                Change={setTask}  />
                    <DataListSingleTime fetch={(response !== undefined && response !== null ? response : [])} value1={'task_id'} value2={'regard'} response={''} idInput={'task'} name={'test'} id={'Task'} Store={Store} label={'Aufgabe'}   />
                    <Form.Group className="mb-3" >
                        <Form.Control type="hidden" id={'timeid'} name={'id'}  />
                    </Form.Group>
                </div>

                <div className="d-grid gap-2 my-3" id="button_time">
                    <Button variant="outline-primary" id={'timeregister'} onClick={()=>addTime('timeregister')}>Anmelden</Button>
                    <Button variant="primary" id={'registertime'} onClick={()=>addTime('registertime')} className={'d-none'}>Abmelden</Button>
                </div>
                {/*<div className="d-grid gap-2 my-3" id="time_button">
                    <Button variant="outline-warning" id={'breakeregister'} onClick={()=>addbreack('breakeregister')}>Pause beginnen</Button>
                    <Button variant="warning" id={'registerbreake'} onClick={()=>addbreack('registerbreake')} className={'d-none'} >Pause beenden</Button>
                </div>*/}
                <div className="my-3" id="useronline" />

            </Col>
        </Row>
    )
} else {
    return (<></>)
        }
}

export default ActivityRecordingOverview;
