import { customizerApiType } from './customizerApi.interface'
import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { customizerApiDetailType } from './customizerApiDetail.interface'

/**
 * Sends the call to the FULL REST API with the methods GET, POST, PUT
 * @namespace example
 * @see {@link siteApiType}
 * @prop {any} limit Indicates the current page
 * @prop {any} searchValue Submits search filters
 * @prop {any} post Indicates POST
 * @prop {any} id Transmits the ID of the target element
 * @example
 * getPosts: (limit: any, searchValue: any): Promise<blogApiType[]> => requests.get(`Content/Blog/${limit}/${searchValue}`),
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const customizer = {
    //getPosts: (limit: any, searchValue: any): Promise<exampleApiType[]> => requests.get(`page/${limit}/${searchValue}`),
    getPosts: (limit: any, searchValue: any): Promise<customizerApiType[]> =>
        requests.get(`ewiv/invoices/all/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<customizerApiDetailType[]> =>
        requests.get(`ewiv/invoices/${id}/`),
    updatePost: (post: any, id: number): Promise<customizerApiDetailType> =>
        requests.post(`ewiv/invoices/${id}/`, JSON.stringify(post)),
}
