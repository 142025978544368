import React from 'react'
import TableCell from '@material-ui/core/TableCell'
function image(
    image: any,
    id: number,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any
) {
    return (
        <>
            <TableCell
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
                style={{ textAlign: 'center' }}
            >
                <img
                    alt="Remy Sharp"
                    src={image}
                    width={48}
                    height={48}
                    style={{ borderRadius: '50%' }}
                ></img>
            </TableCell>
        </>
    )
}

export default image
