import React from 'react'
import '../css/matomo.css'
import VisitorTabBuild from '../core/VisitorTabBuild'
import AcquisitionConfig from '../configuration/acquisition/AcquisitionConfig'
import SwitchSearch from '../core/SwitchSearch'
import { useSelector } from 'react-redux'

const Acquisition = (props: { value: any }) => {
    const [Period, setPeriod] = React.useState([localStorage.getItem('period')])
    const [Date, setDate] = React.useState([
        localStorage.getItem('sdate') + ',' + localStorage.getItem('edate')
    ])
    const Store = useSelector((state: any) => state)
    const debug = (Periods: any, Dates: any) => {}

    debug(Period, Date)
    return (
        <>
            <SwitchSearch
                setDate={setDate}
                setPeriod={setPeriod}
                Store={Store}
            />
            <VisitorTabBuild config={AcquisitionConfig()} />
        </>
    )
}

export default Acquisition
