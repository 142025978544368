import React, { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { init2 } from '../../../../@WUM/core/form-serilizer/seriliaze/Formserialize'
import { configType, outputType } from './configuration/mapping'
import PielersForm from '../../../../@WUM/core/form-serilizer'
import Spinner from 'react-bootstrap/Spinner'
import { notify_save } from '../../../../@WUM/core/component/const/notification.service'
import { error } from '../../../../@WUM/core/component/const/error.services'
import { handleSubmitForm } from '../../../../@WUM/core/component/const/handleSubmitForm'
import { btn_back } from '../../../../@WUM/core/component/const/btn_back'
import { Form } from 'react-bootstrap'
import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import { useSelector } from 'react-redux'
import {
    ResponseCustomTypeDetail,
    ResponseCustomTypesFormDetail
} from '../API/response/customTypes'
import { customTypeApiType } from '../API/request/customTypesAPI.interface.'
import { customTypes } from '../API/request/customTypes'
import configurationSingle from './configuration/Page/configurationSingle'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactDOM from 'react-dom'
import Accordion from 'react-bootstrap/Accordion'

const CustomTypeDetail = (value: any) => {
    const { id } = useParams<{ id: string }>()
    const response2 = ResponseCustomTypeDetail(id)
    const [index, setIndex] = useState(0)
    const FormField = ResponseCustomTypesFormDetail(id, '?mode=1', setIndex)
    const history = useNavigate()
    const Store = useSelector((state: any) => state)

    const [currentTutorial, setCurrentTutorial] = useState<customTypeApiType[]>(
        response2 !== true ? response2 : []
    )
    const [validated, setValidated] = useState(false)
    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     * @param form
     */
    const updateData = (status: boolean, id: any, form: any) => {
        /*
         *  Wichtig zum Rausholen der Datan aus Formular und senden
         *  Das Interface muss immer angepasst werden
         */
        let Data = init2(form)

        //console.log(Data)

        let reauestData: customTypeApiType[] & configType = Object.assign(
            {},
            currentTutorial,
            outputType(Data)
        )

        //console.log(reauestData)
        /*
         *  Senden der Erhaltenen Daten an das Frontend
         */
        customTypes
            .updateTypePost(reauestData, id)
            .then((response) => {
                setCurrentTutorial({ ...currentTutorial })
                notify_save(Store)
                setTimeout(() => window.location.reload(), 3000)
            })
            .catch((e) => {
                error(e)
            })
    }
    if (typeof response2 !== 'boolean' && response2?.length > 0) {
        const FormElements = (
            indexs: any,
            id: any,
            appendId: any,
            FormFields: any = false
        ) => {
            let fallback = <></>

            switch (indexs) {
                case 'suneditor':
                    fallback = (
                        <>
                            <Accordion.Item
                                eventKey={
                                    '' +
                                    (FormFields !== false
                                        ? FormFields.sort
                                        : index) +
                                    ''
                                }
                                id={
                                    indexs +
                                    '' +
                                    (FormFields !== false
                                        ? FormFields.sort
                                        : index)
                                }
                            >
                                <Accordion.Header
                                    onClick={() =>
                                        openAccordion(
                                            indexs +
                                                '' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index)
                                        )
                                    }
                                >
                                    Suneditor
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formType"
                                    >
                                        <Form.Control
                                            type="hidden"
                                            name={
                                                'form[' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index) +
                                                '][formType]'
                                            }
                                            value={
                                                FormFields !== false
                                                    ? FormFields.formType
                                                    : indexs
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="type"
                                    >
                                        <Form.Control
                                            type="hidden"
                                            name={
                                                'form[' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index) +
                                                '][type]'
                                            }
                                            value={
                                                FormFields !== false
                                                    ? FormFields.type
                                                    : 'suneditor'
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="typeID"
                                    >
                                        <Form.Control
                                            type="hidden"
                                            name={
                                                'form[' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index) +
                                                '][typeID]'
                                            }
                                            value={
                                                FormFields !== false
                                                    ? FormFields.TypeID
                                                    : id.id
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="id">
                                        <Form.Control
                                            type="hidden"
                                            name={
                                                'form[' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index) +
                                                '][id]'
                                            }
                                            value={
                                                FormFields !== false
                                                    ? FormFields.id
                                                    : '0'
                                            }
                                        />
                                    </Form.Group>
                                    {baseFields(index, FormFields)}
                                </Accordion.Body>
                            </Accordion.Item>
                        </>
                    )
                    break
                case 'input':
                case 'media':
                case 'inputGroup':
                    fallback = (
                        <>
                            <Accordion.Item
                                eventKey={
                                    '' +
                                    (FormFields !== false
                                        ? FormFields.sort
                                        : index) +
                                    ''
                                }
                                id={
                                    indexs +
                                    '' +
                                    (FormFields !== false
                                        ? FormFields.sort
                                        : index)
                                }
                            >
                                <Accordion.Header
                                    onClick={() =>
                                        openAccordion(
                                            indexs +
                                                '' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index)
                                        )
                                    }
                                >
                                    {indexs}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formType"
                                    >
                                        <Form.Control
                                            type="hidden"
                                            name={
                                                'form[' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index) +
                                                '][formType]'
                                            }
                                            value={
                                                FormFields !== false
                                                    ? FormFields.formType
                                                    : indexs
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="type"
                                    >
                                        <Form.Control
                                            type="hidden"
                                            name={
                                                'form[' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index) +
                                                '][type]'
                                            }
                                            value={
                                                FormFields !== false
                                                    ? FormFields.type
                                                    : 'text'
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="typeID"
                                    >
                                        <Form.Control
                                            type="hidden"
                                            name={
                                                'form[' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index) +
                                                '][typeID]'
                                            }
                                            value={
                                                FormFields !== false
                                                    ? FormFields.TypeID
                                                    : id.id
                                            }
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="id">
                                        <Form.Control
                                            type="hidden"
                                            name={
                                                'form[' +
                                                (FormFields !== false
                                                    ? FormFields.sort
                                                    : index) +
                                                '][id]'
                                            }
                                            value={
                                                FormFields !== false
                                                    ? FormFields.id
                                                    : '0'
                                            }
                                        />
                                    </Form.Group>
                                    {baseFields(index, FormFields)}
                                </Accordion.Body>
                            </Accordion.Item>
                        </>
                    )
                    break
            }

            if (FormFields === false) {
                let newElement = fallback
                let temp = document.createElement('span')
                ReactDOM.render(newElement, temp)
                let ele = document.getElementById(appendId)
                if (ele !== null) {
                    ele.appendChild(temp)
                    setIndex(index + 1)
                }
            } else {
                return fallback
            }
        }

        let elements = []
        if (typeof FormField !== 'boolean' && FormField?.length > 0) {
            for (let ff = 0; ff < FormField.length; ff++) {
                elements.push(
                    FormElements(
                        FormField[ff].formType,
                        response2[0],
                        'elements',
                        FormField[ff]
                    )
                )
            }
        }

        return (
            <div>
                <Form
                    id={'form001'}
                    noValidate
                    validated={validated}
                    onSubmit={(e) =>
                        handleSubmitForm(e, updateData, setValidated, id)
                    }
                >
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        key={'tabindex'}
                    >
                        <Tab
                            eventKey="home"
                            title={
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.general"
                                />
                            }
                            key={'AllgemeinForm'}
                            id={'AllgemeinForm'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            {PielersForm(
                                configurationSingle(
                                    'dataColOne',
                                    response2[0],
                                    false,
                                    Store
                                )
                            )}
                        </Tab>
                        <Tab
                            eventKey="form"
                            title={'form'}
                            key={'Formular'}
                            id={'Formular'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            <Row>
                                <Col
                                    className={
                                        'col-2 react-contextmenu-wrapper MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded mb-3'
                                    }
                                >
                                    {' '}
                                    <ListGroup>
                                        <ListGroup.Item
                                            onClick={() =>
                                                FormElements(
                                                    'input',
                                                    response2[0],
                                                    'elements'
                                                )
                                            }
                                        >
                                            input
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            onClick={() =>
                                                FormElements(
                                                    'media',
                                                    response2[0],
                                                    'elements'
                                                )
                                            }
                                        >
                                            media
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            onClick={() =>
                                                FormElements(
                                                    'inputGroup',
                                                    response2[0],
                                                    'elements'
                                                )
                                            }
                                        >
                                            inputGroup
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            onClick={() =>
                                                FormElements(
                                                    'suneditor',
                                                    response2[0],
                                                    'elements'
                                                )
                                            }
                                        >
                                            suneditor
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col
                                    className={
                                        'react-contextmenu-wrapper MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded mb-3'
                                    }
                                >
                                    <Accordion
                                        defaultActiveKey="0"
                                        id={'elements'}
                                    >
                                        {elements}
                                    </Accordion>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                    <div style={{ backgroundColor: 'white', padding: '15px' }}>
                        {btn_back(history, 'common.abort', '', Store)}
                        <Button
                            variant="success"
                            style={{ float: 'right', marginRight: '15px' }}
                            type={'submit'}
                        >
                            <IntlMessage
                                Store={Store}
                                messageId="common.save"
                            />
                        </Button>
                        <br style={{ clear: 'both' }} />
                    </div>
                </Form>
            </div>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </>
        )
    }
}

export default CustomTypeDetail

const openAccordion = (name: any) => {
    let Accordion = $('#elements').find('.accordion-item')

    for (let acc = 0; acc < Accordion.length; acc++) {
        if (name !== Accordion[acc].id) {
            let modalss = (
                (document.getElementById(Accordion[acc].id) as HTMLDivElement)
                    .childNodes[0] as HTMLDivElement
            ).childNodes[0] as HTMLButtonElement
            let modalc = (
                document.getElementById(Accordion[acc].id) as HTMLDivElement
            ).childNodes[1] as HTMLDivElement

            modalc.classList.remove('show')
            modalss.classList.add('collapsed')
        }
    }

    let modals = (
        (document.getElementById(name) as HTMLDivElement)
            .childNodes[0] as HTMLDivElement
    ).childNodes[0] as HTMLButtonElement
    let modal = (document.getElementById(name) as HTMLDivElement)
        .childNodes[1] as HTMLDivElement
    if (modal.className.includes('show')) {
        modal.classList.remove('show')
        modals.classList.add('collapsed')
    } else {
        modal.classList.add('show')
        modals.classList.remove('collapsed')
    }
}

const baseFields = (index: any, FormFields: any = false) => {
    return (
        <>
            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control
                            type="text"
                            name={
                                'form[' +
                                (FormFields !== false
                                    ? FormFields.sort
                                    : index) +
                                '][name]'
                            }
                            defaultValue={
                                FormFields !== false ? FormFields.name : ''
                            }
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="placeholder">
                        <Form.Label>Placeholder:</Form.Label>
                        <Form.Control
                            type="text"
                            name={
                                'form[' +
                                (FormFields !== false
                                    ? FormFields.sort
                                    : index) +
                                '][placeholder]'
                            }
                            defaultValue={
                                FormFields !== false
                                    ? FormFields.placeholder
                                    : ''
                            }
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="label">
                        <Form.Label>Label:</Form.Label>
                        <Form.Control
                            type="text"
                            name={
                                'form[' +
                                (FormFields !== false
                                    ? FormFields.sort
                                    : index) +
                                '][label]'
                            }
                            defaultValue={
                                FormFields !== false ? FormFields.label : ''
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Form.Group className="mb-3" controlId="preText">
                        <Form.Label>PreText:</Form.Label>
                        <Form.Control
                            type="text"
                            name={
                                'form[' +
                                (FormFields !== false
                                    ? FormFields.sort
                                    : index) +
                                '][preText]'
                            }
                            defaultValue={
                                FormFields !== false ? FormFields.preText : ''
                            }
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="afterText">
                        <Form.Label>AfterText:</Form.Label>
                        <Form.Control
                            type="text"
                            name={
                                'form[' +
                                (FormFields !== false
                                    ? FormFields.sort
                                    : index) +
                                '][afterText]'
                            }
                            defaultValue={
                                FormFields !== false ? FormFields.afterText : ''
                            }
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="required">
                        <Form.Label>Required:</Form.Label>
                        <Form.Control
                            type="text"
                            name={
                                'form[' +
                                (FormFields !== false
                                    ? FormFields.sort
                                    : index) +
                                '][required]'
                            }
                            defaultValue={
                                FormFields !== false ? FormFields.required : ''
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group className="mb-3" controlId="sort">
                <Form.Label>Position:</Form.Label>
                <Form.Control
                    type="text"
                    name={
                        'form[' +
                        (FormFields !== false ? FormFields.sort : index) +
                        '][sort]'
                    }
                    defaultValue={
                        FormFields !== false ? FormFields.sort : index
                    }
                />
            </Form.Group>
        </>
    )
}
