import { Translation } from '../../API/request/taxon/allTacxonApi.interface'

export interface config {
    position: string
    show_in_navigation: string
    parent_id: string
    tree_root: number
    tree_level: number
    code: string
    id: number
    translation: Translation
}

export function output(Data: any) {
    let back = {
        position: Data.position,
        show_in_navigation: Data.show_in_navigation,
        parent_id: Data.parent_id,
        tree_root: Data.tree_root,
        tree_level: 0,
        tree_left: 0,
        tree_right: 0,
        created_at: '2022-05-09',
        code: Data.code,
        id: parseInt(Data.id),
        translation: Data.translation
    }

    return back
}
