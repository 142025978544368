import TableCell from "@material-ui/core/TableCell";
import React from "react";

const PumpenwolfKdIcon = ( text: any,
                           id: any,
                           index: number,
                           VisitData: any,
                           VisitDataDel: any,
                           Store: any,
                           tableBodyPositionElement: any) => {

    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
                id={'td' + id + '_' + index}
            >
                {parseInt(text) === 1
                    ? <>
                        <span className="material-icons text-warning" >
                        report_problem
                        </span>
                    </>
                    : <>
                        <span className="material-icons">
                        people
                        </span>
                    </>}

            </TableCell>
        </>
    )

}

export default PumpenwolfKdIcon;
