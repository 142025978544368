import IntlMessage, {
    IntlMessageValue
} from '../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'
import SunEditorBig from '../../../../../@WUM/core/component/const/SunEditorBig'
import { SunEditorConfig } from '../../../../../@WUM/core/component/const/SunEditorConfig'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    const titel = (
        <>
            <h2>
                <IntlMessage
                    messageId="configuration.home.title"
                    Store={Store}
                />
            </h2>
        </>
    )

    const description = (
        <>
            <p>
                <IntlMessage
                    messageId="configuration.home.description"
                    Store={Store}
                />
            </p>
        </>
    )

    const dataColOne = [
        {
            formType: 'input',
            label: <IntlMessage messageId="common.title" Store={Store} />,
            required: true,
            type: 'text',
            name: 'name',
            value: newElement === false ? response.name : ''
        },
        {
            formType: 'LoopSunEditor',
            label: 'Tagesorndungspunkt',
            type: 'text',
            value: newElement === false ? response : '',
            valueKey: "topics",
            keyEditor: "decission",
            newElement: newElement

        },
        /*SunEditorBig(
            response,
            'decission',
            newElement,
            { lang: 'de', height: '350px;', config: SunEditorConfig() },
            'decission',
            'content',
            '',
            '',
            '',
            '',
            'classic',
            '200'
        ),*/

        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.visible" />,
            name: 'state',
            selected: newElement === false ? response.state : 0,
            options: [
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: 'accepted'
                },
                {
                    label: 'Wartend',
                    value: 'Draft'
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: 'cancel'
                }
            ]
        }
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'dataColOne') {
        return dataColOne
    } else if (conf === 'dataColtwo') {
        return dataColOne
    }
}

export default configuration
