import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ResponseCustomTypesDelete } from '../API/response/customTypes'
import { notify_save } from '../../../../@WUM/core/component/const/notification.service'

const RewriteDeleted = (value: any) => {
    const Store = useSelector((state: any) => state)
    const { deleteID } = useParams<{ page: string; deleteID: string }>()
    ResponseCustomTypesDelete(deleteID)
    let landingpages = '/rewrite/overview/1'
    notify_save(Store)
    setTimeout(() => (window.location.href = landingpages), 3000)
    return <></>
}
export default RewriteDeleted
