export const TabsHeadline = {
    eventkey: 'module_headline',
    key: 'module_headline',
    title: 'Module headline',
    content: [
        {
            label: 'module_headline.font-weight_module_content_headline',
            discr: 'font-weight_module_content_headline',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'module_headline.font-color_module_content_headline',
            discr: 'font-color_module_content_headline',
            size: [3, 3, 3],
            type: 'color'
        }
    ]
}
