import React from 'react'
import AppLocale from '../../../../templates/default/localization'
import { useSelector } from 'react-redux'

/**
 * @typedef LanguageSwitcherProps
 * @prop {string} messageId ID des Language Key
 * @prop {string} preperator Preperator Function name
 * @prop {string} param Param for Preperator Function
 * @prop {string} replace Replace value for Preperator
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
interface LanguageSwitcherProps {
    messageId: string
}

/**
 *
 * @param {LanguageSwitcherProps} props Übergabe Werte für Preperatoren, Language id
 * @constructor
 * @returns messageValue give part of now Locale messageValue back and before use any preperator for customize  as JSX Element
 * @author Marcel Ugurcu
 * @version 0.0.1
 * Example for Configuration
 * @example
 * <IntlMessage Store={Store} messageId={'blog.new.post'} />
 *
 */
const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props) => {
    const Store = useSelector((state: any) => state)

    /**
     * locale wird aus der localStorage geholt
     */
    let locale =
        Store.locale.locale !== '' && Store.locale.locale !== undefined
            ? Store.locale.locale
            : localStorage.getItem('locale')

    /**
     * Language Datei wird in languageFile gepackt
     */
    let languageFile
    // @ts-ignore
    if (locale === null) {
        return <></>
    }
    if (AppLocale[locale.toLowerCase()] !== undefined) {
        // @ts-ignore
        languageFile = AppLocale[locale.toLowerCase()]?.messages

        /**
         *  Falls vorhanden wird der Language string entnommen.
         */

        let messageValue = languageFile[props.messageId]

        /**
         *
         * Langvalue wurde nicht gefunden.
         * Es wird der übergebene Langkey zurück gegeben
         */
        if (messageValue === undefined) {
            messageValue = props.messageId
        }

        /**
         * Ruckgabe der Langvalue
         */
        return messageValue
    } else {
        return <></>
    }
}

export default LanguageSwitcher

export const LanguageSwitcherValue = (messageId: any) => {
    const Store = useSelector((state: any) => state)

    /**
     * locale wird aus der localStorage geholt
     */
    let locale =
        Store.locale.locale !== '' && Store.locale.locale !== undefined
            ? Store.locale.locale
            : localStorage.getItem('locale')

    /**
     * Language Datei wird in languageFile gepackt
     */
    let languageFile
    // @ts-ignore
    if (locale === null) {
        return <></>
    }
    if (AppLocale[locale.toLowerCase()] !== undefined) {
        // @ts-ignore
        languageFile = AppLocale[locale.toLowerCase()]?.messages

        /**
         *  Falls vorhanden wird der Language string entnommen.
         */

        let messageValue = languageFile[messageId]

        /**
         *
         * Langvalue wurde nicht gefunden.
         * Es wird der übergebene Langkey zurück gegeben
         */
        if (messageValue === undefined) {
            messageValue = messageId
        }

        /**
         * Ruckgabe der Langvalue
         */
        return messageValue
    } else {
        return <></>
    }
}
