import setValue from '../setter/setValue'

export const Pielersserialize = (map: any, form: any) => {
    let FormDatas = []
    let stringJSON: string = ''
    var inputs = form.elements

    stringJSON += '{'
    // Iterate over the form controlsc
    for (let i = 0; i < inputs.length; i++) {
        // Disable all form controls
        if (inputs[i].hasAttribute('name')) {
            for (let x = 0; x < map.length; x++) {
                if (inputs[i].getAttribute('name') === map[x]) {
                    stringJSON += setValue(map, x, inputs, i)
                }
            }
        }
    }

    stringJSON += '}'
    FormDatas.push(JSON.parse(stringJSON))

    return FormDatas[0]
}

export default Pielersserialize
