import { calenderApiType } from './calenderApi.interface'
import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { calenderApiDetail } from './calenderApiDetail.interface'

export const calender = {
    getPosts: (
        limit: string,
        searchValue: string
    ): Promise<calenderApiType[]> =>
        requests.get(`menberDecission/all/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<calenderApiDetail[]> =>
        requests.get(`/menberDecission/Single/${id}/`),
    createPost: (post: calenderApiType): Promise<calenderApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<calenderApiDetail> =>
        requests.put(`/menberDecission/save/${id}/`, post),
    updatePostNew: (post: any, id: number): Promise<calenderApiDetail> =>
        requests.put(`/menberDecission/new/${id}/`, post),
    deletePost: (id: string): Promise<void> =>
        requests.delete(`/menberDecission/deleted/${id}/`)
}
