import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import 'react-toastify/dist/ReactToastify.css'
import Form from 'react-bootstrap/Form'
import {
    notifyProvision,
    notifyvacation,
    notifyvisible,
    notifyenabled,
    notify,
    notifyapproved
} from '../../../../../../../@WUM/core/component/const/notification.service'
import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage'

const Header = (
    response: any,
    savevacation: any,
    setsavevacation: any,
    savevisible: boolean,
    setSavevisible: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    savestate: boolean,
    setSavestate: (value: ((prevState: boolean) => boolean) | boolean) => void,
    StateSave: any,
    enabled: any,
    stateValue: unknown,
    enabledState: unknown,
    saveProvision: (id: any, provision: any, notification: any) => void,
    approveState: any,
    saveApproved: (id: any, provision: any, notification: any) => void,
    Store: any
) => {
    // This function is triggered when the select changes
    const selectChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        if (response !== undefined && response.hasOwnProperty('merchant_id')) {
            if (value.length === 2) {
                saveProvision(response.merchant_id, value, notifyProvision)
            }
        }
    }

    if (response !== undefined && response.hasOwnProperty('merchant_id')) {
        let state
        if (stateValue === 'vacation' && savevacation === false) {
            state = (
                <>
                    <Col className={'text-center'}>
                        <div
                            onClick={() =>
                                StateSave(
                                    response.merchant_id,
                                    1,
                                    0,
                                    notifyvacation
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="currentColor"
                                className="bi bi-tsunami bi-tsunami-color"
                                viewBox="0 0 16 16"
                            >
                                <path d="M.036 12.314a.5.5 0 0 1 .65-.278l1.757.703a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.757-.703a.5.5 0 1 1 .372.928l-1.758.703a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.757-.703a.5.5 0 0 1-.278-.65zm0 2a.5.5 0 0 1 .65-.278l1.757.703a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.757-.703a.5.5 0 1 1 .372.928l-1.758.703a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.757-.703a.5.5 0 0 1-.278-.65zM2.662 8.08c-.456 1.063-.994 2.098-1.842 2.804a.5.5 0 0 1-.64-.768c.652-.544 1.114-1.384 1.564-2.43.14-.328.281-.68.427-1.044.302-.754.624-1.559 1.01-2.308C3.763 3.2 4.528 2.105 5.7 1.299 6.877.49 8.418 0 10.5 0c1.463 0 2.511.4 3.179 1.058.67.66.893 1.518.819 2.302-.074.771-.441 1.516-1.02 1.965a1.878 1.878 0 0 1-1.904.27c-.65.642-.907 1.679-.71 2.614C11.076 9.215 11.784 10 13 10h2.5a.5.5 0 0 1 0 1H13c-1.784 0-2.826-1.215-3.114-2.585-.232-1.1.005-2.373.758-3.284L10.5 5.06l-.777.388a.5.5 0 0 1-.447 0l-1-.5a.5.5 0 0 1 .447-.894l.777.388.776-.388a.5.5 0 0 1 .447 0l1 .5a.493.493 0 0 1 .034.018c.44.264.81.195 1.108-.036.328-.255.586-.729.637-1.27.05-.529-.1-1.076-.525-1.495-.426-.42-1.19-.77-2.477-.77-1.918 0-3.252.448-4.232 1.123C5.283 2.8 4.61 3.738 4.07 4.79c-.365.71-.655 1.433-.945 2.16-.15.376-.301.753-.463 1.13z" />
                            </svg>
                            <br />
                            <p>
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.vacation_mode"
                                />
                            </p>
                        </div>
                    </Col>
                    <Col className={'text-center'}>
                        <div
                            onClick={() =>
                                StateSave(
                                    response.merchant_id,
                                    2,
                                    1,
                                    notifyvisible
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="currentColor"
                                className="bi bi-eye bi-tsunami-color"
                                viewBox="0 0 16 16"
                            >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg>
                            <br />
                            <p>
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.visible"
                                />
                            </p>
                        </div>
                    </Col>
                </>
            )
        } else if (stateValue === 'visible' && savevisible === false) {
            state = (
                <>
                    <Col className={'text-center'}>
                        <div
                            onClick={() =>
                                StateSave(
                                    response.merchant_id,
                                    1,
                                    1,
                                    notifyvacation
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="currentColor"
                                className="bi bi-tsunami bi-tsunami-color-gray"
                                viewBox="0 0 16 16"
                            >
                                <path d="M.036 12.314a.5.5 0 0 1 .65-.278l1.757.703a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.757-.703a.5.5 0 1 1 .372.928l-1.758.703a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.757-.703a.5.5 0 0 1-.278-.65zm0 2a.5.5 0 0 1 .65-.278l1.757.703a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.757-.703a.5.5 0 1 1 .372.928l-1.758.703a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.757-.703a.5.5 0 0 1-.278-.65zM2.662 8.08c-.456 1.063-.994 2.098-1.842 2.804a.5.5 0 0 1-.64-.768c.652-.544 1.114-1.384 1.564-2.43.14-.328.281-.68.427-1.044.302-.754.624-1.559 1.01-2.308C3.763 3.2 4.528 2.105 5.7 1.299 6.877.49 8.418 0 10.5 0c1.463 0 2.511.4 3.179 1.058.67.66.893 1.518.819 2.302-.074.771-.441 1.516-1.02 1.965a1.878 1.878 0 0 1-1.904.27c-.65.642-.907 1.679-.71 2.614C11.076 9.215 11.784 10 13 10h2.5a.5.5 0 0 1 0 1H13c-1.784 0-2.826-1.215-3.114-2.585-.232-1.1.005-2.373.758-3.284L10.5 5.06l-.777.388a.5.5 0 0 1-.447 0l-1-.5a.5.5 0 0 1 .447-.894l.777.388.776-.388a.5.5 0 0 1 .447 0l1 .5a.493.493 0 0 1 .034.018c.44.264.81.195 1.108-.036.328-.255.586-.729.637-1.27.05-.529-.1-1.076-.525-1.495-.426-.42-1.19-.77-2.477-.77-1.918 0-3.252.448-4.232 1.123C5.283 2.8 4.61 3.738 4.07 4.79c-.365.71-.655 1.433-.945 2.16-.15.376-.301.753-.463 1.13z" />
                            </svg>
                            <br />
                            <p>
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.vacation_mode"
                                />
                            </p>
                        </div>
                    </Col>
                    <Col className={'text-center'}>
                        <div
                            onClick={() =>
                                StateSave(
                                    response.merchant_id,
                                    2,
                                    1,
                                    notifyvisible
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="currentColor"
                                className="bi bi-eye bi-tsunami-color"
                                viewBox="0 0 16 16"
                            >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg>
                            <br />
                            <p>
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.visible"
                                />
                            </p>
                        </div>
                    </Col>
                </>
            )
        } else if (stateValue === 'hidden' && savevisible === false) {
            state = (
                <>
                    <Col className={'text-center'}>
                        <div
                            onClick={() =>
                                StateSave(
                                    response.merchant_id,
                                    1,
                                    1,
                                    notifyvacation
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="currentColor"
                                className="bi bi-tsunami bi-tsunami-color-gray"
                                viewBox="0 0 16 16"
                            >
                                <path d="M.036 12.314a.5.5 0 0 1 .65-.278l1.757.703a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.757-.703a.5.5 0 1 1 .372.928l-1.758.703a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.757-.703a.5.5 0 0 1-.278-.65zm0 2a.5.5 0 0 1 .65-.278l1.757.703a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.014-.406a2.5 2.5 0 0 1 1.857 0l1.015.406a1.5 1.5 0 0 0 1.114 0l1.757-.703a.5.5 0 1 1 .372.928l-1.758.703a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.014-.406a1.5 1.5 0 0 0-1.114 0l-1.015.406a2.5 2.5 0 0 1-1.857 0l-1.757-.703a.5.5 0 0 1-.278-.65zM2.662 8.08c-.456 1.063-.994 2.098-1.842 2.804a.5.5 0 0 1-.64-.768c.652-.544 1.114-1.384 1.564-2.43.14-.328.281-.68.427-1.044.302-.754.624-1.559 1.01-2.308C3.763 3.2 4.528 2.105 5.7 1.299 6.877.49 8.418 0 10.5 0c1.463 0 2.511.4 3.179 1.058.67.66.893 1.518.819 2.302-.074.771-.441 1.516-1.02 1.965a1.878 1.878 0 0 1-1.904.27c-.65.642-.907 1.679-.71 2.614C11.076 9.215 11.784 10 13 10h2.5a.5.5 0 0 1 0 1H13c-1.784 0-2.826-1.215-3.114-2.585-.232-1.1.005-2.373.758-3.284L10.5 5.06l-.777.388a.5.5 0 0 1-.447 0l-1-.5a.5.5 0 0 1 .447-.894l.777.388.776-.388a.5.5 0 0 1 .447 0l1 .5a.493.493 0 0 1 .034.018c.44.264.81.195 1.108-.036.328-.255.586-.729.637-1.27.05-.529-.1-1.076-.525-1.495-.426-.42-1.19-.77-2.477-.77-1.918 0-3.252.448-4.232 1.123C5.283 2.8 4.61 3.738 4.07 4.79c-.365.71-.655 1.433-.945 2.16-.15.376-.301.753-.463 1.13z" />
                            </svg>
                            <br />
                            <p>
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.vacation_mode"
                                />
                            </p>
                        </div>
                    </Col>
                    <Col className={'text-center'}>
                        <div
                            onClick={() =>
                                StateSave(
                                    response.merchant_id,
                                    2,
                                    0,
                                    notifyvisible
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="currentColor"
                                className="bi bi-eye bi-tsunami-color-red"
                                viewBox="0 0 16 16"
                            >
                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                            </svg>
                            <br />
                            <p>
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.invisible"
                                />
                            </p>
                        </div>
                    </Col>
                </>
            )
        }

        let enable

        if (enabledState === 1) {
            enable = (
                <>
                    <Col className={'text-center'}>
                        <div
                            onClick={() =>
                                enabled(response.merchant_id, 0, notifyenabled)
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="currentColor"
                                className="bi bi-toggle-on bi-tsunami-color"
                                viewBox="0 0 16 16"
                            >
                                <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                            </svg>
                            <br />
                            <p>
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.activated"
                                />
                            </p>
                        </div>
                    </Col>
                </>
            )
        } else if (enabledState === 0) {
            enable = (
                <>
                    <Col className={'text-center'}>
                        <div
                            onClick={() =>
                                enabled(response.merchant_id, 1, notifyenabled)
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="currentColor"
                                className="bi bi-toggle-on bi-tsunami-color-red"
                                viewBox="0 0 16 16"
                            >
                                <path d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                            </svg>
                            <br />
                            <p>
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.deactivated"
                                />
                            </p>
                        </div>
                    </Col>
                </>
            )
        }
        let approved
        if (approveState === 'waiting') {
            approved = (
                <>
                    <Col xl={2} md={2} sm={2}>
                        <h5 className={'text-center'}>
                            <IntlMessage
                                Store={Store}
                                messageId="common.receivable"
                            />
                        </h5>
                        <Row>
                            <Col>
                                <span
                                    className={'float-start'}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        saveApproved(
                                            response.merchant_id,
                                            1,
                                            notifyapproved
                                        )
                                    }
                                >
                                    <span
                                        className="material-icons"
                                        style={{
                                            verticalAlign: '-25%',
                                            color: 'green'
                                        }}
                                    >
                                        check_circle
                                    </span>{' '}
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.approve"
                                    />
                                </span>
                            </Col>
                            <Col>
                                <span
                                    className={'float-end'}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        saveApproved(
                                            response.merchant_id,
                                            0,
                                            notifyapproved
                                        )
                                    }
                                >
                                    <span
                                        className="material-icons"
                                        style={{
                                            verticalAlign: '-25%',
                                            color: 'red'
                                        }}
                                    >
                                        block
                                    </span>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.decline"
                                    />
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </>
            )
        }

        if (
            savevacation === false &&
            savevisible === false &&
            savestate === false
        ) {
            return (
                <>
                    <Row className={'headerMC'}>
                        <Col xl={6} md={6} sm={6}>
                            <h4>
                                {response.merchant.company} (
                                <Form.Control
                                    className={'inputProvision'}
                                    name={'provision'}
                                    type={'text'}
                                    defaultValue={response.provision / 100}
                                    key={'formFileInputprovision'}
                                    onInput={selectChanges}
                                    maxLength={2}
                                />
                                %{' '}
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.provision"
                                />
                                )
                            </h4>
                            <h5>
                                <strong>
                                    {response.address.first_name}{' '}
                                    {response.address.last_name}:
                                </strong>{' '}
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.email"
                                />
                                :{' '}
                                <a href={'mailto:' + response.merchant.email}>
                                    senden
                                </a>{' '}
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.phone"
                                />
                                :{' '}
                                <a
                                    href={
                                        'mailto:' +
                                        response.address.phone_number
                                    }
                                >
                                    {response.address.phone_number}
                                </a>
                            </h5>
                        </Col>
                        {approved}
                        <Col className={'text-center'}>
                            <div
                                onClick={() =>
                                    copyToClipBoard(
                                        response.merchant.slug,
                                        notify
                                    )
                                }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="currentColor"
                                    className="bi bi-clipboard"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg>
                                <br />
                                <p>
                                    <IntlMessage
                                        Store={Store}
                                        messageId="common.link"
                                    />
                                </p>
                            </div>
                        </Col>
                        {state}
                        {enable}
                    </Row>
                </>
            )
        } else {
            return (
                <>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">
                            <IntlMessage
                                Store={Store}
                                messageId="common.loading"
                            />
                        </span>
                    </Spinner>
                </>
            )
        }
    }
}

export default Header

function copyToClipBoard(slug: any, notify: any) {
    let link
    if (process.env.REACT_APP_MODE === 'LIVE') {
        link = process.env.REACT_APP_PREVIEW_LINK_LIVE
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        link = process.env.REACT_APP_PREVIEW_LINK_DEVELOPMENT
    } else {
        link = process.env.REACT_APP_PREVIEW_LINK_LOCAL
    }
    var content = link + '/anbieter/' + slug + '/'

    if (content !== undefined) {
        navigator.clipboard
            ?.writeText(content)
            .then(() => {
                //console.log("Text copied to clipboard...")
                notify()
            })
            .catch((err) => {
                //console.log('Something went wrong', err);
            })
    } else {
        //console.log('content undefinded'+content)
    }
}
