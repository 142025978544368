import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'

const Home = () => {
    return (
        <>
            <CreateMatomoIframe
                headline={'Entwicklung über den Zeitraum'}
                width={'100%'}
                height={'600'}
                url={WidgetURLMapping({ name: 'development over the period' })}
                scrolling={'yes'}
                id={'th1'}
            />
        </>
    )
}

export default Home
