import React from 'react'
import TodolistOverview from "./Pages/overview";

export const DynamikTodolistPagesConfig = (value: any) => {
    return [
        {
            path: '/todolist/overview/:page',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            elements: <TodolistOverview value={value} />
        }
    ]
}
export const DynamikTodolistMenuConfig = (value: any) => {
    return [
        {
            header: 'Todo',
            icon: 'todo',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            menuitem: [
                {
                    name: 'Liste',
                    route: '/todolist/overview/1',
                    icon: 'list',
                    roles: ['superadmin', 'admin', 'ContentCreator']
                }
            ]
        }
    ]
}

export const DynamikTodolistMenuSingleConfig = (value: any) => {
    return [
        {
            name: 'Todo Liste',
            route: '/todolist/overview/1',
            icon: 'list',
            roles: ['superadmin', 'admin']
        }
    ]
}

export const DynamiktTodolistDasboard = () => [
    {
        title: 'Liste',
        href: '/todolist/overview/1',
        undertitle: 'Liste',
        icon: 'list',
        text: 'Liste',
        roles: ['superadmin', 'admin']
    }
]
