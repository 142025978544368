import {useEffect, useState} from "react";
import {ribbon} from "../request/ribbon";
import {error} from "../../../component/const/error.services";
import {authApiDetailType} from "../../../../templates/default/Auth/API/request/authApiDetailType";
import Setter from "../../../RestFullApi/local/Setter";

export const ResponseGetRibbon = (part: string,endoint:any,jsonFavo:any,setribbonexist?:any) => {
    const [posts, setPosts] = useState<authApiDetailType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await ribbon
                .getRibbons(endoint)
                .then((data) => {
                    localStorage.setItem('Favo',JSON.stringify(Setter(part,data)))
                    if(setribbonexist !== undefined){
                        setribbonexist(true)
                    }
                    return setPosts(Setter(part,data))
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [part,endoint,jsonFavo])

    return isError ? posts : posts
}
export const ResponsePutRibbon = (datas:any,id:any,endoint:any) => {
    const [posts, setPosts] = useState()
    const [isError, setIsError] = useState<boolean>(false)
    /*let Holder = [];

    for(let x=0;x<datas.favos.length;x++) {
        if(datas.favos[x] !== undefined) {
            Holder.push(datas.favos[x])
        }
    }

    datas.favos = Holder;*/
    useEffect(() => {
        ;(async () => {
            await ribbon
                .putRibbons(datas,id,endoint)
                .then((data) => {
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id,endoint])

    return isError ? posts : posts
}
