export const TabsM22 = {
    eventkey: 'm22',
    key: 'm22',
    title: 'Text with Image',
    content: [
        {
            label: 'm22.m22-padding',
            discr: 'm22-padding',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'm22.m22-p-font-size',
            discr: 'm22-p-font-size',
            size: [3, 3, 3],
            type: 'text'
        }
    ]
}
