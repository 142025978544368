import addNotification from "react-push-notification";


export const NotificationService = (notification:any) => {

    if (notification.length > 0) {

        for (let n = 0; n < notification.length; n++) {
            addNotification({
                title: notification[n].title,
                subtitle: notification[n].subtitle,
                message: notification[n].message,
                theme: 'darkblue',
                duration: 10000,
                native: true,
                silent: true
            });
        }
    }
}
