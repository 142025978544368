import { handleSubmitFormNew } from '../../const/handleSubmitForm'

import TabsBuilder from './TabsBuilder'
import FormFooter from './FormFooter'
import { Form } from 'react-bootstrap'
import React from 'react'
import FormCMSProps from '../interface/FormCMSProps.interface'

const FormCMS = (props: FormCMSProps) => {
    console.log(props.isTab)
    return (
        <>
            <Form
                id={'form001'}
                className={'formControl'}
                noValidate
                validated={props.validated}
                onSubmit={(e) =>
                    handleSubmitFormNew({
                        event: e,
                        updateData: props.updateData,
                        setValidated: props.setValidated,
                        id: props.id,
                        currentTutorial: props.currentTutorial,
                        setCurrentTutorial: props.setCurrentTutorial,
                        NowRoute: props.NowRoute,
                        part: props.part,
                        Store: props.Store,
                        request: props.request
                    })
                }
            >
                <TabsBuilder
                    config={props.config({
                        response2: props.response2[0],
                        element: props.element,
                        setElement: props.setElement,
                        modalShow: props.modalShow,
                        setModalShow: props.setModalShow,
                        modalData: props.modalData,
                        setModalData: props.setModalData,
                        setdeleteElement: props.setdeleteElement,
                        Index: props.Index,
                        setIndex: props.setIndex,
                        seo_title: props.seo_title,
                        setseo_title: props.setseo_title,
                        seo_description: props.seo_description,
                        setseo_description: props.setseo_description,
                        handleShow: props.handleShow,
                        galerie: props.galerie,
                        galerieItems: props.galerieItems
                    })}
                    isTab={props.isTab}
                />
                <FormFooter
                    history={props.history}
                    Store={props.Store}
                    response={props.response2[0]}
                    nonPreview={props.nonPreview}
                    back={props.back}
                    noneStyle={props.noneStyle}
                />
            </Form>
        </>
    )
}

export default FormCMS
