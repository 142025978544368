import IntlMessage, {
    IntlMessageValue
} from '../../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    let config = [
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    label: (
                        <IntlMessage Store={Store} messageId="common.name" />
                    ),
                    type: 'text',
                    required: true,
                    placeholder: IntlMessageValue(
                        'de',
                        'shipping.name.legend',
                        Store
                    ),
                    name: 'name',
                    value: newElement === false ? response.name : ''
                },
                {
                    formType: 'input',
                    label: (
                        <IntlMessage Store={Store} messageId="common.code" />
                    ),
                    type: 'text',
                    required: true,
                    placeholder: IntlMessageValue(
                        'de',
                        'shipping.code.legend',
                        Store
                    ),
                    name: 'code',
                    value: newElement === false ? response.code : ''
                }
            ]
        },
        {
            formType: 'textarea',
            label: (
                <IntlMessage
                    Store={Store}
                    messageId="common.short_description"
                />
            ),
            type: 'textarea',
            row: 3,
            name: 'description',
            value: newElement === false ? response.description : ''
        }
    ]

    return config
}

export default configuration
