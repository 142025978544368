/**
 * @typedef DataAction
 */
export enum DataActionType {
    DATA_INIT = 'DATA_INIT',
    DATA_SUCCESS = 'DATA_SUCCESS',
    DATA_FAIL = 'DATA_FAIL'
}

/**
 * @typedef ProductAction
 */

export enum ProductDataAction {
    PRODUCT_INIT = 'PRODUCT_INIT',
    PRODUCT_SUCCESS = 'PRODUCT_SUCCESS',
    PRODUCT_FAIL = 'PRODUCT_FAIL',
    PRODUCT_DATASHEET = 'PRODUCT_DATASHEET',
    PRODUCT_SHIPPING = 'PRODUCT_SHIPPING',
    PRODUCT_PRODUCT_TAX = 'PRODUCT_PRODUCT_TAX',
    PRODUCT_ALL_TAXON = 'PRODUCT_ALL_TAXON',
    PRODUCT_ALL_PRODUCT = 'PRODUCT_ALL_PRODUCT',
    PRODUCT_IMAGES = 'PRODUCT_IMAGES',
    PRODUCT_ARTICLE = 'PRODUCT_ARTICLE',
    PRODUCT_DIMENSIONS = 'PRODUCT_DIMENSIONS',
    PRODUCT_VARIANT = 'PRODUCT_VARIANT'
}

/**
 * @typedef PageActionType
 */
export enum PageActionType {
    PAGE_INIT = 'PAGE_INIT',
    PAGE_SUCCESS = 'PAGE_SUCCESS',
    PAGE_DELETE_SUCCESS = 'PAGE_DELETE_SUCCESS',
    PAGE_SINGLE_DATA = 'PAGE_SINGLE_DATA',
    PAGE_CLEAR_DATA = 'PAGE_CLEAR_DATA',
    PAGE_FAIL = 'PAGE_FAIL'
}
