import React from 'react'
import IntlMessage from '../../component/const/IntlMessage'
import TableCell from '@material-ui/core/TableCell'
function coupon(
    data: any,
    id: number,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any
) {
    let text = ''
    if (data !== 0 && data !== null) {
        text = 'common.yes'
    } else {
        text = 'common.no'
    }

    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
            >
                <IntlMessage Store={Store} messageId={text} />
            </TableCell>
        </>
    )
}

export default coupon
