import IntlMessage, {
    IntlMessageValue
} from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'
import SunEditorBig from '../../../../@WUM/core/component/const/SunEditorBig'
import { SunEditorConfig } from '../../../../@WUM/core/component/const/SunEditorConfig'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    let cutDate =
        newElement === false
            ? response.newsletter.date !== undefined && response.newsletter.date !== null
                ? response.newsletter.date.split(' ')[0]
                : ' '
            : ' '


    const titel = (
        <>
            <h2>
                <IntlMessage
                    messageId="configuration.home.title"
                    Store={Store}
                />
            </h2>
        </>
    )

    const description = (
        <>
            <p>
                <IntlMessage
                    messageId="configuration.home.description"
                    Store={Store}
                />
            </p>
        </>
    )

    const dataColOne = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'newsletter[state]',
            value: newElement === false ? response.newsletter.state : '1'
        },
        {
            formType: 'input',
            type: 'hidden',
            name: 'newsletter[id]',
            value: newElement === false ? response.newsletter.id : null
        },
        {
            formType: 'multiply',
            cols: [
        {
            formType: 'input',
            label: 'Name',
            required: true,
            type: 'text',
            placeholder: 'Bitte Newsletter Namen eingeben',
            name: 'newsletter[name]',
            value: newElement === false ? response.newsletter.name : ''
        },
                {
                    formType: 'input',
                    label: ('Newsletter Date'),
                    type: 'datetime-local',
                    name: 'newsletter[date]',
                    format: 'en',
                    nowFormat: 'en',
                    placeholder: 'xx.xx.xxxx',
                    value: cutDate
                },
            ]
        },
        SunEditorBig(
            response.newsletter,
            'content',
            newElement,
            { lang: 'de', height: '250px;', config: SunEditorConfig() },
            'newsletter[content]',
            'Inhalt',
            '',
            '',
            '',
            '',
            'classic',
            '300'
        )
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'dataColOne') {
        return dataColOne
    } else if (conf === 'dataColtwo') {
        return ''
    }
}

export default configuration
