import React from 'react'
import Form from 'react-bootstrap/Form'
import SendImage, { SendMultiMedia } from '../../component/const/SaveImage'
import IntlMessage from '../../component/const/IntlMessage'
import MediaImageUpload from '../../component/const/MediaImageUpload'
import MediaImageUploadCDNUrl from "../../component/const/MediaImageUploadCDNUrl";

function formFileBucket(data: any, Store: any) {
    let type: boolean
    if (data.Buckettype !== undefined && data.Buckettype) {
        type = true
    } else {
        type = false
    }
    let id: any
    let url = ''
    if (data.merchant_id !== undefined && data.merchant_id) {
        id = data.merchant_id
        url = 'merchant/'
    } else if (data.product_id !== undefined && data.product_id) {
        id = data.product_id
        url = 'Product'
    } else {
        id = false
    }

    let multi = false
    if (data.multiple !== undefined) {
        multi = data.multiple
    }
    let srcType = ''
    if (data.srcType !== undefined) {
        srcType = data.srcType
    }

    if (data.experiment !== undefined) {
        return (
            <>
                <Form.Group
                    controlId={'formFile' + data.label}
                    key={'formFile' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        type={data.type}
                        name={data.name}
                        id={data.id}
                        multiple={(data.multiple !== undefined && data.multiple !== null ? data.multiple :  true)}
                        data-context={data.onclick_context}
                        data-append={data.append}
                        onChange={(e) => {(data.method !== null && data.method !== undefined
                        ?
                                MediaImageUploadCDNUrl(
                                    e,
                                    data.append,
                                    data.onclick_context,
                                    data.src,
                                    type,
                                    id,
                                    url,
                                    srcType,
                                    Store,
                                    data.namespace
                                )
                                :
                                MediaImageUpload(
                                    e,
                                    data.append,
                                    data.onclick_context,
                                    data.src,
                                    type,
                                    id,
                                    url,
                                    srcType,
                                    Store,
                                    data.namespace
                                )
                        )

                        }}
                    />
                </Form.Group>
                <p
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em',
                        marginTop: '5px'
                    }}
                    key={'legende'}
                >
                    <IntlMessage
                        Store={Store}
                        messageId="bucket.file.produkt.placeholder"
                    />
                </p>
            </>
        )
    } else if (multi === true) {
        return (
            <>
                <Form.Group
                    controlId={'formFile' + data.label}
                    key={'formFile' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        type={data.type}
                        multiple={multi}
                        name={data.name}
                        id={data.id}
                        data-context={data.onclick_context}
                        data-append={data.append}
                        onChange={(e) => {
                            SendMultiMedia(
                                e,
                                data.append,
                                data.onclick_context,
                                data.src,
                                type,
                                id,
                                url,
                                Store
                            )
                        }}
                    />
                </Form.Group>
                <p
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em',
                        marginTop: '5px'
                    }}
                    key={'legende'}
                >
                    <IntlMessage
                        Store={Store}
                        messageId="bucket.file.produkt.placeholder"
                    />
                </p>
            </>
        )
    } else if (srcType === 'video') {
        return (
            <>
                <Form.Group
                    controlId={'formFile' + data.label}
                    key={'formFile' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        type={data.type}
                        name={data.name}
                        id={data.id}
                        data-context={data.onclick_context}
                        data-append={data.append}
                        onChange={(e) => {
                            SendImage(
                                e,
                                data.append,
                                data.onclick_context,
                                data.src,
                                type,
                                id,
                                url,
                                srcType,
                                Store
                            )
                        }}
                    />
                </Form.Group>
                <p
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em',
                        marginTop: '5px'
                    }}
                    key={'legende'}
                >
                    <IntlMessage
                        Store={Store}
                        messageId="bucket.file.video.placeholder"
                    />
                </p>
            </>
        )
    } else {
        return (
            <>
                <Form.Group
                    controlId={'formFile' + data.label}
                    key={'formFile' + data.label}
                    className="mb-3"
                >
                    <Form.Label>{data.label}</Form.Label>
                    <Form.Control
                        type={data.type}
                        name={data.name}
                        id={data.id}
                        data-context={data.onclick_context}
                        data-append={data.append}
                        onChange={(e) => {
                            SendImage(
                                e,
                                data.append,
                                data.onclick_context,
                                data.src,
                                type,
                                id,
                                url,
                                '',
                                Store
                            )
                        }}
                    />
                </Form.Group>
                <p
                    style={{
                        color: '#b0b0b0',
                        fontSize: '14px',
                        lineHeight: '1.3em',
                        marginTop: '5px'
                    }}
                    key={'legende'}
                >
                    <IntlMessage
                        Store={Store}
                        messageId="bucket.file.placeholder"
                    />
                </p>
            </>
        )
    }
}

export default formFileBucket
