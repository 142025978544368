import excape from '../helper/excape'
import FormSerializeElementsTranslations from '../helper/translations'

const serializeTranslationsSeo = (
    elementsdataTranslation: any,
    Data: any,
    form: any
) => {
    let elementstmps: string = ''
    if (elementsdataTranslation.length > 0) {
        elementstmps += '{'
        for (let i = 0; i < elementsdataTranslation.length; i++) {
            let end = elementsdataTranslation.length - 1

            for (let z = 0; z < elementsdataTranslation[i].length; z++) {
                if (i === end) {
                    elementstmps += excape(
                        FormSerializeElementsTranslations(
                            elementsdataTranslation[i][z],
                            form,
                            z
                        )
                    )
                } else {
                    elementstmps += excape(
                        FormSerializeElementsTranslations(
                            elementsdataTranslation[i][z],
                            form,
                            z
                        )
                    )
                }
            }
            if (elementsdataTranslation.length - 1 === i) {
            } else {
                elementstmps += ','
            }
        }
        elementstmps += '}'
        if (Data.translation !== undefined) {
            Data['translation']['seo'] = JSON.parse(
                JSON.stringify(JSON.parse(elementstmps))
            )
        }
    }

    return Data
}

export default serializeTranslationsSeo
