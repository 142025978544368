import { useEffect, useState } from 'react'
import { error } from '../../../../../../@WUM/core/component/const/error.services'
import { promotion } from '../../request/Orders/Promotion'
import { PromotionApiType } from '../../request/Orders/PromotionAPI.interface'
import { PromotionDetailApiType } from '../../request/Orders/PromotionDetailAPI.interface'
import { checkLogin } from '../../../../../../@WUM/templates/default/Auth/service/AuthService'

export const ResponsePromotion = (limit: string, searchValue: any) => {
    const [posts, setPosts] = useState<PromotionApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await promotion
                .getPosts(limit, searchValue)
                .then((data) => {
                    checkLogin(data)
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

export const ResponsePromotionDetail = (id: string) => {
    const [posts, setPosts] = useState<PromotionDetailApiType>()
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await promotion
                .getAPost(id)
                .then((data) => {
                    checkLogin(data)
                    return setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}
