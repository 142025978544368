import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import ReactDOM from 'react-dom'
import React from 'react'
import addform from './addform'
import Form from 'react-bootstrap/Form'
import { close, deletedMenu, show } from './switchdisplay'

const addMenu = (
    append: any,
    name: any,
    x: any,
    AllPage: any,
    settypes: (value: ((prevState: string) => string) | string) => void,
    types: any
) => {
    let newMenu = $(
        document.getElementById('wrapperContent') as HTMLElement
    ).find('.MenuItem').length

    let Name = document.getElementById(name) as HTMLInputElement
    let newElement = (
        <>
            <Button
                variant="primary"
                style={{ width: '100%' }}
                id={'menuButton' + newMenu}
                className={'rounded-0 border border-dark p-2'}
            >
                {Name.value}

                <p
                    className={'IconPlus float-end '}
                    style={{ left: '15px', top: '0px' }}
                >
                    <span
                        className="material-icons text-white"
                        onClick={() =>
                            show('menuButton' + newMenu, 'menu' + newMenu)
                        }
                    >
                        edit
                    </span>
                </p>

                <p
                    className={'IconPlus float-end '}
                    style={{ right: '0px', top: '0px' }}
                >
                    <span
                        className="material-icons text-white"
                        onClick={(e) => deletedMenu('Menu' + x, settypes)}
                    >
                        delete
                    </span>
                </p>
                <p
                    className={'float-end '}
                    style={{ right: '35px', top: '2px', position: 'absolute' }}
                >
                    <Form.Select
                        id={'settype' + x}
                        onChange={(e) => {
                            addform(
                                'group' + x,
                                x,
                                AllPage,
                                settypes,
                                e.target.value
                            )
                            e.target.value = ''
                        }}
                    >
                        <option></option>
                        <option value={'page'}>Page</option>
                        <option value={'link'}>Link</option>
                        <option value={'html'}>HTML</option>
                    </Form.Select>
                </p>
            </Button>
            <div id={'menu' + x} style={{ display: 'none' }}>
                <Form.Control
                    type="text"
                    name={'Menu[' + newMenu + '][name]'}
                    id={'menu' + newMenu}
                    defaultValue={Name.value}
                    style={{
                        backgroundColor: '#015b91',
                        borderColor: '#015b91',
                        color: 'white'
                    }}
                />
                <Form.Control type="hidden" name={'Menu[' + x + '][id]'} />
                <p
                    className={'IconPlus float-end '}
                    style={{ right: '10px', top: '0px' }}
                >
                    <span
                        className="material-icons text-white"
                        onClick={() =>
                            close('menuButton' + newMenu, 'menu' + newMenu)
                        }
                    >
                        close
                    </span>
                </p>
            </div>
            <ListGroup
                id={'group' + newMenu}
                className={'nested-sortable rounded-0 h95'}
                data-menuid={newMenu}
                style={{ minHeight: '30px' }}
            ></ListGroup>
        </>
    )
    let temp = document.createElement('div')
    temp.className = 'col col-4 MenuItem p-0'
    temp.id = 'Menu' + newMenu
    ReactDOM.render(newElement, temp)
    let ele = document.getElementById(append)
    if (ele !== null) {
        ele.appendChild(temp)
        Name.value = ''
        ;(
            document.getElementById('addButton') as HTMLButtonElement
        ).setAttribute('disabled', 'true')
        settypes('addMenu')
    }
}

export default addMenu
