export const TabsLogo = {
    eventkey: 'Logo',
    key: 'Logo',
    title: 'Logo',
    content: [
        {
            label: 'logo.logo-size',
            discr: 'logo-size',
            size: [3, 3, 3],
            type: 'text'
        }
    ]
}
