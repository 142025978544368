import { requestCDN, requests } from '../../../../../@WUM/core/RestFullApi/api'
import { promoApiType } from './promoData.interface'

export const promo = {
    getPosts: (limit: any, searchValue: any): Promise<promoApiType[]> =>
        requestCDN.get(`/promo/user/`),
    getPostsData: (id: string | undefined): Promise<promoApiType[]> =>
        requestCDN.get(`promo/user/${id}/`),
    getPostsDataMerchant: (id: string): Promise<promoApiType[]> =>
        requests.get(`/User/Data/Merchant/${id}/`),
    getPostsDataUpdate: (post: any, id: string): Promise<promoApiType[]> =>
        requests.put(`/User/Data/${id}/`, post),
    getPostsDataUpdateMerchant: (
        post: any,
        id: string
    ): Promise<promoApiType[]> =>
        requests.put(`/User/Data/Merchant/${id}/`, post),
    getPostsPreHeader: (): Promise<promoApiType[]> =>
        requests.get('/User/PreHeader/'),
    getPostsPreHeaderUpdate: (post: any, id: string): Promise<promoApiType[]> =>
        requests.put(`/User/PreHeader/`, post)
}
