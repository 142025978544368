import { saveDataIndividualGet } from '../../RestFullApi/ApiHook'
import { useState } from 'react'
import { error } from './error.services'

const FetchselectAPI = () => {
    const [parent_idBlogs, setparent_idBlogs] = useState(false)
    const rowBlogs: any = []
    if (parent_idBlogs === false) {
        localStorage.removeItem('fetchSelectBlog')
        const getBlogsSelect = () => {
            saveDataIndividualGet('/Content/Blog/Select/0/')
                .then((data: any) => {
                    rowBlogs.push(data)
                    localStorage.setItem(
                        'fetchSelectBlog',
                        JSON.stringify(data)
                    )
                })
                .catch((e) => {
                    error(e)
                })
        }
        getBlogsSelect()
        setparent_idBlogs(true)
    }
    const [parent_idPages, setparent_idPages] = useState(false)
    const rowPages: any = []
    if (parent_idPages === false) {
        localStorage.removeItem('fetchSelect')
        const getPagesSelect = () => {
            saveDataIndividualGet('/Content/Pages/Select/0/')
                .then((data: any) => {
                    rowPages.push(data)
                    localStorage.setItem('fetchSelect', JSON.stringify(data))
                })
                .catch((e) => {
                    error(e)
                })
        }
        getPagesSelect()
        setparent_idPages(true)
    }
    const [parent_idBlogsCategory, setparent_idBlogsCategory] = useState(false)
    const rowBlogsCategory: any = []
    if (parent_idBlogsCategory === false) {
        localStorage.removeItem('fetchSelectBlogCategory')
        const getBlogsSelectCategory = () => {
            saveDataIndividualGet('/Content/Blog/all/category/')
                .then((data: any) => {
                    rowBlogsCategory.push(data)
                    localStorage.setItem(
                        'fetchSelectBlogCategory',
                        JSON.stringify(data)
                    )
                })
                .catch((e) => {
                    error(e)
                })
        }
        getBlogsSelectCategory()
        setparent_idBlogsCategory(true)
    }
}

export default FetchselectAPI
