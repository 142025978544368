import { merchantApiType } from './merchantApi.interface'
import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { merchantApiDetail } from './merchantApiDetail.interface'
import { DatenblattDetailApiType } from '../../../../products/API/request/Product/DatenblattApi.interface'

export const merchant = {
    getPosts: (page: string, searchValue: string): Promise<merchantApiType[]> =>
        requests.get(`channel/merchant/${page}/${searchValue}`),
    getAPost: (id: string | undefined): Promise<merchantApiDetail[]> =>
        requests.get(`/Merchants/${id}/`),
    createPost: (post: merchantApiType): Promise<merchantApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<merchantApiType> =>
        requests.put(`/Merchants/${id}/`, post),
    deletePost: (id: number): Promise<void> => requests.delete(`posts/${id}`),
    getDatenblatt: (id: number): Promise<DatenblattDetailApiType[]> =>
        requests.get(`channel/merchant/Datenblatt/${id}/`)
}
