/**
 * Gibt eine Number in der Locale zurück punkt oder Komma
 * @param Locale
 * @param numbers
 */
export const number = (Locale: any, numbers: any) => {
    return numbers.toLocaleString(Locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
}
