import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';

const FormSettings = (response: any = [], mode: any = 'new') => {

    return(
        <>
            <h3>Einstellungen</h3>
            <hr />
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Maximale Wert:</InputGroup.Text>
                        <Form.Control
                            type="number"
                            min="0"
                            max="100"
                            defaultValue={
                                mode === 'new' ? '40' : '40'
                            }
                        />
                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Grenzwert Wert 1:</InputGroup.Text>

                            <Form.Control
                                type="number"
                                min="0"
                                max="100"
                                defaultValue={
                                    mode === 'new' ? '10' : '10'
                                }
                            />

                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Grenzwert Wert 2:</InputGroup.Text>

                            <Form.Control
                                type="text"
                                min="0"
                                max="100"
                                defaultValue={
                                    mode === 'new' ? '20' : '20'
                                }
                            />

                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Grenzwert Wert 3:</InputGroup.Text>

                            <Form.Control
                                type="number"
                                min="0"
                                max="100"
                                defaultValue={
                                    mode === 'new' ? '30' : '30'
                                }
                            />

                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">Grenzwert Wert 4:</InputGroup.Text>

                            <Form.Control
                                type="number"
                                min="0"
                                max="100"
                                defaultValue={
                                    mode === 'new' ? '40' : '40'
                                }
                            />

                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>
        </>
    )

}

export default FormSettings;
