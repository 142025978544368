import { RegisterRoute } from '../../../../Register/RegisterRoute'
import { HelpDeskPagesConfig } from '../../../helpdesk/modules/register'
import { AuthPagesConfig } from '../Auth/modules'

const RoutingConfig = (value: any) => {
    if (
        process.env.REACT_APP_HELPDESK_EDIT !== undefined &&
        process.env.REACT_APP_HELPDESK_EDIT === 'true'
    ) {
        return [
            ...AuthPagesConfig(value),
            ...RegisterRoute(value),
            ...HelpDeskPagesConfig(value)
        ]
    } else {
        return [...AuthPagesConfig(value), ...RegisterRoute(value)]
    }
}

export default RoutingConfig
