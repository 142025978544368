export const footerNavi = {
    eventkey: 'FooterNavi',
    key: 'FooterNavi',
    title: 'FooterNavi',
    content: [
        {
            label: 'footer.font-color-footer-hover',
            discr: 'font-color-footer-hover',
            size: [3, 3, 3],
            type: 'color'
        },
        {
            label: 'footer.font-color-footer-active',
            discr: 'font-color-footer-active',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'footer.font-size-footer',
            discr: 'font-size-footer',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'footer.font-weight-footer',
            discr: 'font-weight-footer',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'footer.font-color-footer',
            discr: 'font-color-footer',
            size: [3, 3, 3],
            type: 'color'
        }
    ]
}
