import '../css/worktimetracking.css'
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import setNavi from "../../../@WUM/core/component/const/setNavi";
const WorkTimeTrackingOverview = (props:{value:any}) => {
    setNavi({
        route: '/support/software/overview/'
    })


    return (
        <>

        </>
    )
}
export default WorkTimeTrackingOverview;
