const SearchConfig = (destination: any) => {
    return [
        {
            namespace: destination + '_searchValue',
            destination: destination,
            input: [
                {
                    id: 'search_name',
                    translation: 'Nach Unternehmensnamen suchen',
                    param: 'companyName',
                    method: 'like'
                }
            ],
            button: ['common.search', 'common.del.search', 'Neue EWIV Anlegen']
        }
    ]
}

export default SearchConfig
