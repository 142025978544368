import { OrdersApiType } from './OrdersAPI.interface'
import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { OrdersDetailApiType } from './OrdersDetailAPI.interface'
import { OrdersMerchantDetailApiType } from './OrdersDetailMerchantAPI.interface'
import { PromotionApiType } from './PromotionAPI.interface'
import { PromotionDetailApiType } from './PromotionDetailAPI.interface'

export const promotion = {
    getPosts: (limit: string, searchValue: any): Promise<PromotionApiType[]> =>
        requests.get(`promotion/${limit}/${searchValue}`),
    getPostsMerchant: (id: string, limit: string): Promise<OrdersApiType[]> =>
        requests.get(`channel/merchant/orders/${id}/${limit}/`),
    getAPost: (id: string): Promise<PromotionDetailApiType> =>
        requests.get(`promotion/Single/${id}/`),
    getAPostMerchant: (id: string): Promise<OrdersMerchantDetailApiType> =>
        requests.get(`/channel/merchant/order/${id}/`),
    createPost: (post: OrdersApiType): Promise<OrdersDetailApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<OrdersDetailApiType> =>
        requests.put(`/Orders/${id}/Emails/`, post),
    deletePost: (id: number): Promise<void> => requests.delete(`posts/${id}`),
    getPostsStatust: (post: any, id: number): Promise<void> =>
        requests.put(`/Orders/Order/${id}/`, post)
}
