import React from 'react'
import renderFormSwitch from './registerForm'

function Form(
    data: any,
    optionalFuncFirst: any = false,
    optionalFuncSecond: any = false,
    Store: any = null
) : any {
    const row = []
    if (data !== undefined) {
        for (let i = 0; i < data.length; i++) {
            if (
                data[i].hasOwnProperty('formType') === true &&
                data[i].formType !== undefined
            ) {
                row.push(
                    renderFormSwitch(
                        data,
                        i,
                        optionalFuncFirst,
                        optionalFuncSecond,
                        Store
                    )
                )
            }
        }
        return <>{row}</>
    }
}

export default Form
