import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'

const CampaignUrlGenerator = () => {
    return (
        <>
            <CreateMatomoIframe
                headline={'Kampagnen URL Erzeuger'}
                width={'100%'}
                height={'600'}
                url={WidgetURLMapping({ name: 'Campaign URL Generator' })}
                scrolling={'yes'}
                id={'cug1'}
            />
        </>
    )
}

export default CampaignUrlGenerator
