import React from 'react'
import BlogOverview from './Pages/overview'
import BlogNew from './Pages/new'
import BlogDetail from './Pages/Detail'
import { LanguageSwitcherValue } from '../../../../@WUM/core/component/core/components/LanguageSwitcher'
import BlogNewCategory from './Pages/newCategory'
import BlogDetailCategory from './Pages/DetailCategory'
import BlogDeleted from './Pages/deleted'

export const BlogPagesConfig = (value: any) => {
    return [
        {
            path: '/Blog/:page',
            elements: <BlogOverview value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        },
        {
            path: '/Blog/Delete/:page/:deleteID',
            elements: <BlogDeleted value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        },
        {
            path: '/Blog/new/new',
            elements: <BlogNew value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        },
        {
            path: '/Blog/new/:page',
            elements: <BlogNew value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        },
        {
            path: '/Blog/new/category/:page',
            elements: <BlogNewCategory value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        },
        {
            path: '/Blog/Detail/:id',
            elements: <BlogDetail value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        },
        {
            path: '/Blog/category/Detail/:id',
            elements: <BlogDetailCategory value={value} />,
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        }
    ]
}

export const BlogMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('cms.blog.header'),
            route: '/Blog/1',
            icon: 'post_add',
            roles: [
                'superadmin',
                'admin',
                'author',
                'contentcreator',
                'operator'
            ]
        }
    ]
}
