import IntlMessage from '../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

/**
 *
 * @memberOf blog
 * @prop conf
 * @prop response
 * @prop page
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
function table(conf: string, response: any, page: any, Store: any) {
    return {
        tableBody: {
            data: response,
            dataID: 'id',
            visitMenu: 'translation.slug',
            underdata: true,
            keys: [
                'id',
                'type',
                'invoiceNr',
                'subject',
                'date',
                'address.first_name',
                'address.last_name'
            ],
            type: [
                'string',
                'string',
                'string',
                'string',
                'date',
                'string',
                'string'
            ]
        },
        tableHeader: [
            'ID',
            'Type',
            'Nummer',
            'Betreff',
            'Datum',
            'Vorname',
            'Nachname'
        ],
        tableHeaderWidth: ['10%', '20%', '15%', '15%', '10%', '10%', '10%'],
        tablecolor: ['light'],
        menu: {
            data: response,
            menuDisable: false,
            menutriggerDelete: true,
            menuItem: [
                <IntlMessage Store={Store} messageId="common.edit" />
            ],
            menuroute: ['/ewiv/Detail'],
            menuParams: ['id'],
            menuIcons: ['pencil']
        }
    }
}

export default table
