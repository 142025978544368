import React from 'react'
import TableCell from '@material-ui/core/TableCell'
function indi(
    text: string,
    data: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    Rows: any,
    i: any,
    tableBodyPositionElement: any
) {
    let var1 = Rows.split('|')[2].split('-')[0]
    let var2 = Rows.split('|')[2].split('-')[1]

    let back = data[Rows.split('|')[1]] === 0 ? var1 : var2

    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                key={'td' + i + '_' + index}
            >
                {back}
            </TableCell>
        </>
    )
}

export default indi
