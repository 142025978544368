interface CreateMatomoWidgetProps {
    Widget: string | undefined
}

const CreateMatomoWidget = (props: CreateMatomoWidgetProps) => {
    let createLink =
        process.env.REACT_APP_MATOMO_URL +
        '' +
        props.Widget +
        '&token_auth=' +
        process.env.REACT_APP_MATOMO_TOKEN +
        ''

    return createLink
}

export default CreateMatomoWidget
