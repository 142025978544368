export const TabsTopbar = {
    eventkey: 'Topbar',
    key: 'Topbar',
    title: 'Topbar',
    content: [
        {
            label: 'topbar.topbar-bg',
            discr: 'topbar-bg',
            size: [3, 3, 3],
            type: 'color'
        },
        {
            label: 'topbar.font-size-topbar',
            discr: 'font-size-topbar',
            size: [3, 3, 3],
            type: 'text'
        }
    ]
}
