export interface config {
    eventdate: string
    title?: any
    shortDescription: string
    linkTitle: string
    linkUrl?: any
    mediaId: number
    channel_id: number
    visible: number
    eventEnd: string
    taxon_id: string
}

export function output(Data: any) {
    let back = {
        eventdate: Data.eventdate,
        title: Data.title,
        shortDescription: Data.shortDescription,
        linkTitle: Data.linkTitle,
        linkUrl: Data.linkUrl,
        mediaId: Data.mediaId,
        channel_id: Data.channel_id,
        visible: Data.visible,
        eventEnd: Data.eventEnd,
        taxon_id: Data.taxon_id
    }

    return back
}
