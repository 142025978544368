import { blogApiType } from './blogApi.interface'
import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { blogApiDetail } from './blogApiDetail.interface'
import { galeriApiType } from '../../../../../../@WUM/core/component/interface/galeryApi.interface'

/**
 * Sends the call to the FULL REST API with the methods GET, POST, PUT
 * @namespace blog
 * @see {@link blogApiType}
 * @see {@link blogApiDetail}
 * @prop {any} limit Indicates the current page
 * @prop {any} searchValue Submits search filters
 * @prop {any} post Indicates POST
 * @prop {any} id Transmits the ID of the target element
 * @example
 * getPosts: (limit: any, searchValue: any): Promise<blogApiType[]> => requests.get(`Content/Blog/${limit}/${searchValue}`),
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const email = {
    getPosts: (limit: any, searchValue: any): Promise<blogApiType[]> =>
        requests.get(`Content/Email/${limit}/${searchValue}`),
    //getPosts: (): Promise<blogApiType[]> => requests.get(`Content/Blog/`),
    getPostsAuhtor: (): Promise<blogApiType[]> => requests.get(`User/Author/`),
    getAPost: (id: string | undefined): Promise<blogApiDetail[]> =>
        requests.get(`Content/Email/Single/${id}/`),
    createPost: (post: blogApiType, id: number): Promise<blogApiType> =>
        requests.put(`/Content/Email/insert/${id}/`, post),
    updatePost: (post: any, id: number): Promise<blogApiDetail> =>
        requests.post(`Content/Email/update/${id}/`, post),
    deletePost: (id: string): Promise<void> =>
        requests.delete(`SingleEmail/on/delete/${id}/`),
    galeryPosts: (limit: any, searchValue: any): Promise<galeriApiType[]> =>
        requests.get(`gallery/${limit}/${searchValue}`)
}
