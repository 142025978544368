import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PielersForm from '../../../../../../../@WUM/core/form-serilizer'
import { notify_del_element } from '../../../../../../../@WUM/core/component/const/notification.service'
import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
const _handleKeyDown = (values: any) => {
    const value = values
    localStorage.setItem('Img', value)
}

export const setImg = (
    setModalImgShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void
) => {
    setModalImgShow(true)
}

export const setterImg = (
    ImgValue: string | undefined,
    setImgValue: (arg0: any) => void,
    response: { translation: { allergy: any } }[]
) => {
    if (ImgValue === '' || ImgValue === undefined) {
        setImgValue(response[0].translation.allergy)
    }
}

const deleteImage = (id: any, cointaienrID: any, Store: any) => {
    let api_URL
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        api_URL = process.env.REACT_APP_API_URL_LOCAL
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        api_URL = process.env.REACT_APP_API_URL_LIVE
    } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
        api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD
    }

    let token = localStorage.getItem('token')
    fetch(api_URL + 'Single/images/' + id + '/', {
        method: 'DELETE',
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then((response) => response.json())
        .then((data) => {
            notify_del_element(Store)
            // Identifizieren des Kindknotens
            var element = document.getElementById(cointaienrID)

            // Aufruf des Elternknotens, um dann dessen Kindknoten zu löschen
            element?.parentNode?.removeChild(element)
        })
        .catch((e) => {
            //error(e);
        })
    setTimeout(() => window.location.reload(), 3000)
}

export const modalImg = (
    modalImgShow: boolean,
    setModalImgShow: (
        value: ((prevState: boolean) => boolean) | boolean
    ) => void,
    saveMaterials: any,
    ImgValue: any,
    id: any,
    Store: any
) => {
    let DataMaterial: any = ImgValue

    return (
        <>
            <ImgModal
                show={modalImgShow}
                data={DataMaterial}
                fetchId={id}
                onHide={() => setModalImgShow(false)}
                saveMaterials={saveMaterials}
                _handleKeyDown={_handleKeyDown}
                Store={Store}
            />
        </>
    )
}

const buildImages = (Store: any) => {
    let images: any = JSON.parse(localStorage.getItem('images') as string)

    let videoHolder = []
    let pdfHolder = []
    let ImageHolder = []
    if (images !== null) {
        for (let element = 0; element < images.length; element++) {
            if (images[element].path === undefined) {
            } else if (images[element].path.split('.')[1] === 'mp4') {
                videoHolder.push(
                    <>
                        <Col
                            xl={4}
                            md={4}
                            sm={4}
                            style={{ marginBottom: '10px' }}
                            id={'mediaVideo' + images[element].id}
                        >
                            <div className={'img-thumbnail'}>
                                <video width="215" height="140" controls>
                                    <source
                                        src={
                                            (process.env.REACT_APP_MODE ===
                                            'LOCAL'
                                                ? process.env
                                                      .REACT_APP_CDN_URL_LOCAL
                                                : process.env
                                                      .REACT_APP_CDN_URL) +
                                            '' +
                                            images[element].path
                                        }
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            {/*<input defaultValue={images[element].position} style={{width: '100%'}}/>*/}
                            <Button
                                variant={'danger'}
                                type={'button'}
                                style={{ width: '100%' }}
                                onClick={() =>
                                    deleteImage(
                                        images[element].id,
                                        'mediaVideo' + images[element].id,
                                        Store
                                    )
                                }
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.delete"
                                />
                            </Button>
                        </Col>
                    </>
                )
            } else if (images[element].path.split('.')[1] === 'pdf') {
                let name = images[element].path.split('/')
                pdfHolder.push(
                    <>
                        <Col
                            xl={2}
                            md={2}
                            sm={2}
                            style={{ marginBottom: '10px' }}
                            id={'mediaDocument' + images[element].id}
                        >
                            <div className={'img-thumbnail'}>
                                <span
                                    className="material-icons"
                                    style={{
                                        verticalAlign: '-20%',
                                        color: 'red',
                                        fontSize: '65px'
                                    }}
                                >
                                    picture_as_pdf
                                </span>
                                <br />
                                <a
                                    href={
                                        (process.env.REACT_APP_MODE === 'LOCAL'
                                            ? process.env
                                                  .REACT_APP_CDN_URL_LOCAL
                                            : process.env.REACT_APP_CDN_URL) +
                                        '' +
                                        images[element].path
                                    }
                                >
                                    {name[name.length - 1]}
                                </a>
                            </div>
                            {/*<input width="215" type={'number'} defaultValue={images[element].position} style={{width: '100%'}}/>*/}
                            <Button
                                variant={'danger'}
                                type={'button'}
                                style={{ width: '100%' }}
                                onClick={() =>
                                    deleteImage(
                                        images[element].id,
                                        'mediaDocument' + images[element].id,
                                        Store
                                    )
                                }
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.delete"
                                />
                            </Button>
                        </Col>
                    </>
                )
            } else {
                ImageHolder.push(
                    <>
                        <Col
                            xl={2}
                            md={2}
                            sm={2}
                            style={{ marginBottom: '10px' }}
                            id={'media' + images[element].id}
                        >
                            <div
                                className={'img-thumbnail'}
                                style={{ height: '100px' }}
                            >
                                <img
                                    alt={images[element].id}
                                    src={
                                        (process.env.REACT_APP_MODE === 'LOCAL'
                                            ? process.env
                                                  .REACT_APP_CDN_URL_LOCAL
                                            : process.env.REACT_APP_CDN_URL) +
                                        '' +
                                        images[element].path
                                    }
                                />
                            </div>
                            {/*<input width="215" type={'number'} defaultValue={images[element].position} style={{width: '100%'}}/>*/}
                            <Button
                                variant={'danger'}
                                type={'button'}
                                style={{ width: '100%' }}
                                onClick={() =>
                                    deleteImage(
                                        images[element].id,
                                        'media' + images[element].id,
                                        Store
                                    )
                                }
                            >
                                <IntlMessage
                                    Store={Store}
                                    messageId="common.delete"
                                />
                            </Button>
                        </Col>
                    </>
                )
            }
        }
    }

    let returnHolder = [
        { img: ImageHolder, video: videoHolder, pdf: pdfHolder }
    ]

    return returnHolder
}
const buildForm = (id: any, Store: any) => {
    let product_id = id

    let configuration = [
        {
            formType: 'br'
        },
        {
            formType: 'formFileBucket',
            onclick: true,
            label: (
                <IntlMessage
                    Store={Store}
                    messageId="common.product.image.add.label"
                />
            ),
            name: 'preview_product_img_FormFile',
            id: 'preview_product_img_FormFile',
            onclick_context: '',
            append: '',
            multiple: true,
            product_id: product_id,
            Buckettype: true,
            src: 'preview_product_img',
            type: 'file',
            size: {
                xl: 12,
                md: 12,
                sm: 12
            }
        }
    ]

    return configuration
}

const ImgModal = (props: any) => {
    let elements = buildImages(props.Store)

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <IntlMessage Store={props.Store} messageId="images.edit" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'modalBody'}>
                    <Tabs
                        defaultActiveKey="img"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        key={'tabindex'}
                    >
                        <Tab
                            eventKey="img"
                            title={
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.pic"
                                />
                            }
                            key={'imgs'}
                            id={'imgs'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            <h5>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.pic"
                                />
                            </h5>
                            <Row id={'imgBody'}>{elements[0].img}</Row>
                        </Tab>
                        <Tab
                            eventKey="document"
                            title={
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.document"
                                />
                            }
                            key={'documents'}
                            id={'documents'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            <h5>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.document"
                                />
                            </h5>
                            <Row id={'documentBody'}>{elements[0].pdf}</Row>
                        </Tab>
                        <Tab
                            eventKey="video"
                            title={
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.video"
                                />
                            }
                            key={'videos'}
                            id={'videos'}
                            style={{
                                backgroundColor: 'White',
                                padding: '15px',
                                marginTop: '-15px'
                            }}
                        >
                            <h5>
                                <IntlMessage
                                    Store={props.Store}
                                    messageId="common.video"
                                />
                            </h5>
                            <Row id={'videoBody'}>{elements[0].video}</Row>
                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {PielersForm(buildForm(props.fetchId, props.Store))}
                <p>
                    <IntlMessage
                        Store={props.Store}
                        messageId="media.img.tooltip"
                    />
                </p>
                <br />
                <Row>
                    <Col>
                        <Button
                            variant={'info'}
                            href={
                                'https://www.iloveimg.com/de/bild-komprimieren'
                            }
                            target={'_blank'}
                        >
                            <IntlMessage
                                Store={props.Store}
                                messageId="optimize.image.external"
                            />
                        </Button>{' '}
                    </Col>
                    <Col>
                        <Button
                            variant={'info'}
                            href={'https://tools.pdf24.org/de/pdf-optimieren'}
                            target={'_blank'}
                        >
                            <IntlMessage
                                Store={props.Store}
                                messageId="optimize.pdf.external"
                            />
                        </Button>
                    </Col>
                </Row>
                <br />
                <hr />
                <Button onClick={props.onHide}>
                    <IntlMessage Store={props.Store} messageId="common.close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
