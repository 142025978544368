import React from 'react'
import IntlMessage from '../../component/const/IntlMessage'
import TableCell from '@material-ui/core/TableCell'
function invoicetype(
    text: string,
    id: any,
    index: number,
    VisitData: any,
    VisitDataDel: boolean,
    Store: any,
    tableBodyPositionElement: any
) {
    let state: any = {
        merchant_order_invoice: {
            text: (
                <IntlMessage
                    Store={Store}
                    messageId={'merchant_order_invoice'}
                />
            )
        },
        provision_operator_invoice: {
            text: (
                <IntlMessage
                    Store={Store}
                    messageId={'provision_operator_invoice'}
                />
            )
        },
        operator_shipping_invoice: {
            text: (
                <IntlMessage
                    Store={Store}
                    messageId={'operator_shipping_invoice'}
                />
            )
        },
        provision_invoice: {
            text: <IntlMessage Store={Store} messageId={'provision_invoice'} />
        },
        discount_order_invoice: {
            text: (
                <IntlMessage
                    Store={Store}
                    messageId={'discount_order_invoice'}
                />
            )
        },
        reversal_order_invoice: {
            text: (
                <IntlMessage
                    Store={Store}
                    messageId={'reversal_order_invoice'}
                />
            )
        }
    }

    let stateText = text
    if (state[text] !== undefined) {
        stateText = state[text].text
    }

    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
                id={'td' + id + '_' + index}
            >
                {stateText}
            </TableCell>
        </>
    )
}

export default invoicetype
