import React from 'react'
import MemberOverview from './Pages/overview'
import MemberDetail from './Pages/Detail'
import MemberNew from './Pages/new'

export const DynamikMemberPagesConfig = (value: any) => {
    return [
        {
            path: '/SystemMember/overview/:page',
            elements: <MemberOverview value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/SystemMember/Detail/:id',
            elements: <MemberDetail value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/SystemMember/Delete/:page/:deleteID',
            elements: <MemberOverview value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        },
        {
            path: '/SystemMember/new/new',
            elements: <MemberNew value={value} />,
            roles: ['superadmin', 'admin', 'merchant']
        }
    ]
}

export const DynamikMemberMenuSingleConfig = (value: any) => {
    return [
        {
            name: 'Systemnutzer',
            route: '/SystemMember/overview/1',
            icon: 'manage_accounts',
            roles: ['superadmin', 'admin', 'merchant']
        }
    ]
}
export const DynamikMemberMenuConfig = (value: any) => {
    return [
        {
            header: 'Interne News',
            icon: 'manage_accounts',
            roles: ['superadmin', 'admin', 'ContentCreator'],
            menuitem: [
                {
                    name: 'Systemnutzer',
                    route: '/SystemMember/overview/1',
                    icon: 'Membersystem',
                    roles: ['superadmin', 'admin', 'ContentCreator']
                }
            ]
        }
    ]
}

export const DynamikMemberDasboard = () => [
    {
        title: 'Systemnutzer',
        href: '/SystemMember/overview/1',
        undertitle: 'system',
        icon: 'manage_accounts',
        text: 'Membersystem',
        roles: ['superadmin', 'admin']
    }
]
