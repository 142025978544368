import React from 'react'
import { useParams } from 'react-router-dom'
import { ResponseCalenderDelete } from '../API/response/calender/calender'

const CalenderDeleted = (value: any) => {
    const { deleteID } = useParams<{ page: string; deleteID: string }>()
    ResponseCalenderDelete(deleteID)
    let landingpages = '/menberDecission/overview/1'
    /*notify_save(Store);*/
    setTimeout(() => (window.location.href = landingpages), 3000)
    return <></>
}
export default CalenderDeleted
