import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import IntlMessage, {
    IntlMessageValue
} from '../../../component/const/IntlMessage'
import SunEditorBig from '../../../component/const/SunEditorBig'
import CopyElements from '../../../component/const/CopyElements'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import WebBuilderElementsHead from '../../../component/const/WebBuilderElementsHead'

function module_duoWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any
) {
    const index = newElement === false ? i : newelementIndex

    const configuration = [
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.headline" />,
            type: 'text',
            name: 'elements[' + index + '][translation][headline]',
            placeholder: IntlMessageValue('de', 'common.headline', Store),
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.headline
                        : ''
                    : ''
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.topline" />,
            type: 'text',
            name: 'elements[' + index + '][translation][topline]',
            placeholder: IntlMessageValue('de', 'common.topline', Store),
            value:
                newElement === false
                    ? element.translation !== undefined
                        ? element.translation.de.topline
                        : ''
                    : ''
        },
        SunEditorBig(
            newElement === false && element.translation !== undefined
                ? element.translation.de
                : '',
            'text',
            newElement,
            data,
            'elements[' + index + '][translation][text]',
            <IntlMessage
                Store={Store}
                messageId="module.block_n"
                preperator="replace"
                param="[n]"
                replace={1}
            />
        ),
        {
            formType: 'br'
        },
        {
            formType: 'fetchselect',
            local: 'allProducts',
            valueState: 'id',
            appendid: 'elements[' + index + ']',
            beginn: 'true',
            start: 0,
            type: 'Products',
            onClick: 'true',
            onClick_id: 'element_' + index + '_Products',
            labelState: 'name',
            selected: '',
            idforward: newElement === false ? element.products : 0,
            label: (
                <IntlMessage Store={Store} messageId="category.products.add" />
            )
        },
        {
            formType: 'holder',
            ressource: '',
            type: 'Products',
            appendid: 'elements[' + index + ']',
            cointainerid: 'element_' + index + '_Products',
            index: 'm11' + index,
            item: newElement === false ? element.products : ''
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/m_duo.html'
        }
    ]

    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex)
    )
    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Row
                id={'module_duo' + index}
                data-appendclasses={newElement === false ? element.class : ''}
                className={'boxShadowCard card ElementBorder'}
                style={{
                    paddingBottom: '15px',
                    paddingTop: '15px',
                    width: '100%',
                    margin: '0px 0px 10px 0px'
                }}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                <div id={'Teaser'}>module_duo</div>
                {WebBuilderElementsHead(
                    <IntlMessage Store={Store} messageId="module.duo" />,
                    'module_duo',
                    newElement === false
                        ? element.translation !== undefined
                            ? element.translation.de.headline
                            : ''
                        : '',
                    Status,
                    index,
                    'elementButton',
                    <IntlMessage Store={Store} messageId="module.duo" />,
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_module_duo' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: element.previews
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_module_duo' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_module_duo' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <div id={'Teaser'}>module_duo</div>
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                module_duo
                            </Col>
                            {PielersForm(newConfiguration)}
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'module_duo'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.duo"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>
            </Row>
        </>
    )
}

export default module_duoWebBuilder
