import {worktimetrackingApiType} from "./worktimetrackingApi.interface";
import {requests} from "../../../../@WUM/core/RestFullApi/api";
import {timetrackingApiType} from "../../../activityRecording/API/request/TimetrackingApi.interface";

export const worktimetracking = {
    getPosts: (id:any): Promise<worktimetrackingApiType[]> =>
        requests.get(`work/timetracking/${id}/`),
    getPostsTimeSheet: (id:any,date:any): Promise<worktimetrackingApiType[]> =>
        requests.get(`work/timetracking/timesheet/${id}/${date}/`),
    getPostsPlaner: (): Promise<worktimetrackingApiType[]> =>
        requests.get(`work/planer/`),
    updatePost: (post: any, id: number): Promise<worktimetrackingApiType> =>
        requests.put(`User/update/${id}/`, post),
    deletePost: (id: any): Promise<void> =>
        requests.delete(`User/delete/${id}/`),
}
