import React from 'react'
import PielersForm from '../../index'
import { basesettings } from './base'
import IntlMessage, {
    IntlMessageValue
} from '../../../component/const/IntlMessage'
import CopyElements from '../../../component/const/CopyElements'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import WebBuilderElementsHead from '../../../component/const/WebBuilderElementsHead'
import Box from '@mui/material/Box'

function formularWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any
) {
    const index = newElement === false ? i : newelementIndex

    const configuration = [
        {
            formType: 'formular'
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/formular.html'
        },
        {
            formType: 'header',
            type: 'h5',
            value: IntlMessageValue('de', 'common.general', Store)
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.status" />,
            br: true,
            name: 'elements[' + index + '][visible]',
            selected: newElement === false ? element.visible : 1,
            options: [
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: '1'
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: '0'
                }
            ]
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][translations][locale]',
            type: 'hidden',
            value: 'de'
        },
        {
            formType: 'input',
            label: <IntlMessage Store={Store} messageId="common.css_class" />,
            type: 'text',
            placeholder: IntlMessageValue('de', 'common.css_class', Store),
            name: 'elements[' + index + '][class]',
            value: newElement === false ? element.class : ''
        },
        {
            formType: 'fetchselect',
            local: 'formular',
            valueState: 'name',
            beginn: 'true',
            start: 0,
            name: 'elements[' + index + '][div_id]',
            type: 'formular',
            labelState: 'name',
            selected: newElement === false ? element.div_id : 1,
            uppercase: true,
            label: 'Form pick'
        }
    ]

    let newConfiguration = configuration

    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }
    return (
        <>
            <Box
                id={'formular' + index}
                data-appendclasses={
                    newElement === false ? ' ' + element.class : ''
                }
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
            >
                {WebBuilderElementsHead(
                    'Formular',
                    'formular',
                    newElement === false ? (element.div_id as string) : '1',
                    Status,
                    index,
                    'elementButton',
                    'Text',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id
                )}
                <div
                    id={'preview-container_formular' + index}
                    className={'preview-container ' + Status}
                    style={{ display: newElement === false ? 'block' : 'none' }}
                >
                    {newElement === false ? (
                        <>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: element.previews
                                        .replaceAll(/name="(.*?)"/g, '')
                                        .replaceAll('required', '')
                                }}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                <Row
                    id={'mein_formular' + index}
                    className={'boxShadowCard card ElementBorder'}
                    style={{
                        width: '100%',
                        margin: '0px 0px 10px 0px',
                        border: '0',
                        boxShadow: 'unset'
                    }}
                >
                    <Col className={'element-body'}>
                        <div
                            id={'edit_formular' + index}
                            className={'boxShadowCard card ' + Status}
                            style={{
                                display:
                                    newElement === false ? 'none' : 'block',
                                padding: '15px',
                                width: '100%',
                                marginBottom: '20px'
                            }}
                        >
                            <div id={'Teaser'}>carouselItem_end</div>
                            <Col
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}
                            >
                                Formular
                            </Col>
                        </div>
                    </Col>
                    <div
                        id={'settingstext' + index + 'formular'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store
                            )
                        )}
                        <h3>Modul</h3>
                        {PielersForm(newConfiguration)}
                    </div>
                    {newElement === false ? (
                        CopyElements(element.id, 'Formular', Store)
                    ) : (
                        <></>
                    )}
                </Row>
            </Box>
        </>
    )
}

export default formularWebBuilder
