import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { textBlocksApiType } from './textBlocksAPI.interface'

export const textBlocks = {
    getPosts: (
        limit: string,
        searchValue: string
    ): Promise<textBlocksApiType[]> =>
        requests.get(`TextBlock/${limit}/${searchValue}`),
    getAPost: (id: string): Promise<textBlocksApiType[]> =>
        requests.get(`TextBlock/Single/${id}/`),
    createPost: (
        post: textBlocksApiType,
        id: number
    ): Promise<textBlocksApiType> =>
        requests.post(`Content/TextBlock/insert${id}/`, post),
    updatePost: (post: any, id: number): Promise<textBlocksApiType> =>
        requests.post(`TextBlock/update/${id}/`, post),
    deletePost: (id: any): Promise<void> => requests.delete(`User/${id}/`)
}
