import React, { useState } from 'react'
import SidebarItems from '../../templates/default/config/SidebarItems'
import {
    Sidebar as SidebarT,
    Menu,
    useProSidebar,
    SubMenu
} from 'react-pro-sidebar'

import ItemMenu from './ItemMenu'
import MenuIcon from './MenuIcon'
import { IntlMessageValue } from '../component/const/IntlMessage'
import ChangeHeader from './ChangeHeader'
import { useSelector } from 'react-redux'
import './sidebar.css'
import Gallery from '../component/const/galery'
import SecureCheck from '../RoleSecury'
import { mappingSubmenu } from './mappingSubmenu'
import {Link} from "react-router-dom";

const Sidebar = (props: { setValue: any; value: any }) => {
    const [menuCollapse, setMenuCollapse] = useState(false)
    let Store = useSelector((state: any) => state)
    const [active, setActive] = useState('')
    const { collapseSidebar } = useProSidebar()

    const menuIconClick = () => {
        let Header = document.getElementsByClassName(
            'theme-header'
        )[0] as HTMLDivElement
        let Footer = document.getElementsByClassName(
            'theme-footer'
        )[0] as HTMLDivElement
        if (menuCollapse) {
            setMenuCollapse(false)
            collapseSidebar()
            Header.style.marginLeft = '-270px'
            Footer.style.marginLeft = '-270px'
            Footer.style.zIndex = '1008'
        } else {
            setMenuCollapse(true)
            collapseSidebar()
            Header.style.marginLeft = '-80px'
            Footer.style.marginLeft = '-80px'
            Footer.style.zIndex = '1009'
        }
    }

    return (
        <>
            <SidebarT className={' pro-sidebar sidebarBackground'}>
                <div className={'sidebarHeader m-0'}>
                    {menuCollapse ? (

                            <Link className={''} to={
                                process.env.REACT_APP_LANDINGPAGES !== undefined
                                    ? process.env.REACT_APP_LANDINGPAGES
                                    : '/Blog/overview/1'
                            } >

                            <img
                                src={'/'+process.env.REACT_APP_LOGO}
                                alt={'Logo'}
                                id={'logoFavicon'}
                                className={'SidebarLogo'}
                                style={{width:'60px',height:'60px',padding:'5px'}}
                            />
                            </Link>
                    ) : (
                        <Link className={''} to={
                            process.env.REACT_APP_LANDINGPAGES !== undefined
                                ? process.env.REACT_APP_LANDINGPAGES
                                : '/Blog/overview/1'
                        } >
                            <img
                                src={process.env.REACT_APP_LOGO_IMG}
                                height={60}
                                alt={'Logo'}
                                id={'logo'}
                                className={'SidebarLogo'}
                            />
                        </Link>
                    )}
                </div>
                <div className={'SidebarContent'}>
                    <div className="logotext"></div>
                    <div
                        className="closemenu box"
                        onClick={() => menuIconClick()}
                    >
                        <h3>
                            {menuCollapse ? (
                                <svg
                                    className={'float-start ml-2'}
                                    stroke="currentColor"
                                    fill="white"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <polyline points="12 16 16 12 12 8"></polyline>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            ) : (
                                <svg
                                    className={'float-end mr-2'}
                                    stroke="currentColor"
                                    fill="white"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <polyline points="12 8 8 12 12 16"></polyline>
                                    <line x1="16" y1="12" x2="8" y2="12"></line>
                                </svg>
                            )}
                            <br style={{ clear: 'both' }} />
                        </h3>
                    </div>

                    {SidebarItems(props.value).map((item, index) => {
                        if (
                            item.icon !== undefined &&
                            item.icon !== '' &&
                            SecureCheck(item.roles)
                        ) {
                            return (
                                <>
                                    <Menu key={'Menu' + index}>
                                        <SubMenu
                                            defaultOpen={mappingSubmenu(
                                                IntlMessageValue(
                                                    props.value,
                                                    item.header,
                                                    Store
                                                ),
                                                active
                                            )}
                                            key={'SubMenu' + index}
                                            label={ChangeHeader({
                                                header: item.header,
                                                value: props.value,
                                                Store: Store
                                            })}
                                            icon={
                                                <MenuIcon
                                                    icon={
                                                        item.icon !== undefined
                                                            ? item.icon
                                                            : ''
                                                    }
                                                />
                                            }
                                            className={'first'}
                                        >
                                            <ItemMenu
                                                header={item.header}
                                                active={active}
                                                setActive={setActive}
                                                key={'ItemMenu' + index}
                                                items={item.menuitem}
                                                value={props.value}
                                                Store={Store}
                                            />
                                        </SubMenu>
                                    </Menu>
                                </>
                            )
                        } else {
                            if (
                                item.icon !== undefined &&
                                item.icon !== '' &&
                                SecureCheck(item.roles)
                            ) {
                                return (
                                    <>
                                        <Menu
                                            key={'Menu' + index}
                                            /*   iconShape="circle"*/
                                        >
                                            <SubMenu
                                                key={'SubMenu' + index}
                                                defaultOpen={mappingSubmenu(
                                                    IntlMessageValue(
                                                        props.value,
                                                        item.header,
                                                        Store
                                                    ),
                                                    active
                                                )}
                                                title={IntlMessageValue(
                                                    props.value,
                                                    item.header,
                                                    Store
                                                )}
                                            >
                                                <ItemMenu
                                                    header={item.header}
                                                    key={'ItemMenu' + index}
                                                    active={active}
                                                    setActive={setActive}
                                                    items={item.menuitem}
                                                    value={props.value}
                                                    Store={Store}
                                                />
                                            </SubMenu>
                                        </Menu>
                                    </>
                                )
                            }
                        }
                        return null
                    })}
                </div>
                <div className="sidebarFooter sidebar-btn-wrapper">
                    <a
                        href={
                            process.env.REACT_APP_LOGO_IMG_WUM_LINK !==
                            undefined
                                ? process.env.REACT_APP_LOGO_IMG_WUM_LINK
                                : 'https://wum-solution.de'
                        }
                        target="_blank"
                        className="sidebar-btn"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={process.env.REACT_APP_LOGO_IMG_WUM}
                            style={{ maxHeight: '60px' }}
                            alt={'Logo'}
                        />
                    </a>
                </div>
            </SidebarT>
        </>
    )
}
export default Sidebar

export const ShowDialog = (Store: any) => {
    let eles = document.getElementById('dialog') as HTMLDivElement
    eles.style.display = 'block'
    Gallery('', '', '', Store, '', null, null, null, false)
}
