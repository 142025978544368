import { init2 } from '../../../form-serilizer/seriliaze/Formserialize'
import {
    notify_save_intvall_reload,
    notify_save
} from '../../const/notification.service'
import updateDataMapping from '../../../../../Register/updateData.mapping'
import { error } from '../../const/error.services'

/**
 * Sendet Formular Daten an die API
 * @param status
 * @param id
 * @param currentTutorial
 * @param setCurrentTutorial
 * @param Nowrequest
 * @param part
 * @param Store
 * @param forms
 * @param request
 */
const updateData = (
    status: boolean,
    id: any,
    currentTutorial: any,
    setCurrentTutorial: any,
    Nowrequest: any,
    part: any,
    Store: any,
    forms?: any,
    request?: any
) => {
    /*
     *  Wichtig zum Rausholen der Datan aus Formular und senden
     *  Das Interface muss immer angepasst werden
     */
    /*let Data = init();*/

    let Data = init2(forms)

    //console.log(Data)

    let requestData = updateDataMapping({
        part: part,
        currentTutorial: currentTutorial,
        Data: Data
    })

    //console.log(requestData)

    /*
     *  Senden der Erhaltenen Daten an das Frontend
     */

    let req =
        request !== undefined
            ? Nowrequest.createPost(requestData, id)
            : Nowrequest.updatePost(requestData, id)

    req.then((response: any) => {
        setCurrentTutorial({ ...currentTutorial })
        //console.log(response);
        if (request !== undefined) {
            notify_save(Store)
            setTimeout(() => (window.location.href = request), 3000)
        } else {
            notify_save_intvall_reload(Store)
            setTimeout(() => window.location.reload(), 3000)
            $('#btn_save')[0].setAttribute('disabled', 'disbaled')
        }
    }).catch((e: any) => {
        error(e)
    })
}

export default updateData
