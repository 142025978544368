import PielersserializeMerchantShippingType from '../helper/elementsshippingTypes'
import excape from '../helper/excape'

const serializMerchantShippingType = (
    elementsdata: any,
    Data: any,
    form: any
) => {
    let elementstmp: string = ''
    let zahl: number = 0
    let Iterator = elementsdata.length / 3
    elementstmp += '['
    if (elementsdata.length > 0) {
        for (let x = 0; x < Iterator; x++) {
            elementstmp += '{'

            let setter = (x + 1) * 3 - 1

            for (let i = 0; i < elementsdata.length; i++) {
                let type = elementsdata[i][0].split('[')
                let typeident = type[1].split(']')
                let head: number = parseFloat(typeident[0])
                zahl = head

                if (zahl === x) {
                    let end = elementsdata.length - 1
                    if (i === end) {
                        elementstmp +=
                            excape(
                                PielersserializeMerchantShippingType(
                                    elementsdata[i],
                                    form
                                )
                            ) + '}'
                    } else if (i === setter) {
                        elementstmp +=
                            excape(
                                PielersserializeMerchantShippingType(
                                    elementsdata[i],
                                    form
                                )
                            ) + '},'
                    } else {
                        elementstmp +=
                            excape(
                                PielersserializeMerchantShippingType(
                                    elementsdata[i],
                                    form
                                )
                            ) + ','
                    }
                }
            }
        }

        elementstmp += ']'
        Data['MerchantShippingType'] = JSON.parse(elementstmp)
    }
    return Data
}

export default serializMerchantShippingType
