import Button from 'react-bootstrap/Button';

const buttonTabeMaker = (data: any) => {

    if(data.withBr === true) {
        return(
            <>
            <Button className={data.class} variant="primary" onClick={() => data.setter(data.next)}> {data.label}</Button>
            <br />
            </>
        );
    } else {
        return(
            <Button className={data.class} variant="primary" onClick={() => data.setter(data.next)}> {data.label}</Button>

        );
    }


}
export default buttonTabeMaker;
