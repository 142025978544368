export const ReplaceHtmlEntities = (obj: any) => {


        for (var k in obj) {
            if (typeof obj[k] == "object" && obj[k] !== null)
                ReplaceHtmlEntities(obj[k]);
            else {
                var entity = /&(?:#x[a-f0-9]+|#[0-9]+|[a-z0-9]+);?/ig;
                let m;
                var element = document.createElement('div');
                const replace = (match:string) => {
                    element.innerHTML = match;
                    return element.textContent! as string;
                }
                while ((m = entity.exec(obj[k])) !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (m.index === entity.lastIndex) {
                        entity.lastIndex++;
                    }

                    // The result can be accessed through the `m`-variable.
                    m.forEach((match, groupIndex) => {
                        obj[k] = obj[k] .replace(entity, replace( match));
                    });
                }


                // regular expression matching HTML entities

                if (typeof obj[k] === 'string' || obj[k] instanceof String) {
                    let str: string = obj[k] as string;

                }
                // reset the value
                element.textContent = '';
            }
        }

    return obj;
}
