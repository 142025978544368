import { requests } from '../../../../../@WUM/core/RestFullApi/api'
import { RewriteApiType } from './rewriteAPI.interface.'

export const rewrite = {
    getPosts: (limit: string, search: any): Promise<RewriteApiType[]> =>
        requests.get(`rewrite/${limit}/${search}`),
    getAPost: (id: string | undefined): Promise<RewriteApiType[]> =>
        requests.get(`rewrite/Single/${id}/`),
    createPost: (post: RewriteApiType): Promise<RewriteApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<RewriteApiType> =>
        requests.post(`rewrite/Single/${id}/`, post),
    deletePost: (id: any): Promise<void> =>
        requests.delete(`rewrite/Single/${id}/`)
}
