import React from 'react'
import { LanguageSwitcherValue } from '../../../../@WUM/core/component/core/components/LanguageSwitcher'
import TextBlocksOverview from './Pages/overview'
import TextBlocksDetail from './Pages/Detail'
import TextBlocksNew from './Pages/new'

export const TextBlocksPagesConfig = (value: any) => {
    return [
        {
            path: '/TextBlocks/overview/:page',
            elements: <TextBlocksOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/TextBlocks/Detail/:id',
            elements: <TextBlocksDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/TextBlocks/new/new',
            elements: <TextBlocksNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/TextBlocks/new/:page',
            elements: <TextBlocksNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}

export const TextBlocksMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('cms.textblocks.header'),
            route: '/TextBlocks/overview/1',
            icon: 'text_snippet',
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}
