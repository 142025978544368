import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {colorCheck} from "../../component/const/ColorCheck";

export const TableBadge  = (
    text: string,
    id: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any,
    Rows: any,
) => {

    let ColorMap= [
        {name:'0 Erfasst', color:'#FF0000'},
        {name:'1 Geplant', color:'#F44336'},
        {name:'2 Bericht erstellt', color:'#FF5722'},
        {name:'4 Angebot erstellt', color:'#FFEB3B'},
        {name:'5 Auftrag erteilt', color:'#CDDC39'},
        {name:'6 Teile bestellt', color:'#FF9800'},
        {name:'7 Reparatur erledigt', color:'#8BC34A'},
        {name:'8 Kunden informiert', color:'#4CAF50'},
        {name:'9 Geschlossen', color:'#00FF00'},
        {name:'10 Termin geplant', color:'#FF9800'},
        {name:'11 Bericht erstellt', color:'#8BC34A'},
    ]

    let Name = text;
    let Color = '';

    for(let c=0;c<ColorMap.length;c++) {
        if(Name === ColorMap[c].name) {
            Color = ColorMap[c].color
        }
    }

    return (
        <>
            <TableCell
                style={{textAlign: tableBodyPositionElement}}
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
                id={'td' + id + '_' + index}
            >
                <span className="badge rounded-pill" style={{background:Color,color:(Color !== undefined && colorCheck(Color,false) === false ? 'white' : 'black')}}>{Name} </span>
            </TableCell>
        </>
    )
}

