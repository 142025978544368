import Tabs from 'react-bootstrap/Tabs'
import Tab from "react-bootstrap/Tab";
import React from "react";

const TabMaker = (data: any) => {

    let tabsHolder = [];

    for(let x=0;x<data.tabs.length;x++){
        tabsHolder.push(

                <Tab
                    eventKey={data.tabs[x].eventKey}
                    title={data.tabs[x].title}
                    key={data.tabs[x].key}
                    id={data.tabs[x].id}
                    className={data.class}
                    disabled={true}
                    style={{
                        backgroundColor: 'White',
                        padding: '15px',
                        marginTop: '-15px'
                    }}
                >
                    {data.tabs[x].content}
                </Tab>

        )
    }

    return(
        <>
            <Tabs
                defaultActiveKey={data.startEvent}
                id="uncontrolled-tab-examples"
                className="mb-3 TableMaker"
                key={'tabindex'}
                activeKey={data.currenttab}
                fill

            >
                {tabsHolder}
            </Tabs>
        </>
    )

}

export default TabMaker;
