import CreateMatomoIframe from '../../core/CreateMatomoIframe'
import WidgetURLMapping from '../../core/WidgetURLMapping'
import React from 'react'

const Transitions = () => {
    return (
        <>
            <CreateMatomoIframe
                headline={'Transitionen'}
                width={'100%'}
                height={'900'}
                url={WidgetURLMapping({ name: 'transitions' })}
                scrolling={'yes'}
                id={'transition1'}
            />
        </>
    )
}

export default Transitions
