import React from 'react'
import PielersForm from '../../index'
import { base, basesettings } from './base'
import IntlMessage from '../../../component/const/IntlMessage'
import CopyElements from '../../../component/const/CopyElements'
import WebBuilderElementsHead from '../../../component/const/WebBuilderElementsHead'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Box from '@mui/material/Box'
import ReactDOM from 'react-dom'

function containerWebBuilder(
    data: any,
    element: any,
    i: any,
    newElement: any = false,
    newelementIndex: any = 0,
    modalShow: boolean,
    setModalShow: (value: ((prevState: boolean) => boolean) | boolean) => void,
    modalData: any[],
    setModalData: (value: ((prevState: any[]) => any[]) | any[]) => void,
    content: any = [],
    setElements: any = [],
    Store: any,
    overStart?: any,
    col?: any,
    row?: any,
    child?: any
) {
    const index = newElement === false ? i : newelementIndex

    let rows = newElement === false ? element.row : row
    let Cols = newElement === false ? element.col.split(',') : col

    const configuration = [
        {
            formType: 'input',
            name: 'elements[' + index + '][row]',
            type: 'hidden',
            value: rows
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][col]',
            type: 'hidden',
            value: Cols
        },
        {
            formType: 'input',
            name: 'elements[' + index + '][template]',
            type: 'hidden',
            value: 'Elements/container.html'
        }
    ]

    let newConfiguration = configuration.concat(
        base(element, i, newElement, newelementIndex, overStart)
    )
    let Status
    if (element.visible === 1) {
        Status = 'elementActive'
    }
    if (element.visible === 0) {
        Status = 'elementDeactive'
    } else if (newElement === true) {
        Status = 'elementActive'
    }

    let RowHolder = []
    for (let r = 0; r < rows; r++) {
        let ColHolder = []
        /*console.log(r+''+Cols[r])
    console.log(Cols)*/
        for (let c = 0; c < Cols[r]; c++) {
            ColHolder.push(
                <>
                    <Col
                        id={
                            'order' +
                            index +
                            '-' +
                            rows +
                            '' +
                            r +
                            '' +
                            Cols[r] +
                            '' +
                            c
                        }
                        data-id={c}
                        data-appendclass={'col-' + 12 / Cols[r]}
                        data-appendto={
                            'order' +
                            index +
                            '-' +
                            rows +
                            '' +
                            r +
                            '' +
                            Cols[r] +
                            '' +
                            c
                        }
                        className={
                            'list-group nested-sortable mt-3 mb-3 bg-light border col-' +
                            12 / Cols[r]
                        }
                        style={{ minHeight: '100px' }}
                    >
                        {Cols[r] - 1 === c ? (
                            <p
                                id={index + 'add' + c + '' + r}
                                className={'IconPlus float-end'}
                            >
                                <span
                                    className="material-icons text-white"
                                    onClick={() =>
                                        addedCol(
                                            'row' + index + '-' + rows + '' + r,
                                            r,
                                            col,
                                            index,
                                            rows,
                                            parseInt(Cols[r]) + 1,
                                            parseInt(Cols[r]) + 1,
                                            parseInt(Cols[r]),
                                            setModalData
                                        )
                                    }
                                >
                                    added
                                </span>
                            </p>
                        ) : (
                            <></>
                        )}
                    </Col>
                </>
            )
        }
        RowHolder.push(
            <Row id={'row' + index + '-' + rows + '' + r}>{ColHolder}</Row>
        )
    }

    return (
        <>
            <Box
                id={'container' + index}
                data-appendid={index}
                data-appendcontainer={
                    newElement === false &&
                    element.appendto !== null &&
                    element.appendto !== undefined &&
                    element.appendto !== ''
                        ? element.appendto
                        : 'false'
                }
                style={{
                    border: 'thick double rgb(56, 112, 247)',
                    padding: '5px',
                    marginTop: ' 35px',
                    marginBottom: '40px'
                }}
            >
                {WebBuilderElementsHead(
                    'container',
                    'container',
                    '',
                    Status,
                    index,
                    'elementButton',
                    'Text',
                    modalShow,
                    setModalShow,
                    modalData,
                    setModalData,
                    newElement,
                    element,
                    content,
                    setElements,
                    Store,
                    '',
                    '',
                    element.id,
                    'mtminus25',
                    'containerend' + (index + rows * Cols + 2)
                )}

                <Row
                    className={'boxShadowCard card bbunset'}
                    style={{
                        width: '100%',
                        background: 'transparent',
                        height: '1px'
                    }}
                >
                    <div id={'Teaser'}>Cointaner</div>
                    <Col>Cointaner</Col>
                    <Col className={'accordion-body element-body'}>
                        {PielersForm(newConfiguration)}
                    </Col>
                    <div
                        id={'settingstext' + index + 'container'}
                        className={'settingsWebBuilderModul '}
                        style={{ display: 'none' }}
                    >
                        {PielersForm(
                            basesettings(
                                element,
                                i,
                                newElement,
                                newelementIndex,
                                Store,
                                true
                            )
                        )}
                    </div>
                    {newElement === false ? (
                        CopyElements(
                            element.id,
                            <IntlMessage
                                messageId="module.row_start"
                                Store={Store}
                            />,
                            Store
                        )
                    ) : (
                        <></>
                    )}
                </Row>

                <Row style={{ marginTop: '-14px', marginBottom: '0px' }} />
                <Row
                    className={''}
                    style={{
                        paddingBottom: '0px',
                        paddingTop: '15px',
                        width: '100%',
                        margin: '0px 0px 0px 0px'
                    }}
                    data-injectEnd={true}
                    data-injectid={newElement === false ? element.position : 0}
                >
                    {RowHolder}

                    {child}
                    <div
                        id={'Injectcontainerend' + index}
                        style={{ display: 'none' }}
                    ></div>
                </Row>
            </Box>
        </>
    )
}

export default containerWebBuilder

const addedCol = (
    id: any,
    r: any,
    col: any,
    index: any,
    rows: any,
    c: number,
    newCols: any,
    oldc: any,
    setModalData: any
) => {
    let dataid = newCols - 1
    let newElement = (
        <>
            {/* <Col id={'order'+index+'-'+rows+''+r+''+c+''+newCols} data-id={newCols-1} data-appendclass={'col-'+(12/newCols)} data-appendto={'order'+index+'-'+rows+''+r+''+newCols+''+c}  className={'list-group nested-sortable mt-2 bg-light border col-'+(12/newCols)} style={{minHeight: '100px'}}>*/}

            {dataid !== 11 ? (
                <p
                    id={index + 'add' + newCols + '' + r}
                    className={'IconPlus float-end'}
                >
                    <span
                        className="material-icons text-white"
                        onClick={() =>
                            addedCol(
                                'row' + index + '-' + rows + '' + r,
                                r,
                                col,
                                index,
                                rows,
                                c + 1,
                                newCols + 1,
                                oldc,
                                setModalData
                            )
                        }
                    >
                        add
                    </span>
                </p>
            ) : (
                <></>
            )}
            {/*</Col>*/}
        </>
    )
    console.log(c)
    let temp = document.createElement('div')
    temp.id =
        'order' + index + '-' + rows + '' + r + '' + oldc + '' + (newCols - 1)

    temp.dataset.id = '' + dataid + ''
    temp.dataset.appendclass = 'col-' + 12 / (c === 5 ? 6 : c > 6 ? 12 : c)
    temp.dataset.appendto =
        'order' + index + '-' + rows + '' + r + '' + newCols + '' + c
    temp.classList.add(
        'list-group',
        'nested-sortable',
        'mt-3',
        'mb-3',
        'bg-light',
        'border',
        'col',
        'col-' + 12 / newCols + ''
    )
    ReactDOM.render(newElement, temp)
    let ele = document.getElementById(id)
    if (ele !== null) {
        ele.appendChild(temp)
        for (let change = 1; change <= newCols; change++) {
            let BeforeCol = document.getElementById(
                'order' +
                    index +
                    '-' +
                    rows +
                    '' +
                    r +
                    '' +
                    oldc +
                    '' +
                    (newCols - change)
            ) as HTMLDivElement
            //console.log('order'+index+'-'+rows+''+r+''+oldc+''+(newCols-change))
            if (BeforeCol !== undefined && BeforeCol !== null) {
                let Beforec = c - 1
                //console.log('12/'+(Beforec === 5 ? 6 :(Beforec > 6  ? 12 : Beforec)))
                BeforeCol.classList.remove(
                    'col-' +
                        12 / (Beforec === 5 ? 6 : Beforec > 6 ? 12 : Beforec)
                )
                //console.log('col-'+(12/(Beforec === 5 ? 6 :(Beforec > 6  ? 12 : Beforec))))
                //console.log('col-'+(12/(newCols === 5 ? 6 :(newCols > 6 ? 12 : newCols))))
                BeforeCol.classList.add(
                    'col-' +
                        12 / (newCols === 5 ? 6 : newCols > 6 ? 12 : newCols)
                )
                //console.log(index+"add"+(oldc-1)+''+r)
                const element = document.getElementById(
                    index + 'add' + (oldc - 1) + '' + r
                ) as HTMLParagraphElement
                //console.log(element)
                if (element !== null) {
                    element.remove()
                } else {
                    //console.log(index+"add"+(newCols-1)+''+r)
                    const element = document.getElementById(
                        index + 'add' + (newCols - 1) + '' + r
                    ) as HTMLParagraphElement
                    //console.log(element)
                    if (element !== null) {
                        element.remove()
                    }
                }
            }
        }
        setModalData([])
    }
}
