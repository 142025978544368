const UniNotification = (children:any) =>{

    return(
        <>
        <div id={'notification'}>
            {children.children}
        </div>
        </>
    )

}

export default UniNotification;
