import getToken from '../modules/components/Token'
import ApiURL from '../modules/components/ApiURL'
import GetAvatarView from '../modules/components/getAvatarView'
import ReactDOM from 'react-dom'
import React from 'react'
import {
    notify_del_element,
    notify_save
} from '../../../@WUM/core/component/const/notification.service'
export const saveTester = (e: any, issueID: any, Store: any) => {
    e.preventDefault()
    let data = e.currentTarget.value
    let setMethod: string = e.target[e.target.selectedIndex].getAttribute(
        'data-param'
    ) as string
    var myHeaders = new Headers()
    e.target.value = ''
    var formdata = new FormData()
    myHeaders.append('Authorization', 'Bearer ' + getToken())
    formdata.append('tester', data)
    var raw = JSON.stringify({
        tester: formdata.get('tester'),
        issue_id: issueID
    })

    let requestOptions = {
        method: setMethod,
        headers: myHeaders,
        body: raw
    }

    fetch(
        ApiURL() +
            'tester-on-issue/' +
            (setMethod === 'Post' ? 'update' : 'delete') +
            '/' +
            issueID +
            '/',
        requestOptions
    )
        .then((response) => response.text())
        .then((result) =>
            setMethod === 'Post' ? newComment(result) : DeleteLabel(data)
        )
        .catch((error) => console.log('error', error))

    let optionsfield = document.getElementById(
        'optionTester' + data.replaceAll(' ', '_')
    ) as HTMLOptionElement

    if (setMethod === 'Post') {
        optionsfield.setAttribute('data-param', 'Delete')
        optionsfield.innerHTML = optionsfield.innerHTML.replace(
            optionsfield.innerHTML,
            '☑ ' + optionsfield.innerHTML
        )
    } else {
        optionsfield.setAttribute('data-param', 'Post')
        optionsfield.innerHTML = optionsfield.innerHTML.replace('☑', '')
    }

    const DeleteLabel = (data: any) => {
        let element = document.getElementById(
            'Tester' + data.replaceAll(' ', '_')
        ) as HTMLDivElement
        element.innerHTML = ''
        element.removeAttribute('class')
        element.removeAttribute('style')

        notify_del_element(Store)
    }

    const createComment = (data: any) => {
        let Result = JSON.parse(data.item)

        if (Result !== '') {
            let tester = Result.tester

            return (
                <>
                    <div id={'Tester' + tester.replaceAll(' ', '_')}>
                        <GetAvatarView
                            name={tester}
                            color={'blue'}
                            class={'ticketAvatarFloatLeft'}
                        />
                        <p className={'pAvatar'}>{tester}</p>
                    </div>
                </>
            )
        }
    }

    const newComment = (result: any) => {
        let newElement = createComment({ item: result })
        let temp = document.createElement('span')
        // @ts-ignore
        ReactDOM.render(newElement, temp)
        let ele = document.getElementById('TesterHolder')
        if (ele !== null) {
            ele.appendChild(temp)
            notify_save(Store)
        }
    }
}
