import SearchJSX from '../../../@WUM/core/component/const/SearchJSX'
import ContentSwitch from '../../../@WUM/core/component/const/ContentSwitch'
import IntlMessage, {
    IntlMessageValue
} from '../../../@WUM/core/component/const/IntlMessage'
import { removeUseLocalStorage } from '../../../@WUM/core/component/const/useLocalStoreage'
import { SpinnerJSX } from '../../../@WUM/core/component/const/Spinner'
const DynamikComponents = {
    SearchJSX: SearchJSX,
    ContentSwitch: ContentSwitch,
    IntlMessage: IntlMessage,
    IntlMessageValue: IntlMessageValue,
    removeUseLocalStorage: removeUseLocalStorage,
    SpinnerJSX: SpinnerJSX
}

export default DynamikComponents
