import React from 'react'

import Button from 'react-bootstrap/Button'

function header(data: any) {
    let variant = data.variants
    let label = data.label
    let value = data.value

    let target = data.target !== undefined ? data.target : '_blank'

    function JSONRemoveSlide() {
        let JsonP = JSON.parse(
            (document.getElementById(data.jsonID) as HTMLInputElement).value
        )

        let tmpItem = []

        for (let x = 0; x < JsonP.item.length; x++) {
            if (x !== data.removeID) {
                tmpItem.push(JsonP.item[x])
            }
        }

        JsonP.item = tmpItem
        ;(document.getElementById(data.jsonID) as HTMLInputElement).value =
            JSON.stringify(JsonP)
        // Identifizieren des Kindknotens
        var element = document.getElementById(
            data.namespace + '' + data.removeID + '_' + data.removeIndex
        ) as HTMLDivElement

        element?.parentNode?.removeChild(element)

        //console.log(JsonP.item);
    }

    if (data.removeID !== undefined && data.removeID !== null) {
        return (
            <>
                <>
                    <br />
                    <Button variant={variant} onClick={() => JSONRemoveSlide()}>
                        {label}
                    </Button>
                </>
            </>
        )
    } else {
        return (
            <>
                <>
                    <br />
                    <Button
                        variant={variant}
                        as={'a'}
                        href={value}
                        target={target}
                    >
                        {label}
                    </Button>
                </>
            </>
        )
    }
}

export default header
