const setNavi = (props:{route:any}) => {

    let Holder = document.getElementsByClassName('unLink');

    if(Holder !== null && Holder.length > 0) {
        for(let h=0;h<Holder.length;h++) {
            let tmp = Holder[h] as HTMLLIElement
            let Childs = Holder[h].childNodes;

            let TMPChild = Childs[0] as HTMLLinkElement;

            if(TMPChild !== undefined) {
                let tmpLink = TMPChild.dataset;
                if(tmpLink.link !== undefined) {
                    if (props.route.split('/')[1] === tmpLink.link.split('/')[1]) {
                        tmp.classList.add("activeSidebar");
                        tmp.classList.remove("deactiveSidebar");
                    } else {
                        tmp.classList.remove("activeSidebar");
                        tmp.classList.add("deactiveSidebar");
                    }
                }
            }
        }
    }

}

export default setNavi;
