const LoadIframe = (props: { IframeID: any; LoadingID: any }) => {
    // Query the elements
    const iframeEle = document.getElementById(props.IframeID)
    const loadingEle = document.getElementById(props.LoadingID)

    // Hide the loading indicator
    if (loadingEle !== undefined && loadingEle !== null) {
        loadingEle.style.display = 'none'
    }
    // Bring the iframe back
    if (iframeEle !== undefined && iframeEle !== null) {
        iframeEle.style.opacity = '1'
        iframeEle.style.display = 'block'
    }
}

export default LoadIframe
