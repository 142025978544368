import React from 'react'
import CustomTypesOverview from './Pages/overview'
import CustomTypesDetail from './Pages/Detail'
import CustomTypeDetail from './Pages/DetailType'
import RewriteDeleted from './Pages/deleted'
import CustomTypeDeleted from './Pages/deletedType'
import CustomTypesNew from './Pages/new'
import CustomTypeNew from './Pages/newCustomTypes'

export const CustomTypesPagesConfig = (value: any) => {
    return [
        {
            path: '/customTypes/overview/:page',
            elements: <CustomTypesOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/customTypes/Detail/:id',
            elements: <CustomTypesDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/customType/Detail/:id',
            elements: <CustomTypeDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/customTypes/Delete/:page/:deleteID',
            elements: <RewriteDeleted value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/customType/Delete/:page/:deleteID',
            elements: <CustomTypeDeleted value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/customTypes/new/',
            elements: <CustomTypesNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/customTypes/new/:id',
            elements: <CustomTypesNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/customTypes/Type/new/',
            elements: <CustomTypeNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/customTypes/Type/new/:id',
            elements: <CustomTypeNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}

export const CustomTypesMenuConfig = (value: any) => {
    return [
        {
            name: 'CustomTypes',
            route: '/customTypes/overview/1',
            icon: 'dashboard_customize',
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}
