export const H6 = {
    eventkey: 'H6',
    key: 'H6',
    title: 'H6',
    content: [
        {
            label: 'headline.h6.font-size-h6',
            discr: 'font-size-h6',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h6.font-weight-h6',
            discr: 'font-weight-h6',
            size: [3, 3, 3],
            type: 'text'
        },
        {
            label: 'headline.h6.font-color-h6',
            discr: 'font-color-h6',
            size: [3, 3, 3],
            type: 'text'
        }
    ]
}
