import React from 'react'
import IssueBadgeProps from '../../Interface/badge.interface'

const IssueBadge = (props: IssueBadgeProps) => {
    let id =
        props.elementID !== undefined ? props.elementID : 'Badge' + props.id

    if (props.onClick === undefined) {
        return (
            <>
                <span
                    id={id}
                    className="m-1 PielersBadge rounded-pill"
                    style={{ backgroundColor: props.bg, color: props.tg }}
                >
                    {props.text}
                </span>
            </>
        )
    } else {
        return (
            <>
                <span
                    id={id}
                    className="m-1 PielersBadge rounded-pill"
                    style={{ backgroundColor: props.bg, color: props.tg }}
                    onClick={(e) => props.onClick(e, props.i)}
                >
                    {props.text}
                </span>
            </>
        )
    }
}

export default IssueBadge
