import React from 'react'
import Home from '../../elements/acquisition/Home'
import AllChannels from '../../elements/acquisition/AllChannels'
import SearchEnginesAndSearchTerms from '../../elements/acquisition/SearchEnginesAndSearchTerms'
import WebPages from '../../elements/acquisition/WebPages'
import SocialNetworks from '../../elements/acquisition/SocialNetworks'
import Campaigns from '../../elements/acquisition/Campaigns'
import CampaignUrlGenerator from '../../elements/acquisition/CampaignUrlGenerator'
import { LanguageSwitcherValue } from '../../components/LanguageSwitcher'

const AcquisitionConfig = () => {
    let config = [
        {
            tab: {
                defaultActiveKey: 'home',
                id: 'uncontrolled-tab-example',
                className: 'mb-3 tabMatomo',
                key: 'tabindex'
            },
            tabs: [
                {
                    eventKey: 'home',
                    title: LanguageSwitcherValue(
                        'AcquisitionConfig.home.title'
                    ),
                    key: 'home',
                    id: 'home',
                    class: 'tabsMatomo',
                    content: <Home />
                },
                {
                    eventKey: 'all_channels',
                    title: LanguageSwitcherValue(
                        'AcquisitionConfig.all_channels.title'
                    ),
                    key: 'all_channels',
                    id: 'all_channels',
                    class: 'tabsMatomo',
                    content: <AllChannels />
                },
                {
                    eventKey: 'search_engines_and_search_terms',
                    title: LanguageSwitcherValue(
                        'AcquisitionConfig.search_engines_and_search_terms.title'
                    ),
                    key: 'search_engines_and_search_terms',
                    id: 'search_engines_and_search_terms',
                    class: 'tabsMatomo',
                    content: <SearchEnginesAndSearchTerms />
                },
                {
                    eventKey: 'web_pages',
                    title: LanguageSwitcherValue(
                        'AcquisitionConfig.web_pages.title'
                    ),
                    key: 'web_pages',
                    id: 'web_pages',
                    class: 'tabsMatomo',
                    content: <WebPages />
                },
                {
                    eventKey: 'social_networks',
                    title: LanguageSwitcherValue(
                        'AcquisitionConfig.social_networks.title'
                    ),
                    key: 'social_networks',
                    id: 'social_networks',
                    class: 'tabsMatomo',
                    content: <SocialNetworks />
                },
                {
                    eventKey: 'campaigns',
                    title: LanguageSwitcherValue(
                        'AcquisitionConfig.campaigns.title'
                    ),
                    key: 'campaigns',
                    id: 'campaigns',
                    class: 'tabsMatomo',
                    content: <Campaigns />
                },
                {
                    eventKey: 'campaign_url_generator',
                    title: LanguageSwitcherValue(
                        'AcquisitionConfig.campaign_url_generator.title'
                    ),
                    key: 'campaign_url_generator',
                    id: 'campaign_url_generator',
                    class: 'tabsMatomo',
                    content: <CampaignUrlGenerator />
                }
            ]
        }
    ]

    return config
}

export default AcquisitionConfig
