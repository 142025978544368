import IntlMessage from '../../../../../../../@WUM/core/component/const/IntlMessage'
import React from 'react'

function configuration(conf: string, response: any, Store: any) {
    const information = [
        {
            formType: 'header',
            value: (
                <IntlMessage Store={Store} messageId="common.cover_picture" />
            )
        },

        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'imageMerchant',
                    name: '',
                    id: 'images.title.path',
                    class: 'img-fluid merchantTitle',
                    path:
                        response.images !== undefined &&
                        response.images.hasOwnProperty('title')
                            ? response.images.title.path
                            : ' '
                }
            ],
            class: 'text-center'
        },
        {
            formType: 'formFileBucket',
            onclick: true,
            name: 'images.title.path_FormFile',
            id: 'images.title.path_FormFile',
            onclick_context: 'title',
            append: '',
            merchant_id: response.merchant_id,
            Buckettype: true,
            src: 'images.title.path',
            type: 'file',
            size: {
                xl: 12,
                md: 12,
                sm: 12
            }
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'header',
                    value: <IntlMessage Store={Store} messageId="common.logo" />
                },
                {
                    formType: 'header',
                    value: (
                        <IntlMessage
                            Store={Store}
                            messageId="common.profile_picture"
                        />
                    )
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'imageMerchant',
                    name: '',
                    id: 'images.logo.path',
                    class: 'img-thumbnail ',
                    path:
                        response.images !== undefined &&
                        response.images.hasOwnProperty('logo')
                            ? response.images.logo.path
                            : ' ',
                    size: {
                        xl: 6,
                        md: 6,
                        sm: 6
                    }
                },
                {
                    formType: 'imageMerchant',
                    name: '',
                    id: 'images.profile.path',
                    class: 'img-thumbnail ',
                    path:
                        response.images !== undefined &&
                        response.images.hasOwnProperty('profile')
                            ? response.images.profile.path
                            : ' ',
                    size: {
                        xl: 6,
                        md: 6,
                        sm: 6
                    }
                }
            ]
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'formFileBucket',
                    onclick: true,
                    name: 'images.logo.path_FormFile',
                    id: 'images.logo.path_FormFile',
                    onclick_context: 'logo',
                    append: '',
                    merchant_id: response.merchant_id,
                    Buckettype: true,
                    src: 'images.logo.path',
                    type: 'file',
                    size: {
                        xl: 6,
                        md: 6,
                        sm: 6
                    }
                },
                {
                    formType: 'formFileBucket',
                    onclick: true,
                    name: 'images.profile.path_FormFile',
                    id: 'images.profile.path_FormFile',
                    onclick_context: 'profile',
                    append: '',
                    merchant_id: response.merchant_id,
                    Buckettype: true,
                    src: 'images.profile.path',
                    type: 'file',
                    size: {
                        xl: 6,
                        md: 6,
                        sm: 6
                    }
                }
            ]
        }
    ]

    if (conf === 'image') {
        return information
    }
}

export default configuration
