import React from 'react'
import Home from '../../elements/targets/Home'
import SEO from '../../elements/targets/SEO'
import Insights from '../../elements/targets/Insights'
import { LanguageSwitcherValue } from '../../components/LanguageSwitcher'

const TargetsConfig = () => {
    let config = [
        {
            tab: {
                defaultActiveKey: 'home',
                id: 'uncontrolled-tab-example',
                className: 'mb-3 tabMatomo',
                key: 'tabindex'
            },
            tabs: [
                {
                    eventKey: 'home',
                    title: LanguageSwitcherValue('TargetsConfig.home.title'),
                    key: 'home',
                    id: 'home',
                    class: 'tabsMatomo',
                    content: <Home />
                },
                {
                    eventKey: 'seo',
                    title: LanguageSwitcherValue('TargetsConfig.seo.title'),
                    key: 'seo',
                    id: 'seo',
                    class: 'tabsMatomo',
                    content: <SEO />
                },
                {
                    eventKey: 'insights',
                    title: LanguageSwitcherValue(
                        'TargetsConfig.insights.title'
                    ),
                    key: 'insights',
                    id: 'insights',
                    class: 'tabsMatomo',
                    content: <Insights />
                }
            ]
        }
    ]

    return config
}

export default TargetsConfig
