import React from 'react'
import IntlMessage, {
    IntlMessageValue
} from '../../../../../../@WUM/core/component/const/IntlMessage'

const configuration = (
    conf: string,
    response: any,
    newElement: any = false,
    Store: any,
    formfield?: any,
    CustomType?: any
) => {
    let config = []

    if (formfield !== undefined && formfield !== null) {
        let holder =
            '[{"formType": "input","type": "hidden", "name": "id","value": ' +
            (newElement !== true ? response.id : '0') +
            '},'

        if (newElement === true) {
            holder +=
                '{"formType": "input","type": "hidden", "name": "status","value": "' +
                CustomType.status +
                '"},{"formType": "input","type": "hidden", "name": "locale","value": "' +
                CustomType.locale +
                '"},{"formType": "input","type": "hidden", "name": "typeId","value": "' +
                CustomType.id +
                '"},{"formType": "input","type": "hidden", "name": "blueprint","value": "' +
                CustomType.blueprint +
                '"},{"formType": "input","type": "hidden", "name": "type","value": "' +
                CustomType.type +
                '"},{"formType": "input","type": "hidden", "name": "template","value": "' +
                CustomType.template +
                '"},'
        }

        for (let x = 0; x < formfield.length; x++) {
            if (x === formfield.length - 1) {
                holder += makeEndField(formfield, x, response, newElement)
            } else {
                holder += makeField(formfield, x, response, newElement)
            }
        }

        holder += ']'

        config = JSON.parse(holder)
    } else {
        config.push(
            {
                formType: 'input',
                type: 'hidden',
                name: 'id',
                value: newElement === false ? response.id : 0
            },
            {
                formType: 'multiply',
                cols: [
                    {
                        formType: 'input',
                        label: 'url',
                        type: 'text',
                        required: true,
                        placeholder: 'Bitte url eingeben',
                        name: 'url',
                        value: newElement === false ? response.url : ''
                    },
                    {
                        formType: 'input',
                        label: 'target',
                        type: 'text',
                        required: true,
                        placeholder: 'Bitte Ziel der umleitung angeben',
                        name: 'target',
                        value: newElement === false ? response.target : ''
                    },
                    {
                        formType: 'select',
                        label: 'Umleitungsmethode',
                        name: 'method',
                        selected: newElement === false ? response.method : 0,
                        options: [
                            {
                                label: '301 permanent umgeleitet',
                                value: '301'
                            },
                            {
                                label: '302 temporär umgeleitet',
                                value: '302'
                            }
                        ]
                    },
                    {
                        formType: 'select',
                        label: (
                            <IntlMessage
                                Store={Store}
                                messageId="common.visible"
                            />
                        ),
                        name: 'active',
                        selected: newElement === false ? response.active : 0,
                        options: [
                            {
                                label: IntlMessageValue(
                                    'de',
                                    'common.activated',
                                    Store
                                ),
                                value: '1'
                            },
                            {
                                label: IntlMessageValue(
                                    'de',
                                    'common.deactivated',
                                    Store
                                ),
                                value: '0'
                            }
                        ]
                    }
                ]
            }
        )
    }
    /* console.log(config)*/
    return config
}

export default configuration

const escapeHtml = (unsafe: any) => {
    if (typeof unsafe === 'string') {
        return unsafe.replace(/"/g, '\\"')
    } else {
        return unsafe
    }
}

const sunEditor = (value: any, name: any, label: any) => {
    return (
        '{"formType": "suneditor","data": {"lang": "de","height": "400px"},"name": "data[' +
        name +
        ']","jsonID":"","height": "400px","id": "","appendJson": "","appendJsonField": "","label": "' +
        label +
        '","value": "' +
        escapeHtml(value) +
        '"}'
    )
}

const media = (name: any, image_id: any, cdn_url: any, required: any) => {
    //  console.log(required)
    let convert = {
        formType: 'multiply',
        cols: [
            {
                formType: 'image',
                altLink: true,
                id: 'image_title_image_' + name,
                path: cdn_url,
                width: '44',
                height: '44',
                class: 'elementsIMg',
                size: {
                    xl: 2,
                    md: 2,
                    sm: 2
                }
            },
            {
                formType: 'formFile',
                onclick: true,
                id: 'image_titleFormField' + name,
                onclick_context: '',
                Gallery: '',
                galerieItems: '',
                expandfile: name + 'galerie',
                experiment: true,
                append: 'data[' + name + '][Media]',
                src: 'image_title_image_' + name,
                size: {
                    xl: 10,
                    md: 10,
                    sm: 10
                }
            },
            {
                formType: 'input',
                name: 'data[' + name + '][Media]',
                id: 'data[' + name + '][Media]',
                visible: true,
                required: required,
                label: 'imageID',
                type: 'text',
                placeholder: 'imageID',
                value: image_id
            }
        ]
    }
    return JSON.stringify(convert)
}

const makeField = (formfield: any, x: any, response: any, newElement: any) => {
    switch (formfield[x].formType) {
        case 'suneditor':
            return (
                sunEditor(
                    newElement !== true ? response.data[formfield[x].name] : '',
                    formfield[x].name,
                    formfield[x].label
                ) + ','
            )
        case 'media':
            return (
                media(
                    formfield[x].name,
                    newElement !== true &&
                        response.data[formfield[x].name] !== undefined
                        ? response.data[formfield[x].name].Media
                        : '',
                    newElement !== true &&
                        response.data[formfield[x].name] !== undefined
                        ? response.data[formfield[x].name].cdnurl
                        : '',
                    formfield[x].required
                ) + ','
            )
        default:
            if (formfield[x].name === 'slug') {
                return (
                    '{"formType": "' +
                    formfield[x].formType +
                    '","required":"' +
                    formfield[x].required +
                    '","type": "' +
                    formfield[x].type +
                    '", "preText": "' +
                    formfield[x].preText +
                    '", "afterText" : "' +
                    formfield[x].afterText +
                    '","label": "' +
                    formfield[x].label +
                    '","name": "' +
                    formfield[x].name +
                    '","value": "' +
                    escapeHtml(
                        newElement !== true ? response[formfield[x].name] : ''
                    ) +
                    '"},'
                )
            } else {
                return (
                    '{"formType": "' +
                    formfield[x].formType +
                    '","required":"' +
                    formfield[x].required +
                    '","type": "' +
                    formfield[x].type +
                    '", "preText": "' +
                    formfield[x].preText +
                    '", "afterText" : "' +
                    formfield[x].afterText +
                    '","label": "' +
                    formfield[x].label +
                    '","name": "data[' +
                    formfield[x].name +
                    ']","value": "' +
                    escapeHtml(
                        newElement !== true
                            ? response.data[formfield[x].name]
                            : ''
                    ) +
                    '"},'
                )
            }
    }
}

const makeEndField = (
    formfield: any,
    x: any,
    response: any,
    newElement: any
) => {
    switch (formfield[x].formType) {
        case 'suneditor':
            return sunEditor(
                newElement !== true ? response.data[formfield[x].name] : '',
                formfield[x].name,
                formfield[x].label
            )
        case 'media':
            return (
                ',' +
                media(
                    formfield[x].name,
                    newElement !== true &&
                        response.data[formfield[x].name] !== undefined
                        ? response.data[formfield[x].name].Media
                        : '',
                    newElement !== true &&
                        response.data[formfield[x].name] !== undefined
                        ? response.data[formfield[x].name].cdnurl
                        : '',
                    formfield[x].required
                )
            )
        default:
            if (formfield[x].name === 'slug') {
                return (
                    '{"formType": "' +
                    formfield[x].formType +
                    '","required":"' +
                    formfield[x].required +
                    '","type": "' +
                    formfield[x].type +
                    '", "preText": "' +
                    formfield[x].preText +
                    '", "afterText" : "' +
                    formfield[x].afterText +
                    '","label": "' +
                    formfield[x].label +
                    '","name": "' +
                    formfield[x].name +
                    '","value": "' +
                    escapeHtml(
                        newElement !== true ? response[formfield[x].name] : ''
                    ) +
                    '"}'
                )
            } else {
                return (
                    '{"formType": "' +
                    formfield[x].formType +
                    '","required":"' +
                    formfield[x].required +
                    '","type": "' +
                    formfield[x].type +
                    '", "preText": "' +
                    formfield[x].preText +
                    '", "afterText" : "' +
                    formfield[x].afterText +
                    '","label": "' +
                    formfield[x].label +
                    '","name": "data[' +
                    formfield[x].name +
                    ']","value": "' +
                    escapeHtml(
                        newElement !== true
                            ? response.data[formfield[x].name]
                            : ''
                    ) +
                    '"}'
                )
            }
    }
}
