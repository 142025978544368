import React from 'react'
import TextBlocksOverview from './Pages/overview'
import TextBlocksDetail from './Pages/Detail'
import TextBlocksNew from './Pages/new'

export const DynamikTextBlocksPagesConfig = (value: any) => {
    return [
        {
            path: '/TextBlocksdynamik/overview/:page',
            elements: <TextBlocksOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/TextBlocksdynamik/Detail/:id',
            elements: <TextBlocksDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/TextBlocksdynamik/new/new',
            elements: <TextBlocksNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/TextBlocksdynamik/new/:page',
            elements: <TextBlocksNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}

export const DynamikTextBlocksMenuConfig = (value: any) => {
    return [
        {
            name: 'Textblocks',
            route: '/TextBlocksdynamik/overview/1',
            icon: 'text_snippet',
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}
export const DynamikTextBlocksDasboard = () => [
    {
        title: 'cms.textblocks.header',
        href: '/TextBlocksdynamik/overview/1',
        undertitle: 'CMS',
        icon: 'text_snippet',
        text: 'cms.textblocks.description',
        roles: ['superadmin', 'admin']
    }
]
