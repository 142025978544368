import { requests } from '../../../../../../@WUM/core/RestFullApi/api'
import { OrdersApiType } from './OrdersAPI.interface'
import { OrdersDetailApiType } from './OrdersDetailAPI.interface'
import { OrdersMerchantDetailApiType } from './OrdersDetailMerchantAPI.interface'

export const orders = {
    getPosts: (limit: string, searchValue: any): Promise<OrdersApiType[]> =>
        requests.get(`Orders/AllOrders/${limit}/${searchValue}`),
    getPostsMerchant: (id: string, limit: string, searchValue: any): Promise<OrdersApiType[]> =>
        requests.get(`channel/merchant/orders/${id}/${limit}/${searchValue}`),
    getAPost: (id: string | undefined): Promise<OrdersDetailApiType> =>
        requests.get(`/Orders/Order/${id}/`),
    getAPostMerchant: (
        id: string | undefined
    ): Promise<OrdersMerchantDetailApiType> =>
        requests.get(`/channel/merchant/order/${id}/`),
    createPost: (post: OrdersApiType): Promise<OrdersDetailApiType> =>
        requests.post('posts', post),
    updatePost: (post: any, id: number): Promise<OrdersDetailApiType> =>
        requests.put(`/Orders/${id}/Emails/`, post),
    deletePost: (id: number): Promise<void> => requests.delete(`posts/${id}`),
    getPostsStatust: (post: any, id: number): Promise<void> =>
        requests.put(`/Orders/Order/${id}/`, post)
}
