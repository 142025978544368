import React from 'react'
import TaxonOverview from './Pages/overview'
import TaxonDetail from './Pages/Detail'
import TaxonNew from './Pages/new'
import { LanguageSwitcherValue } from '../../shop/core/components/LanguageSwitcher'

export const TaxonPagesConfig = (value: any) => {
    return [
        {
            path: '/Taxon/overview/:page',
            elements: <TaxonOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Taxon/Detail/:id',
            elements: <TaxonDetail value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Taxon/Delete/:page/:deleteID',
            elements: <TaxonOverview value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        },
        {
            path: '/Taxon/new/new',
            elements: <TaxonNew value={value} />,
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}

export const TaxonMenuConfig = (value: any) => {
    return [
        {
            name: LanguageSwitcherValue('settings.taxon.header'),
            route: '/Taxon/overview/1',
            icon: 'category',
            roles: ['superadmin', 'admin', 'operator']
        }
    ]
}
