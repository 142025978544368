import React from 'react'
import { useParams } from 'react-router-dom'
import { ResponseCustomTypeDelete } from '../API/response/customTypes'

const CustomTypeDeleted = (value: any) => {
    const { deleteID } = useParams<{ page: string; deleteID: string }>()
    ResponseCustomTypeDelete(deleteID)
    let landingpages = '/customTypes/overview/1'

    setTimeout(() => (window.location.href = landingpages), 3000)
    return <></>
}
export default CustomTypeDeleted
