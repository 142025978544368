import TableCell from '@material-ui/core/TableCell'
import React from 'react'

export const time = (
    text: string,
    id: any,
    index: number,
    VisitData: any,
    VisitDataDel: any,
    Store: any,
    tableBodyPositionElement: any
) => {
    if (text === '' || text === null) text = '00:00:00'
    return (
        <>
            <TableCell
                style={{ textAlign: tableBodyPositionElement }}
                key={'td' + id + '_' + index}
                data-param={VisitData}
                data-param-del={VisitDataDel}
                data-id={id}
                id={'td' + id + '_' + index}
            >
                {text}
            </TableCell>
        </>
    )
}
