import React from 'react'
import Image from 'react-bootstrap/Image'

function image(data: any) {
    let link

    if (data.altLink !== null && data.altLink !== undefined) {
        link =
            process.env.REACT_APP_MODE === 'LOCAL'
                ? data.path !== undefined
                    ? data.path.replace(
                          process.env.REACT_APP_CDN_URL,
                          process.env.REACT_APP_CDN_URL_LOCAL
                      )
                    : data.path
                : data.path
    } else {
        link =
            'https://d12pljs2iucqg.cloudfront.net/media/cache/marketplace_product_thumbnail/' +
            data.path
    }

    let width = ''
    if (data.width !== undefined) {
        width = data.width
    } else {
        width = 'auto'
    }

    let height = ''
    if (data.height !== undefined) {
        height = data.height
    } else {
        height = 'auto'
    }

    let att: string = ''
    if (data.class !== undefined) {
        att = data.class
    }

    let id: string = ''
    if (data.id !== undefined) {
        id = data.id
    }

    if (data.type !== undefined && data.type === 'video') {
        return (
            <>
                <br />
                <video id={id} width={width} height={height} controls>
                    <source src={link} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </>
        )
    } else if (data.type !== undefined && data.type === 'pdf') {
        return (
            <>
                <object
                    data={link}
                    width={width}
                    height={height}
                    aria-label={'pdf'}
                />
            </>
        )
    } else {
        return (
            <>
                <br />
                <Image
                    src={link}
                    id={id}
                    className={att}
                    style={{ width: width, height: height }}
                />
            </>
        )
    }
}

export default image
