import React from 'react'
import { Response } from '../../../API/extern/mandrill/response/outbound'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from 'react-bootstrap/Button'
import { IntlMessageValue } from '../../../../../@WUM/core/component/const/IntlMessage'
import { useSelector } from 'react-redux'

const MonitoringSystemAPIOutbound = (value: any) => {
    const response = Response(process.env.REACT_APP_EMAIL)
    const Store = useSelector((state: any) => state)
    const setContent = (id: any) => {
        // @ts-ignore
        document
            .getElementById('FrameContent')
            .setAttribute('src', '/Monitoring/email/detail/' + id + '')
        // @ts-ignore
        document.getElementById('Content').style.display = 'block'
    }

    let row = []

    const closeModal = () => {
        // @ts-ignore
        document.getElementById('Content').style.display = 'none'
    }

    for (let x = 0; x < response.length; x++) {
        row.push(
            <>
                <TableRow>
                    <TableCell>
                        {response[x].state}
                        <br />
                        {
                            response[x]['@timestamp']
                                .replace('T', ' ')
                                .split('.')[0]
                        }
                    </TableCell>
                    <TableCell>{response[x].sender}</TableCell>
                    <TableCell>{response[x].email}</TableCell>
                    <TableCell>{response[x].subject}</TableCell>
                    <TableCell>
                        <Button
                            variant="primary"
                            onClick={() => setContent(response[x]._id)}
                        >
                            View Content
                        </Button>
                    </TableCell>
                    <TableCell>{response[x].opens}</TableCell>
                    <TableCell>{response[x].clicks}</TableCell>
                    {/*<TableCell>
            <Button variant="primary"

            >
              Resend  (Cooming Soon)
            </Button>
          </TableCell>*/}
                </TableRow>
            </>
        )
    }

    return (
        <>
            <div style={{ backgroundColor: 'white', padding: '15px' }}>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {IntlMessageValue(
                                        value,
                                        'common.email.status',
                                        Store
                                    )}
                                </TableCell>
                                <TableCell>
                                    {IntlMessageValue(
                                        value,
                                        'common.email.sender',
                                        Store
                                    )}
                                </TableCell>
                                <TableCell>
                                    {IntlMessageValue(
                                        value,
                                        'common.email.email',
                                        Store
                                    )}
                                </TableCell>
                                <TableCell>
                                    {IntlMessageValue(
                                        value,
                                        'common.email.subject',
                                        Store
                                    )}
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    {IntlMessageValue(
                                        value,
                                        'common.email.opens',
                                        Store
                                    )}
                                </TableCell>
                                <TableCell>
                                    {IntlMessageValue(
                                        value,
                                        'common.email.clicks',
                                        Store
                                    )}
                                </TableCell>
                                {/*<TableCell></TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>{row}</TableBody>
                    </Table>
                </Paper>
            </div>
            <div
                id={'Content'}
                className={'modalviewContent'}
                style={{ display: 'none' }}
            >
                <p className={'closeModal'} onClick={() => closeModal()}>
                    X
                </p>
                <iframe
                    id={'FrameContent'}
                    title={'Email Content'}
                    width={'100%'}
                    height={'100%'}
                    src=""
                />
            </div>
        </>
    )
}

export default MonitoringSystemAPIOutbound
